@import "nbui/utilities";
@import "variables";
@import "typography";
@import "mixins";

.btn {
  border-radius: $border-radius-default;

  color: $primary-white;
  font-size: $font-btn-regular;

  margin: 0 $margin-default-12 0 $margin-default-12;

  @media screen and (max-width: 1200px) {
    margin: 0 $margin-xsmall-3 0 $margin-xsmall-3;
  }
}

.btn-secondary {
  color: $primary;
  background-color: $primary-navy-95;

  &:hover {
    color: $primary;
  }
}

.btn__regular {
  max-width: 200px !important;

  @media screen and (min-width: 768px) {
    min-width: 100px;
    min-height: 40px;
  }

  @media screen and (min-width: 1200px) {

    min-width: 120px;
    line-height: 150%;
  }

}

.btn__icon {
  border-radius: $border-radius-default;
  border: solid 0.071em; // 1px;
  color: $primary-white;

  height: 30px;
  width: 30px;

  @media screen and (min-width: 1200px) {
    height: 40px;
    width: 40px;
  }

  & img {
    height: 15px;
    width: 15px;

    @media screen and (min-width: 1200px) {
      height: 20px;
      width: 20px;
    }
  }

  &--round {
    &-always {
      border-radius: $border-radius-round;
      width: 2.2rem !important;
      height: 2.2rem !important;
      align-items: unset;
    }

    &-desktop {
      @include media-breakpoint-up(sm) {
        border-radius: $border-radius-round;
        width: 2rem !important;
        height: 2rem !important;
        align-items: unset;
      }
    }
  }
}

.btn__regular-icon {
  background-color: $primary-off-blue;
  border-radius: $border-radius-default;
  border: none !important;

  &--active {
    &-green {
      background-color: $primary-active-green;
      border: none !important;

      svg {
        fill: #008843;

        @media screen and (max-width: 1200px) {
          height: 13px;
        }
      }

      &:disabled {
        background-color: $primary-disabled;
        opacity: 1 !important;
        border: none !important;

        svg {
          fill: #A6ACAF;

          @media screen and (max-width: 1200px) {
            height: 13px;
          }
        }
      }
      &:hover {
        background-color: $primary-active-green !important;
        border: none !important;
      }
      &:focus {
        background-color: $primary-active-green !important;
        border: none !important;
      }
      &:active {
        border: none !important;
      }
    }

    &-blue {
      background-color: $primary-active-blue;
      border: none !important;

      svg {
        fill: #103783;

        @media screen and (max-width: 1200px) {
          height: 13px;
        }
      }

      &:disabled {
        background-color: $primary-disabled;
        opacity: 1 !important;
        border: none !important;

        svg {
          fill: #A6ACAF;

          @media screen and (max-width: 1200px) {
            height: 13px;
          }
        }
      }

      &:hover {
        background-color: $primary-active-blue !important;
        border: none !important;
      }
      &:focus {
        background-color: $primary-active-blue !important;
        border: none !important;
      }
      &:active {
        border: none !important;
      }
    }

    &-red {
      background-color: $primary-active-red;

      svg {
        fill: #AB2930;
      }

      &:disabled {
        background-color: $primary-disabled;
        opacity: 1 !important;

        svg {
          fill: #A6ACAF;
        }
      }
    }
  }

  &:disabled {
    background-color: $primary-disabled;
    opacity: 1 !important;
    border: none !important;

    svg {
      fill: #A6ACAF;
    }
  }
}

.btn__table {
  width: 93px !important;
}

.btn__secondary-icon {
  background-color: $theme-primary-dark-blue;
  color: white;
}

.btn__green-icon {
  background-color: #bfdecc;
  border-radius: $border-radius-default;
  width: auto;
}

.btn__red-icon {
  background-color: #ffe9ea;
  border-radius: $border-radius-default;
  width: auto;
}

.btn__send-email {
  background-color: rgba($success-100, 0.6);
  color: $success-500;
  border: none
}

.btn__stretched-icon {
  background-color: $theme-nb-blue;
  border-radius: $border-radius-default;
  width: auto;

  & img {
    width: auto;
  }

  &:hover {
    background-color: $primary-off-blue;
    color: $theme-primary-dark-blue;

    img {
      filter: brightness(0) saturate(100%) invert(14%) sepia(59%) saturate(3435%) hue-rotate(217deg) brightness(93%) contrast(97%);
    }
  }
}

.btn_padding {
  padding: 0 14px !important;
}

.btn__sm {
  height: 32px;
  width: 80px;
  font-size: $font-sm-14;
  padding: ($padding-default-12/2) $padding-default-12;
  line-height: 140%;

}

.btn__md {
  font-size: $font-mobile-size-p;

  @media screen and (min-width: 768px) {
    width: 60px;
    height: 30px;
  }

  @media screen and (min-width: 1200px) {
    width: 120px;
    height: 35px;
  }

  padding: ($padding-default-12/2) $padding-default-12;
  line-height: 150%;
}

.btn__primary {
  background-color: $theme-primary-dark-blue;
  border: none;
}

.btn__primary:active {
  background-color: $theme-primary-dark-blue;
}

.btn__primary_navy_light {
  border-radius: $input-border-radius;
  background-color: $primary-navy-99;
  color: $primary-navy-05;
  border: none;

  &:hover {
    background-color: $primary-navy-95;
  }
}

.btn__secondary {
  background-color: $theme-secondary-blue;
}

.btn__secondary_action {
  background-color: $theme-secondary-blue;
  color: $primary-white;
  border: none;
}

.btn__tertiary {
  background-color: $button-tertiary-color;
}

.btn_back_button {
  color: $theme-primary-dark-blue;
  background-color: $primary-off-blue;
  border-radius: $border-radius-default;
  border: none;
}

.btn__warning {
  background-color: #f3cfd1 !important;
  color: #ab2930 !important;
}

.btn__yes {
  background-color: rgba($success-100, 0.6) !important;
  color: $success-500 !important;
  border: none !important;

  &:hover {
    background-color: rgba($success-100, 0.8) !important;
    color: $success-500 !important;
    border: none !important;
  }
}

.btn__roomoptions {
  background-color: $monotone-100 !important;
  color: $primary-navy-05 !important;
  border: 1px solid $primary-navy-05;
  margin: 0 !important;

  &:hover {
    background-color: $primary-navy-95 !important;
    border: 0.5px solid $primary !important;
  }
}

.btn__outlined {
  background-color: $monotone-100 !important;
  color: $primary-navy-05 !important;
  border: 1px solid $primary-navy-05;

  &:hover {
    background-color: $primary-navy-95 !important;
    border: 0.5px solid $primary !important;
  }
}

.btn__no {
  background-color: rgba($error-100, 0.6) !important;
  color: $error-60 !important;
  border: none !important;

  &:hover {
    background-color: rgba($error-100, 0.8) !important;
    color: $error-60 !important;
    border: none !important;
  }
}



.btn__green {
  background-color: $theme-success-green-dark !important;
  color: $primary-white !important;
}

.btn__red {
  background-color: $primary-dark-red;
}

.btn__green-confirm-icon {
  background-color: $primary-active-green;
  border: none;

  svg {
    fill: $theme-success-green-dark;
  }
}

.btn__red-cancel-icon {
  background-color: $primary-active-red;

  svg {
    fill: $primary-dark-red;
  }
}

.btn__green:hover,
.btn__regular-icon:hover,
.btn__warning:hover,
.btn_back_button:hover,
.btn__tertiary:hover,
.btn__secondary-icon:hover,
.btn__green-confirm-icon:hover,
.btn__red-cancel-icon:hover {
  opacity: 0.5;
}

.btn__green[disabled],
.btn__regular-icon[disabled],
.btn__warning[disabled],
.btn_back_button[disabled],
.btn__tertiary[disabled],
.btn__secondary_action[disabled],
.btn__secondary[disabled],
.btn__primary[disabled],
.btn__secondary-icon[disabled],
.btn__green-confirm-icon[disabled] {
  color: #555;
  background-color: #E9E9E9;
  opacity: 1;
  border: none !important;
  cursor: default;

  svg {
    fill: #555;
  }
}

.btn__secondary_action:hover,
.btn__secondary:hover,
.btn__primary:hover {
  background-color: $primary-off-blue;
  color: $theme-primary-dark-blue;
}

.btn__fullWidth {
  width: 100%;
  width: -webkit-fill-available;
  width: stretch;
  max-width: unset !important;
}

.btn__minWidth {
  padding: 0 5px;
  max-width: unset !important;
  min-width: unset !important;
}

.btn__badge {
  position: relative;

  &--icon {
    width: 26px;
    height: 26px;
    display: block;
    border-radius: 50%;
    background-color: #F3CFD1;
    position: absolute;
    right: -13px;
    top: -13px;

    svg {
      height: 18px;
      width: 14px;
      fill: #AB2930;
      @include absolute-center;
    }
  }

  .badge-info {
    background-color: $primary-aqua-90;

    svg{
    fill: $primary-navy-05;
    }
  }
}

.btn__badge_responsive {
  position: relative;

  &--icon {
    width: 26px;
    height: 26px;
    display: block;
    border-radius: 50%;
    background-color: #F3CFD1;
    position: absolute;
    right: -5px;
    top: -5px;

    svg {
      height: 18px;
      width: 14px;
      fill: #AB2930;
      @include absolute-center;
    }
  }
}

.btn__badge_warning {
  position: relative;

  &--icon {
    width: 26px;
    height: 26px;
    display: block;
    border-radius: 50%;
    background-color: $info-600;
    position: absolute;
    right: -13px;
    top: -13px;

    svg {
      height: 18px;
      width: 14px;
      fill: $info-800;
      @include absolute-center;
    }
  }
}

.btn__badge_responsive_warning {
  position: relative;

  &--icon {
    width: 26px;
    height: 26px;
    display: block;
    border-radius: 50%;
    background-color: $info-600;
    position: absolute;
    right: -8px;
    top: -5px;

    svg {
      height: 18px;
      width: 14px;
      fill: $info-800;
      @include absolute-center;
    }
  }
}

.btn__badge_responsive{}

.btn__tool-tip {
  margin: 0;
  position: absolute;
  right: 13px;
  bottom: 19px;
}

::ng-deep .no-spinner ::-webkit-inner-spin-button,
.no-spinner ::-webkit-outer-spin-button {
  display: none;
}
