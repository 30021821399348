/*
* Theme / Colours
* Semantic naming for primary, secondary, tertiary, danger, info, success, warning
* Core colors - ESSENTIAL WEIGHTS
*/

$primary-navy-05: #00236F;
$primary-navy-10: #0B3187;
$primary-navy-20: #113A97;
$primary-navy-30: #1843A7;
$primary-navy-50: #2656C8;
$primary-navy-60: #466FD1;
$primary-navy-90: #C4D5FE;
$primary-navy-99: #F5F8FE;
$primary-navy-95: #E8EFFF;
$primary-90: #C4D5FE;
$primary-off-blue: #E4F1FC;

$secondary-800: #C8D2EB;
$secondary-600: #CCD7EE;
$secondary-400: #D5DFF3;
$secondary-200: #E8EFFF;
$secondary-100: #E6E9F1;

$primary-aqua-10: #00A2D7;
$primary-aqua-80: #8DD8F1;
$primary-aqua-90: #A7E2F6;
$primary-aqua-95: #BDEBFB;
$primary-aqua-99: #D7F5FF;

$primary-aqua-20: #0EA7DA;
$primary-aqua-60: #5FC6E8;


$neutral-800: #4D4D4D;
$neutral-700: #919396;
$neutral-600: #EDECEB;
$monotone-80: #F5F7FB;
$monotone-90: #FBFCFE;
$monotone-50: #E7E9ED;
$neutral-200: #FBFBFB;
$monotone-100: #FFFFFF;

$monotone-40: #B3B6C2;

$danger-800: #410002;
$danger-600: #861816;
$danger-400: #BA1A1A;
$danger-200: #FF8080;
$danger-100: #F0DEE0;

$error-10: #650000;
$error-50: #ED6565;
$error-60: #A00101;
$error-70: #B50E0E;
$error-100: #FFDAD6;
$error-110: #FFF5F4;
$error-120: #FFE5E5;
$error-140: #F3CFD1;

$alert-100: #FFE1B5;
$info-800: #663904;
$info-600: #FFC142;
$info-400: #FFE18E;

$success-10: #014420;
$success-70: #0EB551;
$success-100: #B0F3B7;
$success-500: #007235;

$warning-800: #250569;
$warning-600: #930188;
$warning-400: #CCA5FD;
$warning-200: #FCE3FF;
$warning-300: #9C5400;
$warning-700: #FFAB00;

$A30: #9C5400;
$A70: #FFAB00;
$A100: $alert-100;

$secondary-violet-10: #480670;
$secondary-violet-70: #9753EB;
$secondary-violet-100: #F0E4FF;

$monotone-90: #FBFCFE;
$monotone-80: #F5F7FB;
$monotone-40: #B3B6C2;
$monotone-30: #888B9C;
$monotone-20: #23252B;
$monotone-10: #191A1D;

/* Custom color utilities */
.color-primary {
  background-color: $primary-navy-05;
}

.color-primary-light {
  background-color: $primary-navy-99;
}

.color-primary-aqua-10 {
  color: $primary-aqua-10;
}

.color-tertiary {
  background-color: $primary-aqua-95;
}

.color-primary-light-text {
  color: $primary-navy-99;
}

.color-primary-text {
  color: $monotone-100;
}

.color-secondary {
  background-color: $secondary-200;
}

.color-secondary-text {
  color: $primary-navy-05;
}

.color-warning {
  background-color: $warning-400;
}

.color-warning-text {
  color: $warning-800;
}

.color-warning-icon-light {
  color: $warning-300;
}

.color-warning-text-light {
  color: $warning-300;
  &:hover {
    color: $info-800 !important;
  }
  &:active {
    color: $info-800 !important;
  }
  &:focus {
    color: $info-800 !important;
  }
}

.color-warning-secondary {
  background-color: $warning-200;
}

.color-warning-secondary-text {
  color: $warning-600;
}

.color-info {
  background-color: $info-400;
}

.color-info-light {
  background-color: $alert-100;
  &:hover {
    background-color: $alert-100;
  }
  &:active {
    background-color: $alert-100 !important;
    border: none !important;
  }
  &:focus {
    background-color: $alert-100 !important;
    border: none !important;
  }
  &:disabled {
    background-color: $alert-100;
    opacity: 50%;
    border: none;
    color: $info-800 !important;
  }
}

.color-info-secondary {
  background-color: $info-600;
}

.color-info-text {
  color: $info-800;
}

.color-danger {
  background-color: $danger-400;
}

.color-danger-text {
  color: $danger-800;
}

.color-danger-secondary {
  background-color: $danger-200;
}

.color-danger-secondary-text {
  color: $danger-600;
}

.color-danger-tertiary {
  background-color: $danger-100;
}

.color-danger-tertiary-text {
  color: $danger-400;
}

.color-success-primary-text{
  color: $success-500;
}

.color-success {
  background-color: $success-100;
}

.color-success-secondary {
  background-color: $success-70;
}

.color-success-text {
  color: $success-10;
}

.color-brand {
  background-color: $primary-aqua-20;
}

.color-brand-secondary {
  background-color: $primary-aqua-60;
}

.color-neutral {
  background-color: $monotone-80;
}

.color-neutral-tertiary {
  color: $neutral-700;
}

.color-neutral-text {
  color: $monotone-100;
  fill: $monotone-100;
}

.color-outline {
  background-color: $monotone-50;
}

.color-outline-text {
  color: $monotone-50;
}

.border-neutral {
  border-color: $monotone-50;
}

.color-error-primary {
  background-color: $error-70;
}

.color-error-primary-light {
  background-color: $error-50;
}

.color-error-primary-light-text {
  color: $error-50;
}

.color-error-primary-text {
  color: $error-70;
}

.color-error-secondary-light {
  background-color: $error-110;
}

.color-error-secondary {
  background-color: $error-100;
}

.color-error-tertiary {
  background-color: $error-120;
}

.color-violet {
  background-color: $secondary-violet-100;
}

.color-violet-secondary {
  background-color: $secondary-violet-70;
}

.color-violet-text {
  color: $secondary-violet-10;
}

.color-mono-primary-text {
  color: $monotone-30;
}

.color-mono-secondary-text {
  color: $monotone-20;
}

.color-mono-tertiary-text {
  color: $monotone-10;
}

.color-navy{
  background-color: $primary-90;
}
