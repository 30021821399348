@charset "UTF-8";
/*
* Theme / Colours
* Semantic naming for primary, secondary, tertiary, danger, info, success, warning
* Core colors - ESSENTIAL WEIGHTS
*/
/* Custom color utilities */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
.color-primary {
  background-color: #00236F;
}

.color-primary-light {
  background-color: #F5F8FE;
}

.color-primary-aqua-10 {
  color: #00A2D7;
}

.color-tertiary {
  background-color: #BDEBFB;
}

.color-primary-light-text {
  color: #F5F8FE;
}

.color-primary-text {
  color: #FFFFFF;
}

.color-secondary {
  background-color: #E8EFFF;
}

.color-secondary-text {
  color: #00236F;
}

.color-warning {
  background-color: #CCA5FD;
}

.color-warning-text {
  color: #250569;
}

.color-warning-icon-light {
  color: #9C5400;
}

.color-warning-text-light {
  color: #9C5400;
}
.color-warning-text-light:hover {
  color: #663904 !important;
}
.color-warning-text-light:active {
  color: #663904 !important;
}
.color-warning-text-light:focus {
  color: #663904 !important;
}

.color-warning-secondary {
  background-color: #FCE3FF;
}

.color-warning-secondary-text {
  color: #930188;
}

.color-info {
  background-color: #FFE18E;
}

.color-info-light {
  background-color: #FFE1B5;
}
.color-info-light:hover {
  background-color: #FFE1B5;
}
.color-info-light:active {
  background-color: #FFE1B5 !important;
  border: none !important;
}
.color-info-light:focus {
  background-color: #FFE1B5 !important;
  border: none !important;
}
.color-info-light:disabled {
  background-color: #FFE1B5;
  opacity: 50%;
  border: none;
  color: #663904 !important;
}

.color-info-secondary {
  background-color: #FFC142;
}

.color-info-text {
  color: #663904;
}

.color-danger {
  background-color: #BA1A1A;
}

.color-danger-text {
  color: #410002;
}

.color-danger-secondary {
  background-color: #FF8080;
}

.color-danger-secondary-text {
  color: #861816;
}

.color-danger-tertiary {
  background-color: #F0DEE0;
}

.color-danger-tertiary-text {
  color: #BA1A1A;
}

.color-success-primary-text {
  color: #007235;
}

.color-success {
  background-color: #B0F3B7;
}

.color-success-secondary {
  background-color: #0EB551;
}

.color-success-text {
  color: #014420;
}

.color-brand {
  background-color: #0EA7DA;
}

.color-brand-secondary {
  background-color: #5FC6E8;
}

.color-neutral {
  background-color: #F5F7FB;
}

.color-neutral-tertiary {
  color: #919396;
}

.color-neutral-text {
  color: #FFFFFF;
  fill: #FFFFFF;
}

.color-outline {
  background-color: #E7E9ED;
}

.color-outline-text {
  color: #E7E9ED;
}

.border-neutral {
  border-color: #E7E9ED;
}

.color-error-primary {
  background-color: #B50E0E;
}

.color-error-primary-light {
  background-color: #ED6565;
}

.color-error-primary-light-text {
  color: #ED6565;
}

.color-error-primary-text {
  color: #B50E0E;
}

.color-error-secondary-light {
  background-color: #FFF5F4;
}

.color-error-secondary {
  background-color: #FFDAD6;
}

.color-error-tertiary {
  background-color: #FFE5E5;
}

.color-violet {
  background-color: #F0E4FF;
}

.color-violet-secondary {
  background-color: #9753EB;
}

.color-violet-text {
  color: #480670;
}

.color-mono-primary-text {
  color: #888B9C;
}

.color-mono-secondary-text {
  color: #23252B;
}

.color-mono-tertiary-text {
  color: #191A1D;
}

.color-navy {
  background-color: #C4D5FE;
}

/*
* Theme / Colours
* Semantic naming for primary, secondary, tertiary, danger, info, success, warning
* Core colors - ESSENTIAL WEIGHTS
*/
/* Custom color utilities */
.color-primary {
  background-color: #00236F;
}

.color-primary-light {
  background-color: #F5F8FE;
}

.color-primary-aqua-10 {
  color: #00A2D7;
}

.color-tertiary {
  background-color: #BDEBFB;
}

.color-primary-light-text {
  color: #F5F8FE;
}

.color-primary-text {
  color: #FFFFFF;
}

.color-secondary {
  background-color: #E8EFFF;
}

.color-secondary-text {
  color: #00236F;
}

.color-warning {
  background-color: #CCA5FD;
}

.color-warning-text {
  color: #250569;
}

.color-warning-icon-light {
  color: #9C5400;
}

.color-warning-text-light {
  color: #9C5400;
}
.color-warning-text-light:hover {
  color: #663904 !important;
}
.color-warning-text-light:active {
  color: #663904 !important;
}
.color-warning-text-light:focus {
  color: #663904 !important;
}

.color-warning-secondary {
  background-color: #FCE3FF;
}

.color-warning-secondary-text {
  color: #930188;
}

.color-info {
  background-color: #FFE18E;
}

.color-info-light {
  background-color: #FFE1B5;
}
.color-info-light:hover {
  background-color: #FFE1B5;
}
.color-info-light:active {
  background-color: #FFE1B5 !important;
  border: none !important;
}
.color-info-light:focus {
  background-color: #FFE1B5 !important;
  border: none !important;
}
.color-info-light:disabled {
  background-color: #FFE1B5;
  opacity: 50%;
  border: none;
  color: #663904 !important;
}

.color-info-secondary {
  background-color: #FFC142;
}

.color-info-text {
  color: #663904;
}

.color-danger {
  background-color: #BA1A1A;
}

.color-danger-text {
  color: #410002;
}

.color-danger-secondary {
  background-color: #FF8080;
}

.color-danger-secondary-text {
  color: #861816;
}

.color-danger-tertiary {
  background-color: #F0DEE0;
}

.color-danger-tertiary-text {
  color: #BA1A1A;
}

.color-success-primary-text {
  color: #007235;
}

.color-success {
  background-color: #B0F3B7;
}

.color-success-secondary {
  background-color: #0EB551;
}

.color-success-text {
  color: #014420;
}

.color-brand {
  background-color: #0EA7DA;
}

.color-brand-secondary {
  background-color: #5FC6E8;
}

.color-neutral {
  background-color: #F5F7FB;
}

.color-neutral-tertiary {
  color: #919396;
}

.color-neutral-text {
  color: #FFFFFF;
  fill: #FFFFFF;
}

.color-outline {
  background-color: #E7E9ED;
}

.color-outline-text {
  color: #E7E9ED;
}

.border-neutral {
  border-color: #E7E9ED;
}

.color-error-primary {
  background-color: #B50E0E;
}

.color-error-primary-light {
  background-color: #ED6565;
}

.color-error-primary-light-text {
  color: #ED6565;
}

.color-error-primary-text {
  color: #B50E0E;
}

.color-error-secondary-light {
  background-color: #FFF5F4;
}

.color-error-secondary {
  background-color: #FFDAD6;
}

.color-error-tertiary {
  background-color: #FFE5E5;
}

.color-violet {
  background-color: #F0E4FF;
}

.color-violet-secondary {
  background-color: #9753EB;
}

.color-violet-text {
  color: #480670;
}

.color-mono-primary-text {
  color: #888B9C;
}

.color-mono-secondary-text {
  color: #23252B;
}

.color-mono-tertiary-text {
  color: #191A1D;
}

.color-navy {
  background-color: #C4D5FE;
}

/*
* Component bootstrap variables
*/
/* Accordion */
/* Input */
/* Button */
/* Form Control */
/* Table */
/* Card */
/* Date picker */
/*
* Theme / Colours
* Semantic naming for primary, secondary, tertiary, danger, info, success, warning
* Core colors - ESSENTIAL WEIGHTS
*/
/* Custom color utilities */
.color-primary {
  background-color: #00236F;
}

.color-primary-light {
  background-color: #F5F8FE;
}

.color-primary-aqua-10 {
  color: #00A2D7;
}

.color-tertiary {
  background-color: #BDEBFB;
}

.color-primary-light-text {
  color: #F5F8FE;
}

.color-primary-text {
  color: #FFFFFF;
}

.color-secondary {
  background-color: #E8EFFF;
}

.color-secondary-text {
  color: #00236F;
}

.color-warning {
  background-color: #CCA5FD;
}

.color-warning-text {
  color: #250569;
}

.color-warning-icon-light {
  color: #9C5400;
}

.color-warning-text-light {
  color: #9C5400;
}
.color-warning-text-light:hover {
  color: #663904 !important;
}
.color-warning-text-light:active {
  color: #663904 !important;
}
.color-warning-text-light:focus {
  color: #663904 !important;
}

.color-warning-secondary {
  background-color: #FCE3FF;
}

.color-warning-secondary-text {
  color: #930188;
}

.color-info {
  background-color: #FFE18E;
}

.color-info-light {
  background-color: #FFE1B5;
}
.color-info-light:hover {
  background-color: #FFE1B5;
}
.color-info-light:active {
  background-color: #FFE1B5 !important;
  border: none !important;
}
.color-info-light:focus {
  background-color: #FFE1B5 !important;
  border: none !important;
}
.color-info-light:disabled {
  background-color: #FFE1B5;
  opacity: 50%;
  border: none;
  color: #663904 !important;
}

.color-info-secondary {
  background-color: #FFC142;
}

.color-info-text {
  color: #663904;
}

.color-danger {
  background-color: #BA1A1A;
}

.color-danger-text {
  color: #410002;
}

.color-danger-secondary {
  background-color: #FF8080;
}

.color-danger-secondary-text {
  color: #861816;
}

.color-danger-tertiary {
  background-color: #F0DEE0;
}

.color-danger-tertiary-text {
  color: #BA1A1A;
}

.color-success-primary-text {
  color: #007235;
}

.color-success {
  background-color: #B0F3B7;
}

.color-success-secondary {
  background-color: #0EB551;
}

.color-success-text {
  color: #014420;
}

.color-brand {
  background-color: #0EA7DA;
}

.color-brand-secondary {
  background-color: #5FC6E8;
}

.color-neutral {
  background-color: #F5F7FB;
}

.color-neutral-tertiary {
  color: #919396;
}

.color-neutral-text {
  color: #FFFFFF;
  fill: #FFFFFF;
}

.color-outline {
  background-color: #E7E9ED;
}

.color-outline-text {
  color: #E7E9ED;
}

.border-neutral {
  border-color: #E7E9ED;
}

.color-error-primary {
  background-color: #B50E0E;
}

.color-error-primary-light {
  background-color: #ED6565;
}

.color-error-primary-light-text {
  color: #ED6565;
}

.color-error-primary-text {
  color: #B50E0E;
}

.color-error-secondary-light {
  background-color: #FFF5F4;
}

.color-error-secondary {
  background-color: #FFDAD6;
}

.color-error-tertiary {
  background-color: #FFE5E5;
}

.color-violet {
  background-color: #F0E4FF;
}

.color-violet-secondary {
  background-color: #9753EB;
}

.color-violet-text {
  color: #480670;
}

.color-mono-primary-text {
  color: #888B9C;
}

.color-mono-secondary-text {
  color: #23252B;
}

.color-mono-tertiary-text {
  color: #191A1D;
}

.color-navy {
  background-color: #C4D5FE;
}

/*
* Component bootstrap variables
*/
/* Accordion */
/* Input */
/* Button */
/* Form Control */
/* Table */
/* Card */
/* Date picker */
.min-w-40 {
  min-width: 40px !important;
}

.max-w-40 {
  max-width: 40px !important;
}

.min-h-40 {
  min-height: 40px !important;
}

.max-h-40 {
  max-height: 40px !important;
}

.min-w-48 {
  min-width: 48px !important;
}

.max-w-48 {
  max-width: 48px !important;
}

.min-h-48 {
  min-height: 48px !important;
}

.max-h-48 {
  max-height: 48px !important;
}

.min-w-75 {
  min-width: 75px !important;
}

.max-w-75 {
  max-width: 75px !important;
}

.min-h-75 {
  min-height: 75px !important;
}

.max-h-75 {
  max-height: 75px !important;
}

.min-w-80 {
  min-width: 80px !important;
}

.max-w-80 {
  max-width: 80px !important;
}

.min-h-80 {
  min-height: 80px !important;
}

.max-h-80 {
  max-height: 80px !important;
}

.min-w-96 {
  min-width: 96px !important;
}

.max-w-96 {
  max-width: 96px !important;
}

.min-h-96 {
  min-height: 96px !important;
}

.max-h-96 {
  max-height: 96px !important;
}

.min-w-100 {
  min-width: 100px !important;
}

.max-w-100 {
  max-width: 100px !important;
}

.min-h-100 {
  min-height: 100px !important;
}

.max-h-100 {
  max-height: 100px !important;
}

.min-w-112 {
  min-width: 112px !important;
}

.max-w-112 {
  max-width: 112px !important;
}

.min-h-112 {
  min-height: 112px !important;
}

.max-h-112 {
  max-height: 112px !important;
}

.min-w-120 {
  min-width: 120px !important;
}

.max-w-120 {
  max-width: 120px !important;
}

.min-h-120 {
  min-height: 120px !important;
}

.max-h-120 {
  max-height: 120px !important;
}

.min-w-130 {
  min-width: 130px !important;
}

.max-w-130 {
  max-width: 130px !important;
}

.min-h-130 {
  min-height: 130px !important;
}

.max-h-130 {
  max-height: 130px !important;
}

.min-w-140 {
  min-width: 140px !important;
}

.max-w-140 {
  max-width: 140px !important;
}

.min-h-140 {
  min-height: 140px !important;
}

.max-h-140 {
  max-height: 140px !important;
}

.min-w-170 {
  min-width: 170px !important;
}

.max-w-170 {
  max-width: 170px !important;
}

.min-h-170 {
  min-height: 170px !important;
}

.max-h-170 {
  max-height: 170px !important;
}

.min-w-180 {
  min-width: 180px !important;
}

.max-w-180 {
  max-width: 180px !important;
}

.min-h-180 {
  min-height: 180px !important;
}

.max-h-180 {
  max-height: 180px !important;
}

.min-w-265 {
  min-width: 265px !important;
}

.max-w-265 {
  max-width: 265px !important;
}

.min-h-265 {
  min-height: 265px !important;
}

.max-h-265 {
  max-height: 265px !important;
}

.min-w-320 {
  min-width: 320px !important;
}

.max-w-320 {
  max-width: 320px !important;
}

.min-h-320 {
  min-height: 320px !important;
}

.max-h-320 {
  max-height: 320px !important;
}

.min-w-400 {
  min-width: 400px !important;
}

.max-w-400 {
  max-width: 400px !important;
}

.min-h-400 {
  min-height: 400px !important;
}

.max-h-400 {
  max-height: 400px !important;
}

.min-w-410 {
  min-width: 410px !important;
}

.max-w-410 {
  max-width: 410px !important;
}

.min-h-410 {
  min-height: 410px !important;
}

.max-h-410 {
  max-height: 410px !important;
}

.min-w-560 {
  min-width: 560px !important;
}

.max-w-560 {
  max-width: 560px !important;
}

.min-h-560 {
  min-height: 560px !important;
}

.max-h-560 {
  max-height: 560px !important;
}

.min-w-620 {
  min-width: 620px !important;
}

.max-w-620 {
  max-width: 620px !important;
}

.min-h-620 {
  min-height: 620px !important;
}

.max-h-620 {
  max-height: 620px !important;
}

.max-wh-40 {
  max-width: 40px;
  max-height: 40px;
}

.max-h {
  max-height: 16px;
}

@media (min-width: 600px) {
  .max-h-sm-none {
    max-height: none !important;
  }
}

@media (min-width: 600px) {
  .max-h-sm-48 {
    max-height: 48px !important;
  }
}

.max-w-50 {
  max-width: 50% !important;
}

@media (min-width: 600px) {
  .min-w-sm-620 {
    min-width: 620px !important;
  }
}

@media (min-width: 600px) {
  .max-w-sm-70 {
    max-width: 70%;
  }
}

@media (min-width: 600px) {
  .max-sm-w-100 {
    max-width: 100% !important;
  }
}

@media (min-width: 600px) {
  .max-sm-w-30 {
    max-width: 30% !important;
  }
}

.min-vh-100 {
  min-height: 100vh;
}

@media (min-width: 600px) {
  .max-vh-sm-365 {
    max-height: calc(100vh - 365px) !important;
  }
}

@media (min-width: 600px) {
  .vw-sm-50 {
    width: 50vw !important;
  }
}

.w-304 {
  width: 19rem !important;
}

.w-128 {
  width: 8rem !important;
}

.w-120 {
  width: 120% !important;
}

.h-25 {
  height: 25px !important;
}

.h-32 {
  height: 32px !important;
}

.w-32 {
  width: 32px !important;
}

.h-612 {
  height: 612px;
}

.w-500 {
  width: 500px !important;
}

.w-600 {
  width: 600px;
}

.w-250 {
  width: 250px;
}

.w-240 {
  width: 240px;
}

.h-40 {
  height: 40px !important;
}

.max-h-40 {
  max-height: 40px !important;
}

.h-80 {
  height: 80px;
}

.h-100 {
  height: 100%;
}

.h-98 {
  height: 6.125rem;
}

.h-64 {
  height: 64px;
}

.h-45 {
  height: 45px !important;
}

.h-50vh {
  height: 50vh;
}

@media (min-width: 600px) {
  .h-sm-38 {
    height: 38px;
  }
}

@media (min-width: 600px) {
  .h-sm-36 {
    height: 2rem !important;
  }
}

@media (min-width: 600px) {
  .h-sm-80 {
    height: 80px !important;
  }
}

@media (min-width: 600px) {
  .h-sm-500 {
    height: 500px !important;
  }
}

.w-160 {
  width: 10rem !important;
}

.w-24 {
  width: 24px;
}

.w-80 {
  width: 80px;
}

.w-48 {
  width: 3rem !important;
}

.w-66 {
  width: 66px;
}

.w-60 {
  width: 60px;
}

.w-40 {
  width: 40px !important;
}

.max-content {
  width: max-content;
}

.lh-25 {
  line-height: 25px;
}

.lh-24 {
  line-height: 24px;
}

@media (min-width: 600px) {
  .lh-sm-24 {
    line-height: 24px !important;
  }
}

::ng-deep .button-icon-svg > span > div > svg {
  width: 24px !important;
  height: 1rem !important;
}

.lh-21 {
  line-height: 21px;
}

.lh-20 {
  line-height: 21px;
}

.lh-32 {
  line-height: 32px;
}

.lh-35 {
  line-height: 35px;
}

.lh-38 {
  line-height: 18px;
}

.lh-16 {
  line-height: 16px;
}

.lh-18 {
  line-height: 18px;
}

@media (min-width: 600px) {
  .lh-sm-38 {
    line-height: 38px;
  }
}

@media (min-width: 600px) {
  .pr-sm-0 {
    padding-right: 0px !important;
  }
}

.lh-30 {
  line-height: 30px;
}

.lh-40 {
  line-height: 40px;
}

.lh-45 {
  line-height: 45px;
}

@media (min-width: 600px) {
  .lh-sm-45 {
    line-height: 45px;
  }
}

@media (min-width: 600px) {
  .lh-sm-normal {
    line-height: normal;
  }
}

.lh-150 {
  line-height: 150%;
}

.ls-tight {
  letter-spacing: -0.5px;
}

.mx-n3 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.white-space-pre-line {
  white-space: pre-line;
}

.fw-medium {
  font-weight: 500;
}

@media (min-width: 600px) {
  .fw-sm-bold {
    font-weight: 700;
  }
}

.fw-semi-bold {
  font-weight: 600;
}

.flex-auto {
  flex: auto;
}

.flex-none {
  flex: none;
}

@media (min-width: 600px) {
  .flex-sm-none {
    flex: none !important;
  }
}

.flex-1 {
  flex: 1 1 0;
}

@media (min-width: 600px) {
  .flex-sm-no-grow {
    flex: 0 1 0 !important;
  }
}

@media (min-width: 600px) {
  .flex-sm-1 {
    flex: 1 1 0 !important;
  }
}

.flex-2 {
  flex: 2 1 0;
}

@media (min-width: 600px) {
  .flex-sm-2 {
    flex: 2 1 0 !important;
  }
}

.flex-3 {
  flex: 3 1 0;
}

@media (min-width: 600px) {
  .flex-sm-3 {
    flex: 3 1 0 !important;
  }
}

.flex-shrink-1 {
  flex: 1 1 0;
}

.flex-basis-0 {
  flex-basis: 0 !important;
}

@media (min-width: 600px) {
  .flex-basis-sm-0 {
    flex-basis: 0 !important;
  }
}

.flex-basis-48 {
  flex-basis: 48% !important;
}

@media (min-width: 600px) {
  .flex-basis-sm-48 {
    flex-basis: 48% !important;
  }
}

.justify-self-start {
  justify-self: start;
}

.vertical-align-sub {
  vertical-align: sub !important;
}

.text-align-right > input {
  text-align: right !important;
}

.row-gap-3 {
  row-gap: 1rem;
}

.column-gap-2 {
  column-gap: 0.5rem;
}

.cursor-pointer {
  cursor: pointer;
}

.text-align-right {
  text-align: right;
}

.float-right {
  float: right;
}

.indent-li {
  margin-left: 20px;
}

.border-radius-50 {
  border-radius: 50% !important;
}

.border-radius-16 {
  border-radius: 16px !important;
}

.col-md-3 {
  width: 25% !important;
}

.col-md-4 {
  width: 33.33333333% !important;
}

.border-none {
  border: none !important;
}

.position-relative {
  position: relative;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.fs-20 {
  font-size: 20px;
}

.invalid-feedback {
  display: block;
}

.w-0p {
  width: 0%;
}

@media (min-width: 600px) {
  .w-sm-0p {
    width: 0% !important;
  }
}

.w-max-0p {
  max-width: 0%;
}

@media (min-width: 600px) {
  .w-max-sm-0p {
    max-width: 0% !important;
  }
}

.w-min-0p {
  min-width: 0%;
}

@media (min-width: 600px) {
  .w-min-sm-0p {
    max-width: 0% !important;
  }
}

.w-5p {
  width: 5%;
}

@media (min-width: 600px) {
  .w-sm-5p {
    width: 5% !important;
  }
}

.w-max-5p {
  max-width: 5%;
}

@media (min-width: 600px) {
  .w-max-sm-5p {
    max-width: 5% !important;
  }
}

.w-min-5p {
  min-width: 5%;
}

@media (min-width: 600px) {
  .w-min-sm-5p {
    max-width: 5% !important;
  }
}

.w-10p {
  width: 10%;
}

@media (min-width: 600px) {
  .w-sm-10p {
    width: 10% !important;
  }
}

.w-max-10p {
  max-width: 10%;
}

@media (min-width: 600px) {
  .w-max-sm-10p {
    max-width: 10% !important;
  }
}

.w-min-10p {
  min-width: 10%;
}

@media (min-width: 600px) {
  .w-min-sm-10p {
    max-width: 10% !important;
  }
}

.w-15p {
  width: 15%;
}

@media (min-width: 600px) {
  .w-sm-15p {
    width: 15% !important;
  }
}

.w-max-15p {
  max-width: 15%;
}

@media (min-width: 600px) {
  .w-max-sm-15p {
    max-width: 15% !important;
  }
}

.w-min-15p {
  min-width: 15%;
}

@media (min-width: 600px) {
  .w-min-sm-15p {
    max-width: 15% !important;
  }
}

.w-18p {
  width: 18%;
}

@media (min-width: 600px) {
  .w-sm-18p {
    width: 18% !important;
  }
}

.w-max-18p {
  max-width: 18%;
}

@media (min-width: 600px) {
  .w-max-sm-18p {
    max-width: 18% !important;
  }
}

.w-min-18p {
  min-width: 18%;
}

@media (min-width: 600px) {
  .w-min-sm-18p {
    max-width: 18% !important;
  }
}

.w-20p {
  width: 20%;
}

@media (min-width: 600px) {
  .w-sm-20p {
    width: 20% !important;
  }
}

.w-max-20p {
  max-width: 20%;
}

@media (min-width: 600px) {
  .w-max-sm-20p {
    max-width: 20% !important;
  }
}

.w-min-20p {
  min-width: 20%;
}

@media (min-width: 600px) {
  .w-min-sm-20p {
    max-width: 20% !important;
  }
}

.w-22p {
  width: 23%;
}

@media (min-width: 600px) {
  .w-sm-22p {
    width: 23% !important;
  }
}

.w-max-22p {
  max-width: 23%;
}

@media (min-width: 600px) {
  .w-max-sm-22p {
    max-width: 23% !important;
  }
}

.w-min-22p {
  min-width: 23%;
}

@media (min-width: 600px) {
  .w-min-sm-22p {
    max-width: 23% !important;
  }
}

.w-25p {
  width: 25%;
}

@media (min-width: 600px) {
  .w-sm-25p {
    width: 25% !important;
  }
}

.w-max-25p {
  max-width: 25%;
}

@media (min-width: 600px) {
  .w-max-sm-25p {
    max-width: 25% !important;
  }
}

.w-min-25p {
  min-width: 25%;
}

@media (min-width: 600px) {
  .w-min-sm-25p {
    max-width: 25% !important;
  }
}

.w-30p {
  width: 30%;
}

@media (min-width: 600px) {
  .w-sm-30p {
    width: 30% !important;
  }
}

.w-max-30p {
  max-width: 30%;
}

@media (min-width: 600px) {
  .w-max-sm-30p {
    max-width: 30% !important;
  }
}

.w-min-30p {
  min-width: 30%;
}

@media (min-width: 600px) {
  .w-min-sm-30p {
    min-width: 30% !important;
  }
}

.w-35p {
  width: 35%;
}

@media (min-width: 600px) {
  .w-sm-35p {
    width: 35% !important;
  }
}

.w-max-35p {
  max-width: 35%;
}

@media (min-width: 600px) {
  .w-max-sm-35p {
    max-width: 35% !important;
  }
}

.w-min-35p {
  min-width: 35%;
}

@media (min-width: 600px) {
  .w-min-sm-35p {
    min-width: 35% !important;
  }
}

.w-40p {
  width: 40%;
}

@media (min-width: 600px) {
  .w-sm-40p {
    width: 40% !important;
  }
}

.w-max-40p {
  max-width: 40%;
}

@media (min-width: 600px) {
  .w-max-sm-40p {
    max-width: 40% !important;
  }
}

.w-min-40p {
  min-width: 40%;
}

@media (min-width: 600px) {
  .w-min-sm-40p {
    min-width: 40% !important;
  }
}

.w-45p {
  width: 45%;
}

@media (min-width: 600px) {
  .w-sm-45p {
    width: 45% !important;
  }
}

.w-max-45p {
  max-width: 45%;
}

@media (min-width: 600px) {
  .w-max-sm-45p {
    max-width: 45% !important;
  }
}

.w-min-45p {
  min-width: 45%;
}

@media (min-width: 600px) {
  .w-min-sm-45p {
    min-width: 45% !important;
  }
}

.w-48p {
  width: 48%;
}

@media (min-width: 600px) {
  .w-sm-48p {
    width: 48% !important;
  }
}

.w-max-48p {
  max-width: 48%;
}

@media (min-width: 600px) {
  .w-max-sm-48p {
    max-width: 48% !important;
  }
}

.w-min-48p {
  min-width: 45%;
}

@media (min-width: 600px) {
  .w-min-sm-48p {
    min-width: 45% !important;
  }
}

.w-50p {
  width: 50%;
}

@media (min-width: 600px) {
  .w-sm-50p {
    width: 50% !important;
  }
}

.w-max-50p {
  max-width: 50%;
}

@media (min-width: 600px) {
  .w-max-sm-50p {
    max-width: 50% !important;
  }
}

.w-min-50p {
  min-width: 50%;
}

@media (min-width: 600px) {
  .w-min-sm-50p {
    min-width: 50% !important;
  }
}

.w-55p {
  width: 55%;
}

@media (min-width: 600px) {
  .w-sm-55p {
    width: 55% !important;
  }
}

.w-max-55p {
  max-width: 55%;
}

@media (min-width: 600px) {
  .w-max-sm-55p {
    max-width: 55% !important;
  }
}

.w-min-55p {
  min-width: 55%;
}

@media (min-width: 600px) {
  .w-min-sm-55p {
    min-width: 55% !important;
  }
}

.w-60p {
  width: 60%;
}

@media (min-width: 600px) {
  .w-sm-60p {
    width: 60% !important;
  }
}

.w-max-60p {
  max-width: 60%;
}

@media (min-width: 600px) {
  .w-max-sm-60p {
    max-width: 60% !important;
  }
}

.w-min-60p {
  min-width: 60%;
}

@media (min-width: 600px) {
  .w-min-sm-60p {
    min-width: 60% !important;
  }
}

.w-65p {
  width: 65%;
}

@media (min-width: 600px) {
  .w-sm-65p {
    width: 65% !important;
  }
}

.w-max-65p {
  max-width: 65%;
}

@media (min-width: 600px) {
  .w-max-sm-65p {
    max-width: 65% !important;
  }
}

.w-min-65p {
  min-width: 65%;
}

@media (min-width: 600px) {
  .w-min-sm-65p {
    min-width: 65% !important;
  }
}

.w-70p {
  width: 70%;
}

@media (min-width: 600px) {
  .w-sm-70p {
    width: 70% !important;
  }
}

.w-max-70p {
  max-width: 70%;
}

@media (min-width: 600px) {
  .w-max-sm-70p {
    max-width: 70% !important;
  }
}

.w-min-70p {
  min-width: 70%;
}

@media (min-width: 600px) {
  .w-min-sm-70p {
    min-width: 70% !important;
  }
}

.w-75p {
  width: 75%;
}

@media (min-width: 600px) {
  .w-sm-75p {
    width: 75% !important;
  }
}

.w-max-75p {
  max-width: 75%;
}

@media (min-width: 600px) {
  .w-max-sm-75p {
    max-width: 75% !important;
  }
}

.w-min-75p {
  min-width: 75%;
}

@media (min-width: 600px) {
  .w-min-sm-75p {
    min-width: 75% !important;
  }
}

.w-80p {
  width: 80%;
}

@media (min-width: 600px) {
  .w-sm-80p {
    width: 80% !important;
  }
}

.w-max-80p {
  max-width: 80%;
}

@media (min-width: 600px) {
  .w-max-sm-80p {
    max-width: 80% !important;
  }
}

.w-min-80p {
  min-width: 80%;
}

@media (min-width: 600px) {
  .w-min-sm-80p {
    min-width: 80% !important;
  }
}

.w-85p {
  width: 85%;
}

@media (min-width: 600px) {
  .w-sm-85p {
    width: 85% !important;
  }
}

.w-max-85p {
  max-width: 85%;
}

@media (min-width: 600px) {
  .w-max-sm-85p {
    max-width: 85% !important;
  }
}

.w-min-85p {
  min-width: 85%;
}

@media (min-width: 600px) {
  .w-min-sm-85p {
    min-width: 85% !important;
  }
}

.w-90p {
  width: 90%;
}

@media (min-width: 600px) {
  .w-sm-90p {
    width: 90% !important;
  }
}

.w-max-90p {
  max-width: 90%;
}

@media (min-width: 600px) {
  .w-max-sm-90p {
    max-width: 90% !important;
  }
}

.w-min-90p {
  min-width: 90%;
}

@media (min-width: 600px) {
  .w-min-sm-90p {
    min-width: 90% !important;
  }
}

.w-95p {
  width: 95%;
}

@media (min-width: 600px) {
  .w-sm-95p {
    width: 95% !important;
  }
}

.w-max-95p {
  max-width: 95%;
}

@media (min-width: 600px) {
  .w-max-sm-95p {
    max-width: 95% !important;
  }
}

.w-min-95p {
  min-width: 95%;
}

@media (min-width: 600px) {
  .w-min-sm-95p {
    min-width: 95% !important;
  }
}

.w-100p {
  width: 100%;
}

@media (min-width: 600px) {
  .w-sm-100p {
    width: 100% !important;
  }
}

.w-max-100p {
  max-width: 100%;
}

@media (min-width: 600px) {
  .w-max-sm-100p {
    max-width: 100% !important;
  }
}

.w-min-100p {
  min-width: 100%;
}

@media (min-width: 600px) {
  .w-min-sm-100p {
    min-width: 100% !important;
  }
}

.h-0p {
  height: 0%;
}

@media (min-width: 600px) {
  .h-sm-0p {
    height: 0% !important;
  }
}

.h-max-0p {
  max-height: 0%;
}

@media (min-width: 600px) {
  .h-max-sm-0p {
    max-height: 0% !important;
  }
}

.h-min-0p {
  min-height: 0%;
}

@media (min-width: 600px) {
  .h-min-sm-0p {
    min-height: 0% !important;
  }
}

.h-5p {
  height: 5%;
}

@media (min-width: 600px) {
  .h-sm-5p {
    height: 5% !important;
  }
}

.h-max-5p {
  max-height: 5%;
}

@media (min-width: 600px) {
  .h-max-sm-5p {
    max-height: 5% !important;
  }
}

.h-min-5p {
  min-height: 5%;
}

@media (min-width: 600px) {
  .h-min-sm-5p {
    min-height: 5% !important;
  }
}

.h-10p {
  height: 10%;
}

@media (min-width: 600px) {
  .h-sm-10p {
    height: 10% !important;
  }
}

.h-max-10p {
  max-height: 10%;
}

@media (min-width: 600px) {
  .h-max-sm-10p {
    max-height: 10% !important;
  }
}

.h-min-10p {
  min-height: 10%;
}

@media (min-width: 600px) {
  .h-min-sm-10p {
    min-height: 10% !important;
  }
}

.h-15p {
  height: 15%;
}

@media (min-width: 600px) {
  .h-sm-15p {
    height: 15% !important;
  }
}

.h-max-15p {
  max-height: 15%;
}

@media (min-width: 600px) {
  .h-max-sm-15p {
    max-height: 15% !important;
  }
}

.h-min-15p {
  min-height: 15%;
}

@media (min-width: 600px) {
  .h-min-sm-15p {
    min-height: 15% !important;
  }
}

.h-20p {
  height: 20%;
}

@media (min-width: 600px) {
  .h-sm-20p {
    height: 20% !important;
  }
}

.h-max-20p {
  max-height: 20%;
}

@media (min-width: 600px) {
  .h-max-sm-20p {
    max-height: 20% !important;
  }
}

.h-min-20p {
  min-height: 20%;
}

@media (min-width: 600px) {
  .h-min-sm-20p {
    min-height: 20% !important;
  }
}

.h-25p {
  height: 25%;
}

@media (min-width: 600px) {
  .h-sm-25p {
    height: 25% !important;
  }
}

.h-max-25p {
  max-height: 25%;
}

@media (min-width: 600px) {
  .h-max-sm-25p {
    max-height: 25% !important;
  }
}

.h-min-25p {
  min-height: 25%;
}

@media (min-width: 600px) {
  .h-min-sm-25p {
    min-height: 25% !important;
  }
}

.h-30p {
  height: 30%;
}

@media (min-width: 600px) {
  .h-sm-30p {
    height: 30% !important;
  }
}

.h-max-30p {
  max-height: 30%;
}

@media (min-width: 600px) {
  .h-max-sm-30p {
    max-height: 30% !important;
  }
}

.h-min-30p {
  min-height: 30%;
}

@media (min-width: 600px) {
  .h-min-sm-30p {
    min-height: 30% !important;
  }
}

.h-35p {
  height: 35%;
}

@media (min-width: 600px) {
  .h-sm-35p {
    height: 35% !important;
  }
}

.h-max-35p {
  max-height: 35%;
}

@media (min-width: 600px) {
  .h-max-sm-35p {
    max-height: 35% !important;
  }
}

.h-min-35p {
  min-height: 35%;
}

@media (min-width: 600px) {
  .h-min-sm-35p {
    min-height: 35% !important;
  }
}

.h-40p {
  height: 40%;
}

@media (min-width: 600px) {
  .h-sm-40p {
    height: 40% !important;
  }
}

.h-max-40p {
  max-height: 40%;
}

@media (min-width: 600px) {
  .h-max-sm-40p {
    max-height: 40% !important;
  }
}

.h-min-40p {
  min-height: 40%;
}

@media (min-width: 600px) {
  .h-min-sm-40p {
    min-height: 40% !important;
  }
}

.h-45p {
  height: 45%;
}

@media (min-width: 600px) {
  .h-sm-45p {
    height: 45% !important;
  }
}

.h-max-45p {
  max-height: 45%;
}

@media (min-width: 600px) {
  .h-max-sm-45p {
    max-height: 45% !important;
  }
}

.h-min-45p {
  min-height: 45%;
}

@media (min-width: 600px) {
  .h-min-sm-45p {
    min-height: 45% !important;
  }
}

.h-50p {
  height: 50%;
}

@media (min-width: 600px) {
  .h-sm-50p {
    height: 50% !important;
  }
}

.h-max-50p {
  max-height: 50%;
}

@media (min-width: 600px) {
  .h-max-sm-50p {
    max-height: 50% !important;
  }
}

.h-min-50p {
  min-height: 50%;
}

@media (min-width: 600px) {
  .h-min-sm-50p {
    min-height: 50% !important;
  }
}

.h-55p {
  height: 55%;
}

@media (min-width: 600px) {
  .h-sm-55p {
    height: 55% !important;
  }
}

.h-max-55p {
  max-height: 55%;
}

@media (min-width: 600px) {
  .h-max-sm-55p {
    max-height: 55% !important;
  }
}

.h-min-55p {
  min-height: 55%;
}

@media (min-width: 600px) {
  .h-min-sm-55p {
    min-height: 55% !important;
  }
}

.h-60p {
  height: 60%;
}

@media (min-width: 600px) {
  .h-sm-60p {
    height: 60% !important;
  }
}

.h-max-60p {
  max-height: 60%;
}

@media (min-width: 600px) {
  .h-max-sm-60p {
    max-height: 60% !important;
  }
}

.h-min-60p {
  min-height: 60%;
}

@media (min-width: 600px) {
  .h-min-sm-60p {
    min-height: 60% !important;
  }
}

.h-65p {
  height: 65%;
}

@media (min-width: 600px) {
  .h-sm-65p {
    height: 65% !important;
  }
}

.h-max-65p {
  max-height: 65%;
}

@media (min-width: 600px) {
  .h-max-sm-65p {
    max-height: 65% !important;
  }
}

.h-min-65p {
  min-height: 65%;
}

@media (min-width: 600px) {
  .h-min-sm-65p {
    min-height: 65% !important;
  }
}

.h-70p {
  height: 70%;
}

@media (min-width: 600px) {
  .h-sm-70p {
    height: 70% !important;
  }
}

.h-max-70p {
  max-height: 70%;
}

@media (min-width: 600px) {
  .h-max-sm-70p {
    max-height: 70% !important;
  }
}

.h-min-70p {
  min-height: 70%;
}

@media (min-width: 600px) {
  .h-min-sm-70p {
    min-height: 70% !important;
  }
}

.h-75p {
  height: 75%;
}

@media (min-width: 600px) {
  .h-sm-75p {
    height: 75% !important;
  }
}

.h-max-75p {
  max-height: 75%;
}

@media (min-width: 600px) {
  .h-max-sm-75p {
    max-height: 75% !important;
  }
}

.h-min-75p {
  min-height: 75%;
}

@media (min-width: 600px) {
  .h-min-sm-75p {
    min-height: 75% !important;
  }
}

.h-80p {
  height: 80%;
}

@media (min-width: 600px) {
  .h-sm-80p {
    height: 80% !important;
  }
}

.h-max-80p {
  max-height: 80%;
}

@media (min-width: 600px) {
  .h-max-sm-80p {
    max-height: 80% !important;
  }
}

.h-min-80p {
  min-height: 80%;
}

@media (min-width: 600px) {
  .h-min-sm-80p {
    min-height: 80% !important;
  }
}

.h-85p {
  height: 85%;
}

@media (min-width: 600px) {
  .h-sm-85p {
    height: 85% !important;
  }
}

.h-max-85p {
  max-height: 85%;
}

@media (min-width: 600px) {
  .h-max-sm-85p {
    max-height: 85% !important;
  }
}

.h-min-85p {
  min-height: 85%;
}

@media (min-width: 600px) {
  .h-min-sm-85p {
    min-height: 85% !important;
  }
}

.h-90p {
  height: 90%;
}

@media (min-width: 600px) {
  .h-sm-90p {
    height: 90% !important;
  }
}

.h-max-90p {
  max-height: 90%;
}

@media (min-width: 600px) {
  .h-max-sm-90p {
    max-height: 90% !important;
  }
}

.h-min-90p {
  min-height: 90%;
}

@media (min-width: 600px) {
  .h-min-sm-90p {
    min-height: 90% !important;
  }
}

.h-95p {
  height: 95%;
}

@media (min-width: 600px) {
  .h-sm-95p {
    height: 95% !important;
  }
}

.h-max-95p {
  max-height: 95%;
}

@media (min-width: 600px) {
  .h-max-sm-95p {
    max-height: 95% !important;
  }
}

.h-min-95p {
  min-height: 95%;
}

@media (min-width: 600px) {
  .h-min-sm-95p {
    min-height: 95% !important;
  }
}

.h-100p {
  height: 100%;
}

@media (min-width: 600px) {
  .h-sm-100p {
    height: 100% !important;
  }
}

.h-max-100p {
  max-height: 100%;
}

@media (min-width: 600px) {
  .h-max-sm-100p {
    max-height: 100% !important;
  }
}

.h-min-100p {
  min-height: 100%;
}

@media (min-width: 600px) {
  .h-min-sm-100p {
    min-height: 100% !important;
  }
}

.min-w-120p {
  min-width: 7.5rem !important;
}

@media (min-width: 600px) {
  .w-max-sm-120p {
    max-width: 7.5rem !important;
  }
}

@media (min-width: 600px) {
  .w-min-sm-120p {
    min-width: 7.5rem !important;
  }
}

.w-150p {
  width: 9.375rem !important;
}

@media (min-width: 600px) {
  .w-max-sm-150p {
    max-width: 9.375rem !important;
  }
}

@media (min-width: 600px) {
  .w-min-sm-150p {
    min-width: 9.375rem !important;
  }
}

.w-200p {
  width: 12.5rem !important;
}

.w-max-200p {
  max-width: 12.5rem !important;
}

@media (min-width: 600px) {
  .w-max-sm-200p {
    max-width: 12.5rem !important;
  }
}

@media (min-width: 600px) {
  .w-min-sm-200p {
    min-width: 12.5rem !important;
  }
}

.w-360p {
  width: 22.5rem !important;
}

@media (min-width: 600px) {
  .w-max-sm-360 {
    max-width: 22.5rem !important;
  }
}

@media (min-width: 600px) {
  .w-min-sm-360 {
    min-width: 22.5rem !important;
  }
}

.h-48 {
  height: 3rem;
}

.max-h-48 {
  max-height: 48px;
}

@media (min-width: 600px) {
  .h-sm-48 {
    height: 3rem !important;
  }
}

@media (min-width: 600px) {
  .max-h-sm-48 {
    max-height: 48px !important;
  }
}

.no-wrap {
  white-space: nowrap !important;
}

@media (max-width: 599.98px) {
  .w-sm-auto {
    width: auto !important;
  }
}

@media (min-width: 600px) {
  .border-sm-bottom-0 {
    border-bottom: 0 !important;
  }
}

/*FONTS DESKTOP*/
/*FONTS MOBILE*/
/*FONT BUTTON*/
/*FONT COLOR*/
/*PRIMARY COLOR*/
/*THEME PRIMARY*/
/*THEME SECONDARY*/
/*LIGHT THEME*/
/*THEME SUCCESS*/
/*THEME ERROR*/
/*MARGIN*/
/*PADDING-INTERNAL*/
/*INPUT*/
/*TABLE*/
/*NAVBAR*/
/*BUTTON-COLOR*/
/*BOOKING-STATUS*/
/*NAVIGATION-BAR*/
/*!
 * Bootstrap  v5.2.3 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #00236F;
  --bs-secondary: #F5F8FE;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #BA1A1A;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 0, 35, 111;
  --bs-secondary-rgb: 245, 248, 254;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 186, 26, 26;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #E8EFFF;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #00236F;
  --bs-link-hover-color: #001c59;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 600px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 900px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 1200px) {
  .container-lg, .container-mdl, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1536px) {
  .container-xl, .container-lg, .container-mdl, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.container-xl, .container-lg, .container-mdl, .container-md, .container-sm, .container {
  max-width: 1390px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 2.3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 2.3rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 2.8rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 2.8rem;
}

.g-12,
.gx-12 {
  --bs-gutter-x: 0.75rem;
}

.g-12,
.gy-12 {
  --bs-gutter-y: 0.75rem;
}

.g-20,
.gx-20 {
  --bs-gutter-x: 1.25rem;
}

.g-20,
.gy-20 {
  --bs-gutter-y: 1.25rem;
}

.g-200,
.gx-200 {
  --bs-gutter-x: 2rem;
}

.g-200,
.gy-200 {
  --bs-gutter-y: 2rem;
}

.g-sm,
.gx-sm {
  --bs-gutter-x: 0.875rem;
}

.g-sm,
.gy-sm {
  --bs-gutter-y: 0.875rem;
}

.g-xs,
.gx-xs {
  --bs-gutter-x: 0.375rem;
}

.g-xs,
.gy-xs {
  --bs-gutter-y: 0.375rem;
}

.g-xxs,
.gx-xxs {
  --bs-gutter-x: 0.188rem;
}

.g-xxs,
.gy-xxs {
  --bs-gutter-y: 0.188rem;
}

@media (min-width: 600px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 2.3rem;
  }
  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 2.3rem;
  }
  .g-sm-6,
.gx-sm-6 {
    --bs-gutter-x: 2.8rem;
  }
  .g-sm-6,
.gy-sm-6 {
    --bs-gutter-y: 2.8rem;
  }
  .g-sm-12,
.gx-sm-12 {
    --bs-gutter-x: 0.75rem;
  }
  .g-sm-12,
.gy-sm-12 {
    --bs-gutter-y: 0.75rem;
  }
  .g-sm-20,
.gx-sm-20 {
    --bs-gutter-x: 1.25rem;
  }
  .g-sm-20,
.gy-sm-20 {
    --bs-gutter-y: 1.25rem;
  }
  .g-sm-200,
.gx-sm-200 {
    --bs-gutter-x: 2rem;
  }
  .g-sm-200,
.gy-sm-200 {
    --bs-gutter-y: 2rem;
  }
  .g-sm-sm,
.gx-sm-sm {
    --bs-gutter-x: 0.875rem;
  }
  .g-sm-sm,
.gy-sm-sm {
    --bs-gutter-y: 0.875rem;
  }
  .g-sm-xs,
.gx-sm-xs {
    --bs-gutter-x: 0.375rem;
  }
  .g-sm-xs,
.gy-sm-xs {
    --bs-gutter-y: 0.375rem;
  }
  .g-sm-xxs,
.gx-sm-xxs {
    --bs-gutter-x: 0.188rem;
  }
  .g-sm-xxs,
.gy-sm-xxs {
    --bs-gutter-y: 0.188rem;
  }
}
@media (min-width: 900px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 2.3rem;
  }
  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 2.3rem;
  }
  .g-md-6,
.gx-md-6 {
    --bs-gutter-x: 2.8rem;
  }
  .g-md-6,
.gy-md-6 {
    --bs-gutter-y: 2.8rem;
  }
  .g-md-12,
.gx-md-12 {
    --bs-gutter-x: 0.75rem;
  }
  .g-md-12,
.gy-md-12 {
    --bs-gutter-y: 0.75rem;
  }
  .g-md-20,
.gx-md-20 {
    --bs-gutter-x: 1.25rem;
  }
  .g-md-20,
.gy-md-20 {
    --bs-gutter-y: 1.25rem;
  }
  .g-md-200,
.gx-md-200 {
    --bs-gutter-x: 2rem;
  }
  .g-md-200,
.gy-md-200 {
    --bs-gutter-y: 2rem;
  }
  .g-md-sm,
.gx-md-sm {
    --bs-gutter-x: 0.875rem;
  }
  .g-md-sm,
.gy-md-sm {
    --bs-gutter-y: 0.875rem;
  }
  .g-md-xs,
.gx-md-xs {
    --bs-gutter-x: 0.375rem;
  }
  .g-md-xs,
.gy-md-xs {
    --bs-gutter-y: 0.375rem;
  }
  .g-md-xxs,
.gx-md-xxs {
    --bs-gutter-x: 0.188rem;
  }
  .g-md-xxs,
.gy-md-xxs {
    --bs-gutter-y: 0.188rem;
  }
}
@media (min-width: 1024px) {
  .col-mdl {
    flex: 1 0 0%;
  }
  .row-cols-mdl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-mdl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-mdl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-mdl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-mdl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-mdl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-mdl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-mdl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-mdl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-mdl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-mdl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-mdl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-mdl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-mdl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-mdl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-mdl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-mdl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-mdl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-mdl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-mdl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-mdl-0 {
    margin-left: 0;
  }
  .offset-mdl-1 {
    margin-left: 8.33333333%;
  }
  .offset-mdl-2 {
    margin-left: 16.66666667%;
  }
  .offset-mdl-3 {
    margin-left: 25%;
  }
  .offset-mdl-4 {
    margin-left: 33.33333333%;
  }
  .offset-mdl-5 {
    margin-left: 41.66666667%;
  }
  .offset-mdl-6 {
    margin-left: 50%;
  }
  .offset-mdl-7 {
    margin-left: 58.33333333%;
  }
  .offset-mdl-8 {
    margin-left: 66.66666667%;
  }
  .offset-mdl-9 {
    margin-left: 75%;
  }
  .offset-mdl-10 {
    margin-left: 83.33333333%;
  }
  .offset-mdl-11 {
    margin-left: 91.66666667%;
  }
  .g-mdl-0,
.gx-mdl-0 {
    --bs-gutter-x: 0;
  }
  .g-mdl-0,
.gy-mdl-0 {
    --bs-gutter-y: 0;
  }
  .g-mdl-1,
.gx-mdl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-mdl-1,
.gy-mdl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-mdl-2,
.gx-mdl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-mdl-2,
.gy-mdl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-mdl-3,
.gx-mdl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-mdl-3,
.gy-mdl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-mdl-4,
.gx-mdl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-mdl-4,
.gy-mdl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-mdl-5,
.gx-mdl-5 {
    --bs-gutter-x: 2.3rem;
  }
  .g-mdl-5,
.gy-mdl-5 {
    --bs-gutter-y: 2.3rem;
  }
  .g-mdl-6,
.gx-mdl-6 {
    --bs-gutter-x: 2.8rem;
  }
  .g-mdl-6,
.gy-mdl-6 {
    --bs-gutter-y: 2.8rem;
  }
  .g-mdl-12,
.gx-mdl-12 {
    --bs-gutter-x: 0.75rem;
  }
  .g-mdl-12,
.gy-mdl-12 {
    --bs-gutter-y: 0.75rem;
  }
  .g-mdl-20,
.gx-mdl-20 {
    --bs-gutter-x: 1.25rem;
  }
  .g-mdl-20,
.gy-mdl-20 {
    --bs-gutter-y: 1.25rem;
  }
  .g-mdl-200,
.gx-mdl-200 {
    --bs-gutter-x: 2rem;
  }
  .g-mdl-200,
.gy-mdl-200 {
    --bs-gutter-y: 2rem;
  }
  .g-mdl-sm,
.gx-mdl-sm {
    --bs-gutter-x: 0.875rem;
  }
  .g-mdl-sm,
.gy-mdl-sm {
    --bs-gutter-y: 0.875rem;
  }
  .g-mdl-xs,
.gx-mdl-xs {
    --bs-gutter-x: 0.375rem;
  }
  .g-mdl-xs,
.gy-mdl-xs {
    --bs-gutter-y: 0.375rem;
  }
  .g-mdl-xxs,
.gx-mdl-xxs {
    --bs-gutter-x: 0.188rem;
  }
  .g-mdl-xxs,
.gy-mdl-xxs {
    --bs-gutter-y: 0.188rem;
  }
}
@media (min-width: 1200px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 2.3rem;
  }
  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 2.3rem;
  }
  .g-lg-6,
.gx-lg-6 {
    --bs-gutter-x: 2.8rem;
  }
  .g-lg-6,
.gy-lg-6 {
    --bs-gutter-y: 2.8rem;
  }
  .g-lg-12,
.gx-lg-12 {
    --bs-gutter-x: 0.75rem;
  }
  .g-lg-12,
.gy-lg-12 {
    --bs-gutter-y: 0.75rem;
  }
  .g-lg-20,
.gx-lg-20 {
    --bs-gutter-x: 1.25rem;
  }
  .g-lg-20,
.gy-lg-20 {
    --bs-gutter-y: 1.25rem;
  }
  .g-lg-200,
.gx-lg-200 {
    --bs-gutter-x: 2rem;
  }
  .g-lg-200,
.gy-lg-200 {
    --bs-gutter-y: 2rem;
  }
  .g-lg-sm,
.gx-lg-sm {
    --bs-gutter-x: 0.875rem;
  }
  .g-lg-sm,
.gy-lg-sm {
    --bs-gutter-y: 0.875rem;
  }
  .g-lg-xs,
.gx-lg-xs {
    --bs-gutter-x: 0.375rem;
  }
  .g-lg-xs,
.gy-lg-xs {
    --bs-gutter-y: 0.375rem;
  }
  .g-lg-xxs,
.gx-lg-xxs {
    --bs-gutter-x: 0.188rem;
  }
  .g-lg-xxs,
.gy-lg-xxs {
    --bs-gutter-y: 0.188rem;
  }
}
@media (min-width: 1536px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 2.3rem;
  }
  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 2.3rem;
  }
  .g-xl-6,
.gx-xl-6 {
    --bs-gutter-x: 2.8rem;
  }
  .g-xl-6,
.gy-xl-6 {
    --bs-gutter-y: 2.8rem;
  }
  .g-xl-12,
.gx-xl-12 {
    --bs-gutter-x: 0.75rem;
  }
  .g-xl-12,
.gy-xl-12 {
    --bs-gutter-y: 0.75rem;
  }
  .g-xl-20,
.gx-xl-20 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xl-20,
.gy-xl-20 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xl-200,
.gx-xl-200 {
    --bs-gutter-x: 2rem;
  }
  .g-xl-200,
.gy-xl-200 {
    --bs-gutter-y: 2rem;
  }
  .g-xl-sm,
.gx-xl-sm {
    --bs-gutter-x: 0.875rem;
  }
  .g-xl-sm,
.gy-xl-sm {
    --bs-gutter-y: 0.875rem;
  }
  .g-xl-xs,
.gx-xl-xs {
    --bs-gutter-x: 0.375rem;
  }
  .g-xl-xs,
.gy-xl-xs {
    --bs-gutter-y: 0.375rem;
  }
  .g-xl-xxs,
.gx-xl-xxs {
    --bs-gutter-x: 0.188rem;
  }
  .g-xl-xxs,
.gy-xl-xxs {
    --bs-gutter-y: 0.188rem;
  }
}
.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: #F5F7FB;
  --bs-table-border-color: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: #FBFCFE;
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #ccd3e2;
  --bs-table-border-color: #b8becb;
  --bs-table-striped-bg: #c2c8d7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8becb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdc3d1;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #fdfeff;
  --bs-table-border-color: #e4e5e6;
  --bs-table-striped-bg: #f0f1f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e4e5e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #eaebec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f1d1d1;
  --bs-table-border-color: #d9bcbc;
  --bs-table-striped-bg: #e5c7c7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d9bcbc;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #dfc1c1;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 599.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 899.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1023.98px) {
  .table-responsive-mdl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1535.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(1rem + 1px);
  padding-bottom: calc(1rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(1rem + 1px);
  padding-bottom: calc(1rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(1rem + 1px);
  padding-bottom: calc(1rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 1rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #E7E7ED;
  appearance: none;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #8091b7;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 35, 111, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 1rem 0.75rem;
  margin: -1rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 1rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 2rem + 2px);
  padding: 1rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.form-control-sm::file-selector-button {
  padding: 1rem 0.5rem;
  margin: -1rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 2rem + 2px);
  padding: 1rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}
.form-control-lg::file-selector-button {
  padding: 1rem 1rem;
  margin: -1rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 2rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 2rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 2rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 2rem + 2px);
  padding: 1rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 8px;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 8px;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 2rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 2rem + 2px);
}

.form-select {
  display: block;
  width: 100%;
  padding: 1rem 2.25rem 1rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #E7E9ED;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #8091b7;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 35, 111, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #8091b7;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 35, 111, 0.25);
}
.form-check-input:checked {
  background-color: #00236F;
  border-color: #00236F;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #00236F;
  border-color: #00236F;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238091b7'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(0, 35, 111, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(0, 35, 111, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #00236F;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b3bdd4;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #00236F;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b3bdd4;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.5rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.5rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 1rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #E7E9ED;
  border-radius: 8px;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 1rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 1rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.375rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.5rem) center;
  background-size: calc(0.75em + 1rem) calc(0.75em + 1rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 2rem);
  background-position: top calc(0.375em + 0.5rem) right calc(0.375em + 0.5rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 1rem) calc(0.75em + 1rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 2rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #BA1A1A;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(186, 26, 26, 0.9);
  border-radius: 0.375rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #BA1A1A;
  padding-right: calc(1.5em + 2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23BA1A1A'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23BA1A1A' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.5rem) center;
  background-size: calc(0.75em + 1rem) calc(0.75em + 1rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #BA1A1A;
  box-shadow: 0 0 0 0.25rem rgba(186, 26, 26, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 2rem);
  background-position: top calc(0.375em + 0.5rem) right calc(0.375em + 0.5rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #BA1A1A;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23BA1A1A'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23BA1A1A' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 1rem) calc(0.75em + 1rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #BA1A1A;
  box-shadow: 0 0 0 0.25rem rgba(186, 26, 26, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 2rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #BA1A1A;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #BA1A1A;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(186, 26, 26, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #BA1A1A;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #00236F;
  --bs-btn-border-color: #00236F;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #001e5e;
  --bs-btn-hover-border-color: #001c59;
  --bs-btn-focus-shadow-rgb: 38, 68, 133;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #001c59;
  --bs-btn-active-border-color: #001a53;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #00236F;
  --bs-btn-disabled-border-color: #00236F;
}

.btn-secondary {
  --bs-btn-color: #000;
  --bs-btn-bg: #F5F8FE;
  --bs-btn-border-color: #F5F8FE;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f7f9fe;
  --bs-btn-hover-border-color: #f6f9fe;
  --bs-btn-focus-shadow-rgb: 208, 211, 216;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f7f9fe;
  --bs-btn-active-border-color: #f6f9fe;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #F5F8FE;
  --bs-btn-disabled-border-color: #F5F8FE;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #BA1A1A;
  --bs-btn-border-color: #BA1A1A;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #9e1616;
  --bs-btn-hover-border-color: #951515;
  --bs-btn-focus-shadow-rgb: 196, 60, 60;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #951515;
  --bs-btn-active-border-color: #8c1414;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #BA1A1A;
  --bs-btn-disabled-border-color: #BA1A1A;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #00236F;
  --bs-btn-border-color: #00236F;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00236F;
  --bs-btn-hover-border-color: #00236F;
  --bs-btn-focus-shadow-rgb: 0, 35, 111;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00236F;
  --bs-btn-active-border-color: #00236F;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00236F;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00236F;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #F5F8FE;
  --bs-btn-border-color: #F5F8FE;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #F5F8FE;
  --bs-btn-hover-border-color: #F5F8FE;
  --bs-btn-focus-shadow-rgb: 245, 248, 254;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #F5F8FE;
  --bs-btn-active-border-color: #F5F8FE;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #F5F8FE;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F5F8FE;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #BA1A1A;
  --bs-btn-border-color: #BA1A1A;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #BA1A1A;
  --bs-btn-hover-border-color: #BA1A1A;
  --bs-btn-focus-shadow-rgb: 186, 26, 26;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #BA1A1A;
  --bs-btn-active-border-color: #BA1A1A;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #BA1A1A;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #BA1A1A;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 38, 68, 133;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 0.5rem;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.25rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #00236F;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 600px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 900px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1024px) {
  .dropdown-menu-mdl-start {
    --bs-position: start;
  }
  .dropdown-menu-mdl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-mdl-end {
    --bs-position: end;
  }
  .dropdown-menu-mdl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1536px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #00236F;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 0.375rem;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #00236F;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(0, 0, 0, 0.55);
  --bs-navbar-hover-color: rgba(0, 0, 0, 0.7);
  --bs-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --bs-navbar-active-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .show > .nav-link,
.navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 600px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 900px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1024px) {
  .navbar-expand-mdl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-mdl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-mdl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-mdl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-mdl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-mdl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-mdl .navbar-toggler {
    display: none;
  }
  .navbar-expand-mdl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-mdl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-mdl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1536px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: #E7E9ED;
  --bs-card-border-radius: 8px;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: 7px;
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #FFFFFF;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 600px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #FFFFFF;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 14px;
  --bs-accordion-btn-padding-y: 10px;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.5rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23002064'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #8091b7;
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 14px;
  --bs-accordion-body-padding-y: 0px;
  --bs-accordion-active-color: #002064;
  --bs-accordion-active-bg: #e6e9f1;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(0, 35, 111, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #00236F;
  --bs-pagination-active-border-color: #00236F;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: 0.5rem;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: 0.25rem;
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.375rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: #001543;
  --bs-alert-bg: #ccd3e2;
  --bs-alert-border-color: #b3bdd4;
}
.alert-primary .alert-link {
  color: #001136;
}

.alert-secondary {
  --bs-alert-color: #626366;
  --bs-alert-bg: #fdfeff;
  --bs-alert-border-color: #fcfdff;
}
.alert-secondary .alert-link {
  color: #4e4f52;
}

.alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}
.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}
.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  --bs-alert-color: #701010;
  --bs-alert-bg: #f1d1d1;
  --bs-alert-border-color: #eababa;
}
.alert-danger .alert-link {
  color: #5a0d0d;
}

.alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}
.alert-dark .alert-link {
  color: #101214;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 0.375rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #00236F;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #00236F;
  --bs-list-group-active-border-color: #00236F;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 600px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 900px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1024px) {
  .list-group-horizontal-mdl {
    flex-direction: row;
  }
  .list-group-horizontal-mdl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-mdl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-mdl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-mdl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-mdl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1536px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #001543;
  background-color: #ccd3e2;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #001543;
  background-color: #b8becb;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #001543;
  border-color: #001543;
}

.list-group-item-secondary {
  color: #626366;
  background-color: #fdfeff;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #626366;
  background-color: #e4e5e6;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #626366;
  border-color: #626366;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #701010;
  background-color: #f1d1d1;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #701010;
  background-color: #d9bcbc;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #701010;
  border-color: #701010;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 35, 111, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.375rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 600px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 1200px) {
  .modal-lg,
.modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1536px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 599.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
.modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 899.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
.modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1023.98px) {
  .modal-fullscreen-mdl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-mdl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-mdl-down .modal-header,
.modal-fullscreen-mdl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-mdl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
.modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1535.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
.modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.375rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: 0.5rem;
  --bs-popover-inner-border-radius: calc(0.5rem - 1px);
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: ;
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xl, .offcanvas-lg, .offcanvas-mdl, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

@media (max-width: 599.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 599.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 599.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 599.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 599.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 599.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 599.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 599.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 600px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 899.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 899.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 899.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 899.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 899.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 899.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 899.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 899.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 900px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1023.98px) {
  .offcanvas-mdl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1023.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-mdl {
    transition: none;
  }
}
@media (max-width: 1023.98px) {
  .offcanvas-mdl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1023.98px) {
  .offcanvas-mdl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1023.98px) {
  .offcanvas-mdl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1023.98px) {
  .offcanvas-mdl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1023.98px) {
  .offcanvas-mdl.showing, .offcanvas-mdl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1023.98px) {
  .offcanvas-mdl.showing, .offcanvas-mdl.hiding, .offcanvas-mdl.show {
    visibility: visible;
  }
}
@media (min-width: 1024px) {
  .offcanvas-mdl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-mdl .offcanvas-header {
    display: none;
  }
  .offcanvas-mdl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1535.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
  }
}
@media (max-width: 1535.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1535.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1535.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1535.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1535.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1535.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1535.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1536px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(0, 35, 111, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(245, 248, 254, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(186, 26, 26, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #00236F !important;
}
.link-primary:hover, .link-primary:focus {
  color: #001c59 !important;
}

.link-secondary {
  color: #F5F8FE !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: #f7f9fe !important;
}

.link-success {
  color: #198754 !important;
}
.link-success:hover, .link-success:focus {
  color: #146c43 !important;
}

.link-info {
  color: #0dcaf0 !important;
}
.link-info:hover, .link-info:focus {
  color: #3dd5f3 !important;
}

.link-warning {
  color: #ffc107 !important;
}
.link-warning:hover, .link-warning:focus {
  color: #ffcd39 !important;
}

.link-danger {
  color: #BA1A1A !important;
}
.link-danger:hover, .link-danger:focus {
  color: #951515 !important;
}

.link-light {
  color: #f8f9fa !important;
}
.link-light:hover, .link-light:focus {
  color: #f9fafb !important;
}

.link-dark {
  color: #212529 !important;
}
.link-dark:hover, .link-dark:focus {
  color: #1a1e21 !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 600px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 900px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1024px) {
  .sticky-mdl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-mdl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1536px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2.3rem !important;
}

.m-6 {
  margin: 2.8rem !important;
}

.m-12 {
  margin: 0.75rem !important;
}

.m-20 {
  margin: 1.25rem !important;
}

.m-200 {
  margin: 2rem !important;
}

.m-sm {
  margin: 0.875rem !important;
}

.m-xs {
  margin: 0.375rem !important;
}

.m-xxs {
  margin: 0.188rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 2.3rem !important;
  margin-left: 2.3rem !important;
}

.mx-6 {
  margin-right: 2.8rem !important;
  margin-left: 2.8rem !important;
}

.mx-12 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-20 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-200 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-sm {
  margin-right: 0.875rem !important;
  margin-left: 0.875rem !important;
}

.mx-xs {
  margin-right: 0.375rem !important;
  margin-left: 0.375rem !important;
}

.mx-xxs {
  margin-right: 0.188rem !important;
  margin-left: 0.188rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2.3rem !important;
  margin-bottom: 2.3rem !important;
}

.my-6 {
  margin-top: 2.8rem !important;
  margin-bottom: 2.8rem !important;
}

.my-12 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-20 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-200 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-sm {
  margin-top: 0.875rem !important;
  margin-bottom: 0.875rem !important;
}

.my-xs {
  margin-top: 0.375rem !important;
  margin-bottom: 0.375rem !important;
}

.my-xxs {
  margin-top: 0.188rem !important;
  margin-bottom: 0.188rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2.3rem !important;
}

.mt-6 {
  margin-top: 2.8rem !important;
}

.mt-12 {
  margin-top: 0.75rem !important;
}

.mt-20 {
  margin-top: 1.25rem !important;
}

.mt-200 {
  margin-top: 2rem !important;
}

.mt-sm {
  margin-top: 0.875rem !important;
}

.mt-xs {
  margin-top: 0.375rem !important;
}

.mt-xxs {
  margin-top: 0.188rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 2.3rem !important;
}

.me-6 {
  margin-right: 2.8rem !important;
}

.me-12 {
  margin-right: 0.75rem !important;
}

.me-20 {
  margin-right: 1.25rem !important;
}

.me-200 {
  margin-right: 2rem !important;
}

.me-sm {
  margin-right: 0.875rem !important;
}

.me-xs {
  margin-right: 0.375rem !important;
}

.me-xxs {
  margin-right: 0.188rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2.3rem !important;
}

.mb-6 {
  margin-bottom: 2.8rem !important;
}

.mb-12 {
  margin-bottom: 0.75rem !important;
}

.mb-20 {
  margin-bottom: 1.25rem !important;
}

.mb-200 {
  margin-bottom: 2rem !important;
}

.mb-sm {
  margin-bottom: 0.875rem !important;
}

.mb-xs {
  margin-bottom: 0.375rem !important;
}

.mb-xxs {
  margin-bottom: 0.188rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 2.3rem !important;
}

.ms-6 {
  margin-left: 2.8rem !important;
}

.ms-12 {
  margin-left: 0.75rem !important;
}

.ms-20 {
  margin-left: 1.25rem !important;
}

.ms-200 {
  margin-left: 2rem !important;
}

.ms-sm {
  margin-left: 0.875rem !important;
}

.ms-xs {
  margin-left: 0.375rem !important;
}

.ms-xxs {
  margin-left: 0.188rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2.3rem !important;
}

.m-6 {
  margin: 2.8rem !important;
}

.m-12 {
  margin: 0.75rem !important;
}

.m-20 {
  margin: 1.25rem !important;
}

.m-200 {
  margin: 2rem !important;
}

.m-sm {
  margin: 0.875rem !important;
}

.m-xs {
  margin: 0.375rem !important;
}

.m-xxs {
  margin: 0.188rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -0.625rem !important;
}

.m-n4 {
  margin: -0.875rem !important;
}

.m-n5 {
  margin: -1.5rem !important;
}

.m-n6 {
  margin: -2.3rem !important;
}

.m-n7 {
  margin: -2.8rem !important;
}

.m-n12 {
  margin: -0.75rem !important;
}

.m-n20 {
  margin: -1.25rem !important;
}

.m-n200 {
  margin: -2rem !important;
}

.m-nsm {
  margin: -0.875rem !important;
}

.m-nxs {
  margin: -0.375rem !important;
}

.m-nxxs {
  margin: -0.188rem !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 2.3rem !important;
  margin-left: 2.3rem !important;
}

.mx-6 {
  margin-right: 2.8rem !important;
  margin-left: 2.8rem !important;
}

.mx-12 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-20 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-200 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-sm {
  margin-right: 0.875rem !important;
  margin-left: 0.875rem !important;
}

.mx-xs {
  margin-right: 0.375rem !important;
  margin-left: 0.375rem !important;
}

.mx-xxs {
  margin-right: 0.188rem !important;
  margin-left: 0.188rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -0.625rem !important;
  margin-left: -0.625rem !important;
}

.mx-n4 {
  margin-right: -0.875rem !important;
  margin-left: -0.875rem !important;
}

.mx-n5 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n6 {
  margin-right: -2.3rem !important;
  margin-left: -2.3rem !important;
}

.mx-n7 {
  margin-right: -2.8rem !important;
  margin-left: -2.8rem !important;
}

.mx-n12 {
  margin-right: -0.75rem !important;
  margin-left: -0.75rem !important;
}

.mx-n20 {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important;
}

.mx-n200 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-nsm {
  margin-right: -0.875rem !important;
  margin-left: -0.875rem !important;
}

.mx-nxs {
  margin-right: -0.375rem !important;
  margin-left: -0.375rem !important;
}

.mx-nxxs {
  margin-right: -0.188rem !important;
  margin-left: -0.188rem !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2.3rem !important;
  margin-bottom: 2.3rem !important;
}

.my-6 {
  margin-top: 2.8rem !important;
  margin-bottom: 2.8rem !important;
}

.my-12 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-20 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-200 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-sm {
  margin-top: 0.875rem !important;
  margin-bottom: 0.875rem !important;
}

.my-xs {
  margin-top: 0.375rem !important;
  margin-bottom: 0.375rem !important;
}

.my-xxs {
  margin-top: 0.188rem !important;
  margin-bottom: 0.188rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -0.625rem !important;
  margin-bottom: -0.625rem !important;
}

.my-n4 {
  margin-top: -0.875rem !important;
  margin-bottom: -0.875rem !important;
}

.my-n5 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n6 {
  margin-top: -2.3rem !important;
  margin-bottom: -2.3rem !important;
}

.my-n7 {
  margin-top: -2.8rem !important;
  margin-bottom: -2.8rem !important;
}

.my-n12 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.my-n20 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.my-n200 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-nsm {
  margin-top: -0.875rem !important;
  margin-bottom: -0.875rem !important;
}

.my-nxs {
  margin-top: -0.375rem !important;
  margin-bottom: -0.375rem !important;
}

.my-nxxs {
  margin-top: -0.188rem !important;
  margin-bottom: -0.188rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2.3rem !important;
}

.mt-6 {
  margin-top: 2.8rem !important;
}

.mt-12 {
  margin-top: 0.75rem !important;
}

.mt-20 {
  margin-top: 1.25rem !important;
}

.mt-200 {
  margin-top: 2rem !important;
}

.mt-sm {
  margin-top: 0.875rem !important;
}

.mt-xs {
  margin-top: 0.375rem !important;
}

.mt-xxs {
  margin-top: 0.188rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -0.625rem !important;
}

.mt-n4 {
  margin-top: -0.875rem !important;
}

.mt-n5 {
  margin-top: -1.5rem !important;
}

.mt-n6 {
  margin-top: -2.3rem !important;
}

.mt-n7 {
  margin-top: -2.8rem !important;
}

.mt-n12 {
  margin-top: -0.75rem !important;
}

.mt-n20 {
  margin-top: -1.25rem !important;
}

.mt-n200 {
  margin-top: -2rem !important;
}

.mt-nsm {
  margin-top: -0.875rem !important;
}

.mt-nxs {
  margin-top: -0.375rem !important;
}

.mt-nxxs {
  margin-top: -0.188rem !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 2.3rem !important;
}

.me-6 {
  margin-right: 2.8rem !important;
}

.me-12 {
  margin-right: 0.75rem !important;
}

.me-20 {
  margin-right: 1.25rem !important;
}

.me-200 {
  margin-right: 2rem !important;
}

.me-sm {
  margin-right: 0.875rem !important;
}

.me-xs {
  margin-right: 0.375rem !important;
}

.me-xxs {
  margin-right: 0.188rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -0.625rem !important;
}

.me-n4 {
  margin-right: -0.875rem !important;
}

.me-n5 {
  margin-right: -1.5rem !important;
}

.me-n6 {
  margin-right: -2.3rem !important;
}

.me-n7 {
  margin-right: -2.8rem !important;
}

.me-n12 {
  margin-right: -0.75rem !important;
}

.me-n20 {
  margin-right: -1.25rem !important;
}

.me-n200 {
  margin-right: -2rem !important;
}

.me-nsm {
  margin-right: -0.875rem !important;
}

.me-nxs {
  margin-right: -0.375rem !important;
}

.me-nxxs {
  margin-right: -0.188rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2.3rem !important;
}

.mb-6 {
  margin-bottom: 2.8rem !important;
}

.mb-12 {
  margin-bottom: 0.75rem !important;
}

.mb-20 {
  margin-bottom: 1.25rem !important;
}

.mb-200 {
  margin-bottom: 2rem !important;
}

.mb-sm {
  margin-bottom: 0.875rem !important;
}

.mb-xs {
  margin-bottom: 0.375rem !important;
}

.mb-xxs {
  margin-bottom: 0.188rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -0.625rem !important;
}

.mb-n4 {
  margin-bottom: -0.875rem !important;
}

.mb-n5 {
  margin-bottom: -1.5rem !important;
}

.mb-n6 {
  margin-bottom: -2.3rem !important;
}

.mb-n7 {
  margin-bottom: -2.8rem !important;
}

.mb-n12 {
  margin-bottom: -0.75rem !important;
}

.mb-n20 {
  margin-bottom: -1.25rem !important;
}

.mb-n200 {
  margin-bottom: -2rem !important;
}

.mb-nsm {
  margin-bottom: -0.875rem !important;
}

.mb-nxs {
  margin-bottom: -0.375rem !important;
}

.mb-nxxs {
  margin-bottom: -0.188rem !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 2.3rem !important;
}

.ms-6 {
  margin-left: 2.8rem !important;
}

.ms-12 {
  margin-left: 0.75rem !important;
}

.ms-20 {
  margin-left: 1.25rem !important;
}

.ms-200 {
  margin-left: 2rem !important;
}

.ms-sm {
  margin-left: 0.875rem !important;
}

.ms-xs {
  margin-left: 0.375rem !important;
}

.ms-xxs {
  margin-left: 0.188rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -0.625rem !important;
}

.ms-n4 {
  margin-left: -0.875rem !important;
}

.ms-n5 {
  margin-left: -1.5rem !important;
}

.ms-n6 {
  margin-left: -2.3rem !important;
}

.ms-n7 {
  margin-left: -2.8rem !important;
}

.ms-n12 {
  margin-left: -0.75rem !important;
}

.ms-n20 {
  margin-left: -1.25rem !important;
}

.ms-n200 {
  margin-left: -2rem !important;
}

.ms-nsm {
  margin-left: -0.875rem !important;
}

.ms-nxs {
  margin-left: -0.375rem !important;
}

.ms-nxxs {
  margin-left: -0.188rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2.3rem !important;
}

.p-6 {
  padding: 2.8rem !important;
}

.p-12 {
  padding: 0.75rem !important;
}

.p-20 {
  padding: 1.25rem !important;
}

.p-200 {
  padding: 2rem !important;
}

.p-sm {
  padding: 0.875rem !important;
}

.p-xs {
  padding: 0.375rem !important;
}

.p-xxs {
  padding: 0.188rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 2.3rem !important;
  padding-left: 2.3rem !important;
}

.px-6 {
  padding-right: 2.8rem !important;
  padding-left: 2.8rem !important;
}

.px-12 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.px-20 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-200 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-sm {
  padding-right: 0.875rem !important;
  padding-left: 0.875rem !important;
}

.px-xs {
  padding-right: 0.375rem !important;
  padding-left: 0.375rem !important;
}

.px-xxs {
  padding-right: 0.188rem !important;
  padding-left: 0.188rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2.3rem !important;
  padding-bottom: 2.3rem !important;
}

.py-6 {
  padding-top: 2.8rem !important;
  padding-bottom: 2.8rem !important;
}

.py-12 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-20 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-200 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-sm {
  padding-top: 0.875rem !important;
  padding-bottom: 0.875rem !important;
}

.py-xs {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}

.py-xxs {
  padding-top: 0.188rem !important;
  padding-bottom: 0.188rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2.3rem !important;
}

.pt-6 {
  padding-top: 2.8rem !important;
}

.pt-12 {
  padding-top: 0.75rem !important;
}

.pt-20 {
  padding-top: 1.25rem !important;
}

.pt-200 {
  padding-top: 2rem !important;
}

.pt-sm {
  padding-top: 0.875rem !important;
}

.pt-xs {
  padding-top: 0.375rem !important;
}

.pt-xxs {
  padding-top: 0.188rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 2.3rem !important;
}

.pe-6 {
  padding-right: 2.8rem !important;
}

.pe-12 {
  padding-right: 0.75rem !important;
}

.pe-20 {
  padding-right: 1.25rem !important;
}

.pe-200 {
  padding-right: 2rem !important;
}

.pe-sm {
  padding-right: 0.875rem !important;
}

.pe-xs {
  padding-right: 0.375rem !important;
}

.pe-xxs {
  padding-right: 0.188rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2.3rem !important;
}

.pb-6 {
  padding-bottom: 2.8rem !important;
}

.pb-12 {
  padding-bottom: 0.75rem !important;
}

.pb-20 {
  padding-bottom: 1.25rem !important;
}

.pb-200 {
  padding-bottom: 2rem !important;
}

.pb-sm {
  padding-bottom: 0.875rem !important;
}

.pb-xs {
  padding-bottom: 0.375rem !important;
}

.pb-xxs {
  padding-bottom: 0.188rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 2.3rem !important;
}

.ps-6 {
  padding-left: 2.8rem !important;
}

.ps-12 {
  padding-left: 0.75rem !important;
}

.ps-20 {
  padding-left: 1.25rem !important;
}

.ps-200 {
  padding-left: 2rem !important;
}

.ps-sm {
  padding-left: 0.875rem !important;
}

.ps-xs {
  padding-left: 0.375rem !important;
}

.ps-xxs {
  padding-left: 0.188rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 2.3rem !important;
}

.gap-6 {
  gap: 2.8rem !important;
}

.gap-12 {
  gap: 0.75rem !important;
}

.gap-20 {
  gap: 1.25rem !important;
}

.gap-200 {
  gap: 2rem !important;
}

.gap-sm {
  gap: 0.875rem !important;
}

.gap-xs {
  gap: 0.375rem !important;
}

.gap-xxs {
  gap: 0.188rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fs-12 {
  font-size: 0.75rem !important;
}

.fs-14 {
  font-size: 0.875rem !important;
}

.fs-16 {
  font-size: 1rem !important;
}

.fs-18 {
  font-size: 1.125rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 600px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2.3rem !important;
  }
  .m-sm-6 {
    margin: 2.8rem !important;
  }
  .m-sm-12 {
    margin: 0.75rem !important;
  }
  .m-sm-20 {
    margin: 1.25rem !important;
  }
  .m-sm-200 {
    margin: 2rem !important;
  }
  .m-sm-sm {
    margin: 0.875rem !important;
  }
  .m-sm-xs {
    margin: 0.375rem !important;
  }
  .m-sm-xxs {
    margin: 0.188rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important;
  }
  .mx-sm-6 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important;
  }
  .mx-sm-12 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-sm-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-sm-200 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-sm-sm {
    margin-right: 0.875rem !important;
    margin-left: 0.875rem !important;
  }
  .mx-sm-xs {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-sm-xxs {
    margin-right: 0.188rem !important;
    margin-left: 0.188rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important;
  }
  .my-sm-6 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important;
  }
  .my-sm-12 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-sm-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-sm-200 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-sm-sm {
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }
  .my-sm-xs {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-sm-xxs {
    margin-top: 0.188rem !important;
    margin-bottom: 0.188rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 2.3rem !important;
  }
  .mt-sm-6 {
    margin-top: 2.8rem !important;
  }
  .mt-sm-12 {
    margin-top: 0.75rem !important;
  }
  .mt-sm-20 {
    margin-top: 1.25rem !important;
  }
  .mt-sm-200 {
    margin-top: 2rem !important;
  }
  .mt-sm-sm {
    margin-top: 0.875rem !important;
  }
  .mt-sm-xs {
    margin-top: 0.375rem !important;
  }
  .mt-sm-xxs {
    margin-top: 0.188rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 2.3rem !important;
  }
  .me-sm-6 {
    margin-right: 2.8rem !important;
  }
  .me-sm-12 {
    margin-right: 0.75rem !important;
  }
  .me-sm-20 {
    margin-right: 1.25rem !important;
  }
  .me-sm-200 {
    margin-right: 2rem !important;
  }
  .me-sm-sm {
    margin-right: 0.875rem !important;
  }
  .me-sm-xs {
    margin-right: 0.375rem !important;
  }
  .me-sm-xxs {
    margin-right: 0.188rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 2.3rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 2.8rem !important;
  }
  .mb-sm-12 {
    margin-bottom: 0.75rem !important;
  }
  .mb-sm-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-sm-200 {
    margin-bottom: 2rem !important;
  }
  .mb-sm-sm {
    margin-bottom: 0.875rem !important;
  }
  .mb-sm-xs {
    margin-bottom: 0.375rem !important;
  }
  .mb-sm-xxs {
    margin-bottom: 0.188rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 2.3rem !important;
  }
  .ms-sm-6 {
    margin-left: 2.8rem !important;
  }
  .ms-sm-12 {
    margin-left: 0.75rem !important;
  }
  .ms-sm-20 {
    margin-left: 1.25rem !important;
  }
  .ms-sm-200 {
    margin-left: 2rem !important;
  }
  .ms-sm-sm {
    margin-left: 0.875rem !important;
  }
  .ms-sm-xs {
    margin-left: 0.375rem !important;
  }
  .ms-sm-xxs {
    margin-left: 0.188rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2.3rem !important;
  }
  .m-sm-6 {
    margin: 2.8rem !important;
  }
  .m-sm-12 {
    margin: 0.75rem !important;
  }
  .m-sm-20 {
    margin: 1.25rem !important;
  }
  .m-sm-200 {
    margin: 2rem !important;
  }
  .m-sm-sm {
    margin: 0.875rem !important;
  }
  .m-sm-xs {
    margin: 0.375rem !important;
  }
  .m-sm-xxs {
    margin: 0.188rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -0.625rem !important;
  }
  .m-sm-n4 {
    margin: -0.875rem !important;
  }
  .m-sm-n5 {
    margin: -1.5rem !important;
  }
  .m-sm-n6 {
    margin: -2.3rem !important;
  }
  .m-sm-n7 {
    margin: -2.8rem !important;
  }
  .m-sm-n12 {
    margin: -0.75rem !important;
  }
  .m-sm-n20 {
    margin: -1.25rem !important;
  }
  .m-sm-n200 {
    margin: -2rem !important;
  }
  .m-sm-nsm {
    margin: -0.875rem !important;
  }
  .m-sm-nxs {
    margin: -0.375rem !important;
  }
  .m-sm-nxxs {
    margin: -0.188rem !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important;
  }
  .mx-sm-6 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important;
  }
  .mx-sm-12 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-sm-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-sm-200 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-sm-sm {
    margin-right: 0.875rem !important;
    margin-left: 0.875rem !important;
  }
  .mx-sm-xs {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-sm-xxs {
    margin-right: 0.188rem !important;
    margin-left: 0.188rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-sm-n4 {
    margin-right: -0.875rem !important;
    margin-left: -0.875rem !important;
  }
  .mx-sm-n5 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n6 {
    margin-right: -2.3rem !important;
    margin-left: -2.3rem !important;
  }
  .mx-sm-n7 {
    margin-right: -2.8rem !important;
    margin-left: -2.8rem !important;
  }
  .mx-sm-n12 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-sm-n20 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-sm-n200 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-sm-nsm {
    margin-right: -0.875rem !important;
    margin-left: -0.875rem !important;
  }
  .mx-sm-nxs {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-sm-nxxs {
    margin-right: -0.188rem !important;
    margin-left: -0.188rem !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important;
  }
  .my-sm-6 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important;
  }
  .my-sm-12 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-sm-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-sm-200 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-sm-sm {
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }
  .my-sm-xs {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-sm-xxs {
    margin-top: 0.188rem !important;
    margin-bottom: 0.188rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-sm-n4 {
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }
  .my-sm-n5 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n6 {
    margin-top: -2.3rem !important;
    margin-bottom: -2.3rem !important;
  }
  .my-sm-n7 {
    margin-top: -2.8rem !important;
    margin-bottom: -2.8rem !important;
  }
  .my-sm-n12 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-sm-n20 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-sm-n200 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-sm-nsm {
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }
  .my-sm-nxs {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-sm-nxxs {
    margin-top: -0.188rem !important;
    margin-bottom: -0.188rem !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 2.3rem !important;
  }
  .mt-sm-6 {
    margin-top: 2.8rem !important;
  }
  .mt-sm-12 {
    margin-top: 0.75rem !important;
  }
  .mt-sm-20 {
    margin-top: 1.25rem !important;
  }
  .mt-sm-200 {
    margin-top: 2rem !important;
  }
  .mt-sm-sm {
    margin-top: 0.875rem !important;
  }
  .mt-sm-xs {
    margin-top: 0.375rem !important;
  }
  .mt-sm-xxs {
    margin-top: 0.188rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -0.625rem !important;
  }
  .mt-sm-n4 {
    margin-top: -0.875rem !important;
  }
  .mt-sm-n5 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n6 {
    margin-top: -2.3rem !important;
  }
  .mt-sm-n7 {
    margin-top: -2.8rem !important;
  }
  .mt-sm-n12 {
    margin-top: -0.75rem !important;
  }
  .mt-sm-n20 {
    margin-top: -1.25rem !important;
  }
  .mt-sm-n200 {
    margin-top: -2rem !important;
  }
  .mt-sm-nsm {
    margin-top: -0.875rem !important;
  }
  .mt-sm-nxs {
    margin-top: -0.375rem !important;
  }
  .mt-sm-nxxs {
    margin-top: -0.188rem !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 2.3rem !important;
  }
  .me-sm-6 {
    margin-right: 2.8rem !important;
  }
  .me-sm-12 {
    margin-right: 0.75rem !important;
  }
  .me-sm-20 {
    margin-right: 1.25rem !important;
  }
  .me-sm-200 {
    margin-right: 2rem !important;
  }
  .me-sm-sm {
    margin-right: 0.875rem !important;
  }
  .me-sm-xs {
    margin-right: 0.375rem !important;
  }
  .me-sm-xxs {
    margin-right: 0.188rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -0.625rem !important;
  }
  .me-sm-n4 {
    margin-right: -0.875rem !important;
  }
  .me-sm-n5 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n6 {
    margin-right: -2.3rem !important;
  }
  .me-sm-n7 {
    margin-right: -2.8rem !important;
  }
  .me-sm-n12 {
    margin-right: -0.75rem !important;
  }
  .me-sm-n20 {
    margin-right: -1.25rem !important;
  }
  .me-sm-n200 {
    margin-right: -2rem !important;
  }
  .me-sm-nsm {
    margin-right: -0.875rem !important;
  }
  .me-sm-nxs {
    margin-right: -0.375rem !important;
  }
  .me-sm-nxxs {
    margin-right: -0.188rem !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 2.3rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 2.8rem !important;
  }
  .mb-sm-12 {
    margin-bottom: 0.75rem !important;
  }
  .mb-sm-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-sm-200 {
    margin-bottom: 2rem !important;
  }
  .mb-sm-sm {
    margin-bottom: 0.875rem !important;
  }
  .mb-sm-xs {
    margin-bottom: 0.375rem !important;
  }
  .mb-sm-xxs {
    margin-bottom: 0.188rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -0.625rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -0.875rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -2.3rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -2.8rem !important;
  }
  .mb-sm-n12 {
    margin-bottom: -0.75rem !important;
  }
  .mb-sm-n20 {
    margin-bottom: -1.25rem !important;
  }
  .mb-sm-n200 {
    margin-bottom: -2rem !important;
  }
  .mb-sm-nsm {
    margin-bottom: -0.875rem !important;
  }
  .mb-sm-nxs {
    margin-bottom: -0.375rem !important;
  }
  .mb-sm-nxxs {
    margin-bottom: -0.188rem !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 2.3rem !important;
  }
  .ms-sm-6 {
    margin-left: 2.8rem !important;
  }
  .ms-sm-12 {
    margin-left: 0.75rem !important;
  }
  .ms-sm-20 {
    margin-left: 1.25rem !important;
  }
  .ms-sm-200 {
    margin-left: 2rem !important;
  }
  .ms-sm-sm {
    margin-left: 0.875rem !important;
  }
  .ms-sm-xs {
    margin-left: 0.375rem !important;
  }
  .ms-sm-xxs {
    margin-left: 0.188rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -0.625rem !important;
  }
  .ms-sm-n4 {
    margin-left: -0.875rem !important;
  }
  .ms-sm-n5 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n6 {
    margin-left: -2.3rem !important;
  }
  .ms-sm-n7 {
    margin-left: -2.8rem !important;
  }
  .ms-sm-n12 {
    margin-left: -0.75rem !important;
  }
  .ms-sm-n20 {
    margin-left: -1.25rem !important;
  }
  .ms-sm-n200 {
    margin-left: -2rem !important;
  }
  .ms-sm-nsm {
    margin-left: -0.875rem !important;
  }
  .ms-sm-nxs {
    margin-left: -0.375rem !important;
  }
  .ms-sm-nxxs {
    margin-left: -0.188rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2.3rem !important;
  }
  .p-sm-6 {
    padding: 2.8rem !important;
  }
  .p-sm-12 {
    padding: 0.75rem !important;
  }
  .p-sm-20 {
    padding: 1.25rem !important;
  }
  .p-sm-200 {
    padding: 2rem !important;
  }
  .p-sm-sm {
    padding: 0.875rem !important;
  }
  .p-sm-xs {
    padding: 0.375rem !important;
  }
  .p-sm-xxs {
    padding: 0.188rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 2.3rem !important;
    padding-left: 2.3rem !important;
  }
  .px-sm-6 {
    padding-right: 2.8rem !important;
    padding-left: 2.8rem !important;
  }
  .px-sm-12 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-sm-20 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-sm-200 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-sm-sm {
    padding-right: 0.875rem !important;
    padding-left: 0.875rem !important;
  }
  .px-sm-xs {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-sm-xxs {
    padding-right: 0.188rem !important;
    padding-left: 0.188rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 2.3rem !important;
    padding-bottom: 2.3rem !important;
  }
  .py-sm-6 {
    padding-top: 2.8rem !important;
    padding-bottom: 2.8rem !important;
  }
  .py-sm-12 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-sm-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-sm-200 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-sm-sm {
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
  }
  .py-sm-xs {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-sm-xxs {
    padding-top: 0.188rem !important;
    padding-bottom: 0.188rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 2.3rem !important;
  }
  .pt-sm-6 {
    padding-top: 2.8rem !important;
  }
  .pt-sm-12 {
    padding-top: 0.75rem !important;
  }
  .pt-sm-20 {
    padding-top: 1.25rem !important;
  }
  .pt-sm-200 {
    padding-top: 2rem !important;
  }
  .pt-sm-sm {
    padding-top: 0.875rem !important;
  }
  .pt-sm-xs {
    padding-top: 0.375rem !important;
  }
  .pt-sm-xxs {
    padding-top: 0.188rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 2.3rem !important;
  }
  .pe-sm-6 {
    padding-right: 2.8rem !important;
  }
  .pe-sm-12 {
    padding-right: 0.75rem !important;
  }
  .pe-sm-20 {
    padding-right: 1.25rem !important;
  }
  .pe-sm-200 {
    padding-right: 2rem !important;
  }
  .pe-sm-sm {
    padding-right: 0.875rem !important;
  }
  .pe-sm-xs {
    padding-right: 0.375rem !important;
  }
  .pe-sm-xxs {
    padding-right: 0.188rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 2.3rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 2.8rem !important;
  }
  .pb-sm-12 {
    padding-bottom: 0.75rem !important;
  }
  .pb-sm-20 {
    padding-bottom: 1.25rem !important;
  }
  .pb-sm-200 {
    padding-bottom: 2rem !important;
  }
  .pb-sm-sm {
    padding-bottom: 0.875rem !important;
  }
  .pb-sm-xs {
    padding-bottom: 0.375rem !important;
  }
  .pb-sm-xxs {
    padding-bottom: 0.188rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 2.3rem !important;
  }
  .ps-sm-6 {
    padding-left: 2.8rem !important;
  }
  .ps-sm-12 {
    padding-left: 0.75rem !important;
  }
  .ps-sm-20 {
    padding-left: 1.25rem !important;
  }
  .ps-sm-200 {
    padding-left: 2rem !important;
  }
  .ps-sm-sm {
    padding-left: 0.875rem !important;
  }
  .ps-sm-xs {
    padding-left: 0.375rem !important;
  }
  .ps-sm-xxs {
    padding-left: 0.188rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 2.3rem !important;
  }
  .gap-sm-6 {
    gap: 2.8rem !important;
  }
  .gap-sm-12 {
    gap: 0.75rem !important;
  }
  .gap-sm-20 {
    gap: 1.25rem !important;
  }
  .gap-sm-200 {
    gap: 2rem !important;
  }
  .gap-sm-sm {
    gap: 0.875rem !important;
  }
  .gap-sm-xs {
    gap: 0.375rem !important;
  }
  .gap-sm-xxs {
    gap: 0.188rem !important;
  }
  .fs-sm-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .fs-sm-2 {
    font-size: calc(1.325rem + 0.9vw) !important;
  }
  .fs-sm-3 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }
  .fs-sm-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-sm-5 {
    font-size: 1.25rem !important;
  }
  .fs-sm-6 {
    font-size: 1rem !important;
  }
  .fs-sm-12 {
    font-size: 0.75rem !important;
  }
  .fs-sm-14 {
    font-size: 0.875rem !important;
  }
  .fs-sm-16 {
    font-size: 1rem !important;
  }
  .fs-sm-18 {
    font-size: 1.125rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 900px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-auto {
    height: auto !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2.3rem !important;
  }
  .m-md-6 {
    margin: 2.8rem !important;
  }
  .m-md-12 {
    margin: 0.75rem !important;
  }
  .m-md-20 {
    margin: 1.25rem !important;
  }
  .m-md-200 {
    margin: 2rem !important;
  }
  .m-md-sm {
    margin: 0.875rem !important;
  }
  .m-md-xs {
    margin: 0.375rem !important;
  }
  .m-md-xxs {
    margin: 0.188rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important;
  }
  .mx-md-6 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important;
  }
  .mx-md-12 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-md-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-md-200 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-md-sm {
    margin-right: 0.875rem !important;
    margin-left: 0.875rem !important;
  }
  .mx-md-xs {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-md-xxs {
    margin-right: 0.188rem !important;
    margin-left: 0.188rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important;
  }
  .my-md-6 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important;
  }
  .my-md-12 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-md-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-md-200 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-md-sm {
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }
  .my-md-xs {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-md-xxs {
    margin-top: 0.188rem !important;
    margin-bottom: 0.188rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 2.3rem !important;
  }
  .mt-md-6 {
    margin-top: 2.8rem !important;
  }
  .mt-md-12 {
    margin-top: 0.75rem !important;
  }
  .mt-md-20 {
    margin-top: 1.25rem !important;
  }
  .mt-md-200 {
    margin-top: 2rem !important;
  }
  .mt-md-sm {
    margin-top: 0.875rem !important;
  }
  .mt-md-xs {
    margin-top: 0.375rem !important;
  }
  .mt-md-xxs {
    margin-top: 0.188rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 2.3rem !important;
  }
  .me-md-6 {
    margin-right: 2.8rem !important;
  }
  .me-md-12 {
    margin-right: 0.75rem !important;
  }
  .me-md-20 {
    margin-right: 1.25rem !important;
  }
  .me-md-200 {
    margin-right: 2rem !important;
  }
  .me-md-sm {
    margin-right: 0.875rem !important;
  }
  .me-md-xs {
    margin-right: 0.375rem !important;
  }
  .me-md-xxs {
    margin-right: 0.188rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 2.3rem !important;
  }
  .mb-md-6 {
    margin-bottom: 2.8rem !important;
  }
  .mb-md-12 {
    margin-bottom: 0.75rem !important;
  }
  .mb-md-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-md-200 {
    margin-bottom: 2rem !important;
  }
  .mb-md-sm {
    margin-bottom: 0.875rem !important;
  }
  .mb-md-xs {
    margin-bottom: 0.375rem !important;
  }
  .mb-md-xxs {
    margin-bottom: 0.188rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 2.3rem !important;
  }
  .ms-md-6 {
    margin-left: 2.8rem !important;
  }
  .ms-md-12 {
    margin-left: 0.75rem !important;
  }
  .ms-md-20 {
    margin-left: 1.25rem !important;
  }
  .ms-md-200 {
    margin-left: 2rem !important;
  }
  .ms-md-sm {
    margin-left: 0.875rem !important;
  }
  .ms-md-xs {
    margin-left: 0.375rem !important;
  }
  .ms-md-xxs {
    margin-left: 0.188rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2.3rem !important;
  }
  .m-md-6 {
    margin: 2.8rem !important;
  }
  .m-md-12 {
    margin: 0.75rem !important;
  }
  .m-md-20 {
    margin: 1.25rem !important;
  }
  .m-md-200 {
    margin: 2rem !important;
  }
  .m-md-sm {
    margin: 0.875rem !important;
  }
  .m-md-xs {
    margin: 0.375rem !important;
  }
  .m-md-xxs {
    margin: 0.188rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -0.625rem !important;
  }
  .m-md-n4 {
    margin: -0.875rem !important;
  }
  .m-md-n5 {
    margin: -1.5rem !important;
  }
  .m-md-n6 {
    margin: -2.3rem !important;
  }
  .m-md-n7 {
    margin: -2.8rem !important;
  }
  .m-md-n12 {
    margin: -0.75rem !important;
  }
  .m-md-n20 {
    margin: -1.25rem !important;
  }
  .m-md-n200 {
    margin: -2rem !important;
  }
  .m-md-nsm {
    margin: -0.875rem !important;
  }
  .m-md-nxs {
    margin: -0.375rem !important;
  }
  .m-md-nxxs {
    margin: -0.188rem !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important;
  }
  .mx-md-6 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important;
  }
  .mx-md-12 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-md-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-md-200 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-md-sm {
    margin-right: 0.875rem !important;
    margin-left: 0.875rem !important;
  }
  .mx-md-xs {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-md-xxs {
    margin-right: 0.188rem !important;
    margin-left: 0.188rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-md-n4 {
    margin-right: -0.875rem !important;
    margin-left: -0.875rem !important;
  }
  .mx-md-n5 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n6 {
    margin-right: -2.3rem !important;
    margin-left: -2.3rem !important;
  }
  .mx-md-n7 {
    margin-right: -2.8rem !important;
    margin-left: -2.8rem !important;
  }
  .mx-md-n12 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-md-n20 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-md-n200 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-md-nsm {
    margin-right: -0.875rem !important;
    margin-left: -0.875rem !important;
  }
  .mx-md-nxs {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-md-nxxs {
    margin-right: -0.188rem !important;
    margin-left: -0.188rem !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important;
  }
  .my-md-6 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important;
  }
  .my-md-12 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-md-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-md-200 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-md-sm {
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }
  .my-md-xs {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-md-xxs {
    margin-top: 0.188rem !important;
    margin-bottom: 0.188rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-md-n4 {
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }
  .my-md-n5 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n6 {
    margin-top: -2.3rem !important;
    margin-bottom: -2.3rem !important;
  }
  .my-md-n7 {
    margin-top: -2.8rem !important;
    margin-bottom: -2.8rem !important;
  }
  .my-md-n12 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-md-n20 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-md-n200 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-md-nsm {
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }
  .my-md-nxs {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-md-nxxs {
    margin-top: -0.188rem !important;
    margin-bottom: -0.188rem !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 2.3rem !important;
  }
  .mt-md-6 {
    margin-top: 2.8rem !important;
  }
  .mt-md-12 {
    margin-top: 0.75rem !important;
  }
  .mt-md-20 {
    margin-top: 1.25rem !important;
  }
  .mt-md-200 {
    margin-top: 2rem !important;
  }
  .mt-md-sm {
    margin-top: 0.875rem !important;
  }
  .mt-md-xs {
    margin-top: 0.375rem !important;
  }
  .mt-md-xxs {
    margin-top: 0.188rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -0.625rem !important;
  }
  .mt-md-n4 {
    margin-top: -0.875rem !important;
  }
  .mt-md-n5 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n6 {
    margin-top: -2.3rem !important;
  }
  .mt-md-n7 {
    margin-top: -2.8rem !important;
  }
  .mt-md-n12 {
    margin-top: -0.75rem !important;
  }
  .mt-md-n20 {
    margin-top: -1.25rem !important;
  }
  .mt-md-n200 {
    margin-top: -2rem !important;
  }
  .mt-md-nsm {
    margin-top: -0.875rem !important;
  }
  .mt-md-nxs {
    margin-top: -0.375rem !important;
  }
  .mt-md-nxxs {
    margin-top: -0.188rem !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 2.3rem !important;
  }
  .me-md-6 {
    margin-right: 2.8rem !important;
  }
  .me-md-12 {
    margin-right: 0.75rem !important;
  }
  .me-md-20 {
    margin-right: 1.25rem !important;
  }
  .me-md-200 {
    margin-right: 2rem !important;
  }
  .me-md-sm {
    margin-right: 0.875rem !important;
  }
  .me-md-xs {
    margin-right: 0.375rem !important;
  }
  .me-md-xxs {
    margin-right: 0.188rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -0.625rem !important;
  }
  .me-md-n4 {
    margin-right: -0.875rem !important;
  }
  .me-md-n5 {
    margin-right: -1.5rem !important;
  }
  .me-md-n6 {
    margin-right: -2.3rem !important;
  }
  .me-md-n7 {
    margin-right: -2.8rem !important;
  }
  .me-md-n12 {
    margin-right: -0.75rem !important;
  }
  .me-md-n20 {
    margin-right: -1.25rem !important;
  }
  .me-md-n200 {
    margin-right: -2rem !important;
  }
  .me-md-nsm {
    margin-right: -0.875rem !important;
  }
  .me-md-nxs {
    margin-right: -0.375rem !important;
  }
  .me-md-nxxs {
    margin-right: -0.188rem !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 2.3rem !important;
  }
  .mb-md-6 {
    margin-bottom: 2.8rem !important;
  }
  .mb-md-12 {
    margin-bottom: 0.75rem !important;
  }
  .mb-md-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-md-200 {
    margin-bottom: 2rem !important;
  }
  .mb-md-sm {
    margin-bottom: 0.875rem !important;
  }
  .mb-md-xs {
    margin-bottom: 0.375rem !important;
  }
  .mb-md-xxs {
    margin-bottom: 0.188rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -0.625rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -0.875rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -2.3rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -2.8rem !important;
  }
  .mb-md-n12 {
    margin-bottom: -0.75rem !important;
  }
  .mb-md-n20 {
    margin-bottom: -1.25rem !important;
  }
  .mb-md-n200 {
    margin-bottom: -2rem !important;
  }
  .mb-md-nsm {
    margin-bottom: -0.875rem !important;
  }
  .mb-md-nxs {
    margin-bottom: -0.375rem !important;
  }
  .mb-md-nxxs {
    margin-bottom: -0.188rem !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 2.3rem !important;
  }
  .ms-md-6 {
    margin-left: 2.8rem !important;
  }
  .ms-md-12 {
    margin-left: 0.75rem !important;
  }
  .ms-md-20 {
    margin-left: 1.25rem !important;
  }
  .ms-md-200 {
    margin-left: 2rem !important;
  }
  .ms-md-sm {
    margin-left: 0.875rem !important;
  }
  .ms-md-xs {
    margin-left: 0.375rem !important;
  }
  .ms-md-xxs {
    margin-left: 0.188rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -0.625rem !important;
  }
  .ms-md-n4 {
    margin-left: -0.875rem !important;
  }
  .ms-md-n5 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n6 {
    margin-left: -2.3rem !important;
  }
  .ms-md-n7 {
    margin-left: -2.8rem !important;
  }
  .ms-md-n12 {
    margin-left: -0.75rem !important;
  }
  .ms-md-n20 {
    margin-left: -1.25rem !important;
  }
  .ms-md-n200 {
    margin-left: -2rem !important;
  }
  .ms-md-nsm {
    margin-left: -0.875rem !important;
  }
  .ms-md-nxs {
    margin-left: -0.375rem !important;
  }
  .ms-md-nxxs {
    margin-left: -0.188rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2.3rem !important;
  }
  .p-md-6 {
    padding: 2.8rem !important;
  }
  .p-md-12 {
    padding: 0.75rem !important;
  }
  .p-md-20 {
    padding: 1.25rem !important;
  }
  .p-md-200 {
    padding: 2rem !important;
  }
  .p-md-sm {
    padding: 0.875rem !important;
  }
  .p-md-xs {
    padding: 0.375rem !important;
  }
  .p-md-xxs {
    padding: 0.188rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 2.3rem !important;
    padding-left: 2.3rem !important;
  }
  .px-md-6 {
    padding-right: 2.8rem !important;
    padding-left: 2.8rem !important;
  }
  .px-md-12 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-md-20 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-md-200 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-md-sm {
    padding-right: 0.875rem !important;
    padding-left: 0.875rem !important;
  }
  .px-md-xs {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-md-xxs {
    padding-right: 0.188rem !important;
    padding-left: 0.188rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 2.3rem !important;
    padding-bottom: 2.3rem !important;
  }
  .py-md-6 {
    padding-top: 2.8rem !important;
    padding-bottom: 2.8rem !important;
  }
  .py-md-12 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-md-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-md-200 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-md-sm {
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
  }
  .py-md-xs {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-md-xxs {
    padding-top: 0.188rem !important;
    padding-bottom: 0.188rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 2.3rem !important;
  }
  .pt-md-6 {
    padding-top: 2.8rem !important;
  }
  .pt-md-12 {
    padding-top: 0.75rem !important;
  }
  .pt-md-20 {
    padding-top: 1.25rem !important;
  }
  .pt-md-200 {
    padding-top: 2rem !important;
  }
  .pt-md-sm {
    padding-top: 0.875rem !important;
  }
  .pt-md-xs {
    padding-top: 0.375rem !important;
  }
  .pt-md-xxs {
    padding-top: 0.188rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 2.3rem !important;
  }
  .pe-md-6 {
    padding-right: 2.8rem !important;
  }
  .pe-md-12 {
    padding-right: 0.75rem !important;
  }
  .pe-md-20 {
    padding-right: 1.25rem !important;
  }
  .pe-md-200 {
    padding-right: 2rem !important;
  }
  .pe-md-sm {
    padding-right: 0.875rem !important;
  }
  .pe-md-xs {
    padding-right: 0.375rem !important;
  }
  .pe-md-xxs {
    padding-right: 0.188rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 2.3rem !important;
  }
  .pb-md-6 {
    padding-bottom: 2.8rem !important;
  }
  .pb-md-12 {
    padding-bottom: 0.75rem !important;
  }
  .pb-md-20 {
    padding-bottom: 1.25rem !important;
  }
  .pb-md-200 {
    padding-bottom: 2rem !important;
  }
  .pb-md-sm {
    padding-bottom: 0.875rem !important;
  }
  .pb-md-xs {
    padding-bottom: 0.375rem !important;
  }
  .pb-md-xxs {
    padding-bottom: 0.188rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 2.3rem !important;
  }
  .ps-md-6 {
    padding-left: 2.8rem !important;
  }
  .ps-md-12 {
    padding-left: 0.75rem !important;
  }
  .ps-md-20 {
    padding-left: 1.25rem !important;
  }
  .ps-md-200 {
    padding-left: 2rem !important;
  }
  .ps-md-sm {
    padding-left: 0.875rem !important;
  }
  .ps-md-xs {
    padding-left: 0.375rem !important;
  }
  .ps-md-xxs {
    padding-left: 0.188rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 2.3rem !important;
  }
  .gap-md-6 {
    gap: 2.8rem !important;
  }
  .gap-md-12 {
    gap: 0.75rem !important;
  }
  .gap-md-20 {
    gap: 1.25rem !important;
  }
  .gap-md-200 {
    gap: 2rem !important;
  }
  .gap-md-sm {
    gap: 0.875rem !important;
  }
  .gap-md-xs {
    gap: 0.375rem !important;
  }
  .gap-md-xxs {
    gap: 0.188rem !important;
  }
  .fs-md-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .fs-md-2 {
    font-size: calc(1.325rem + 0.9vw) !important;
  }
  .fs-md-3 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }
  .fs-md-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-md-5 {
    font-size: 1.25rem !important;
  }
  .fs-md-6 {
    font-size: 1rem !important;
  }
  .fs-md-12 {
    font-size: 0.75rem !important;
  }
  .fs-md-14 {
    font-size: 0.875rem !important;
  }
  .fs-md-16 {
    font-size: 1rem !important;
  }
  .fs-md-18 {
    font-size: 1.125rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1024px) {
  .float-mdl-start {
    float: left !important;
  }
  .float-mdl-end {
    float: right !important;
  }
  .float-mdl-none {
    float: none !important;
  }
  .d-mdl-inline {
    display: inline !important;
  }
  .d-mdl-inline-block {
    display: inline-block !important;
  }
  .d-mdl-block {
    display: block !important;
  }
  .d-mdl-grid {
    display: grid !important;
  }
  .d-mdl-table {
    display: table !important;
  }
  .d-mdl-table-row {
    display: table-row !important;
  }
  .d-mdl-table-cell {
    display: table-cell !important;
  }
  .d-mdl-flex {
    display: flex !important;
  }
  .d-mdl-inline-flex {
    display: inline-flex !important;
  }
  .d-mdl-none {
    display: none !important;
  }
  .w-mdl-25 {
    width: 25% !important;
  }
  .w-mdl-50 {
    width: 50% !important;
  }
  .w-mdl-75 {
    width: 75% !important;
  }
  .w-mdl-100 {
    width: 100% !important;
  }
  .w-mdl-auto {
    width: auto !important;
  }
  .h-mdl-25 {
    height: 25% !important;
  }
  .h-mdl-50 {
    height: 50% !important;
  }
  .h-mdl-75 {
    height: 75% !important;
  }
  .h-mdl-100 {
    height: 100% !important;
  }
  .h-mdl-auto {
    height: auto !important;
  }
  .flex-mdl-fill {
    flex: 1 1 auto !important;
  }
  .flex-mdl-row {
    flex-direction: row !important;
  }
  .flex-mdl-column {
    flex-direction: column !important;
  }
  .flex-mdl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-mdl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-mdl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-mdl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-mdl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-mdl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-mdl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-mdl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-mdl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-mdl-start {
    justify-content: flex-start !important;
  }
  .justify-content-mdl-end {
    justify-content: flex-end !important;
  }
  .justify-content-mdl-center {
    justify-content: center !important;
  }
  .justify-content-mdl-between {
    justify-content: space-between !important;
  }
  .justify-content-mdl-around {
    justify-content: space-around !important;
  }
  .justify-content-mdl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-mdl-start {
    align-items: flex-start !important;
  }
  .align-items-mdl-end {
    align-items: flex-end !important;
  }
  .align-items-mdl-center {
    align-items: center !important;
  }
  .align-items-mdl-baseline {
    align-items: baseline !important;
  }
  .align-items-mdl-stretch {
    align-items: stretch !important;
  }
  .align-content-mdl-start {
    align-content: flex-start !important;
  }
  .align-content-mdl-end {
    align-content: flex-end !important;
  }
  .align-content-mdl-center {
    align-content: center !important;
  }
  .align-content-mdl-between {
    align-content: space-between !important;
  }
  .align-content-mdl-around {
    align-content: space-around !important;
  }
  .align-content-mdl-stretch {
    align-content: stretch !important;
  }
  .align-self-mdl-auto {
    align-self: auto !important;
  }
  .align-self-mdl-start {
    align-self: flex-start !important;
  }
  .align-self-mdl-end {
    align-self: flex-end !important;
  }
  .align-self-mdl-center {
    align-self: center !important;
  }
  .align-self-mdl-baseline {
    align-self: baseline !important;
  }
  .align-self-mdl-stretch {
    align-self: stretch !important;
  }
  .order-mdl-first {
    order: -1 !important;
  }
  .order-mdl-0 {
    order: 0 !important;
  }
  .order-mdl-1 {
    order: 1 !important;
  }
  .order-mdl-2 {
    order: 2 !important;
  }
  .order-mdl-3 {
    order: 3 !important;
  }
  .order-mdl-4 {
    order: 4 !important;
  }
  .order-mdl-5 {
    order: 5 !important;
  }
  .order-mdl-last {
    order: 6 !important;
  }
  .m-mdl-0 {
    margin: 0 !important;
  }
  .m-mdl-1 {
    margin: 0.25rem !important;
  }
  .m-mdl-2 {
    margin: 0.5rem !important;
  }
  .m-mdl-3 {
    margin: 1rem !important;
  }
  .m-mdl-4 {
    margin: 1.5rem !important;
  }
  .m-mdl-5 {
    margin: 2.3rem !important;
  }
  .m-mdl-6 {
    margin: 2.8rem !important;
  }
  .m-mdl-12 {
    margin: 0.75rem !important;
  }
  .m-mdl-20 {
    margin: 1.25rem !important;
  }
  .m-mdl-200 {
    margin: 2rem !important;
  }
  .m-mdl-sm {
    margin: 0.875rem !important;
  }
  .m-mdl-xs {
    margin: 0.375rem !important;
  }
  .m-mdl-xxs {
    margin: 0.188rem !important;
  }
  .m-mdl-auto {
    margin: auto !important;
  }
  .mx-mdl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-mdl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-mdl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-mdl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-mdl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-mdl-5 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important;
  }
  .mx-mdl-6 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important;
  }
  .mx-mdl-12 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-mdl-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-mdl-200 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-mdl-sm {
    margin-right: 0.875rem !important;
    margin-left: 0.875rem !important;
  }
  .mx-mdl-xs {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-mdl-xxs {
    margin-right: 0.188rem !important;
    margin-left: 0.188rem !important;
  }
  .mx-mdl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-mdl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-mdl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-mdl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-mdl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-mdl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-mdl-5 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important;
  }
  .my-mdl-6 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important;
  }
  .my-mdl-12 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-mdl-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-mdl-200 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-mdl-sm {
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }
  .my-mdl-xs {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-mdl-xxs {
    margin-top: 0.188rem !important;
    margin-bottom: 0.188rem !important;
  }
  .my-mdl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-mdl-0 {
    margin-top: 0 !important;
  }
  .mt-mdl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-mdl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-mdl-3 {
    margin-top: 1rem !important;
  }
  .mt-mdl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-mdl-5 {
    margin-top: 2.3rem !important;
  }
  .mt-mdl-6 {
    margin-top: 2.8rem !important;
  }
  .mt-mdl-12 {
    margin-top: 0.75rem !important;
  }
  .mt-mdl-20 {
    margin-top: 1.25rem !important;
  }
  .mt-mdl-200 {
    margin-top: 2rem !important;
  }
  .mt-mdl-sm {
    margin-top: 0.875rem !important;
  }
  .mt-mdl-xs {
    margin-top: 0.375rem !important;
  }
  .mt-mdl-xxs {
    margin-top: 0.188rem !important;
  }
  .mt-mdl-auto {
    margin-top: auto !important;
  }
  .me-mdl-0 {
    margin-right: 0 !important;
  }
  .me-mdl-1 {
    margin-right: 0.25rem !important;
  }
  .me-mdl-2 {
    margin-right: 0.5rem !important;
  }
  .me-mdl-3 {
    margin-right: 1rem !important;
  }
  .me-mdl-4 {
    margin-right: 1.5rem !important;
  }
  .me-mdl-5 {
    margin-right: 2.3rem !important;
  }
  .me-mdl-6 {
    margin-right: 2.8rem !important;
  }
  .me-mdl-12 {
    margin-right: 0.75rem !important;
  }
  .me-mdl-20 {
    margin-right: 1.25rem !important;
  }
  .me-mdl-200 {
    margin-right: 2rem !important;
  }
  .me-mdl-sm {
    margin-right: 0.875rem !important;
  }
  .me-mdl-xs {
    margin-right: 0.375rem !important;
  }
  .me-mdl-xxs {
    margin-right: 0.188rem !important;
  }
  .me-mdl-auto {
    margin-right: auto !important;
  }
  .mb-mdl-0 {
    margin-bottom: 0 !important;
  }
  .mb-mdl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-mdl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-mdl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-mdl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-mdl-5 {
    margin-bottom: 2.3rem !important;
  }
  .mb-mdl-6 {
    margin-bottom: 2.8rem !important;
  }
  .mb-mdl-12 {
    margin-bottom: 0.75rem !important;
  }
  .mb-mdl-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-mdl-200 {
    margin-bottom: 2rem !important;
  }
  .mb-mdl-sm {
    margin-bottom: 0.875rem !important;
  }
  .mb-mdl-xs {
    margin-bottom: 0.375rem !important;
  }
  .mb-mdl-xxs {
    margin-bottom: 0.188rem !important;
  }
  .mb-mdl-auto {
    margin-bottom: auto !important;
  }
  .ms-mdl-0 {
    margin-left: 0 !important;
  }
  .ms-mdl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-mdl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-mdl-3 {
    margin-left: 1rem !important;
  }
  .ms-mdl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-mdl-5 {
    margin-left: 2.3rem !important;
  }
  .ms-mdl-6 {
    margin-left: 2.8rem !important;
  }
  .ms-mdl-12 {
    margin-left: 0.75rem !important;
  }
  .ms-mdl-20 {
    margin-left: 1.25rem !important;
  }
  .ms-mdl-200 {
    margin-left: 2rem !important;
  }
  .ms-mdl-sm {
    margin-left: 0.875rem !important;
  }
  .ms-mdl-xs {
    margin-left: 0.375rem !important;
  }
  .ms-mdl-xxs {
    margin-left: 0.188rem !important;
  }
  .ms-mdl-auto {
    margin-left: auto !important;
  }
  .m-mdl-0 {
    margin: 0 !important;
  }
  .m-mdl-1 {
    margin: 0.25rem !important;
  }
  .m-mdl-2 {
    margin: 0.5rem !important;
  }
  .m-mdl-3 {
    margin: 1rem !important;
  }
  .m-mdl-4 {
    margin: 1.5rem !important;
  }
  .m-mdl-5 {
    margin: 2.3rem !important;
  }
  .m-mdl-6 {
    margin: 2.8rem !important;
  }
  .m-mdl-12 {
    margin: 0.75rem !important;
  }
  .m-mdl-20 {
    margin: 1.25rem !important;
  }
  .m-mdl-200 {
    margin: 2rem !important;
  }
  .m-mdl-sm {
    margin: 0.875rem !important;
  }
  .m-mdl-xs {
    margin: 0.375rem !important;
  }
  .m-mdl-xxs {
    margin: 0.188rem !important;
  }
  .m-mdl-n1 {
    margin: -0.25rem !important;
  }
  .m-mdl-n2 {
    margin: -0.5rem !important;
  }
  .m-mdl-n3 {
    margin: -0.625rem !important;
  }
  .m-mdl-n4 {
    margin: -0.875rem !important;
  }
  .m-mdl-n5 {
    margin: -1.5rem !important;
  }
  .m-mdl-n6 {
    margin: -2.3rem !important;
  }
  .m-mdl-n7 {
    margin: -2.8rem !important;
  }
  .m-mdl-n12 {
    margin: -0.75rem !important;
  }
  .m-mdl-n20 {
    margin: -1.25rem !important;
  }
  .m-mdl-n200 {
    margin: -2rem !important;
  }
  .m-mdl-nsm {
    margin: -0.875rem !important;
  }
  .m-mdl-nxs {
    margin: -0.375rem !important;
  }
  .m-mdl-nxxs {
    margin: -0.188rem !important;
  }
  .mx-mdl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-mdl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-mdl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-mdl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-mdl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-mdl-5 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important;
  }
  .mx-mdl-6 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important;
  }
  .mx-mdl-12 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-mdl-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-mdl-200 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-mdl-sm {
    margin-right: 0.875rem !important;
    margin-left: 0.875rem !important;
  }
  .mx-mdl-xs {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-mdl-xxs {
    margin-right: 0.188rem !important;
    margin-left: 0.188rem !important;
  }
  .mx-mdl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-mdl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-mdl-n3 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-mdl-n4 {
    margin-right: -0.875rem !important;
    margin-left: -0.875rem !important;
  }
  .mx-mdl-n5 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-mdl-n6 {
    margin-right: -2.3rem !important;
    margin-left: -2.3rem !important;
  }
  .mx-mdl-n7 {
    margin-right: -2.8rem !important;
    margin-left: -2.8rem !important;
  }
  .mx-mdl-n12 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-mdl-n20 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-mdl-n200 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-mdl-nsm {
    margin-right: -0.875rem !important;
    margin-left: -0.875rem !important;
  }
  .mx-mdl-nxs {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-mdl-nxxs {
    margin-right: -0.188rem !important;
    margin-left: -0.188rem !important;
  }
  .my-mdl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-mdl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-mdl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-mdl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-mdl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-mdl-5 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important;
  }
  .my-mdl-6 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important;
  }
  .my-mdl-12 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-mdl-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-mdl-200 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-mdl-sm {
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }
  .my-mdl-xs {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-mdl-xxs {
    margin-top: 0.188rem !important;
    margin-bottom: 0.188rem !important;
  }
  .my-mdl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-mdl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-mdl-n3 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-mdl-n4 {
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }
  .my-mdl-n5 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-mdl-n6 {
    margin-top: -2.3rem !important;
    margin-bottom: -2.3rem !important;
  }
  .my-mdl-n7 {
    margin-top: -2.8rem !important;
    margin-bottom: -2.8rem !important;
  }
  .my-mdl-n12 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-mdl-n20 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-mdl-n200 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-mdl-nsm {
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }
  .my-mdl-nxs {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-mdl-nxxs {
    margin-top: -0.188rem !important;
    margin-bottom: -0.188rem !important;
  }
  .mt-mdl-0 {
    margin-top: 0 !important;
  }
  .mt-mdl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-mdl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-mdl-3 {
    margin-top: 1rem !important;
  }
  .mt-mdl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-mdl-5 {
    margin-top: 2.3rem !important;
  }
  .mt-mdl-6 {
    margin-top: 2.8rem !important;
  }
  .mt-mdl-12 {
    margin-top: 0.75rem !important;
  }
  .mt-mdl-20 {
    margin-top: 1.25rem !important;
  }
  .mt-mdl-200 {
    margin-top: 2rem !important;
  }
  .mt-mdl-sm {
    margin-top: 0.875rem !important;
  }
  .mt-mdl-xs {
    margin-top: 0.375rem !important;
  }
  .mt-mdl-xxs {
    margin-top: 0.188rem !important;
  }
  .mt-mdl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-mdl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-mdl-n3 {
    margin-top: -0.625rem !important;
  }
  .mt-mdl-n4 {
    margin-top: -0.875rem !important;
  }
  .mt-mdl-n5 {
    margin-top: -1.5rem !important;
  }
  .mt-mdl-n6 {
    margin-top: -2.3rem !important;
  }
  .mt-mdl-n7 {
    margin-top: -2.8rem !important;
  }
  .mt-mdl-n12 {
    margin-top: -0.75rem !important;
  }
  .mt-mdl-n20 {
    margin-top: -1.25rem !important;
  }
  .mt-mdl-n200 {
    margin-top: -2rem !important;
  }
  .mt-mdl-nsm {
    margin-top: -0.875rem !important;
  }
  .mt-mdl-nxs {
    margin-top: -0.375rem !important;
  }
  .mt-mdl-nxxs {
    margin-top: -0.188rem !important;
  }
  .me-mdl-0 {
    margin-right: 0 !important;
  }
  .me-mdl-1 {
    margin-right: 0.25rem !important;
  }
  .me-mdl-2 {
    margin-right: 0.5rem !important;
  }
  .me-mdl-3 {
    margin-right: 1rem !important;
  }
  .me-mdl-4 {
    margin-right: 1.5rem !important;
  }
  .me-mdl-5 {
    margin-right: 2.3rem !important;
  }
  .me-mdl-6 {
    margin-right: 2.8rem !important;
  }
  .me-mdl-12 {
    margin-right: 0.75rem !important;
  }
  .me-mdl-20 {
    margin-right: 1.25rem !important;
  }
  .me-mdl-200 {
    margin-right: 2rem !important;
  }
  .me-mdl-sm {
    margin-right: 0.875rem !important;
  }
  .me-mdl-xs {
    margin-right: 0.375rem !important;
  }
  .me-mdl-xxs {
    margin-right: 0.188rem !important;
  }
  .me-mdl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-mdl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-mdl-n3 {
    margin-right: -0.625rem !important;
  }
  .me-mdl-n4 {
    margin-right: -0.875rem !important;
  }
  .me-mdl-n5 {
    margin-right: -1.5rem !important;
  }
  .me-mdl-n6 {
    margin-right: -2.3rem !important;
  }
  .me-mdl-n7 {
    margin-right: -2.8rem !important;
  }
  .me-mdl-n12 {
    margin-right: -0.75rem !important;
  }
  .me-mdl-n20 {
    margin-right: -1.25rem !important;
  }
  .me-mdl-n200 {
    margin-right: -2rem !important;
  }
  .me-mdl-nsm {
    margin-right: -0.875rem !important;
  }
  .me-mdl-nxs {
    margin-right: -0.375rem !important;
  }
  .me-mdl-nxxs {
    margin-right: -0.188rem !important;
  }
  .mb-mdl-0 {
    margin-bottom: 0 !important;
  }
  .mb-mdl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-mdl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-mdl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-mdl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-mdl-5 {
    margin-bottom: 2.3rem !important;
  }
  .mb-mdl-6 {
    margin-bottom: 2.8rem !important;
  }
  .mb-mdl-12 {
    margin-bottom: 0.75rem !important;
  }
  .mb-mdl-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-mdl-200 {
    margin-bottom: 2rem !important;
  }
  .mb-mdl-sm {
    margin-bottom: 0.875rem !important;
  }
  .mb-mdl-xs {
    margin-bottom: 0.375rem !important;
  }
  .mb-mdl-xxs {
    margin-bottom: 0.188rem !important;
  }
  .mb-mdl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-mdl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-mdl-n3 {
    margin-bottom: -0.625rem !important;
  }
  .mb-mdl-n4 {
    margin-bottom: -0.875rem !important;
  }
  .mb-mdl-n5 {
    margin-bottom: -1.5rem !important;
  }
  .mb-mdl-n6 {
    margin-bottom: -2.3rem !important;
  }
  .mb-mdl-n7 {
    margin-bottom: -2.8rem !important;
  }
  .mb-mdl-n12 {
    margin-bottom: -0.75rem !important;
  }
  .mb-mdl-n20 {
    margin-bottom: -1.25rem !important;
  }
  .mb-mdl-n200 {
    margin-bottom: -2rem !important;
  }
  .mb-mdl-nsm {
    margin-bottom: -0.875rem !important;
  }
  .mb-mdl-nxs {
    margin-bottom: -0.375rem !important;
  }
  .mb-mdl-nxxs {
    margin-bottom: -0.188rem !important;
  }
  .ms-mdl-0 {
    margin-left: 0 !important;
  }
  .ms-mdl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-mdl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-mdl-3 {
    margin-left: 1rem !important;
  }
  .ms-mdl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-mdl-5 {
    margin-left: 2.3rem !important;
  }
  .ms-mdl-6 {
    margin-left: 2.8rem !important;
  }
  .ms-mdl-12 {
    margin-left: 0.75rem !important;
  }
  .ms-mdl-20 {
    margin-left: 1.25rem !important;
  }
  .ms-mdl-200 {
    margin-left: 2rem !important;
  }
  .ms-mdl-sm {
    margin-left: 0.875rem !important;
  }
  .ms-mdl-xs {
    margin-left: 0.375rem !important;
  }
  .ms-mdl-xxs {
    margin-left: 0.188rem !important;
  }
  .ms-mdl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-mdl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-mdl-n3 {
    margin-left: -0.625rem !important;
  }
  .ms-mdl-n4 {
    margin-left: -0.875rem !important;
  }
  .ms-mdl-n5 {
    margin-left: -1.5rem !important;
  }
  .ms-mdl-n6 {
    margin-left: -2.3rem !important;
  }
  .ms-mdl-n7 {
    margin-left: -2.8rem !important;
  }
  .ms-mdl-n12 {
    margin-left: -0.75rem !important;
  }
  .ms-mdl-n20 {
    margin-left: -1.25rem !important;
  }
  .ms-mdl-n200 {
    margin-left: -2rem !important;
  }
  .ms-mdl-nsm {
    margin-left: -0.875rem !important;
  }
  .ms-mdl-nxs {
    margin-left: -0.375rem !important;
  }
  .ms-mdl-nxxs {
    margin-left: -0.188rem !important;
  }
  .p-mdl-0 {
    padding: 0 !important;
  }
  .p-mdl-1 {
    padding: 0.25rem !important;
  }
  .p-mdl-2 {
    padding: 0.5rem !important;
  }
  .p-mdl-3 {
    padding: 1rem !important;
  }
  .p-mdl-4 {
    padding: 1.5rem !important;
  }
  .p-mdl-5 {
    padding: 2.3rem !important;
  }
  .p-mdl-6 {
    padding: 2.8rem !important;
  }
  .p-mdl-12 {
    padding: 0.75rem !important;
  }
  .p-mdl-20 {
    padding: 1.25rem !important;
  }
  .p-mdl-200 {
    padding: 2rem !important;
  }
  .p-mdl-sm {
    padding: 0.875rem !important;
  }
  .p-mdl-xs {
    padding: 0.375rem !important;
  }
  .p-mdl-xxs {
    padding: 0.188rem !important;
  }
  .px-mdl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-mdl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-mdl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-mdl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-mdl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-mdl-5 {
    padding-right: 2.3rem !important;
    padding-left: 2.3rem !important;
  }
  .px-mdl-6 {
    padding-right: 2.8rem !important;
    padding-left: 2.8rem !important;
  }
  .px-mdl-12 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-mdl-20 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-mdl-200 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-mdl-sm {
    padding-right: 0.875rem !important;
    padding-left: 0.875rem !important;
  }
  .px-mdl-xs {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-mdl-xxs {
    padding-right: 0.188rem !important;
    padding-left: 0.188rem !important;
  }
  .py-mdl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-mdl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-mdl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-mdl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-mdl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-mdl-5 {
    padding-top: 2.3rem !important;
    padding-bottom: 2.3rem !important;
  }
  .py-mdl-6 {
    padding-top: 2.8rem !important;
    padding-bottom: 2.8rem !important;
  }
  .py-mdl-12 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-mdl-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-mdl-200 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-mdl-sm {
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
  }
  .py-mdl-xs {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-mdl-xxs {
    padding-top: 0.188rem !important;
    padding-bottom: 0.188rem !important;
  }
  .pt-mdl-0 {
    padding-top: 0 !important;
  }
  .pt-mdl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-mdl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-mdl-3 {
    padding-top: 1rem !important;
  }
  .pt-mdl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-mdl-5 {
    padding-top: 2.3rem !important;
  }
  .pt-mdl-6 {
    padding-top: 2.8rem !important;
  }
  .pt-mdl-12 {
    padding-top: 0.75rem !important;
  }
  .pt-mdl-20 {
    padding-top: 1.25rem !important;
  }
  .pt-mdl-200 {
    padding-top: 2rem !important;
  }
  .pt-mdl-sm {
    padding-top: 0.875rem !important;
  }
  .pt-mdl-xs {
    padding-top: 0.375rem !important;
  }
  .pt-mdl-xxs {
    padding-top: 0.188rem !important;
  }
  .pe-mdl-0 {
    padding-right: 0 !important;
  }
  .pe-mdl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-mdl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-mdl-3 {
    padding-right: 1rem !important;
  }
  .pe-mdl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-mdl-5 {
    padding-right: 2.3rem !important;
  }
  .pe-mdl-6 {
    padding-right: 2.8rem !important;
  }
  .pe-mdl-12 {
    padding-right: 0.75rem !important;
  }
  .pe-mdl-20 {
    padding-right: 1.25rem !important;
  }
  .pe-mdl-200 {
    padding-right: 2rem !important;
  }
  .pe-mdl-sm {
    padding-right: 0.875rem !important;
  }
  .pe-mdl-xs {
    padding-right: 0.375rem !important;
  }
  .pe-mdl-xxs {
    padding-right: 0.188rem !important;
  }
  .pb-mdl-0 {
    padding-bottom: 0 !important;
  }
  .pb-mdl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-mdl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-mdl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-mdl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-mdl-5 {
    padding-bottom: 2.3rem !important;
  }
  .pb-mdl-6 {
    padding-bottom: 2.8rem !important;
  }
  .pb-mdl-12 {
    padding-bottom: 0.75rem !important;
  }
  .pb-mdl-20 {
    padding-bottom: 1.25rem !important;
  }
  .pb-mdl-200 {
    padding-bottom: 2rem !important;
  }
  .pb-mdl-sm {
    padding-bottom: 0.875rem !important;
  }
  .pb-mdl-xs {
    padding-bottom: 0.375rem !important;
  }
  .pb-mdl-xxs {
    padding-bottom: 0.188rem !important;
  }
  .ps-mdl-0 {
    padding-left: 0 !important;
  }
  .ps-mdl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-mdl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-mdl-3 {
    padding-left: 1rem !important;
  }
  .ps-mdl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-mdl-5 {
    padding-left: 2.3rem !important;
  }
  .ps-mdl-6 {
    padding-left: 2.8rem !important;
  }
  .ps-mdl-12 {
    padding-left: 0.75rem !important;
  }
  .ps-mdl-20 {
    padding-left: 1.25rem !important;
  }
  .ps-mdl-200 {
    padding-left: 2rem !important;
  }
  .ps-mdl-sm {
    padding-left: 0.875rem !important;
  }
  .ps-mdl-xs {
    padding-left: 0.375rem !important;
  }
  .ps-mdl-xxs {
    padding-left: 0.188rem !important;
  }
  .gap-mdl-0 {
    gap: 0 !important;
  }
  .gap-mdl-1 {
    gap: 0.25rem !important;
  }
  .gap-mdl-2 {
    gap: 0.5rem !important;
  }
  .gap-mdl-3 {
    gap: 1rem !important;
  }
  .gap-mdl-4 {
    gap: 1.5rem !important;
  }
  .gap-mdl-5 {
    gap: 2.3rem !important;
  }
  .gap-mdl-6 {
    gap: 2.8rem !important;
  }
  .gap-mdl-12 {
    gap: 0.75rem !important;
  }
  .gap-mdl-20 {
    gap: 1.25rem !important;
  }
  .gap-mdl-200 {
    gap: 2rem !important;
  }
  .gap-mdl-sm {
    gap: 0.875rem !important;
  }
  .gap-mdl-xs {
    gap: 0.375rem !important;
  }
  .gap-mdl-xxs {
    gap: 0.188rem !important;
  }
  .fs-mdl-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .fs-mdl-2 {
    font-size: calc(1.325rem + 0.9vw) !important;
  }
  .fs-mdl-3 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }
  .fs-mdl-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-mdl-5 {
    font-size: 1.25rem !important;
  }
  .fs-mdl-6 {
    font-size: 1rem !important;
  }
  .fs-mdl-12 {
    font-size: 0.75rem !important;
  }
  .fs-mdl-14 {
    font-size: 0.875rem !important;
  }
  .fs-mdl-16 {
    font-size: 1rem !important;
  }
  .fs-mdl-18 {
    font-size: 1.125rem !important;
  }
  .text-mdl-start {
    text-align: left !important;
  }
  .text-mdl-end {
    text-align: right !important;
  }
  .text-mdl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2.3rem !important;
  }
  .m-lg-6 {
    margin: 2.8rem !important;
  }
  .m-lg-12 {
    margin: 0.75rem !important;
  }
  .m-lg-20 {
    margin: 1.25rem !important;
  }
  .m-lg-200 {
    margin: 2rem !important;
  }
  .m-lg-sm {
    margin: 0.875rem !important;
  }
  .m-lg-xs {
    margin: 0.375rem !important;
  }
  .m-lg-xxs {
    margin: 0.188rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important;
  }
  .mx-lg-6 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important;
  }
  .mx-lg-12 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-lg-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-lg-200 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-lg-sm {
    margin-right: 0.875rem !important;
    margin-left: 0.875rem !important;
  }
  .mx-lg-xs {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-lg-xxs {
    margin-right: 0.188rem !important;
    margin-left: 0.188rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important;
  }
  .my-lg-6 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important;
  }
  .my-lg-12 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-lg-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-lg-200 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-lg-sm {
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }
  .my-lg-xs {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-lg-xxs {
    margin-top: 0.188rem !important;
    margin-bottom: 0.188rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 2.3rem !important;
  }
  .mt-lg-6 {
    margin-top: 2.8rem !important;
  }
  .mt-lg-12 {
    margin-top: 0.75rem !important;
  }
  .mt-lg-20 {
    margin-top: 1.25rem !important;
  }
  .mt-lg-200 {
    margin-top: 2rem !important;
  }
  .mt-lg-sm {
    margin-top: 0.875rem !important;
  }
  .mt-lg-xs {
    margin-top: 0.375rem !important;
  }
  .mt-lg-xxs {
    margin-top: 0.188rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 2.3rem !important;
  }
  .me-lg-6 {
    margin-right: 2.8rem !important;
  }
  .me-lg-12 {
    margin-right: 0.75rem !important;
  }
  .me-lg-20 {
    margin-right: 1.25rem !important;
  }
  .me-lg-200 {
    margin-right: 2rem !important;
  }
  .me-lg-sm {
    margin-right: 0.875rem !important;
  }
  .me-lg-xs {
    margin-right: 0.375rem !important;
  }
  .me-lg-xxs {
    margin-right: 0.188rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 2.3rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 2.8rem !important;
  }
  .mb-lg-12 {
    margin-bottom: 0.75rem !important;
  }
  .mb-lg-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-lg-200 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-sm {
    margin-bottom: 0.875rem !important;
  }
  .mb-lg-xs {
    margin-bottom: 0.375rem !important;
  }
  .mb-lg-xxs {
    margin-bottom: 0.188rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 2.3rem !important;
  }
  .ms-lg-6 {
    margin-left: 2.8rem !important;
  }
  .ms-lg-12 {
    margin-left: 0.75rem !important;
  }
  .ms-lg-20 {
    margin-left: 1.25rem !important;
  }
  .ms-lg-200 {
    margin-left: 2rem !important;
  }
  .ms-lg-sm {
    margin-left: 0.875rem !important;
  }
  .ms-lg-xs {
    margin-left: 0.375rem !important;
  }
  .ms-lg-xxs {
    margin-left: 0.188rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2.3rem !important;
  }
  .m-lg-6 {
    margin: 2.8rem !important;
  }
  .m-lg-12 {
    margin: 0.75rem !important;
  }
  .m-lg-20 {
    margin: 1.25rem !important;
  }
  .m-lg-200 {
    margin: 2rem !important;
  }
  .m-lg-sm {
    margin: 0.875rem !important;
  }
  .m-lg-xs {
    margin: 0.375rem !important;
  }
  .m-lg-xxs {
    margin: 0.188rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -0.625rem !important;
  }
  .m-lg-n4 {
    margin: -0.875rem !important;
  }
  .m-lg-n5 {
    margin: -1.5rem !important;
  }
  .m-lg-n6 {
    margin: -2.3rem !important;
  }
  .m-lg-n7 {
    margin: -2.8rem !important;
  }
  .m-lg-n12 {
    margin: -0.75rem !important;
  }
  .m-lg-n20 {
    margin: -1.25rem !important;
  }
  .m-lg-n200 {
    margin: -2rem !important;
  }
  .m-lg-nsm {
    margin: -0.875rem !important;
  }
  .m-lg-nxs {
    margin: -0.375rem !important;
  }
  .m-lg-nxxs {
    margin: -0.188rem !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important;
  }
  .mx-lg-6 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important;
  }
  .mx-lg-12 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-lg-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-lg-200 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-lg-sm {
    margin-right: 0.875rem !important;
    margin-left: 0.875rem !important;
  }
  .mx-lg-xs {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-lg-xxs {
    margin-right: 0.188rem !important;
    margin-left: 0.188rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-lg-n4 {
    margin-right: -0.875rem !important;
    margin-left: -0.875rem !important;
  }
  .mx-lg-n5 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n6 {
    margin-right: -2.3rem !important;
    margin-left: -2.3rem !important;
  }
  .mx-lg-n7 {
    margin-right: -2.8rem !important;
    margin-left: -2.8rem !important;
  }
  .mx-lg-n12 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-lg-n20 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-lg-n200 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-lg-nsm {
    margin-right: -0.875rem !important;
    margin-left: -0.875rem !important;
  }
  .mx-lg-nxs {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-lg-nxxs {
    margin-right: -0.188rem !important;
    margin-left: -0.188rem !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important;
  }
  .my-lg-6 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important;
  }
  .my-lg-12 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-lg-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-lg-200 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-lg-sm {
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }
  .my-lg-xs {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-lg-xxs {
    margin-top: 0.188rem !important;
    margin-bottom: 0.188rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-lg-n4 {
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }
  .my-lg-n5 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n6 {
    margin-top: -2.3rem !important;
    margin-bottom: -2.3rem !important;
  }
  .my-lg-n7 {
    margin-top: -2.8rem !important;
    margin-bottom: -2.8rem !important;
  }
  .my-lg-n12 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-lg-n20 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-lg-n200 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-lg-nsm {
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }
  .my-lg-nxs {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-lg-nxxs {
    margin-top: -0.188rem !important;
    margin-bottom: -0.188rem !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 2.3rem !important;
  }
  .mt-lg-6 {
    margin-top: 2.8rem !important;
  }
  .mt-lg-12 {
    margin-top: 0.75rem !important;
  }
  .mt-lg-20 {
    margin-top: 1.25rem !important;
  }
  .mt-lg-200 {
    margin-top: 2rem !important;
  }
  .mt-lg-sm {
    margin-top: 0.875rem !important;
  }
  .mt-lg-xs {
    margin-top: 0.375rem !important;
  }
  .mt-lg-xxs {
    margin-top: 0.188rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -0.625rem !important;
  }
  .mt-lg-n4 {
    margin-top: -0.875rem !important;
  }
  .mt-lg-n5 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n6 {
    margin-top: -2.3rem !important;
  }
  .mt-lg-n7 {
    margin-top: -2.8rem !important;
  }
  .mt-lg-n12 {
    margin-top: -0.75rem !important;
  }
  .mt-lg-n20 {
    margin-top: -1.25rem !important;
  }
  .mt-lg-n200 {
    margin-top: -2rem !important;
  }
  .mt-lg-nsm {
    margin-top: -0.875rem !important;
  }
  .mt-lg-nxs {
    margin-top: -0.375rem !important;
  }
  .mt-lg-nxxs {
    margin-top: -0.188rem !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 2.3rem !important;
  }
  .me-lg-6 {
    margin-right: 2.8rem !important;
  }
  .me-lg-12 {
    margin-right: 0.75rem !important;
  }
  .me-lg-20 {
    margin-right: 1.25rem !important;
  }
  .me-lg-200 {
    margin-right: 2rem !important;
  }
  .me-lg-sm {
    margin-right: 0.875rem !important;
  }
  .me-lg-xs {
    margin-right: 0.375rem !important;
  }
  .me-lg-xxs {
    margin-right: 0.188rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -0.625rem !important;
  }
  .me-lg-n4 {
    margin-right: -0.875rem !important;
  }
  .me-lg-n5 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n6 {
    margin-right: -2.3rem !important;
  }
  .me-lg-n7 {
    margin-right: -2.8rem !important;
  }
  .me-lg-n12 {
    margin-right: -0.75rem !important;
  }
  .me-lg-n20 {
    margin-right: -1.25rem !important;
  }
  .me-lg-n200 {
    margin-right: -2rem !important;
  }
  .me-lg-nsm {
    margin-right: -0.875rem !important;
  }
  .me-lg-nxs {
    margin-right: -0.375rem !important;
  }
  .me-lg-nxxs {
    margin-right: -0.188rem !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 2.3rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 2.8rem !important;
  }
  .mb-lg-12 {
    margin-bottom: 0.75rem !important;
  }
  .mb-lg-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-lg-200 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-sm {
    margin-bottom: 0.875rem !important;
  }
  .mb-lg-xs {
    margin-bottom: 0.375rem !important;
  }
  .mb-lg-xxs {
    margin-bottom: 0.188rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -0.625rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -0.875rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -2.3rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -2.8rem !important;
  }
  .mb-lg-n12 {
    margin-bottom: -0.75rem !important;
  }
  .mb-lg-n20 {
    margin-bottom: -1.25rem !important;
  }
  .mb-lg-n200 {
    margin-bottom: -2rem !important;
  }
  .mb-lg-nsm {
    margin-bottom: -0.875rem !important;
  }
  .mb-lg-nxs {
    margin-bottom: -0.375rem !important;
  }
  .mb-lg-nxxs {
    margin-bottom: -0.188rem !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 2.3rem !important;
  }
  .ms-lg-6 {
    margin-left: 2.8rem !important;
  }
  .ms-lg-12 {
    margin-left: 0.75rem !important;
  }
  .ms-lg-20 {
    margin-left: 1.25rem !important;
  }
  .ms-lg-200 {
    margin-left: 2rem !important;
  }
  .ms-lg-sm {
    margin-left: 0.875rem !important;
  }
  .ms-lg-xs {
    margin-left: 0.375rem !important;
  }
  .ms-lg-xxs {
    margin-left: 0.188rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -0.625rem !important;
  }
  .ms-lg-n4 {
    margin-left: -0.875rem !important;
  }
  .ms-lg-n5 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n6 {
    margin-left: -2.3rem !important;
  }
  .ms-lg-n7 {
    margin-left: -2.8rem !important;
  }
  .ms-lg-n12 {
    margin-left: -0.75rem !important;
  }
  .ms-lg-n20 {
    margin-left: -1.25rem !important;
  }
  .ms-lg-n200 {
    margin-left: -2rem !important;
  }
  .ms-lg-nsm {
    margin-left: -0.875rem !important;
  }
  .ms-lg-nxs {
    margin-left: -0.375rem !important;
  }
  .ms-lg-nxxs {
    margin-left: -0.188rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2.3rem !important;
  }
  .p-lg-6 {
    padding: 2.8rem !important;
  }
  .p-lg-12 {
    padding: 0.75rem !important;
  }
  .p-lg-20 {
    padding: 1.25rem !important;
  }
  .p-lg-200 {
    padding: 2rem !important;
  }
  .p-lg-sm {
    padding: 0.875rem !important;
  }
  .p-lg-xs {
    padding: 0.375rem !important;
  }
  .p-lg-xxs {
    padding: 0.188rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 2.3rem !important;
    padding-left: 2.3rem !important;
  }
  .px-lg-6 {
    padding-right: 2.8rem !important;
    padding-left: 2.8rem !important;
  }
  .px-lg-12 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-lg-20 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-lg-200 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-lg-sm {
    padding-right: 0.875rem !important;
    padding-left: 0.875rem !important;
  }
  .px-lg-xs {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-lg-xxs {
    padding-right: 0.188rem !important;
    padding-left: 0.188rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 2.3rem !important;
    padding-bottom: 2.3rem !important;
  }
  .py-lg-6 {
    padding-top: 2.8rem !important;
    padding-bottom: 2.8rem !important;
  }
  .py-lg-12 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-lg-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-lg-200 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-lg-sm {
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
  }
  .py-lg-xs {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-lg-xxs {
    padding-top: 0.188rem !important;
    padding-bottom: 0.188rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 2.3rem !important;
  }
  .pt-lg-6 {
    padding-top: 2.8rem !important;
  }
  .pt-lg-12 {
    padding-top: 0.75rem !important;
  }
  .pt-lg-20 {
    padding-top: 1.25rem !important;
  }
  .pt-lg-200 {
    padding-top: 2rem !important;
  }
  .pt-lg-sm {
    padding-top: 0.875rem !important;
  }
  .pt-lg-xs {
    padding-top: 0.375rem !important;
  }
  .pt-lg-xxs {
    padding-top: 0.188rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 2.3rem !important;
  }
  .pe-lg-6 {
    padding-right: 2.8rem !important;
  }
  .pe-lg-12 {
    padding-right: 0.75rem !important;
  }
  .pe-lg-20 {
    padding-right: 1.25rem !important;
  }
  .pe-lg-200 {
    padding-right: 2rem !important;
  }
  .pe-lg-sm {
    padding-right: 0.875rem !important;
  }
  .pe-lg-xs {
    padding-right: 0.375rem !important;
  }
  .pe-lg-xxs {
    padding-right: 0.188rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 2.3rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 2.8rem !important;
  }
  .pb-lg-12 {
    padding-bottom: 0.75rem !important;
  }
  .pb-lg-20 {
    padding-bottom: 1.25rem !important;
  }
  .pb-lg-200 {
    padding-bottom: 2rem !important;
  }
  .pb-lg-sm {
    padding-bottom: 0.875rem !important;
  }
  .pb-lg-xs {
    padding-bottom: 0.375rem !important;
  }
  .pb-lg-xxs {
    padding-bottom: 0.188rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 2.3rem !important;
  }
  .ps-lg-6 {
    padding-left: 2.8rem !important;
  }
  .ps-lg-12 {
    padding-left: 0.75rem !important;
  }
  .ps-lg-20 {
    padding-left: 1.25rem !important;
  }
  .ps-lg-200 {
    padding-left: 2rem !important;
  }
  .ps-lg-sm {
    padding-left: 0.875rem !important;
  }
  .ps-lg-xs {
    padding-left: 0.375rem !important;
  }
  .ps-lg-xxs {
    padding-left: 0.188rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 2.3rem !important;
  }
  .gap-lg-6 {
    gap: 2.8rem !important;
  }
  .gap-lg-12 {
    gap: 0.75rem !important;
  }
  .gap-lg-20 {
    gap: 1.25rem !important;
  }
  .gap-lg-200 {
    gap: 2rem !important;
  }
  .gap-lg-sm {
    gap: 0.875rem !important;
  }
  .gap-lg-xs {
    gap: 0.375rem !important;
  }
  .gap-lg-xxs {
    gap: 0.188rem !important;
  }
  .fs-lg-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .fs-lg-2 {
    font-size: calc(1.325rem + 0.9vw) !important;
  }
  .fs-lg-3 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }
  .fs-lg-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-lg-5 {
    font-size: 1.25rem !important;
  }
  .fs-lg-6 {
    font-size: 1rem !important;
  }
  .fs-lg-12 {
    font-size: 0.75rem !important;
  }
  .fs-lg-14 {
    font-size: 0.875rem !important;
  }
  .fs-lg-16 {
    font-size: 1rem !important;
  }
  .fs-lg-18 {
    font-size: 1.125rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1536px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2.3rem !important;
  }
  .m-xl-6 {
    margin: 2.8rem !important;
  }
  .m-xl-12 {
    margin: 0.75rem !important;
  }
  .m-xl-20 {
    margin: 1.25rem !important;
  }
  .m-xl-200 {
    margin: 2rem !important;
  }
  .m-xl-sm {
    margin: 0.875rem !important;
  }
  .m-xl-xs {
    margin: 0.375rem !important;
  }
  .m-xl-xxs {
    margin: 0.188rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important;
  }
  .mx-xl-6 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important;
  }
  .mx-xl-12 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xl-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xl-200 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xl-sm {
    margin-right: 0.875rem !important;
    margin-left: 0.875rem !important;
  }
  .mx-xl-xs {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-xl-xxs {
    margin-right: 0.188rem !important;
    margin-left: 0.188rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important;
  }
  .my-xl-6 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important;
  }
  .my-xl-12 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xl-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xl-200 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xl-sm {
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }
  .my-xl-xs {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-xl-xxs {
    margin-top: 0.188rem !important;
    margin-bottom: 0.188rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 2.3rem !important;
  }
  .mt-xl-6 {
    margin-top: 2.8rem !important;
  }
  .mt-xl-12 {
    margin-top: 0.75rem !important;
  }
  .mt-xl-20 {
    margin-top: 1.25rem !important;
  }
  .mt-xl-200 {
    margin-top: 2rem !important;
  }
  .mt-xl-sm {
    margin-top: 0.875rem !important;
  }
  .mt-xl-xs {
    margin-top: 0.375rem !important;
  }
  .mt-xl-xxs {
    margin-top: 0.188rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 2.3rem !important;
  }
  .me-xl-6 {
    margin-right: 2.8rem !important;
  }
  .me-xl-12 {
    margin-right: 0.75rem !important;
  }
  .me-xl-20 {
    margin-right: 1.25rem !important;
  }
  .me-xl-200 {
    margin-right: 2rem !important;
  }
  .me-xl-sm {
    margin-right: 0.875rem !important;
  }
  .me-xl-xs {
    margin-right: 0.375rem !important;
  }
  .me-xl-xxs {
    margin-right: 0.188rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 2.3rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 2.8rem !important;
  }
  .mb-xl-12 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xl-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xl-200 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-sm {
    margin-bottom: 0.875rem !important;
  }
  .mb-xl-xs {
    margin-bottom: 0.375rem !important;
  }
  .mb-xl-xxs {
    margin-bottom: 0.188rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 2.3rem !important;
  }
  .ms-xl-6 {
    margin-left: 2.8rem !important;
  }
  .ms-xl-12 {
    margin-left: 0.75rem !important;
  }
  .ms-xl-20 {
    margin-left: 1.25rem !important;
  }
  .ms-xl-200 {
    margin-left: 2rem !important;
  }
  .ms-xl-sm {
    margin-left: 0.875rem !important;
  }
  .ms-xl-xs {
    margin-left: 0.375rem !important;
  }
  .ms-xl-xxs {
    margin-left: 0.188rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2.3rem !important;
  }
  .m-xl-6 {
    margin: 2.8rem !important;
  }
  .m-xl-12 {
    margin: 0.75rem !important;
  }
  .m-xl-20 {
    margin: 1.25rem !important;
  }
  .m-xl-200 {
    margin: 2rem !important;
  }
  .m-xl-sm {
    margin: 0.875rem !important;
  }
  .m-xl-xs {
    margin: 0.375rem !important;
  }
  .m-xl-xxs {
    margin: 0.188rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -0.625rem !important;
  }
  .m-xl-n4 {
    margin: -0.875rem !important;
  }
  .m-xl-n5 {
    margin: -1.5rem !important;
  }
  .m-xl-n6 {
    margin: -2.3rem !important;
  }
  .m-xl-n7 {
    margin: -2.8rem !important;
  }
  .m-xl-n12 {
    margin: -0.75rem !important;
  }
  .m-xl-n20 {
    margin: -1.25rem !important;
  }
  .m-xl-n200 {
    margin: -2rem !important;
  }
  .m-xl-nsm {
    margin: -0.875rem !important;
  }
  .m-xl-nxs {
    margin: -0.375rem !important;
  }
  .m-xl-nxxs {
    margin: -0.188rem !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important;
  }
  .mx-xl-6 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important;
  }
  .mx-xl-12 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xl-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xl-200 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xl-sm {
    margin-right: 0.875rem !important;
    margin-left: 0.875rem !important;
  }
  .mx-xl-xs {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-xl-xxs {
    margin-right: 0.188rem !important;
    margin-left: 0.188rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-xl-n4 {
    margin-right: -0.875rem !important;
    margin-left: -0.875rem !important;
  }
  .mx-xl-n5 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n6 {
    margin-right: -2.3rem !important;
    margin-left: -2.3rem !important;
  }
  .mx-xl-n7 {
    margin-right: -2.8rem !important;
    margin-left: -2.8rem !important;
  }
  .mx-xl-n12 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-xl-n20 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-xl-n200 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-xl-nsm {
    margin-right: -0.875rem !important;
    margin-left: -0.875rem !important;
  }
  .mx-xl-nxs {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-xl-nxxs {
    margin-right: -0.188rem !important;
    margin-left: -0.188rem !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important;
  }
  .my-xl-6 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important;
  }
  .my-xl-12 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xl-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xl-200 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xl-sm {
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }
  .my-xl-xs {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-xl-xxs {
    margin-top: 0.188rem !important;
    margin-bottom: 0.188rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-xl-n4 {
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }
  .my-xl-n5 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n6 {
    margin-top: -2.3rem !important;
    margin-bottom: -2.3rem !important;
  }
  .my-xl-n7 {
    margin-top: -2.8rem !important;
    margin-bottom: -2.8rem !important;
  }
  .my-xl-n12 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-xl-n20 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-xl-n200 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-xl-nsm {
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }
  .my-xl-nxs {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-xl-nxxs {
    margin-top: -0.188rem !important;
    margin-bottom: -0.188rem !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 2.3rem !important;
  }
  .mt-xl-6 {
    margin-top: 2.8rem !important;
  }
  .mt-xl-12 {
    margin-top: 0.75rem !important;
  }
  .mt-xl-20 {
    margin-top: 1.25rem !important;
  }
  .mt-xl-200 {
    margin-top: 2rem !important;
  }
  .mt-xl-sm {
    margin-top: 0.875rem !important;
  }
  .mt-xl-xs {
    margin-top: 0.375rem !important;
  }
  .mt-xl-xxs {
    margin-top: 0.188rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -0.625rem !important;
  }
  .mt-xl-n4 {
    margin-top: -0.875rem !important;
  }
  .mt-xl-n5 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n6 {
    margin-top: -2.3rem !important;
  }
  .mt-xl-n7 {
    margin-top: -2.8rem !important;
  }
  .mt-xl-n12 {
    margin-top: -0.75rem !important;
  }
  .mt-xl-n20 {
    margin-top: -1.25rem !important;
  }
  .mt-xl-n200 {
    margin-top: -2rem !important;
  }
  .mt-xl-nsm {
    margin-top: -0.875rem !important;
  }
  .mt-xl-nxs {
    margin-top: -0.375rem !important;
  }
  .mt-xl-nxxs {
    margin-top: -0.188rem !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 2.3rem !important;
  }
  .me-xl-6 {
    margin-right: 2.8rem !important;
  }
  .me-xl-12 {
    margin-right: 0.75rem !important;
  }
  .me-xl-20 {
    margin-right: 1.25rem !important;
  }
  .me-xl-200 {
    margin-right: 2rem !important;
  }
  .me-xl-sm {
    margin-right: 0.875rem !important;
  }
  .me-xl-xs {
    margin-right: 0.375rem !important;
  }
  .me-xl-xxs {
    margin-right: 0.188rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -0.625rem !important;
  }
  .me-xl-n4 {
    margin-right: -0.875rem !important;
  }
  .me-xl-n5 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n6 {
    margin-right: -2.3rem !important;
  }
  .me-xl-n7 {
    margin-right: -2.8rem !important;
  }
  .me-xl-n12 {
    margin-right: -0.75rem !important;
  }
  .me-xl-n20 {
    margin-right: -1.25rem !important;
  }
  .me-xl-n200 {
    margin-right: -2rem !important;
  }
  .me-xl-nsm {
    margin-right: -0.875rem !important;
  }
  .me-xl-nxs {
    margin-right: -0.375rem !important;
  }
  .me-xl-nxxs {
    margin-right: -0.188rem !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 2.3rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 2.8rem !important;
  }
  .mb-xl-12 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xl-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xl-200 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-sm {
    margin-bottom: 0.875rem !important;
  }
  .mb-xl-xs {
    margin-bottom: 0.375rem !important;
  }
  .mb-xl-xxs {
    margin-bottom: 0.188rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -0.625rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -0.875rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -2.3rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -2.8rem !important;
  }
  .mb-xl-n12 {
    margin-bottom: -0.75rem !important;
  }
  .mb-xl-n20 {
    margin-bottom: -1.25rem !important;
  }
  .mb-xl-n200 {
    margin-bottom: -2rem !important;
  }
  .mb-xl-nsm {
    margin-bottom: -0.875rem !important;
  }
  .mb-xl-nxs {
    margin-bottom: -0.375rem !important;
  }
  .mb-xl-nxxs {
    margin-bottom: -0.188rem !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 2.3rem !important;
  }
  .ms-xl-6 {
    margin-left: 2.8rem !important;
  }
  .ms-xl-12 {
    margin-left: 0.75rem !important;
  }
  .ms-xl-20 {
    margin-left: 1.25rem !important;
  }
  .ms-xl-200 {
    margin-left: 2rem !important;
  }
  .ms-xl-sm {
    margin-left: 0.875rem !important;
  }
  .ms-xl-xs {
    margin-left: 0.375rem !important;
  }
  .ms-xl-xxs {
    margin-left: 0.188rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -0.625rem !important;
  }
  .ms-xl-n4 {
    margin-left: -0.875rem !important;
  }
  .ms-xl-n5 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n6 {
    margin-left: -2.3rem !important;
  }
  .ms-xl-n7 {
    margin-left: -2.8rem !important;
  }
  .ms-xl-n12 {
    margin-left: -0.75rem !important;
  }
  .ms-xl-n20 {
    margin-left: -1.25rem !important;
  }
  .ms-xl-n200 {
    margin-left: -2rem !important;
  }
  .ms-xl-nsm {
    margin-left: -0.875rem !important;
  }
  .ms-xl-nxs {
    margin-left: -0.375rem !important;
  }
  .ms-xl-nxxs {
    margin-left: -0.188rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2.3rem !important;
  }
  .p-xl-6 {
    padding: 2.8rem !important;
  }
  .p-xl-12 {
    padding: 0.75rem !important;
  }
  .p-xl-20 {
    padding: 1.25rem !important;
  }
  .p-xl-200 {
    padding: 2rem !important;
  }
  .p-xl-sm {
    padding: 0.875rem !important;
  }
  .p-xl-xs {
    padding: 0.375rem !important;
  }
  .p-xl-xxs {
    padding: 0.188rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 2.3rem !important;
    padding-left: 2.3rem !important;
  }
  .px-xl-6 {
    padding-right: 2.8rem !important;
    padding-left: 2.8rem !important;
  }
  .px-xl-12 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-xl-20 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xl-200 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xl-sm {
    padding-right: 0.875rem !important;
    padding-left: 0.875rem !important;
  }
  .px-xl-xs {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-xl-xxs {
    padding-right: 0.188rem !important;
    padding-left: 0.188rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 2.3rem !important;
    padding-bottom: 2.3rem !important;
  }
  .py-xl-6 {
    padding-top: 2.8rem !important;
    padding-bottom: 2.8rem !important;
  }
  .py-xl-12 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-xl-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xl-200 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xl-sm {
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
  }
  .py-xl-xs {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-xl-xxs {
    padding-top: 0.188rem !important;
    padding-bottom: 0.188rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 2.3rem !important;
  }
  .pt-xl-6 {
    padding-top: 2.8rem !important;
  }
  .pt-xl-12 {
    padding-top: 0.75rem !important;
  }
  .pt-xl-20 {
    padding-top: 1.25rem !important;
  }
  .pt-xl-200 {
    padding-top: 2rem !important;
  }
  .pt-xl-sm {
    padding-top: 0.875rem !important;
  }
  .pt-xl-xs {
    padding-top: 0.375rem !important;
  }
  .pt-xl-xxs {
    padding-top: 0.188rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 2.3rem !important;
  }
  .pe-xl-6 {
    padding-right: 2.8rem !important;
  }
  .pe-xl-12 {
    padding-right: 0.75rem !important;
  }
  .pe-xl-20 {
    padding-right: 1.25rem !important;
  }
  .pe-xl-200 {
    padding-right: 2rem !important;
  }
  .pe-xl-sm {
    padding-right: 0.875rem !important;
  }
  .pe-xl-xs {
    padding-right: 0.375rem !important;
  }
  .pe-xl-xxs {
    padding-right: 0.188rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 2.3rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 2.8rem !important;
  }
  .pb-xl-12 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xl-20 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xl-200 {
    padding-bottom: 2rem !important;
  }
  .pb-xl-sm {
    padding-bottom: 0.875rem !important;
  }
  .pb-xl-xs {
    padding-bottom: 0.375rem !important;
  }
  .pb-xl-xxs {
    padding-bottom: 0.188rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 2.3rem !important;
  }
  .ps-xl-6 {
    padding-left: 2.8rem !important;
  }
  .ps-xl-12 {
    padding-left: 0.75rem !important;
  }
  .ps-xl-20 {
    padding-left: 1.25rem !important;
  }
  .ps-xl-200 {
    padding-left: 2rem !important;
  }
  .ps-xl-sm {
    padding-left: 0.875rem !important;
  }
  .ps-xl-xs {
    padding-left: 0.375rem !important;
  }
  .ps-xl-xxs {
    padding-left: 0.188rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 2.3rem !important;
  }
  .gap-xl-6 {
    gap: 2.8rem !important;
  }
  .gap-xl-12 {
    gap: 0.75rem !important;
  }
  .gap-xl-20 {
    gap: 1.25rem !important;
  }
  .gap-xl-200 {
    gap: 2rem !important;
  }
  .gap-xl-sm {
    gap: 0.875rem !important;
  }
  .gap-xl-xs {
    gap: 0.375rem !important;
  }
  .gap-xl-xxs {
    gap: 0.188rem !important;
  }
  .fs-xl-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .fs-xl-2 {
    font-size: calc(1.325rem + 0.9vw) !important;
  }
  .fs-xl-3 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }
  .fs-xl-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-xl-5 {
    font-size: 1.25rem !important;
  }
  .fs-xl-6 {
    font-size: 1rem !important;
  }
  .fs-xl-12 {
    font-size: 0.75rem !important;
  }
  .fs-xl-14 {
    font-size: 0.875rem !important;
  }
  .fs-xl-16 {
    font-size: 1rem !important;
  }
  .fs-xl-18 {
    font-size: 1.125rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
  .fs-sm-1 {
    font-size: 2.5rem !important;
  }
  .fs-sm-2 {
    font-size: 2rem !important;
  }
  .fs-sm-3 {
    font-size: 1.75rem !important;
  }
  .fs-sm-4 {
    font-size: 1.5rem !important;
  }
  .fs-md-1 {
    font-size: 2.5rem !important;
  }
  .fs-md-2 {
    font-size: 2rem !important;
  }
  .fs-md-3 {
    font-size: 1.75rem !important;
  }
  .fs-md-4 {
    font-size: 1.5rem !important;
  }
  .fs-mdl-1 {
    font-size: 2.5rem !important;
  }
  .fs-mdl-2 {
    font-size: 2rem !important;
  }
  .fs-mdl-3 {
    font-size: 1.75rem !important;
  }
  .fs-mdl-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #00236F;
  --bs-secondary: #F5F8FE;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #BA1A1A;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 0, 35, 111;
  --bs-secondary-rgb: 245, 248, 254;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 186, 26, 26;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #E8EFFF;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #00236F;
  --bs-link-hover-color: #001c59;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #FFFFFF;
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 14px;
  --bs-accordion-btn-padding-y: 10px;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.5rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23002064'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #8091b7;
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 14px;
  --bs-accordion-body-padding-y: 0px;
  --bs-accordion-active-color: #002064;
  --bs-accordion-active-bg: #e6e9f1;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #00236F;
  --bs-btn-border-color: #00236F;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #001e5e;
  --bs-btn-hover-border-color: #001c59;
  --bs-btn-focus-shadow-rgb: 38, 68, 133;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #001c59;
  --bs-btn-active-border-color: #001a53;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #00236F;
  --bs-btn-disabled-border-color: #00236F;
}

.btn-secondary {
  --bs-btn-color: #000;
  --bs-btn-bg: #F5F8FE;
  --bs-btn-border-color: #F5F8FE;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f7f9fe;
  --bs-btn-hover-border-color: #f6f9fe;
  --bs-btn-focus-shadow-rgb: 208, 211, 216;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f7f9fe;
  --bs-btn-active-border-color: #f6f9fe;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #F5F8FE;
  --bs-btn-disabled-border-color: #F5F8FE;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #BA1A1A;
  --bs-btn-border-color: #BA1A1A;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #9e1616;
  --bs-btn-hover-border-color: #951515;
  --bs-btn-focus-shadow-rgb: 196, 60, 60;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #951515;
  --bs-btn-active-border-color: #8c1414;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #BA1A1A;
  --bs-btn-disabled-border-color: #BA1A1A;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #00236F;
  --bs-btn-border-color: #00236F;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00236F;
  --bs-btn-hover-border-color: #00236F;
  --bs-btn-focus-shadow-rgb: 0, 35, 111;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00236F;
  --bs-btn-active-border-color: #00236F;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00236F;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00236F;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #F5F8FE;
  --bs-btn-border-color: #F5F8FE;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #F5F8FE;
  --bs-btn-hover-border-color: #F5F8FE;
  --bs-btn-focus-shadow-rgb: 245, 248, 254;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #F5F8FE;
  --bs-btn-active-border-color: #F5F8FE;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #F5F8FE;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #F5F8FE;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #BA1A1A;
  --bs-btn-border-color: #BA1A1A;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #BA1A1A;
  --bs-btn-hover-border-color: #BA1A1A;
  --bs-btn-focus-shadow-rgb: 186, 26, 26;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #BA1A1A;
  --bs-btn-active-border-color: #BA1A1A;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #BA1A1A;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #BA1A1A;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 38, 68, 133;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: 0.5rem;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.25rem;
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #00236F;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 600px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 900px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1024px) {
  .dropdown-menu-mdl-start {
    --bs-position: start;
  }
  .dropdown-menu-mdl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-mdl-end {
    --bs-position: end;
  }
  .dropdown-menu-mdl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1536px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #00236F;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(1rem + 1px);
  padding-bottom: calc(1rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(1rem + 1px);
  padding-bottom: calc(1rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(1rem + 1px);
  padding-bottom: calc(1rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 1rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #E7E7ED;
  appearance: none;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #8091b7;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 35, 111, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 1rem 0.75rem;
  margin: -1rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 1rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 2rem + 2px);
  padding: 1rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.form-control-sm::file-selector-button {
  padding: 1rem 0.5rem;
  margin: -1rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 2rem + 2px);
  padding: 1rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}
.form-control-lg::file-selector-button {
  padding: 1rem 1rem;
  margin: -1rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 2rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 2rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 2rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 2rem + 2px);
  padding: 1rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 8px;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 8px;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 2rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 2rem + 2px);
}

.form-select {
  display: block;
  width: 100%;
  padding: 1rem 2.25rem 1rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #E7E9ED;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #8091b7;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 35, 111, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #8091b7;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 35, 111, 0.25);
}
.form-check-input:checked {
  background-color: #00236F;
  border-color: #00236F;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #00236F;
  border-color: #00236F;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238091b7'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(0, 35, 111, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(0, 35, 111, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #00236F;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b3bdd4;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #00236F;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b3bdd4;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.5rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.5rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 1rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #E7E9ED;
  border-radius: 8px;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 1rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 1rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.375rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.5rem) center;
  background-size: calc(0.75em + 1rem) calc(0.75em + 1rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 2rem);
  background-position: top calc(0.375em + 0.5rem) right calc(0.375em + 0.5rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 1rem) calc(0.75em + 1rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 2rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #BA1A1A;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(186, 26, 26, 0.9);
  border-radius: 0.375rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #BA1A1A;
  padding-right: calc(1.5em + 2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23BA1A1A'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23BA1A1A' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.5rem) center;
  background-size: calc(0.75em + 1rem) calc(0.75em + 1rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #BA1A1A;
  box-shadow: 0 0 0 0.25rem rgba(186, 26, 26, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 2rem);
  background-position: top calc(0.375em + 0.5rem) right calc(0.375em + 0.5rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #BA1A1A;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23BA1A1A'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23BA1A1A' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 1rem) calc(0.75em + 1rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #BA1A1A;
  box-shadow: 0 0 0 0.25rem rgba(186, 26, 26, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 2rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #BA1A1A;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #BA1A1A;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(186, 26, 26, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #BA1A1A;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #00236F;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: #E7E9ED;
  --bs-card-border-radius: 8px;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: 7px;
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #FFFFFF;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 600px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #00236F;
  --bs-list-group-active-border-color: #00236F;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 600px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 900px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1024px) {
  .list-group-horizontal-mdl {
    flex-direction: row;
  }
  .list-group-horizontal-mdl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-mdl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-mdl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-mdl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-mdl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1536px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #001543;
  background-color: #ccd3e2;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #001543;
  background-color: #b8becb;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #001543;
  border-color: #001543;
}

.list-group-item-secondary {
  color: #626366;
  background-color: #fdfeff;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #626366;
  background-color: #e4e5e6;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #626366;
  border-color: #626366;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #701010;
  background-color: #f1d1d1;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #701010;
  background-color: #d9bcbc;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #701010;
  border-color: #701010;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2.3rem !important;
}

.m-6 {
  margin: 2.8rem !important;
}

.m-12 {
  margin: 0.75rem !important;
}

.m-20 {
  margin: 1.25rem !important;
}

.m-200 {
  margin: 2rem !important;
}

.m-sm {
  margin: 0.875rem !important;
}

.m-xs {
  margin: 0.375rem !important;
}

.m-xxs {
  margin: 0.188rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 2.3rem !important;
  margin-left: 2.3rem !important;
}

.mx-6 {
  margin-right: 2.8rem !important;
  margin-left: 2.8rem !important;
}

.mx-12 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-20 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-200 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-sm {
  margin-right: 0.875rem !important;
  margin-left: 0.875rem !important;
}

.mx-xs {
  margin-right: 0.375rem !important;
  margin-left: 0.375rem !important;
}

.mx-xxs {
  margin-right: 0.188rem !important;
  margin-left: 0.188rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2.3rem !important;
  margin-bottom: 2.3rem !important;
}

.my-6 {
  margin-top: 2.8rem !important;
  margin-bottom: 2.8rem !important;
}

.my-12 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-20 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-200 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-sm {
  margin-top: 0.875rem !important;
  margin-bottom: 0.875rem !important;
}

.my-xs {
  margin-top: 0.375rem !important;
  margin-bottom: 0.375rem !important;
}

.my-xxs {
  margin-top: 0.188rem !important;
  margin-bottom: 0.188rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2.3rem !important;
}

.mt-6 {
  margin-top: 2.8rem !important;
}

.mt-12 {
  margin-top: 0.75rem !important;
}

.mt-20 {
  margin-top: 1.25rem !important;
}

.mt-200 {
  margin-top: 2rem !important;
}

.mt-sm {
  margin-top: 0.875rem !important;
}

.mt-xs {
  margin-top: 0.375rem !important;
}

.mt-xxs {
  margin-top: 0.188rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 2.3rem !important;
}

.me-6 {
  margin-right: 2.8rem !important;
}

.me-12 {
  margin-right: 0.75rem !important;
}

.me-20 {
  margin-right: 1.25rem !important;
}

.me-200 {
  margin-right: 2rem !important;
}

.me-sm {
  margin-right: 0.875rem !important;
}

.me-xs {
  margin-right: 0.375rem !important;
}

.me-xxs {
  margin-right: 0.188rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2.3rem !important;
}

.mb-6 {
  margin-bottom: 2.8rem !important;
}

.mb-12 {
  margin-bottom: 0.75rem !important;
}

.mb-20 {
  margin-bottom: 1.25rem !important;
}

.mb-200 {
  margin-bottom: 2rem !important;
}

.mb-sm {
  margin-bottom: 0.875rem !important;
}

.mb-xs {
  margin-bottom: 0.375rem !important;
}

.mb-xxs {
  margin-bottom: 0.188rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 2.3rem !important;
}

.ms-6 {
  margin-left: 2.8rem !important;
}

.ms-12 {
  margin-left: 0.75rem !important;
}

.ms-20 {
  margin-left: 1.25rem !important;
}

.ms-200 {
  margin-left: 2rem !important;
}

.ms-sm {
  margin-left: 0.875rem !important;
}

.ms-xs {
  margin-left: 0.375rem !important;
}

.ms-xxs {
  margin-left: 0.188rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2.3rem !important;
}

.m-6 {
  margin: 2.8rem !important;
}

.m-12 {
  margin: 0.75rem !important;
}

.m-20 {
  margin: 1.25rem !important;
}

.m-200 {
  margin: 2rem !important;
}

.m-sm {
  margin: 0.875rem !important;
}

.m-xs {
  margin: 0.375rem !important;
}

.m-xxs {
  margin: 0.188rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -0.625rem !important;
}

.m-n4 {
  margin: -0.875rem !important;
}

.m-n5 {
  margin: -1.5rem !important;
}

.m-n6 {
  margin: -2.3rem !important;
}

.m-n7 {
  margin: -2.8rem !important;
}

.m-n12 {
  margin: -0.75rem !important;
}

.m-n20 {
  margin: -1.25rem !important;
}

.m-n200 {
  margin: -2rem !important;
}

.m-nsm {
  margin: -0.875rem !important;
}

.m-nxs {
  margin: -0.375rem !important;
}

.m-nxxs {
  margin: -0.188rem !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 2.3rem !important;
  margin-left: 2.3rem !important;
}

.mx-6 {
  margin-right: 2.8rem !important;
  margin-left: 2.8rem !important;
}

.mx-12 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-20 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-200 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.mx-sm {
  margin-right: 0.875rem !important;
  margin-left: 0.875rem !important;
}

.mx-xs {
  margin-right: 0.375rem !important;
  margin-left: 0.375rem !important;
}

.mx-xxs {
  margin-right: 0.188rem !important;
  margin-left: 0.188rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -0.625rem !important;
  margin-left: -0.625rem !important;
}

.mx-n4 {
  margin-right: -0.875rem !important;
  margin-left: -0.875rem !important;
}

.mx-n5 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n6 {
  margin-right: -2.3rem !important;
  margin-left: -2.3rem !important;
}

.mx-n7 {
  margin-right: -2.8rem !important;
  margin-left: -2.8rem !important;
}

.mx-n12 {
  margin-right: -0.75rem !important;
  margin-left: -0.75rem !important;
}

.mx-n20 {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important;
}

.mx-n200 {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}

.mx-nsm {
  margin-right: -0.875rem !important;
  margin-left: -0.875rem !important;
}

.mx-nxs {
  margin-right: -0.375rem !important;
  margin-left: -0.375rem !important;
}

.mx-nxxs {
  margin-right: -0.188rem !important;
  margin-left: -0.188rem !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2.3rem !important;
  margin-bottom: 2.3rem !important;
}

.my-6 {
  margin-top: 2.8rem !important;
  margin-bottom: 2.8rem !important;
}

.my-12 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-20 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-200 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-sm {
  margin-top: 0.875rem !important;
  margin-bottom: 0.875rem !important;
}

.my-xs {
  margin-top: 0.375rem !important;
  margin-bottom: 0.375rem !important;
}

.my-xxs {
  margin-top: 0.188rem !important;
  margin-bottom: 0.188rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -0.625rem !important;
  margin-bottom: -0.625rem !important;
}

.my-n4 {
  margin-top: -0.875rem !important;
  margin-bottom: -0.875rem !important;
}

.my-n5 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n6 {
  margin-top: -2.3rem !important;
  margin-bottom: -2.3rem !important;
}

.my-n7 {
  margin-top: -2.8rem !important;
  margin-bottom: -2.8rem !important;
}

.my-n12 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.my-n20 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.my-n200 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.my-nsm {
  margin-top: -0.875rem !important;
  margin-bottom: -0.875rem !important;
}

.my-nxs {
  margin-top: -0.375rem !important;
  margin-bottom: -0.375rem !important;
}

.my-nxxs {
  margin-top: -0.188rem !important;
  margin-bottom: -0.188rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2.3rem !important;
}

.mt-6 {
  margin-top: 2.8rem !important;
}

.mt-12 {
  margin-top: 0.75rem !important;
}

.mt-20 {
  margin-top: 1.25rem !important;
}

.mt-200 {
  margin-top: 2rem !important;
}

.mt-sm {
  margin-top: 0.875rem !important;
}

.mt-xs {
  margin-top: 0.375rem !important;
}

.mt-xxs {
  margin-top: 0.188rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -0.625rem !important;
}

.mt-n4 {
  margin-top: -0.875rem !important;
}

.mt-n5 {
  margin-top: -1.5rem !important;
}

.mt-n6 {
  margin-top: -2.3rem !important;
}

.mt-n7 {
  margin-top: -2.8rem !important;
}

.mt-n12 {
  margin-top: -0.75rem !important;
}

.mt-n20 {
  margin-top: -1.25rem !important;
}

.mt-n200 {
  margin-top: -2rem !important;
}

.mt-nsm {
  margin-top: -0.875rem !important;
}

.mt-nxs {
  margin-top: -0.375rem !important;
}

.mt-nxxs {
  margin-top: -0.188rem !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 2.3rem !important;
}

.me-6 {
  margin-right: 2.8rem !important;
}

.me-12 {
  margin-right: 0.75rem !important;
}

.me-20 {
  margin-right: 1.25rem !important;
}

.me-200 {
  margin-right: 2rem !important;
}

.me-sm {
  margin-right: 0.875rem !important;
}

.me-xs {
  margin-right: 0.375rem !important;
}

.me-xxs {
  margin-right: 0.188rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -0.625rem !important;
}

.me-n4 {
  margin-right: -0.875rem !important;
}

.me-n5 {
  margin-right: -1.5rem !important;
}

.me-n6 {
  margin-right: -2.3rem !important;
}

.me-n7 {
  margin-right: -2.8rem !important;
}

.me-n12 {
  margin-right: -0.75rem !important;
}

.me-n20 {
  margin-right: -1.25rem !important;
}

.me-n200 {
  margin-right: -2rem !important;
}

.me-nsm {
  margin-right: -0.875rem !important;
}

.me-nxs {
  margin-right: -0.375rem !important;
}

.me-nxxs {
  margin-right: -0.188rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2.3rem !important;
}

.mb-6 {
  margin-bottom: 2.8rem !important;
}

.mb-12 {
  margin-bottom: 0.75rem !important;
}

.mb-20 {
  margin-bottom: 1.25rem !important;
}

.mb-200 {
  margin-bottom: 2rem !important;
}

.mb-sm {
  margin-bottom: 0.875rem !important;
}

.mb-xs {
  margin-bottom: 0.375rem !important;
}

.mb-xxs {
  margin-bottom: 0.188rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -0.625rem !important;
}

.mb-n4 {
  margin-bottom: -0.875rem !important;
}

.mb-n5 {
  margin-bottom: -1.5rem !important;
}

.mb-n6 {
  margin-bottom: -2.3rem !important;
}

.mb-n7 {
  margin-bottom: -2.8rem !important;
}

.mb-n12 {
  margin-bottom: -0.75rem !important;
}

.mb-n20 {
  margin-bottom: -1.25rem !important;
}

.mb-n200 {
  margin-bottom: -2rem !important;
}

.mb-nsm {
  margin-bottom: -0.875rem !important;
}

.mb-nxs {
  margin-bottom: -0.375rem !important;
}

.mb-nxxs {
  margin-bottom: -0.188rem !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 2.3rem !important;
}

.ms-6 {
  margin-left: 2.8rem !important;
}

.ms-12 {
  margin-left: 0.75rem !important;
}

.ms-20 {
  margin-left: 1.25rem !important;
}

.ms-200 {
  margin-left: 2rem !important;
}

.ms-sm {
  margin-left: 0.875rem !important;
}

.ms-xs {
  margin-left: 0.375rem !important;
}

.ms-xxs {
  margin-left: 0.188rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -0.625rem !important;
}

.ms-n4 {
  margin-left: -0.875rem !important;
}

.ms-n5 {
  margin-left: -1.5rem !important;
}

.ms-n6 {
  margin-left: -2.3rem !important;
}

.ms-n7 {
  margin-left: -2.8rem !important;
}

.ms-n12 {
  margin-left: -0.75rem !important;
}

.ms-n20 {
  margin-left: -1.25rem !important;
}

.ms-n200 {
  margin-left: -2rem !important;
}

.ms-nsm {
  margin-left: -0.875rem !important;
}

.ms-nxs {
  margin-left: -0.375rem !important;
}

.ms-nxxs {
  margin-left: -0.188rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2.3rem !important;
}

.p-6 {
  padding: 2.8rem !important;
}

.p-12 {
  padding: 0.75rem !important;
}

.p-20 {
  padding: 1.25rem !important;
}

.p-200 {
  padding: 2rem !important;
}

.p-sm {
  padding: 0.875rem !important;
}

.p-xs {
  padding: 0.375rem !important;
}

.p-xxs {
  padding: 0.188rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 2.3rem !important;
  padding-left: 2.3rem !important;
}

.px-6 {
  padding-right: 2.8rem !important;
  padding-left: 2.8rem !important;
}

.px-12 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.px-20 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-200 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}

.px-sm {
  padding-right: 0.875rem !important;
  padding-left: 0.875rem !important;
}

.px-xs {
  padding-right: 0.375rem !important;
  padding-left: 0.375rem !important;
}

.px-xxs {
  padding-right: 0.188rem !important;
  padding-left: 0.188rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2.3rem !important;
  padding-bottom: 2.3rem !important;
}

.py-6 {
  padding-top: 2.8rem !important;
  padding-bottom: 2.8rem !important;
}

.py-12 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-20 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-200 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-sm {
  padding-top: 0.875rem !important;
  padding-bottom: 0.875rem !important;
}

.py-xs {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}

.py-xxs {
  padding-top: 0.188rem !important;
  padding-bottom: 0.188rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2.3rem !important;
}

.pt-6 {
  padding-top: 2.8rem !important;
}

.pt-12 {
  padding-top: 0.75rem !important;
}

.pt-20 {
  padding-top: 1.25rem !important;
}

.pt-200 {
  padding-top: 2rem !important;
}

.pt-sm {
  padding-top: 0.875rem !important;
}

.pt-xs {
  padding-top: 0.375rem !important;
}

.pt-xxs {
  padding-top: 0.188rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 2.3rem !important;
}

.pe-6 {
  padding-right: 2.8rem !important;
}

.pe-12 {
  padding-right: 0.75rem !important;
}

.pe-20 {
  padding-right: 1.25rem !important;
}

.pe-200 {
  padding-right: 2rem !important;
}

.pe-sm {
  padding-right: 0.875rem !important;
}

.pe-xs {
  padding-right: 0.375rem !important;
}

.pe-xxs {
  padding-right: 0.188rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2.3rem !important;
}

.pb-6 {
  padding-bottom: 2.8rem !important;
}

.pb-12 {
  padding-bottom: 0.75rem !important;
}

.pb-20 {
  padding-bottom: 1.25rem !important;
}

.pb-200 {
  padding-bottom: 2rem !important;
}

.pb-sm {
  padding-bottom: 0.875rem !important;
}

.pb-xs {
  padding-bottom: 0.375rem !important;
}

.pb-xxs {
  padding-bottom: 0.188rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 2.3rem !important;
}

.ps-6 {
  padding-left: 2.8rem !important;
}

.ps-12 {
  padding-left: 0.75rem !important;
}

.ps-20 {
  padding-left: 1.25rem !important;
}

.ps-200 {
  padding-left: 2rem !important;
}

.ps-sm {
  padding-left: 0.875rem !important;
}

.ps-xs {
  padding-left: 0.375rem !important;
}

.ps-xxs {
  padding-left: 0.188rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 2.3rem !important;
}

.gap-6 {
  gap: 2.8rem !important;
}

.gap-12 {
  gap: 0.75rem !important;
}

.gap-20 {
  gap: 1.25rem !important;
}

.gap-200 {
  gap: 2rem !important;
}

.gap-sm {
  gap: 0.875rem !important;
}

.gap-xs {
  gap: 0.375rem !important;
}

.gap-xxs {
  gap: 0.188rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fs-12 {
  font-size: 0.75rem !important;
}

.fs-14 {
  font-size: 0.875rem !important;
}

.fs-16 {
  font-size: 1rem !important;
}

.fs-18 {
  font-size: 1.125rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 600px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-auto {
    height: auto !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2.3rem !important;
  }
  .m-sm-6 {
    margin: 2.8rem !important;
  }
  .m-sm-12 {
    margin: 0.75rem !important;
  }
  .m-sm-20 {
    margin: 1.25rem !important;
  }
  .m-sm-200 {
    margin: 2rem !important;
  }
  .m-sm-sm {
    margin: 0.875rem !important;
  }
  .m-sm-xs {
    margin: 0.375rem !important;
  }
  .m-sm-xxs {
    margin: 0.188rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important;
  }
  .mx-sm-6 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important;
  }
  .mx-sm-12 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-sm-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-sm-200 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-sm-sm {
    margin-right: 0.875rem !important;
    margin-left: 0.875rem !important;
  }
  .mx-sm-xs {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-sm-xxs {
    margin-right: 0.188rem !important;
    margin-left: 0.188rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important;
  }
  .my-sm-6 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important;
  }
  .my-sm-12 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-sm-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-sm-200 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-sm-sm {
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }
  .my-sm-xs {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-sm-xxs {
    margin-top: 0.188rem !important;
    margin-bottom: 0.188rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 2.3rem !important;
  }
  .mt-sm-6 {
    margin-top: 2.8rem !important;
  }
  .mt-sm-12 {
    margin-top: 0.75rem !important;
  }
  .mt-sm-20 {
    margin-top: 1.25rem !important;
  }
  .mt-sm-200 {
    margin-top: 2rem !important;
  }
  .mt-sm-sm {
    margin-top: 0.875rem !important;
  }
  .mt-sm-xs {
    margin-top: 0.375rem !important;
  }
  .mt-sm-xxs {
    margin-top: 0.188rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 2.3rem !important;
  }
  .me-sm-6 {
    margin-right: 2.8rem !important;
  }
  .me-sm-12 {
    margin-right: 0.75rem !important;
  }
  .me-sm-20 {
    margin-right: 1.25rem !important;
  }
  .me-sm-200 {
    margin-right: 2rem !important;
  }
  .me-sm-sm {
    margin-right: 0.875rem !important;
  }
  .me-sm-xs {
    margin-right: 0.375rem !important;
  }
  .me-sm-xxs {
    margin-right: 0.188rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 2.3rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 2.8rem !important;
  }
  .mb-sm-12 {
    margin-bottom: 0.75rem !important;
  }
  .mb-sm-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-sm-200 {
    margin-bottom: 2rem !important;
  }
  .mb-sm-sm {
    margin-bottom: 0.875rem !important;
  }
  .mb-sm-xs {
    margin-bottom: 0.375rem !important;
  }
  .mb-sm-xxs {
    margin-bottom: 0.188rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 2.3rem !important;
  }
  .ms-sm-6 {
    margin-left: 2.8rem !important;
  }
  .ms-sm-12 {
    margin-left: 0.75rem !important;
  }
  .ms-sm-20 {
    margin-left: 1.25rem !important;
  }
  .ms-sm-200 {
    margin-left: 2rem !important;
  }
  .ms-sm-sm {
    margin-left: 0.875rem !important;
  }
  .ms-sm-xs {
    margin-left: 0.375rem !important;
  }
  .ms-sm-xxs {
    margin-left: 0.188rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2.3rem !important;
  }
  .m-sm-6 {
    margin: 2.8rem !important;
  }
  .m-sm-12 {
    margin: 0.75rem !important;
  }
  .m-sm-20 {
    margin: 1.25rem !important;
  }
  .m-sm-200 {
    margin: 2rem !important;
  }
  .m-sm-sm {
    margin: 0.875rem !important;
  }
  .m-sm-xs {
    margin: 0.375rem !important;
  }
  .m-sm-xxs {
    margin: 0.188rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -0.625rem !important;
  }
  .m-sm-n4 {
    margin: -0.875rem !important;
  }
  .m-sm-n5 {
    margin: -1.5rem !important;
  }
  .m-sm-n6 {
    margin: -2.3rem !important;
  }
  .m-sm-n7 {
    margin: -2.8rem !important;
  }
  .m-sm-n12 {
    margin: -0.75rem !important;
  }
  .m-sm-n20 {
    margin: -1.25rem !important;
  }
  .m-sm-n200 {
    margin: -2rem !important;
  }
  .m-sm-nsm {
    margin: -0.875rem !important;
  }
  .m-sm-nxs {
    margin: -0.375rem !important;
  }
  .m-sm-nxxs {
    margin: -0.188rem !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important;
  }
  .mx-sm-6 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important;
  }
  .mx-sm-12 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-sm-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-sm-200 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-sm-sm {
    margin-right: 0.875rem !important;
    margin-left: 0.875rem !important;
  }
  .mx-sm-xs {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-sm-xxs {
    margin-right: 0.188rem !important;
    margin-left: 0.188rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-sm-n4 {
    margin-right: -0.875rem !important;
    margin-left: -0.875rem !important;
  }
  .mx-sm-n5 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n6 {
    margin-right: -2.3rem !important;
    margin-left: -2.3rem !important;
  }
  .mx-sm-n7 {
    margin-right: -2.8rem !important;
    margin-left: -2.8rem !important;
  }
  .mx-sm-n12 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-sm-n20 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-sm-n200 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-sm-nsm {
    margin-right: -0.875rem !important;
    margin-left: -0.875rem !important;
  }
  .mx-sm-nxs {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-sm-nxxs {
    margin-right: -0.188rem !important;
    margin-left: -0.188rem !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important;
  }
  .my-sm-6 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important;
  }
  .my-sm-12 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-sm-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-sm-200 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-sm-sm {
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }
  .my-sm-xs {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-sm-xxs {
    margin-top: 0.188rem !important;
    margin-bottom: 0.188rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-sm-n4 {
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }
  .my-sm-n5 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n6 {
    margin-top: -2.3rem !important;
    margin-bottom: -2.3rem !important;
  }
  .my-sm-n7 {
    margin-top: -2.8rem !important;
    margin-bottom: -2.8rem !important;
  }
  .my-sm-n12 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-sm-n20 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-sm-n200 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-sm-nsm {
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }
  .my-sm-nxs {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-sm-nxxs {
    margin-top: -0.188rem !important;
    margin-bottom: -0.188rem !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 2.3rem !important;
  }
  .mt-sm-6 {
    margin-top: 2.8rem !important;
  }
  .mt-sm-12 {
    margin-top: 0.75rem !important;
  }
  .mt-sm-20 {
    margin-top: 1.25rem !important;
  }
  .mt-sm-200 {
    margin-top: 2rem !important;
  }
  .mt-sm-sm {
    margin-top: 0.875rem !important;
  }
  .mt-sm-xs {
    margin-top: 0.375rem !important;
  }
  .mt-sm-xxs {
    margin-top: 0.188rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -0.625rem !important;
  }
  .mt-sm-n4 {
    margin-top: -0.875rem !important;
  }
  .mt-sm-n5 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n6 {
    margin-top: -2.3rem !important;
  }
  .mt-sm-n7 {
    margin-top: -2.8rem !important;
  }
  .mt-sm-n12 {
    margin-top: -0.75rem !important;
  }
  .mt-sm-n20 {
    margin-top: -1.25rem !important;
  }
  .mt-sm-n200 {
    margin-top: -2rem !important;
  }
  .mt-sm-nsm {
    margin-top: -0.875rem !important;
  }
  .mt-sm-nxs {
    margin-top: -0.375rem !important;
  }
  .mt-sm-nxxs {
    margin-top: -0.188rem !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 2.3rem !important;
  }
  .me-sm-6 {
    margin-right: 2.8rem !important;
  }
  .me-sm-12 {
    margin-right: 0.75rem !important;
  }
  .me-sm-20 {
    margin-right: 1.25rem !important;
  }
  .me-sm-200 {
    margin-right: 2rem !important;
  }
  .me-sm-sm {
    margin-right: 0.875rem !important;
  }
  .me-sm-xs {
    margin-right: 0.375rem !important;
  }
  .me-sm-xxs {
    margin-right: 0.188rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -0.625rem !important;
  }
  .me-sm-n4 {
    margin-right: -0.875rem !important;
  }
  .me-sm-n5 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n6 {
    margin-right: -2.3rem !important;
  }
  .me-sm-n7 {
    margin-right: -2.8rem !important;
  }
  .me-sm-n12 {
    margin-right: -0.75rem !important;
  }
  .me-sm-n20 {
    margin-right: -1.25rem !important;
  }
  .me-sm-n200 {
    margin-right: -2rem !important;
  }
  .me-sm-nsm {
    margin-right: -0.875rem !important;
  }
  .me-sm-nxs {
    margin-right: -0.375rem !important;
  }
  .me-sm-nxxs {
    margin-right: -0.188rem !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 2.3rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 2.8rem !important;
  }
  .mb-sm-12 {
    margin-bottom: 0.75rem !important;
  }
  .mb-sm-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-sm-200 {
    margin-bottom: 2rem !important;
  }
  .mb-sm-sm {
    margin-bottom: 0.875rem !important;
  }
  .mb-sm-xs {
    margin-bottom: 0.375rem !important;
  }
  .mb-sm-xxs {
    margin-bottom: 0.188rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -0.625rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -0.875rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -2.3rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -2.8rem !important;
  }
  .mb-sm-n12 {
    margin-bottom: -0.75rem !important;
  }
  .mb-sm-n20 {
    margin-bottom: -1.25rem !important;
  }
  .mb-sm-n200 {
    margin-bottom: -2rem !important;
  }
  .mb-sm-nsm {
    margin-bottom: -0.875rem !important;
  }
  .mb-sm-nxs {
    margin-bottom: -0.375rem !important;
  }
  .mb-sm-nxxs {
    margin-bottom: -0.188rem !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 2.3rem !important;
  }
  .ms-sm-6 {
    margin-left: 2.8rem !important;
  }
  .ms-sm-12 {
    margin-left: 0.75rem !important;
  }
  .ms-sm-20 {
    margin-left: 1.25rem !important;
  }
  .ms-sm-200 {
    margin-left: 2rem !important;
  }
  .ms-sm-sm {
    margin-left: 0.875rem !important;
  }
  .ms-sm-xs {
    margin-left: 0.375rem !important;
  }
  .ms-sm-xxs {
    margin-left: 0.188rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -0.625rem !important;
  }
  .ms-sm-n4 {
    margin-left: -0.875rem !important;
  }
  .ms-sm-n5 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n6 {
    margin-left: -2.3rem !important;
  }
  .ms-sm-n7 {
    margin-left: -2.8rem !important;
  }
  .ms-sm-n12 {
    margin-left: -0.75rem !important;
  }
  .ms-sm-n20 {
    margin-left: -1.25rem !important;
  }
  .ms-sm-n200 {
    margin-left: -2rem !important;
  }
  .ms-sm-nsm {
    margin-left: -0.875rem !important;
  }
  .ms-sm-nxs {
    margin-left: -0.375rem !important;
  }
  .ms-sm-nxxs {
    margin-left: -0.188rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2.3rem !important;
  }
  .p-sm-6 {
    padding: 2.8rem !important;
  }
  .p-sm-12 {
    padding: 0.75rem !important;
  }
  .p-sm-20 {
    padding: 1.25rem !important;
  }
  .p-sm-200 {
    padding: 2rem !important;
  }
  .p-sm-sm {
    padding: 0.875rem !important;
  }
  .p-sm-xs {
    padding: 0.375rem !important;
  }
  .p-sm-xxs {
    padding: 0.188rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 2.3rem !important;
    padding-left: 2.3rem !important;
  }
  .px-sm-6 {
    padding-right: 2.8rem !important;
    padding-left: 2.8rem !important;
  }
  .px-sm-12 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-sm-20 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-sm-200 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-sm-sm {
    padding-right: 0.875rem !important;
    padding-left: 0.875rem !important;
  }
  .px-sm-xs {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-sm-xxs {
    padding-right: 0.188rem !important;
    padding-left: 0.188rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 2.3rem !important;
    padding-bottom: 2.3rem !important;
  }
  .py-sm-6 {
    padding-top: 2.8rem !important;
    padding-bottom: 2.8rem !important;
  }
  .py-sm-12 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-sm-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-sm-200 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-sm-sm {
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
  }
  .py-sm-xs {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-sm-xxs {
    padding-top: 0.188rem !important;
    padding-bottom: 0.188rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 2.3rem !important;
  }
  .pt-sm-6 {
    padding-top: 2.8rem !important;
  }
  .pt-sm-12 {
    padding-top: 0.75rem !important;
  }
  .pt-sm-20 {
    padding-top: 1.25rem !important;
  }
  .pt-sm-200 {
    padding-top: 2rem !important;
  }
  .pt-sm-sm {
    padding-top: 0.875rem !important;
  }
  .pt-sm-xs {
    padding-top: 0.375rem !important;
  }
  .pt-sm-xxs {
    padding-top: 0.188rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 2.3rem !important;
  }
  .pe-sm-6 {
    padding-right: 2.8rem !important;
  }
  .pe-sm-12 {
    padding-right: 0.75rem !important;
  }
  .pe-sm-20 {
    padding-right: 1.25rem !important;
  }
  .pe-sm-200 {
    padding-right: 2rem !important;
  }
  .pe-sm-sm {
    padding-right: 0.875rem !important;
  }
  .pe-sm-xs {
    padding-right: 0.375rem !important;
  }
  .pe-sm-xxs {
    padding-right: 0.188rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 2.3rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 2.8rem !important;
  }
  .pb-sm-12 {
    padding-bottom: 0.75rem !important;
  }
  .pb-sm-20 {
    padding-bottom: 1.25rem !important;
  }
  .pb-sm-200 {
    padding-bottom: 2rem !important;
  }
  .pb-sm-sm {
    padding-bottom: 0.875rem !important;
  }
  .pb-sm-xs {
    padding-bottom: 0.375rem !important;
  }
  .pb-sm-xxs {
    padding-bottom: 0.188rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 2.3rem !important;
  }
  .ps-sm-6 {
    padding-left: 2.8rem !important;
  }
  .ps-sm-12 {
    padding-left: 0.75rem !important;
  }
  .ps-sm-20 {
    padding-left: 1.25rem !important;
  }
  .ps-sm-200 {
    padding-left: 2rem !important;
  }
  .ps-sm-sm {
    padding-left: 0.875rem !important;
  }
  .ps-sm-xs {
    padding-left: 0.375rem !important;
  }
  .ps-sm-xxs {
    padding-left: 0.188rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 2.3rem !important;
  }
  .gap-sm-6 {
    gap: 2.8rem !important;
  }
  .gap-sm-12 {
    gap: 0.75rem !important;
  }
  .gap-sm-20 {
    gap: 1.25rem !important;
  }
  .gap-sm-200 {
    gap: 2rem !important;
  }
  .gap-sm-sm {
    gap: 0.875rem !important;
  }
  .gap-sm-xs {
    gap: 0.375rem !important;
  }
  .gap-sm-xxs {
    gap: 0.188rem !important;
  }
  .fs-sm-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .fs-sm-2 {
    font-size: calc(1.325rem + 0.9vw) !important;
  }
  .fs-sm-3 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }
  .fs-sm-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-sm-5 {
    font-size: 1.25rem !important;
  }
  .fs-sm-6 {
    font-size: 1rem !important;
  }
  .fs-sm-12 {
    font-size: 0.75rem !important;
  }
  .fs-sm-14 {
    font-size: 0.875rem !important;
  }
  .fs-sm-16 {
    font-size: 1rem !important;
  }
  .fs-sm-18 {
    font-size: 1.125rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 900px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-auto {
    height: auto !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2.3rem !important;
  }
  .m-md-6 {
    margin: 2.8rem !important;
  }
  .m-md-12 {
    margin: 0.75rem !important;
  }
  .m-md-20 {
    margin: 1.25rem !important;
  }
  .m-md-200 {
    margin: 2rem !important;
  }
  .m-md-sm {
    margin: 0.875rem !important;
  }
  .m-md-xs {
    margin: 0.375rem !important;
  }
  .m-md-xxs {
    margin: 0.188rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important;
  }
  .mx-md-6 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important;
  }
  .mx-md-12 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-md-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-md-200 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-md-sm {
    margin-right: 0.875rem !important;
    margin-left: 0.875rem !important;
  }
  .mx-md-xs {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-md-xxs {
    margin-right: 0.188rem !important;
    margin-left: 0.188rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important;
  }
  .my-md-6 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important;
  }
  .my-md-12 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-md-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-md-200 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-md-sm {
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }
  .my-md-xs {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-md-xxs {
    margin-top: 0.188rem !important;
    margin-bottom: 0.188rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 2.3rem !important;
  }
  .mt-md-6 {
    margin-top: 2.8rem !important;
  }
  .mt-md-12 {
    margin-top: 0.75rem !important;
  }
  .mt-md-20 {
    margin-top: 1.25rem !important;
  }
  .mt-md-200 {
    margin-top: 2rem !important;
  }
  .mt-md-sm {
    margin-top: 0.875rem !important;
  }
  .mt-md-xs {
    margin-top: 0.375rem !important;
  }
  .mt-md-xxs {
    margin-top: 0.188rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 2.3rem !important;
  }
  .me-md-6 {
    margin-right: 2.8rem !important;
  }
  .me-md-12 {
    margin-right: 0.75rem !important;
  }
  .me-md-20 {
    margin-right: 1.25rem !important;
  }
  .me-md-200 {
    margin-right: 2rem !important;
  }
  .me-md-sm {
    margin-right: 0.875rem !important;
  }
  .me-md-xs {
    margin-right: 0.375rem !important;
  }
  .me-md-xxs {
    margin-right: 0.188rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 2.3rem !important;
  }
  .mb-md-6 {
    margin-bottom: 2.8rem !important;
  }
  .mb-md-12 {
    margin-bottom: 0.75rem !important;
  }
  .mb-md-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-md-200 {
    margin-bottom: 2rem !important;
  }
  .mb-md-sm {
    margin-bottom: 0.875rem !important;
  }
  .mb-md-xs {
    margin-bottom: 0.375rem !important;
  }
  .mb-md-xxs {
    margin-bottom: 0.188rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 2.3rem !important;
  }
  .ms-md-6 {
    margin-left: 2.8rem !important;
  }
  .ms-md-12 {
    margin-left: 0.75rem !important;
  }
  .ms-md-20 {
    margin-left: 1.25rem !important;
  }
  .ms-md-200 {
    margin-left: 2rem !important;
  }
  .ms-md-sm {
    margin-left: 0.875rem !important;
  }
  .ms-md-xs {
    margin-left: 0.375rem !important;
  }
  .ms-md-xxs {
    margin-left: 0.188rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2.3rem !important;
  }
  .m-md-6 {
    margin: 2.8rem !important;
  }
  .m-md-12 {
    margin: 0.75rem !important;
  }
  .m-md-20 {
    margin: 1.25rem !important;
  }
  .m-md-200 {
    margin: 2rem !important;
  }
  .m-md-sm {
    margin: 0.875rem !important;
  }
  .m-md-xs {
    margin: 0.375rem !important;
  }
  .m-md-xxs {
    margin: 0.188rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -0.625rem !important;
  }
  .m-md-n4 {
    margin: -0.875rem !important;
  }
  .m-md-n5 {
    margin: -1.5rem !important;
  }
  .m-md-n6 {
    margin: -2.3rem !important;
  }
  .m-md-n7 {
    margin: -2.8rem !important;
  }
  .m-md-n12 {
    margin: -0.75rem !important;
  }
  .m-md-n20 {
    margin: -1.25rem !important;
  }
  .m-md-n200 {
    margin: -2rem !important;
  }
  .m-md-nsm {
    margin: -0.875rem !important;
  }
  .m-md-nxs {
    margin: -0.375rem !important;
  }
  .m-md-nxxs {
    margin: -0.188rem !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important;
  }
  .mx-md-6 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important;
  }
  .mx-md-12 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-md-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-md-200 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-md-sm {
    margin-right: 0.875rem !important;
    margin-left: 0.875rem !important;
  }
  .mx-md-xs {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-md-xxs {
    margin-right: 0.188rem !important;
    margin-left: 0.188rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-md-n4 {
    margin-right: -0.875rem !important;
    margin-left: -0.875rem !important;
  }
  .mx-md-n5 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n6 {
    margin-right: -2.3rem !important;
    margin-left: -2.3rem !important;
  }
  .mx-md-n7 {
    margin-right: -2.8rem !important;
    margin-left: -2.8rem !important;
  }
  .mx-md-n12 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-md-n20 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-md-n200 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-md-nsm {
    margin-right: -0.875rem !important;
    margin-left: -0.875rem !important;
  }
  .mx-md-nxs {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-md-nxxs {
    margin-right: -0.188rem !important;
    margin-left: -0.188rem !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important;
  }
  .my-md-6 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important;
  }
  .my-md-12 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-md-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-md-200 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-md-sm {
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }
  .my-md-xs {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-md-xxs {
    margin-top: 0.188rem !important;
    margin-bottom: 0.188rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-md-n4 {
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }
  .my-md-n5 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n6 {
    margin-top: -2.3rem !important;
    margin-bottom: -2.3rem !important;
  }
  .my-md-n7 {
    margin-top: -2.8rem !important;
    margin-bottom: -2.8rem !important;
  }
  .my-md-n12 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-md-n20 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-md-n200 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-md-nsm {
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }
  .my-md-nxs {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-md-nxxs {
    margin-top: -0.188rem !important;
    margin-bottom: -0.188rem !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 2.3rem !important;
  }
  .mt-md-6 {
    margin-top: 2.8rem !important;
  }
  .mt-md-12 {
    margin-top: 0.75rem !important;
  }
  .mt-md-20 {
    margin-top: 1.25rem !important;
  }
  .mt-md-200 {
    margin-top: 2rem !important;
  }
  .mt-md-sm {
    margin-top: 0.875rem !important;
  }
  .mt-md-xs {
    margin-top: 0.375rem !important;
  }
  .mt-md-xxs {
    margin-top: 0.188rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -0.625rem !important;
  }
  .mt-md-n4 {
    margin-top: -0.875rem !important;
  }
  .mt-md-n5 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n6 {
    margin-top: -2.3rem !important;
  }
  .mt-md-n7 {
    margin-top: -2.8rem !important;
  }
  .mt-md-n12 {
    margin-top: -0.75rem !important;
  }
  .mt-md-n20 {
    margin-top: -1.25rem !important;
  }
  .mt-md-n200 {
    margin-top: -2rem !important;
  }
  .mt-md-nsm {
    margin-top: -0.875rem !important;
  }
  .mt-md-nxs {
    margin-top: -0.375rem !important;
  }
  .mt-md-nxxs {
    margin-top: -0.188rem !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 2.3rem !important;
  }
  .me-md-6 {
    margin-right: 2.8rem !important;
  }
  .me-md-12 {
    margin-right: 0.75rem !important;
  }
  .me-md-20 {
    margin-right: 1.25rem !important;
  }
  .me-md-200 {
    margin-right: 2rem !important;
  }
  .me-md-sm {
    margin-right: 0.875rem !important;
  }
  .me-md-xs {
    margin-right: 0.375rem !important;
  }
  .me-md-xxs {
    margin-right: 0.188rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -0.625rem !important;
  }
  .me-md-n4 {
    margin-right: -0.875rem !important;
  }
  .me-md-n5 {
    margin-right: -1.5rem !important;
  }
  .me-md-n6 {
    margin-right: -2.3rem !important;
  }
  .me-md-n7 {
    margin-right: -2.8rem !important;
  }
  .me-md-n12 {
    margin-right: -0.75rem !important;
  }
  .me-md-n20 {
    margin-right: -1.25rem !important;
  }
  .me-md-n200 {
    margin-right: -2rem !important;
  }
  .me-md-nsm {
    margin-right: -0.875rem !important;
  }
  .me-md-nxs {
    margin-right: -0.375rem !important;
  }
  .me-md-nxxs {
    margin-right: -0.188rem !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 2.3rem !important;
  }
  .mb-md-6 {
    margin-bottom: 2.8rem !important;
  }
  .mb-md-12 {
    margin-bottom: 0.75rem !important;
  }
  .mb-md-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-md-200 {
    margin-bottom: 2rem !important;
  }
  .mb-md-sm {
    margin-bottom: 0.875rem !important;
  }
  .mb-md-xs {
    margin-bottom: 0.375rem !important;
  }
  .mb-md-xxs {
    margin-bottom: 0.188rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -0.625rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -0.875rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -2.3rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -2.8rem !important;
  }
  .mb-md-n12 {
    margin-bottom: -0.75rem !important;
  }
  .mb-md-n20 {
    margin-bottom: -1.25rem !important;
  }
  .mb-md-n200 {
    margin-bottom: -2rem !important;
  }
  .mb-md-nsm {
    margin-bottom: -0.875rem !important;
  }
  .mb-md-nxs {
    margin-bottom: -0.375rem !important;
  }
  .mb-md-nxxs {
    margin-bottom: -0.188rem !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 2.3rem !important;
  }
  .ms-md-6 {
    margin-left: 2.8rem !important;
  }
  .ms-md-12 {
    margin-left: 0.75rem !important;
  }
  .ms-md-20 {
    margin-left: 1.25rem !important;
  }
  .ms-md-200 {
    margin-left: 2rem !important;
  }
  .ms-md-sm {
    margin-left: 0.875rem !important;
  }
  .ms-md-xs {
    margin-left: 0.375rem !important;
  }
  .ms-md-xxs {
    margin-left: 0.188rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -0.625rem !important;
  }
  .ms-md-n4 {
    margin-left: -0.875rem !important;
  }
  .ms-md-n5 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n6 {
    margin-left: -2.3rem !important;
  }
  .ms-md-n7 {
    margin-left: -2.8rem !important;
  }
  .ms-md-n12 {
    margin-left: -0.75rem !important;
  }
  .ms-md-n20 {
    margin-left: -1.25rem !important;
  }
  .ms-md-n200 {
    margin-left: -2rem !important;
  }
  .ms-md-nsm {
    margin-left: -0.875rem !important;
  }
  .ms-md-nxs {
    margin-left: -0.375rem !important;
  }
  .ms-md-nxxs {
    margin-left: -0.188rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2.3rem !important;
  }
  .p-md-6 {
    padding: 2.8rem !important;
  }
  .p-md-12 {
    padding: 0.75rem !important;
  }
  .p-md-20 {
    padding: 1.25rem !important;
  }
  .p-md-200 {
    padding: 2rem !important;
  }
  .p-md-sm {
    padding: 0.875rem !important;
  }
  .p-md-xs {
    padding: 0.375rem !important;
  }
  .p-md-xxs {
    padding: 0.188rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 2.3rem !important;
    padding-left: 2.3rem !important;
  }
  .px-md-6 {
    padding-right: 2.8rem !important;
    padding-left: 2.8rem !important;
  }
  .px-md-12 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-md-20 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-md-200 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-md-sm {
    padding-right: 0.875rem !important;
    padding-left: 0.875rem !important;
  }
  .px-md-xs {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-md-xxs {
    padding-right: 0.188rem !important;
    padding-left: 0.188rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 2.3rem !important;
    padding-bottom: 2.3rem !important;
  }
  .py-md-6 {
    padding-top: 2.8rem !important;
    padding-bottom: 2.8rem !important;
  }
  .py-md-12 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-md-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-md-200 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-md-sm {
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
  }
  .py-md-xs {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-md-xxs {
    padding-top: 0.188rem !important;
    padding-bottom: 0.188rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 2.3rem !important;
  }
  .pt-md-6 {
    padding-top: 2.8rem !important;
  }
  .pt-md-12 {
    padding-top: 0.75rem !important;
  }
  .pt-md-20 {
    padding-top: 1.25rem !important;
  }
  .pt-md-200 {
    padding-top: 2rem !important;
  }
  .pt-md-sm {
    padding-top: 0.875rem !important;
  }
  .pt-md-xs {
    padding-top: 0.375rem !important;
  }
  .pt-md-xxs {
    padding-top: 0.188rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 2.3rem !important;
  }
  .pe-md-6 {
    padding-right: 2.8rem !important;
  }
  .pe-md-12 {
    padding-right: 0.75rem !important;
  }
  .pe-md-20 {
    padding-right: 1.25rem !important;
  }
  .pe-md-200 {
    padding-right: 2rem !important;
  }
  .pe-md-sm {
    padding-right: 0.875rem !important;
  }
  .pe-md-xs {
    padding-right: 0.375rem !important;
  }
  .pe-md-xxs {
    padding-right: 0.188rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 2.3rem !important;
  }
  .pb-md-6 {
    padding-bottom: 2.8rem !important;
  }
  .pb-md-12 {
    padding-bottom: 0.75rem !important;
  }
  .pb-md-20 {
    padding-bottom: 1.25rem !important;
  }
  .pb-md-200 {
    padding-bottom: 2rem !important;
  }
  .pb-md-sm {
    padding-bottom: 0.875rem !important;
  }
  .pb-md-xs {
    padding-bottom: 0.375rem !important;
  }
  .pb-md-xxs {
    padding-bottom: 0.188rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 2.3rem !important;
  }
  .ps-md-6 {
    padding-left: 2.8rem !important;
  }
  .ps-md-12 {
    padding-left: 0.75rem !important;
  }
  .ps-md-20 {
    padding-left: 1.25rem !important;
  }
  .ps-md-200 {
    padding-left: 2rem !important;
  }
  .ps-md-sm {
    padding-left: 0.875rem !important;
  }
  .ps-md-xs {
    padding-left: 0.375rem !important;
  }
  .ps-md-xxs {
    padding-left: 0.188rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 2.3rem !important;
  }
  .gap-md-6 {
    gap: 2.8rem !important;
  }
  .gap-md-12 {
    gap: 0.75rem !important;
  }
  .gap-md-20 {
    gap: 1.25rem !important;
  }
  .gap-md-200 {
    gap: 2rem !important;
  }
  .gap-md-sm {
    gap: 0.875rem !important;
  }
  .gap-md-xs {
    gap: 0.375rem !important;
  }
  .gap-md-xxs {
    gap: 0.188rem !important;
  }
  .fs-md-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .fs-md-2 {
    font-size: calc(1.325rem + 0.9vw) !important;
  }
  .fs-md-3 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }
  .fs-md-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-md-5 {
    font-size: 1.25rem !important;
  }
  .fs-md-6 {
    font-size: 1rem !important;
  }
  .fs-md-12 {
    font-size: 0.75rem !important;
  }
  .fs-md-14 {
    font-size: 0.875rem !important;
  }
  .fs-md-16 {
    font-size: 1rem !important;
  }
  .fs-md-18 {
    font-size: 1.125rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1024px) {
  .float-mdl-start {
    float: left !important;
  }
  .float-mdl-end {
    float: right !important;
  }
  .float-mdl-none {
    float: none !important;
  }
  .d-mdl-inline {
    display: inline !important;
  }
  .d-mdl-inline-block {
    display: inline-block !important;
  }
  .d-mdl-block {
    display: block !important;
  }
  .d-mdl-grid {
    display: grid !important;
  }
  .d-mdl-table {
    display: table !important;
  }
  .d-mdl-table-row {
    display: table-row !important;
  }
  .d-mdl-table-cell {
    display: table-cell !important;
  }
  .d-mdl-flex {
    display: flex !important;
  }
  .d-mdl-inline-flex {
    display: inline-flex !important;
  }
  .d-mdl-none {
    display: none !important;
  }
  .w-mdl-25 {
    width: 25% !important;
  }
  .w-mdl-50 {
    width: 50% !important;
  }
  .w-mdl-75 {
    width: 75% !important;
  }
  .w-mdl-100 {
    width: 100% !important;
  }
  .w-mdl-auto {
    width: auto !important;
  }
  .h-mdl-25 {
    height: 25% !important;
  }
  .h-mdl-50 {
    height: 50% !important;
  }
  .h-mdl-75 {
    height: 75% !important;
  }
  .h-mdl-100 {
    height: 100% !important;
  }
  .h-mdl-auto {
    height: auto !important;
  }
  .flex-mdl-fill {
    flex: 1 1 auto !important;
  }
  .flex-mdl-row {
    flex-direction: row !important;
  }
  .flex-mdl-column {
    flex-direction: column !important;
  }
  .flex-mdl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-mdl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-mdl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-mdl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-mdl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-mdl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-mdl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-mdl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-mdl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-mdl-start {
    justify-content: flex-start !important;
  }
  .justify-content-mdl-end {
    justify-content: flex-end !important;
  }
  .justify-content-mdl-center {
    justify-content: center !important;
  }
  .justify-content-mdl-between {
    justify-content: space-between !important;
  }
  .justify-content-mdl-around {
    justify-content: space-around !important;
  }
  .justify-content-mdl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-mdl-start {
    align-items: flex-start !important;
  }
  .align-items-mdl-end {
    align-items: flex-end !important;
  }
  .align-items-mdl-center {
    align-items: center !important;
  }
  .align-items-mdl-baseline {
    align-items: baseline !important;
  }
  .align-items-mdl-stretch {
    align-items: stretch !important;
  }
  .align-content-mdl-start {
    align-content: flex-start !important;
  }
  .align-content-mdl-end {
    align-content: flex-end !important;
  }
  .align-content-mdl-center {
    align-content: center !important;
  }
  .align-content-mdl-between {
    align-content: space-between !important;
  }
  .align-content-mdl-around {
    align-content: space-around !important;
  }
  .align-content-mdl-stretch {
    align-content: stretch !important;
  }
  .align-self-mdl-auto {
    align-self: auto !important;
  }
  .align-self-mdl-start {
    align-self: flex-start !important;
  }
  .align-self-mdl-end {
    align-self: flex-end !important;
  }
  .align-self-mdl-center {
    align-self: center !important;
  }
  .align-self-mdl-baseline {
    align-self: baseline !important;
  }
  .align-self-mdl-stretch {
    align-self: stretch !important;
  }
  .order-mdl-first {
    order: -1 !important;
  }
  .order-mdl-0 {
    order: 0 !important;
  }
  .order-mdl-1 {
    order: 1 !important;
  }
  .order-mdl-2 {
    order: 2 !important;
  }
  .order-mdl-3 {
    order: 3 !important;
  }
  .order-mdl-4 {
    order: 4 !important;
  }
  .order-mdl-5 {
    order: 5 !important;
  }
  .order-mdl-last {
    order: 6 !important;
  }
  .m-mdl-0 {
    margin: 0 !important;
  }
  .m-mdl-1 {
    margin: 0.25rem !important;
  }
  .m-mdl-2 {
    margin: 0.5rem !important;
  }
  .m-mdl-3 {
    margin: 1rem !important;
  }
  .m-mdl-4 {
    margin: 1.5rem !important;
  }
  .m-mdl-5 {
    margin: 2.3rem !important;
  }
  .m-mdl-6 {
    margin: 2.8rem !important;
  }
  .m-mdl-12 {
    margin: 0.75rem !important;
  }
  .m-mdl-20 {
    margin: 1.25rem !important;
  }
  .m-mdl-200 {
    margin: 2rem !important;
  }
  .m-mdl-sm {
    margin: 0.875rem !important;
  }
  .m-mdl-xs {
    margin: 0.375rem !important;
  }
  .m-mdl-xxs {
    margin: 0.188rem !important;
  }
  .m-mdl-auto {
    margin: auto !important;
  }
  .mx-mdl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-mdl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-mdl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-mdl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-mdl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-mdl-5 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important;
  }
  .mx-mdl-6 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important;
  }
  .mx-mdl-12 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-mdl-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-mdl-200 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-mdl-sm {
    margin-right: 0.875rem !important;
    margin-left: 0.875rem !important;
  }
  .mx-mdl-xs {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-mdl-xxs {
    margin-right: 0.188rem !important;
    margin-left: 0.188rem !important;
  }
  .mx-mdl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-mdl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-mdl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-mdl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-mdl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-mdl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-mdl-5 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important;
  }
  .my-mdl-6 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important;
  }
  .my-mdl-12 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-mdl-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-mdl-200 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-mdl-sm {
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }
  .my-mdl-xs {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-mdl-xxs {
    margin-top: 0.188rem !important;
    margin-bottom: 0.188rem !important;
  }
  .my-mdl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-mdl-0 {
    margin-top: 0 !important;
  }
  .mt-mdl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-mdl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-mdl-3 {
    margin-top: 1rem !important;
  }
  .mt-mdl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-mdl-5 {
    margin-top: 2.3rem !important;
  }
  .mt-mdl-6 {
    margin-top: 2.8rem !important;
  }
  .mt-mdl-12 {
    margin-top: 0.75rem !important;
  }
  .mt-mdl-20 {
    margin-top: 1.25rem !important;
  }
  .mt-mdl-200 {
    margin-top: 2rem !important;
  }
  .mt-mdl-sm {
    margin-top: 0.875rem !important;
  }
  .mt-mdl-xs {
    margin-top: 0.375rem !important;
  }
  .mt-mdl-xxs {
    margin-top: 0.188rem !important;
  }
  .mt-mdl-auto {
    margin-top: auto !important;
  }
  .me-mdl-0 {
    margin-right: 0 !important;
  }
  .me-mdl-1 {
    margin-right: 0.25rem !important;
  }
  .me-mdl-2 {
    margin-right: 0.5rem !important;
  }
  .me-mdl-3 {
    margin-right: 1rem !important;
  }
  .me-mdl-4 {
    margin-right: 1.5rem !important;
  }
  .me-mdl-5 {
    margin-right: 2.3rem !important;
  }
  .me-mdl-6 {
    margin-right: 2.8rem !important;
  }
  .me-mdl-12 {
    margin-right: 0.75rem !important;
  }
  .me-mdl-20 {
    margin-right: 1.25rem !important;
  }
  .me-mdl-200 {
    margin-right: 2rem !important;
  }
  .me-mdl-sm {
    margin-right: 0.875rem !important;
  }
  .me-mdl-xs {
    margin-right: 0.375rem !important;
  }
  .me-mdl-xxs {
    margin-right: 0.188rem !important;
  }
  .me-mdl-auto {
    margin-right: auto !important;
  }
  .mb-mdl-0 {
    margin-bottom: 0 !important;
  }
  .mb-mdl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-mdl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-mdl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-mdl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-mdl-5 {
    margin-bottom: 2.3rem !important;
  }
  .mb-mdl-6 {
    margin-bottom: 2.8rem !important;
  }
  .mb-mdl-12 {
    margin-bottom: 0.75rem !important;
  }
  .mb-mdl-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-mdl-200 {
    margin-bottom: 2rem !important;
  }
  .mb-mdl-sm {
    margin-bottom: 0.875rem !important;
  }
  .mb-mdl-xs {
    margin-bottom: 0.375rem !important;
  }
  .mb-mdl-xxs {
    margin-bottom: 0.188rem !important;
  }
  .mb-mdl-auto {
    margin-bottom: auto !important;
  }
  .ms-mdl-0 {
    margin-left: 0 !important;
  }
  .ms-mdl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-mdl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-mdl-3 {
    margin-left: 1rem !important;
  }
  .ms-mdl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-mdl-5 {
    margin-left: 2.3rem !important;
  }
  .ms-mdl-6 {
    margin-left: 2.8rem !important;
  }
  .ms-mdl-12 {
    margin-left: 0.75rem !important;
  }
  .ms-mdl-20 {
    margin-left: 1.25rem !important;
  }
  .ms-mdl-200 {
    margin-left: 2rem !important;
  }
  .ms-mdl-sm {
    margin-left: 0.875rem !important;
  }
  .ms-mdl-xs {
    margin-left: 0.375rem !important;
  }
  .ms-mdl-xxs {
    margin-left: 0.188rem !important;
  }
  .ms-mdl-auto {
    margin-left: auto !important;
  }
  .m-mdl-0 {
    margin: 0 !important;
  }
  .m-mdl-1 {
    margin: 0.25rem !important;
  }
  .m-mdl-2 {
    margin: 0.5rem !important;
  }
  .m-mdl-3 {
    margin: 1rem !important;
  }
  .m-mdl-4 {
    margin: 1.5rem !important;
  }
  .m-mdl-5 {
    margin: 2.3rem !important;
  }
  .m-mdl-6 {
    margin: 2.8rem !important;
  }
  .m-mdl-12 {
    margin: 0.75rem !important;
  }
  .m-mdl-20 {
    margin: 1.25rem !important;
  }
  .m-mdl-200 {
    margin: 2rem !important;
  }
  .m-mdl-sm {
    margin: 0.875rem !important;
  }
  .m-mdl-xs {
    margin: 0.375rem !important;
  }
  .m-mdl-xxs {
    margin: 0.188rem !important;
  }
  .m-mdl-n1 {
    margin: -0.25rem !important;
  }
  .m-mdl-n2 {
    margin: -0.5rem !important;
  }
  .m-mdl-n3 {
    margin: -0.625rem !important;
  }
  .m-mdl-n4 {
    margin: -0.875rem !important;
  }
  .m-mdl-n5 {
    margin: -1.5rem !important;
  }
  .m-mdl-n6 {
    margin: -2.3rem !important;
  }
  .m-mdl-n7 {
    margin: -2.8rem !important;
  }
  .m-mdl-n12 {
    margin: -0.75rem !important;
  }
  .m-mdl-n20 {
    margin: -1.25rem !important;
  }
  .m-mdl-n200 {
    margin: -2rem !important;
  }
  .m-mdl-nsm {
    margin: -0.875rem !important;
  }
  .m-mdl-nxs {
    margin: -0.375rem !important;
  }
  .m-mdl-nxxs {
    margin: -0.188rem !important;
  }
  .mx-mdl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-mdl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-mdl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-mdl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-mdl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-mdl-5 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important;
  }
  .mx-mdl-6 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important;
  }
  .mx-mdl-12 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-mdl-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-mdl-200 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-mdl-sm {
    margin-right: 0.875rem !important;
    margin-left: 0.875rem !important;
  }
  .mx-mdl-xs {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-mdl-xxs {
    margin-right: 0.188rem !important;
    margin-left: 0.188rem !important;
  }
  .mx-mdl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-mdl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-mdl-n3 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-mdl-n4 {
    margin-right: -0.875rem !important;
    margin-left: -0.875rem !important;
  }
  .mx-mdl-n5 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-mdl-n6 {
    margin-right: -2.3rem !important;
    margin-left: -2.3rem !important;
  }
  .mx-mdl-n7 {
    margin-right: -2.8rem !important;
    margin-left: -2.8rem !important;
  }
  .mx-mdl-n12 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-mdl-n20 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-mdl-n200 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-mdl-nsm {
    margin-right: -0.875rem !important;
    margin-left: -0.875rem !important;
  }
  .mx-mdl-nxs {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-mdl-nxxs {
    margin-right: -0.188rem !important;
    margin-left: -0.188rem !important;
  }
  .my-mdl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-mdl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-mdl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-mdl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-mdl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-mdl-5 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important;
  }
  .my-mdl-6 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important;
  }
  .my-mdl-12 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-mdl-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-mdl-200 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-mdl-sm {
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }
  .my-mdl-xs {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-mdl-xxs {
    margin-top: 0.188rem !important;
    margin-bottom: 0.188rem !important;
  }
  .my-mdl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-mdl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-mdl-n3 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-mdl-n4 {
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }
  .my-mdl-n5 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-mdl-n6 {
    margin-top: -2.3rem !important;
    margin-bottom: -2.3rem !important;
  }
  .my-mdl-n7 {
    margin-top: -2.8rem !important;
    margin-bottom: -2.8rem !important;
  }
  .my-mdl-n12 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-mdl-n20 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-mdl-n200 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-mdl-nsm {
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }
  .my-mdl-nxs {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-mdl-nxxs {
    margin-top: -0.188rem !important;
    margin-bottom: -0.188rem !important;
  }
  .mt-mdl-0 {
    margin-top: 0 !important;
  }
  .mt-mdl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-mdl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-mdl-3 {
    margin-top: 1rem !important;
  }
  .mt-mdl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-mdl-5 {
    margin-top: 2.3rem !important;
  }
  .mt-mdl-6 {
    margin-top: 2.8rem !important;
  }
  .mt-mdl-12 {
    margin-top: 0.75rem !important;
  }
  .mt-mdl-20 {
    margin-top: 1.25rem !important;
  }
  .mt-mdl-200 {
    margin-top: 2rem !important;
  }
  .mt-mdl-sm {
    margin-top: 0.875rem !important;
  }
  .mt-mdl-xs {
    margin-top: 0.375rem !important;
  }
  .mt-mdl-xxs {
    margin-top: 0.188rem !important;
  }
  .mt-mdl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-mdl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-mdl-n3 {
    margin-top: -0.625rem !important;
  }
  .mt-mdl-n4 {
    margin-top: -0.875rem !important;
  }
  .mt-mdl-n5 {
    margin-top: -1.5rem !important;
  }
  .mt-mdl-n6 {
    margin-top: -2.3rem !important;
  }
  .mt-mdl-n7 {
    margin-top: -2.8rem !important;
  }
  .mt-mdl-n12 {
    margin-top: -0.75rem !important;
  }
  .mt-mdl-n20 {
    margin-top: -1.25rem !important;
  }
  .mt-mdl-n200 {
    margin-top: -2rem !important;
  }
  .mt-mdl-nsm {
    margin-top: -0.875rem !important;
  }
  .mt-mdl-nxs {
    margin-top: -0.375rem !important;
  }
  .mt-mdl-nxxs {
    margin-top: -0.188rem !important;
  }
  .me-mdl-0 {
    margin-right: 0 !important;
  }
  .me-mdl-1 {
    margin-right: 0.25rem !important;
  }
  .me-mdl-2 {
    margin-right: 0.5rem !important;
  }
  .me-mdl-3 {
    margin-right: 1rem !important;
  }
  .me-mdl-4 {
    margin-right: 1.5rem !important;
  }
  .me-mdl-5 {
    margin-right: 2.3rem !important;
  }
  .me-mdl-6 {
    margin-right: 2.8rem !important;
  }
  .me-mdl-12 {
    margin-right: 0.75rem !important;
  }
  .me-mdl-20 {
    margin-right: 1.25rem !important;
  }
  .me-mdl-200 {
    margin-right: 2rem !important;
  }
  .me-mdl-sm {
    margin-right: 0.875rem !important;
  }
  .me-mdl-xs {
    margin-right: 0.375rem !important;
  }
  .me-mdl-xxs {
    margin-right: 0.188rem !important;
  }
  .me-mdl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-mdl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-mdl-n3 {
    margin-right: -0.625rem !important;
  }
  .me-mdl-n4 {
    margin-right: -0.875rem !important;
  }
  .me-mdl-n5 {
    margin-right: -1.5rem !important;
  }
  .me-mdl-n6 {
    margin-right: -2.3rem !important;
  }
  .me-mdl-n7 {
    margin-right: -2.8rem !important;
  }
  .me-mdl-n12 {
    margin-right: -0.75rem !important;
  }
  .me-mdl-n20 {
    margin-right: -1.25rem !important;
  }
  .me-mdl-n200 {
    margin-right: -2rem !important;
  }
  .me-mdl-nsm {
    margin-right: -0.875rem !important;
  }
  .me-mdl-nxs {
    margin-right: -0.375rem !important;
  }
  .me-mdl-nxxs {
    margin-right: -0.188rem !important;
  }
  .mb-mdl-0 {
    margin-bottom: 0 !important;
  }
  .mb-mdl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-mdl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-mdl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-mdl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-mdl-5 {
    margin-bottom: 2.3rem !important;
  }
  .mb-mdl-6 {
    margin-bottom: 2.8rem !important;
  }
  .mb-mdl-12 {
    margin-bottom: 0.75rem !important;
  }
  .mb-mdl-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-mdl-200 {
    margin-bottom: 2rem !important;
  }
  .mb-mdl-sm {
    margin-bottom: 0.875rem !important;
  }
  .mb-mdl-xs {
    margin-bottom: 0.375rem !important;
  }
  .mb-mdl-xxs {
    margin-bottom: 0.188rem !important;
  }
  .mb-mdl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-mdl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-mdl-n3 {
    margin-bottom: -0.625rem !important;
  }
  .mb-mdl-n4 {
    margin-bottom: -0.875rem !important;
  }
  .mb-mdl-n5 {
    margin-bottom: -1.5rem !important;
  }
  .mb-mdl-n6 {
    margin-bottom: -2.3rem !important;
  }
  .mb-mdl-n7 {
    margin-bottom: -2.8rem !important;
  }
  .mb-mdl-n12 {
    margin-bottom: -0.75rem !important;
  }
  .mb-mdl-n20 {
    margin-bottom: -1.25rem !important;
  }
  .mb-mdl-n200 {
    margin-bottom: -2rem !important;
  }
  .mb-mdl-nsm {
    margin-bottom: -0.875rem !important;
  }
  .mb-mdl-nxs {
    margin-bottom: -0.375rem !important;
  }
  .mb-mdl-nxxs {
    margin-bottom: -0.188rem !important;
  }
  .ms-mdl-0 {
    margin-left: 0 !important;
  }
  .ms-mdl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-mdl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-mdl-3 {
    margin-left: 1rem !important;
  }
  .ms-mdl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-mdl-5 {
    margin-left: 2.3rem !important;
  }
  .ms-mdl-6 {
    margin-left: 2.8rem !important;
  }
  .ms-mdl-12 {
    margin-left: 0.75rem !important;
  }
  .ms-mdl-20 {
    margin-left: 1.25rem !important;
  }
  .ms-mdl-200 {
    margin-left: 2rem !important;
  }
  .ms-mdl-sm {
    margin-left: 0.875rem !important;
  }
  .ms-mdl-xs {
    margin-left: 0.375rem !important;
  }
  .ms-mdl-xxs {
    margin-left: 0.188rem !important;
  }
  .ms-mdl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-mdl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-mdl-n3 {
    margin-left: -0.625rem !important;
  }
  .ms-mdl-n4 {
    margin-left: -0.875rem !important;
  }
  .ms-mdl-n5 {
    margin-left: -1.5rem !important;
  }
  .ms-mdl-n6 {
    margin-left: -2.3rem !important;
  }
  .ms-mdl-n7 {
    margin-left: -2.8rem !important;
  }
  .ms-mdl-n12 {
    margin-left: -0.75rem !important;
  }
  .ms-mdl-n20 {
    margin-left: -1.25rem !important;
  }
  .ms-mdl-n200 {
    margin-left: -2rem !important;
  }
  .ms-mdl-nsm {
    margin-left: -0.875rem !important;
  }
  .ms-mdl-nxs {
    margin-left: -0.375rem !important;
  }
  .ms-mdl-nxxs {
    margin-left: -0.188rem !important;
  }
  .p-mdl-0 {
    padding: 0 !important;
  }
  .p-mdl-1 {
    padding: 0.25rem !important;
  }
  .p-mdl-2 {
    padding: 0.5rem !important;
  }
  .p-mdl-3 {
    padding: 1rem !important;
  }
  .p-mdl-4 {
    padding: 1.5rem !important;
  }
  .p-mdl-5 {
    padding: 2.3rem !important;
  }
  .p-mdl-6 {
    padding: 2.8rem !important;
  }
  .p-mdl-12 {
    padding: 0.75rem !important;
  }
  .p-mdl-20 {
    padding: 1.25rem !important;
  }
  .p-mdl-200 {
    padding: 2rem !important;
  }
  .p-mdl-sm {
    padding: 0.875rem !important;
  }
  .p-mdl-xs {
    padding: 0.375rem !important;
  }
  .p-mdl-xxs {
    padding: 0.188rem !important;
  }
  .px-mdl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-mdl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-mdl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-mdl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-mdl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-mdl-5 {
    padding-right: 2.3rem !important;
    padding-left: 2.3rem !important;
  }
  .px-mdl-6 {
    padding-right: 2.8rem !important;
    padding-left: 2.8rem !important;
  }
  .px-mdl-12 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-mdl-20 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-mdl-200 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-mdl-sm {
    padding-right: 0.875rem !important;
    padding-left: 0.875rem !important;
  }
  .px-mdl-xs {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-mdl-xxs {
    padding-right: 0.188rem !important;
    padding-left: 0.188rem !important;
  }
  .py-mdl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-mdl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-mdl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-mdl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-mdl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-mdl-5 {
    padding-top: 2.3rem !important;
    padding-bottom: 2.3rem !important;
  }
  .py-mdl-6 {
    padding-top: 2.8rem !important;
    padding-bottom: 2.8rem !important;
  }
  .py-mdl-12 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-mdl-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-mdl-200 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-mdl-sm {
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
  }
  .py-mdl-xs {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-mdl-xxs {
    padding-top: 0.188rem !important;
    padding-bottom: 0.188rem !important;
  }
  .pt-mdl-0 {
    padding-top: 0 !important;
  }
  .pt-mdl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-mdl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-mdl-3 {
    padding-top: 1rem !important;
  }
  .pt-mdl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-mdl-5 {
    padding-top: 2.3rem !important;
  }
  .pt-mdl-6 {
    padding-top: 2.8rem !important;
  }
  .pt-mdl-12 {
    padding-top: 0.75rem !important;
  }
  .pt-mdl-20 {
    padding-top: 1.25rem !important;
  }
  .pt-mdl-200 {
    padding-top: 2rem !important;
  }
  .pt-mdl-sm {
    padding-top: 0.875rem !important;
  }
  .pt-mdl-xs {
    padding-top: 0.375rem !important;
  }
  .pt-mdl-xxs {
    padding-top: 0.188rem !important;
  }
  .pe-mdl-0 {
    padding-right: 0 !important;
  }
  .pe-mdl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-mdl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-mdl-3 {
    padding-right: 1rem !important;
  }
  .pe-mdl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-mdl-5 {
    padding-right: 2.3rem !important;
  }
  .pe-mdl-6 {
    padding-right: 2.8rem !important;
  }
  .pe-mdl-12 {
    padding-right: 0.75rem !important;
  }
  .pe-mdl-20 {
    padding-right: 1.25rem !important;
  }
  .pe-mdl-200 {
    padding-right: 2rem !important;
  }
  .pe-mdl-sm {
    padding-right: 0.875rem !important;
  }
  .pe-mdl-xs {
    padding-right: 0.375rem !important;
  }
  .pe-mdl-xxs {
    padding-right: 0.188rem !important;
  }
  .pb-mdl-0 {
    padding-bottom: 0 !important;
  }
  .pb-mdl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-mdl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-mdl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-mdl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-mdl-5 {
    padding-bottom: 2.3rem !important;
  }
  .pb-mdl-6 {
    padding-bottom: 2.8rem !important;
  }
  .pb-mdl-12 {
    padding-bottom: 0.75rem !important;
  }
  .pb-mdl-20 {
    padding-bottom: 1.25rem !important;
  }
  .pb-mdl-200 {
    padding-bottom: 2rem !important;
  }
  .pb-mdl-sm {
    padding-bottom: 0.875rem !important;
  }
  .pb-mdl-xs {
    padding-bottom: 0.375rem !important;
  }
  .pb-mdl-xxs {
    padding-bottom: 0.188rem !important;
  }
  .ps-mdl-0 {
    padding-left: 0 !important;
  }
  .ps-mdl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-mdl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-mdl-3 {
    padding-left: 1rem !important;
  }
  .ps-mdl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-mdl-5 {
    padding-left: 2.3rem !important;
  }
  .ps-mdl-6 {
    padding-left: 2.8rem !important;
  }
  .ps-mdl-12 {
    padding-left: 0.75rem !important;
  }
  .ps-mdl-20 {
    padding-left: 1.25rem !important;
  }
  .ps-mdl-200 {
    padding-left: 2rem !important;
  }
  .ps-mdl-sm {
    padding-left: 0.875rem !important;
  }
  .ps-mdl-xs {
    padding-left: 0.375rem !important;
  }
  .ps-mdl-xxs {
    padding-left: 0.188rem !important;
  }
  .gap-mdl-0 {
    gap: 0 !important;
  }
  .gap-mdl-1 {
    gap: 0.25rem !important;
  }
  .gap-mdl-2 {
    gap: 0.5rem !important;
  }
  .gap-mdl-3 {
    gap: 1rem !important;
  }
  .gap-mdl-4 {
    gap: 1.5rem !important;
  }
  .gap-mdl-5 {
    gap: 2.3rem !important;
  }
  .gap-mdl-6 {
    gap: 2.8rem !important;
  }
  .gap-mdl-12 {
    gap: 0.75rem !important;
  }
  .gap-mdl-20 {
    gap: 1.25rem !important;
  }
  .gap-mdl-200 {
    gap: 2rem !important;
  }
  .gap-mdl-sm {
    gap: 0.875rem !important;
  }
  .gap-mdl-xs {
    gap: 0.375rem !important;
  }
  .gap-mdl-xxs {
    gap: 0.188rem !important;
  }
  .fs-mdl-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .fs-mdl-2 {
    font-size: calc(1.325rem + 0.9vw) !important;
  }
  .fs-mdl-3 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }
  .fs-mdl-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-mdl-5 {
    font-size: 1.25rem !important;
  }
  .fs-mdl-6 {
    font-size: 1rem !important;
  }
  .fs-mdl-12 {
    font-size: 0.75rem !important;
  }
  .fs-mdl-14 {
    font-size: 0.875rem !important;
  }
  .fs-mdl-16 {
    font-size: 1rem !important;
  }
  .fs-mdl-18 {
    font-size: 1.125rem !important;
  }
  .text-mdl-start {
    text-align: left !important;
  }
  .text-mdl-end {
    text-align: right !important;
  }
  .text-mdl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2.3rem !important;
  }
  .m-lg-6 {
    margin: 2.8rem !important;
  }
  .m-lg-12 {
    margin: 0.75rem !important;
  }
  .m-lg-20 {
    margin: 1.25rem !important;
  }
  .m-lg-200 {
    margin: 2rem !important;
  }
  .m-lg-sm {
    margin: 0.875rem !important;
  }
  .m-lg-xs {
    margin: 0.375rem !important;
  }
  .m-lg-xxs {
    margin: 0.188rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important;
  }
  .mx-lg-6 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important;
  }
  .mx-lg-12 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-lg-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-lg-200 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-lg-sm {
    margin-right: 0.875rem !important;
    margin-left: 0.875rem !important;
  }
  .mx-lg-xs {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-lg-xxs {
    margin-right: 0.188rem !important;
    margin-left: 0.188rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important;
  }
  .my-lg-6 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important;
  }
  .my-lg-12 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-lg-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-lg-200 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-lg-sm {
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }
  .my-lg-xs {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-lg-xxs {
    margin-top: 0.188rem !important;
    margin-bottom: 0.188rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 2.3rem !important;
  }
  .mt-lg-6 {
    margin-top: 2.8rem !important;
  }
  .mt-lg-12 {
    margin-top: 0.75rem !important;
  }
  .mt-lg-20 {
    margin-top: 1.25rem !important;
  }
  .mt-lg-200 {
    margin-top: 2rem !important;
  }
  .mt-lg-sm {
    margin-top: 0.875rem !important;
  }
  .mt-lg-xs {
    margin-top: 0.375rem !important;
  }
  .mt-lg-xxs {
    margin-top: 0.188rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 2.3rem !important;
  }
  .me-lg-6 {
    margin-right: 2.8rem !important;
  }
  .me-lg-12 {
    margin-right: 0.75rem !important;
  }
  .me-lg-20 {
    margin-right: 1.25rem !important;
  }
  .me-lg-200 {
    margin-right: 2rem !important;
  }
  .me-lg-sm {
    margin-right: 0.875rem !important;
  }
  .me-lg-xs {
    margin-right: 0.375rem !important;
  }
  .me-lg-xxs {
    margin-right: 0.188rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 2.3rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 2.8rem !important;
  }
  .mb-lg-12 {
    margin-bottom: 0.75rem !important;
  }
  .mb-lg-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-lg-200 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-sm {
    margin-bottom: 0.875rem !important;
  }
  .mb-lg-xs {
    margin-bottom: 0.375rem !important;
  }
  .mb-lg-xxs {
    margin-bottom: 0.188rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 2.3rem !important;
  }
  .ms-lg-6 {
    margin-left: 2.8rem !important;
  }
  .ms-lg-12 {
    margin-left: 0.75rem !important;
  }
  .ms-lg-20 {
    margin-left: 1.25rem !important;
  }
  .ms-lg-200 {
    margin-left: 2rem !important;
  }
  .ms-lg-sm {
    margin-left: 0.875rem !important;
  }
  .ms-lg-xs {
    margin-left: 0.375rem !important;
  }
  .ms-lg-xxs {
    margin-left: 0.188rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2.3rem !important;
  }
  .m-lg-6 {
    margin: 2.8rem !important;
  }
  .m-lg-12 {
    margin: 0.75rem !important;
  }
  .m-lg-20 {
    margin: 1.25rem !important;
  }
  .m-lg-200 {
    margin: 2rem !important;
  }
  .m-lg-sm {
    margin: 0.875rem !important;
  }
  .m-lg-xs {
    margin: 0.375rem !important;
  }
  .m-lg-xxs {
    margin: 0.188rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -0.625rem !important;
  }
  .m-lg-n4 {
    margin: -0.875rem !important;
  }
  .m-lg-n5 {
    margin: -1.5rem !important;
  }
  .m-lg-n6 {
    margin: -2.3rem !important;
  }
  .m-lg-n7 {
    margin: -2.8rem !important;
  }
  .m-lg-n12 {
    margin: -0.75rem !important;
  }
  .m-lg-n20 {
    margin: -1.25rem !important;
  }
  .m-lg-n200 {
    margin: -2rem !important;
  }
  .m-lg-nsm {
    margin: -0.875rem !important;
  }
  .m-lg-nxs {
    margin: -0.375rem !important;
  }
  .m-lg-nxxs {
    margin: -0.188rem !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important;
  }
  .mx-lg-6 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important;
  }
  .mx-lg-12 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-lg-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-lg-200 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-lg-sm {
    margin-right: 0.875rem !important;
    margin-left: 0.875rem !important;
  }
  .mx-lg-xs {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-lg-xxs {
    margin-right: 0.188rem !important;
    margin-left: 0.188rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-lg-n4 {
    margin-right: -0.875rem !important;
    margin-left: -0.875rem !important;
  }
  .mx-lg-n5 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n6 {
    margin-right: -2.3rem !important;
    margin-left: -2.3rem !important;
  }
  .mx-lg-n7 {
    margin-right: -2.8rem !important;
    margin-left: -2.8rem !important;
  }
  .mx-lg-n12 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-lg-n20 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-lg-n200 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-lg-nsm {
    margin-right: -0.875rem !important;
    margin-left: -0.875rem !important;
  }
  .mx-lg-nxs {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-lg-nxxs {
    margin-right: -0.188rem !important;
    margin-left: -0.188rem !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important;
  }
  .my-lg-6 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important;
  }
  .my-lg-12 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-lg-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-lg-200 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-lg-sm {
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }
  .my-lg-xs {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-lg-xxs {
    margin-top: 0.188rem !important;
    margin-bottom: 0.188rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-lg-n4 {
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }
  .my-lg-n5 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n6 {
    margin-top: -2.3rem !important;
    margin-bottom: -2.3rem !important;
  }
  .my-lg-n7 {
    margin-top: -2.8rem !important;
    margin-bottom: -2.8rem !important;
  }
  .my-lg-n12 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-lg-n20 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-lg-n200 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-lg-nsm {
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }
  .my-lg-nxs {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-lg-nxxs {
    margin-top: -0.188rem !important;
    margin-bottom: -0.188rem !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 2.3rem !important;
  }
  .mt-lg-6 {
    margin-top: 2.8rem !important;
  }
  .mt-lg-12 {
    margin-top: 0.75rem !important;
  }
  .mt-lg-20 {
    margin-top: 1.25rem !important;
  }
  .mt-lg-200 {
    margin-top: 2rem !important;
  }
  .mt-lg-sm {
    margin-top: 0.875rem !important;
  }
  .mt-lg-xs {
    margin-top: 0.375rem !important;
  }
  .mt-lg-xxs {
    margin-top: 0.188rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -0.625rem !important;
  }
  .mt-lg-n4 {
    margin-top: -0.875rem !important;
  }
  .mt-lg-n5 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n6 {
    margin-top: -2.3rem !important;
  }
  .mt-lg-n7 {
    margin-top: -2.8rem !important;
  }
  .mt-lg-n12 {
    margin-top: -0.75rem !important;
  }
  .mt-lg-n20 {
    margin-top: -1.25rem !important;
  }
  .mt-lg-n200 {
    margin-top: -2rem !important;
  }
  .mt-lg-nsm {
    margin-top: -0.875rem !important;
  }
  .mt-lg-nxs {
    margin-top: -0.375rem !important;
  }
  .mt-lg-nxxs {
    margin-top: -0.188rem !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 2.3rem !important;
  }
  .me-lg-6 {
    margin-right: 2.8rem !important;
  }
  .me-lg-12 {
    margin-right: 0.75rem !important;
  }
  .me-lg-20 {
    margin-right: 1.25rem !important;
  }
  .me-lg-200 {
    margin-right: 2rem !important;
  }
  .me-lg-sm {
    margin-right: 0.875rem !important;
  }
  .me-lg-xs {
    margin-right: 0.375rem !important;
  }
  .me-lg-xxs {
    margin-right: 0.188rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -0.625rem !important;
  }
  .me-lg-n4 {
    margin-right: -0.875rem !important;
  }
  .me-lg-n5 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n6 {
    margin-right: -2.3rem !important;
  }
  .me-lg-n7 {
    margin-right: -2.8rem !important;
  }
  .me-lg-n12 {
    margin-right: -0.75rem !important;
  }
  .me-lg-n20 {
    margin-right: -1.25rem !important;
  }
  .me-lg-n200 {
    margin-right: -2rem !important;
  }
  .me-lg-nsm {
    margin-right: -0.875rem !important;
  }
  .me-lg-nxs {
    margin-right: -0.375rem !important;
  }
  .me-lg-nxxs {
    margin-right: -0.188rem !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 2.3rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 2.8rem !important;
  }
  .mb-lg-12 {
    margin-bottom: 0.75rem !important;
  }
  .mb-lg-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-lg-200 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-sm {
    margin-bottom: 0.875rem !important;
  }
  .mb-lg-xs {
    margin-bottom: 0.375rem !important;
  }
  .mb-lg-xxs {
    margin-bottom: 0.188rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -0.625rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -0.875rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -2.3rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -2.8rem !important;
  }
  .mb-lg-n12 {
    margin-bottom: -0.75rem !important;
  }
  .mb-lg-n20 {
    margin-bottom: -1.25rem !important;
  }
  .mb-lg-n200 {
    margin-bottom: -2rem !important;
  }
  .mb-lg-nsm {
    margin-bottom: -0.875rem !important;
  }
  .mb-lg-nxs {
    margin-bottom: -0.375rem !important;
  }
  .mb-lg-nxxs {
    margin-bottom: -0.188rem !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 2.3rem !important;
  }
  .ms-lg-6 {
    margin-left: 2.8rem !important;
  }
  .ms-lg-12 {
    margin-left: 0.75rem !important;
  }
  .ms-lg-20 {
    margin-left: 1.25rem !important;
  }
  .ms-lg-200 {
    margin-left: 2rem !important;
  }
  .ms-lg-sm {
    margin-left: 0.875rem !important;
  }
  .ms-lg-xs {
    margin-left: 0.375rem !important;
  }
  .ms-lg-xxs {
    margin-left: 0.188rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -0.625rem !important;
  }
  .ms-lg-n4 {
    margin-left: -0.875rem !important;
  }
  .ms-lg-n5 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n6 {
    margin-left: -2.3rem !important;
  }
  .ms-lg-n7 {
    margin-left: -2.8rem !important;
  }
  .ms-lg-n12 {
    margin-left: -0.75rem !important;
  }
  .ms-lg-n20 {
    margin-left: -1.25rem !important;
  }
  .ms-lg-n200 {
    margin-left: -2rem !important;
  }
  .ms-lg-nsm {
    margin-left: -0.875rem !important;
  }
  .ms-lg-nxs {
    margin-left: -0.375rem !important;
  }
  .ms-lg-nxxs {
    margin-left: -0.188rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2.3rem !important;
  }
  .p-lg-6 {
    padding: 2.8rem !important;
  }
  .p-lg-12 {
    padding: 0.75rem !important;
  }
  .p-lg-20 {
    padding: 1.25rem !important;
  }
  .p-lg-200 {
    padding: 2rem !important;
  }
  .p-lg-sm {
    padding: 0.875rem !important;
  }
  .p-lg-xs {
    padding: 0.375rem !important;
  }
  .p-lg-xxs {
    padding: 0.188rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 2.3rem !important;
    padding-left: 2.3rem !important;
  }
  .px-lg-6 {
    padding-right: 2.8rem !important;
    padding-left: 2.8rem !important;
  }
  .px-lg-12 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-lg-20 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-lg-200 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-lg-sm {
    padding-right: 0.875rem !important;
    padding-left: 0.875rem !important;
  }
  .px-lg-xs {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-lg-xxs {
    padding-right: 0.188rem !important;
    padding-left: 0.188rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 2.3rem !important;
    padding-bottom: 2.3rem !important;
  }
  .py-lg-6 {
    padding-top: 2.8rem !important;
    padding-bottom: 2.8rem !important;
  }
  .py-lg-12 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-lg-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-lg-200 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-lg-sm {
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
  }
  .py-lg-xs {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-lg-xxs {
    padding-top: 0.188rem !important;
    padding-bottom: 0.188rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 2.3rem !important;
  }
  .pt-lg-6 {
    padding-top: 2.8rem !important;
  }
  .pt-lg-12 {
    padding-top: 0.75rem !important;
  }
  .pt-lg-20 {
    padding-top: 1.25rem !important;
  }
  .pt-lg-200 {
    padding-top: 2rem !important;
  }
  .pt-lg-sm {
    padding-top: 0.875rem !important;
  }
  .pt-lg-xs {
    padding-top: 0.375rem !important;
  }
  .pt-lg-xxs {
    padding-top: 0.188rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 2.3rem !important;
  }
  .pe-lg-6 {
    padding-right: 2.8rem !important;
  }
  .pe-lg-12 {
    padding-right: 0.75rem !important;
  }
  .pe-lg-20 {
    padding-right: 1.25rem !important;
  }
  .pe-lg-200 {
    padding-right: 2rem !important;
  }
  .pe-lg-sm {
    padding-right: 0.875rem !important;
  }
  .pe-lg-xs {
    padding-right: 0.375rem !important;
  }
  .pe-lg-xxs {
    padding-right: 0.188rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 2.3rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 2.8rem !important;
  }
  .pb-lg-12 {
    padding-bottom: 0.75rem !important;
  }
  .pb-lg-20 {
    padding-bottom: 1.25rem !important;
  }
  .pb-lg-200 {
    padding-bottom: 2rem !important;
  }
  .pb-lg-sm {
    padding-bottom: 0.875rem !important;
  }
  .pb-lg-xs {
    padding-bottom: 0.375rem !important;
  }
  .pb-lg-xxs {
    padding-bottom: 0.188rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 2.3rem !important;
  }
  .ps-lg-6 {
    padding-left: 2.8rem !important;
  }
  .ps-lg-12 {
    padding-left: 0.75rem !important;
  }
  .ps-lg-20 {
    padding-left: 1.25rem !important;
  }
  .ps-lg-200 {
    padding-left: 2rem !important;
  }
  .ps-lg-sm {
    padding-left: 0.875rem !important;
  }
  .ps-lg-xs {
    padding-left: 0.375rem !important;
  }
  .ps-lg-xxs {
    padding-left: 0.188rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 2.3rem !important;
  }
  .gap-lg-6 {
    gap: 2.8rem !important;
  }
  .gap-lg-12 {
    gap: 0.75rem !important;
  }
  .gap-lg-20 {
    gap: 1.25rem !important;
  }
  .gap-lg-200 {
    gap: 2rem !important;
  }
  .gap-lg-sm {
    gap: 0.875rem !important;
  }
  .gap-lg-xs {
    gap: 0.375rem !important;
  }
  .gap-lg-xxs {
    gap: 0.188rem !important;
  }
  .fs-lg-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .fs-lg-2 {
    font-size: calc(1.325rem + 0.9vw) !important;
  }
  .fs-lg-3 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }
  .fs-lg-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-lg-5 {
    font-size: 1.25rem !important;
  }
  .fs-lg-6 {
    font-size: 1rem !important;
  }
  .fs-lg-12 {
    font-size: 0.75rem !important;
  }
  .fs-lg-14 {
    font-size: 0.875rem !important;
  }
  .fs-lg-16 {
    font-size: 1rem !important;
  }
  .fs-lg-18 {
    font-size: 1.125rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1536px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2.3rem !important;
  }
  .m-xl-6 {
    margin: 2.8rem !important;
  }
  .m-xl-12 {
    margin: 0.75rem !important;
  }
  .m-xl-20 {
    margin: 1.25rem !important;
  }
  .m-xl-200 {
    margin: 2rem !important;
  }
  .m-xl-sm {
    margin: 0.875rem !important;
  }
  .m-xl-xs {
    margin: 0.375rem !important;
  }
  .m-xl-xxs {
    margin: 0.188rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important;
  }
  .mx-xl-6 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important;
  }
  .mx-xl-12 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xl-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xl-200 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xl-sm {
    margin-right: 0.875rem !important;
    margin-left: 0.875rem !important;
  }
  .mx-xl-xs {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-xl-xxs {
    margin-right: 0.188rem !important;
    margin-left: 0.188rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important;
  }
  .my-xl-6 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important;
  }
  .my-xl-12 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xl-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xl-200 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xl-sm {
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }
  .my-xl-xs {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-xl-xxs {
    margin-top: 0.188rem !important;
    margin-bottom: 0.188rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 2.3rem !important;
  }
  .mt-xl-6 {
    margin-top: 2.8rem !important;
  }
  .mt-xl-12 {
    margin-top: 0.75rem !important;
  }
  .mt-xl-20 {
    margin-top: 1.25rem !important;
  }
  .mt-xl-200 {
    margin-top: 2rem !important;
  }
  .mt-xl-sm {
    margin-top: 0.875rem !important;
  }
  .mt-xl-xs {
    margin-top: 0.375rem !important;
  }
  .mt-xl-xxs {
    margin-top: 0.188rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 2.3rem !important;
  }
  .me-xl-6 {
    margin-right: 2.8rem !important;
  }
  .me-xl-12 {
    margin-right: 0.75rem !important;
  }
  .me-xl-20 {
    margin-right: 1.25rem !important;
  }
  .me-xl-200 {
    margin-right: 2rem !important;
  }
  .me-xl-sm {
    margin-right: 0.875rem !important;
  }
  .me-xl-xs {
    margin-right: 0.375rem !important;
  }
  .me-xl-xxs {
    margin-right: 0.188rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 2.3rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 2.8rem !important;
  }
  .mb-xl-12 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xl-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xl-200 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-sm {
    margin-bottom: 0.875rem !important;
  }
  .mb-xl-xs {
    margin-bottom: 0.375rem !important;
  }
  .mb-xl-xxs {
    margin-bottom: 0.188rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 2.3rem !important;
  }
  .ms-xl-6 {
    margin-left: 2.8rem !important;
  }
  .ms-xl-12 {
    margin-left: 0.75rem !important;
  }
  .ms-xl-20 {
    margin-left: 1.25rem !important;
  }
  .ms-xl-200 {
    margin-left: 2rem !important;
  }
  .ms-xl-sm {
    margin-left: 0.875rem !important;
  }
  .ms-xl-xs {
    margin-left: 0.375rem !important;
  }
  .ms-xl-xxs {
    margin-left: 0.188rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2.3rem !important;
  }
  .m-xl-6 {
    margin: 2.8rem !important;
  }
  .m-xl-12 {
    margin: 0.75rem !important;
  }
  .m-xl-20 {
    margin: 1.25rem !important;
  }
  .m-xl-200 {
    margin: 2rem !important;
  }
  .m-xl-sm {
    margin: 0.875rem !important;
  }
  .m-xl-xs {
    margin: 0.375rem !important;
  }
  .m-xl-xxs {
    margin: 0.188rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -0.625rem !important;
  }
  .m-xl-n4 {
    margin: -0.875rem !important;
  }
  .m-xl-n5 {
    margin: -1.5rem !important;
  }
  .m-xl-n6 {
    margin: -2.3rem !important;
  }
  .m-xl-n7 {
    margin: -2.8rem !important;
  }
  .m-xl-n12 {
    margin: -0.75rem !important;
  }
  .m-xl-n20 {
    margin: -1.25rem !important;
  }
  .m-xl-n200 {
    margin: -2rem !important;
  }
  .m-xl-nsm {
    margin: -0.875rem !important;
  }
  .m-xl-nxs {
    margin: -0.375rem !important;
  }
  .m-xl-nxxs {
    margin: -0.188rem !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 2.3rem !important;
    margin-left: 2.3rem !important;
  }
  .mx-xl-6 {
    margin-right: 2.8rem !important;
    margin-left: 2.8rem !important;
  }
  .mx-xl-12 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xl-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xl-200 {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }
  .mx-xl-sm {
    margin-right: 0.875rem !important;
    margin-left: 0.875rem !important;
  }
  .mx-xl-xs {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-xl-xxs {
    margin-right: 0.188rem !important;
    margin-left: 0.188rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-xl-n4 {
    margin-right: -0.875rem !important;
    margin-left: -0.875rem !important;
  }
  .mx-xl-n5 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n6 {
    margin-right: -2.3rem !important;
    margin-left: -2.3rem !important;
  }
  .mx-xl-n7 {
    margin-right: -2.8rem !important;
    margin-left: -2.8rem !important;
  }
  .mx-xl-n12 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-xl-n20 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-xl-n200 {
    margin-right: -2rem !important;
    margin-left: -2rem !important;
  }
  .mx-xl-nsm {
    margin-right: -0.875rem !important;
    margin-left: -0.875rem !important;
  }
  .mx-xl-nxs {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-xl-nxxs {
    margin-right: -0.188rem !important;
    margin-left: -0.188rem !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 2.3rem !important;
    margin-bottom: 2.3rem !important;
  }
  .my-xl-6 {
    margin-top: 2.8rem !important;
    margin-bottom: 2.8rem !important;
  }
  .my-xl-12 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xl-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xl-200 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xl-sm {
    margin-top: 0.875rem !important;
    margin-bottom: 0.875rem !important;
  }
  .my-xl-xs {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-xl-xxs {
    margin-top: 0.188rem !important;
    margin-bottom: 0.188rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-xl-n4 {
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }
  .my-xl-n5 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n6 {
    margin-top: -2.3rem !important;
    margin-bottom: -2.3rem !important;
  }
  .my-xl-n7 {
    margin-top: -2.8rem !important;
    margin-bottom: -2.8rem !important;
  }
  .my-xl-n12 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-xl-n20 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-xl-n200 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }
  .my-xl-nsm {
    margin-top: -0.875rem !important;
    margin-bottom: -0.875rem !important;
  }
  .my-xl-nxs {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-xl-nxxs {
    margin-top: -0.188rem !important;
    margin-bottom: -0.188rem !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 2.3rem !important;
  }
  .mt-xl-6 {
    margin-top: 2.8rem !important;
  }
  .mt-xl-12 {
    margin-top: 0.75rem !important;
  }
  .mt-xl-20 {
    margin-top: 1.25rem !important;
  }
  .mt-xl-200 {
    margin-top: 2rem !important;
  }
  .mt-xl-sm {
    margin-top: 0.875rem !important;
  }
  .mt-xl-xs {
    margin-top: 0.375rem !important;
  }
  .mt-xl-xxs {
    margin-top: 0.188rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -0.625rem !important;
  }
  .mt-xl-n4 {
    margin-top: -0.875rem !important;
  }
  .mt-xl-n5 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n6 {
    margin-top: -2.3rem !important;
  }
  .mt-xl-n7 {
    margin-top: -2.8rem !important;
  }
  .mt-xl-n12 {
    margin-top: -0.75rem !important;
  }
  .mt-xl-n20 {
    margin-top: -1.25rem !important;
  }
  .mt-xl-n200 {
    margin-top: -2rem !important;
  }
  .mt-xl-nsm {
    margin-top: -0.875rem !important;
  }
  .mt-xl-nxs {
    margin-top: -0.375rem !important;
  }
  .mt-xl-nxxs {
    margin-top: -0.188rem !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 2.3rem !important;
  }
  .me-xl-6 {
    margin-right: 2.8rem !important;
  }
  .me-xl-12 {
    margin-right: 0.75rem !important;
  }
  .me-xl-20 {
    margin-right: 1.25rem !important;
  }
  .me-xl-200 {
    margin-right: 2rem !important;
  }
  .me-xl-sm {
    margin-right: 0.875rem !important;
  }
  .me-xl-xs {
    margin-right: 0.375rem !important;
  }
  .me-xl-xxs {
    margin-right: 0.188rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -0.625rem !important;
  }
  .me-xl-n4 {
    margin-right: -0.875rem !important;
  }
  .me-xl-n5 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n6 {
    margin-right: -2.3rem !important;
  }
  .me-xl-n7 {
    margin-right: -2.8rem !important;
  }
  .me-xl-n12 {
    margin-right: -0.75rem !important;
  }
  .me-xl-n20 {
    margin-right: -1.25rem !important;
  }
  .me-xl-n200 {
    margin-right: -2rem !important;
  }
  .me-xl-nsm {
    margin-right: -0.875rem !important;
  }
  .me-xl-nxs {
    margin-right: -0.375rem !important;
  }
  .me-xl-nxxs {
    margin-right: -0.188rem !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 2.3rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 2.8rem !important;
  }
  .mb-xl-12 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xl-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xl-200 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-sm {
    margin-bottom: 0.875rem !important;
  }
  .mb-xl-xs {
    margin-bottom: 0.375rem !important;
  }
  .mb-xl-xxs {
    margin-bottom: 0.188rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -0.625rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -0.875rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -2.3rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -2.8rem !important;
  }
  .mb-xl-n12 {
    margin-bottom: -0.75rem !important;
  }
  .mb-xl-n20 {
    margin-bottom: -1.25rem !important;
  }
  .mb-xl-n200 {
    margin-bottom: -2rem !important;
  }
  .mb-xl-nsm {
    margin-bottom: -0.875rem !important;
  }
  .mb-xl-nxs {
    margin-bottom: -0.375rem !important;
  }
  .mb-xl-nxxs {
    margin-bottom: -0.188rem !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 2.3rem !important;
  }
  .ms-xl-6 {
    margin-left: 2.8rem !important;
  }
  .ms-xl-12 {
    margin-left: 0.75rem !important;
  }
  .ms-xl-20 {
    margin-left: 1.25rem !important;
  }
  .ms-xl-200 {
    margin-left: 2rem !important;
  }
  .ms-xl-sm {
    margin-left: 0.875rem !important;
  }
  .ms-xl-xs {
    margin-left: 0.375rem !important;
  }
  .ms-xl-xxs {
    margin-left: 0.188rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -0.625rem !important;
  }
  .ms-xl-n4 {
    margin-left: -0.875rem !important;
  }
  .ms-xl-n5 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n6 {
    margin-left: -2.3rem !important;
  }
  .ms-xl-n7 {
    margin-left: -2.8rem !important;
  }
  .ms-xl-n12 {
    margin-left: -0.75rem !important;
  }
  .ms-xl-n20 {
    margin-left: -1.25rem !important;
  }
  .ms-xl-n200 {
    margin-left: -2rem !important;
  }
  .ms-xl-nsm {
    margin-left: -0.875rem !important;
  }
  .ms-xl-nxs {
    margin-left: -0.375rem !important;
  }
  .ms-xl-nxxs {
    margin-left: -0.188rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2.3rem !important;
  }
  .p-xl-6 {
    padding: 2.8rem !important;
  }
  .p-xl-12 {
    padding: 0.75rem !important;
  }
  .p-xl-20 {
    padding: 1.25rem !important;
  }
  .p-xl-200 {
    padding: 2rem !important;
  }
  .p-xl-sm {
    padding: 0.875rem !important;
  }
  .p-xl-xs {
    padding: 0.375rem !important;
  }
  .p-xl-xxs {
    padding: 0.188rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 2.3rem !important;
    padding-left: 2.3rem !important;
  }
  .px-xl-6 {
    padding-right: 2.8rem !important;
    padding-left: 2.8rem !important;
  }
  .px-xl-12 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-xl-20 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xl-200 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .px-xl-sm {
    padding-right: 0.875rem !important;
    padding-left: 0.875rem !important;
  }
  .px-xl-xs {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-xl-xxs {
    padding-right: 0.188rem !important;
    padding-left: 0.188rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 2.3rem !important;
    padding-bottom: 2.3rem !important;
  }
  .py-xl-6 {
    padding-top: 2.8rem !important;
    padding-bottom: 2.8rem !important;
  }
  .py-xl-12 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-xl-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xl-200 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xl-sm {
    padding-top: 0.875rem !important;
    padding-bottom: 0.875rem !important;
  }
  .py-xl-xs {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-xl-xxs {
    padding-top: 0.188rem !important;
    padding-bottom: 0.188rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 2.3rem !important;
  }
  .pt-xl-6 {
    padding-top: 2.8rem !important;
  }
  .pt-xl-12 {
    padding-top: 0.75rem !important;
  }
  .pt-xl-20 {
    padding-top: 1.25rem !important;
  }
  .pt-xl-200 {
    padding-top: 2rem !important;
  }
  .pt-xl-sm {
    padding-top: 0.875rem !important;
  }
  .pt-xl-xs {
    padding-top: 0.375rem !important;
  }
  .pt-xl-xxs {
    padding-top: 0.188rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 2.3rem !important;
  }
  .pe-xl-6 {
    padding-right: 2.8rem !important;
  }
  .pe-xl-12 {
    padding-right: 0.75rem !important;
  }
  .pe-xl-20 {
    padding-right: 1.25rem !important;
  }
  .pe-xl-200 {
    padding-right: 2rem !important;
  }
  .pe-xl-sm {
    padding-right: 0.875rem !important;
  }
  .pe-xl-xs {
    padding-right: 0.375rem !important;
  }
  .pe-xl-xxs {
    padding-right: 0.188rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 2.3rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 2.8rem !important;
  }
  .pb-xl-12 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xl-20 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xl-200 {
    padding-bottom: 2rem !important;
  }
  .pb-xl-sm {
    padding-bottom: 0.875rem !important;
  }
  .pb-xl-xs {
    padding-bottom: 0.375rem !important;
  }
  .pb-xl-xxs {
    padding-bottom: 0.188rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 2.3rem !important;
  }
  .ps-xl-6 {
    padding-left: 2.8rem !important;
  }
  .ps-xl-12 {
    padding-left: 0.75rem !important;
  }
  .ps-xl-20 {
    padding-left: 1.25rem !important;
  }
  .ps-xl-200 {
    padding-left: 2rem !important;
  }
  .ps-xl-sm {
    padding-left: 0.875rem !important;
  }
  .ps-xl-xs {
    padding-left: 0.375rem !important;
  }
  .ps-xl-xxs {
    padding-left: 0.188rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 2.3rem !important;
  }
  .gap-xl-6 {
    gap: 2.8rem !important;
  }
  .gap-xl-12 {
    gap: 0.75rem !important;
  }
  .gap-xl-20 {
    gap: 1.25rem !important;
  }
  .gap-xl-200 {
    gap: 2rem !important;
  }
  .gap-xl-sm {
    gap: 0.875rem !important;
  }
  .gap-xl-xs {
    gap: 0.375rem !important;
  }
  .gap-xl-xxs {
    gap: 0.188rem !important;
  }
  .fs-xl-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .fs-xl-2 {
    font-size: calc(1.325rem + 0.9vw) !important;
  }
  .fs-xl-3 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }
  .fs-xl-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-xl-5 {
    font-size: 1.25rem !important;
  }
  .fs-xl-6 {
    font-size: 1rem !important;
  }
  .fs-xl-12 {
    font-size: 0.75rem !important;
  }
  .fs-xl-14 {
    font-size: 0.875rem !important;
  }
  .fs-xl-16 {
    font-size: 1rem !important;
  }
  .fs-xl-18 {
    font-size: 1.125rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
  .fs-sm-1 {
    font-size: 2.5rem !important;
  }
  .fs-sm-2 {
    font-size: 2rem !important;
  }
  .fs-sm-3 {
    font-size: 1.75rem !important;
  }
  .fs-sm-4 {
    font-size: 1.5rem !important;
  }
  .fs-md-1 {
    font-size: 2.5rem !important;
  }
  .fs-md-2 {
    font-size: 2rem !important;
  }
  .fs-md-3 {
    font-size: 1.75rem !important;
  }
  .fs-md-4 {
    font-size: 1.5rem !important;
  }
  .fs-mdl-1 {
    font-size: 2.5rem !important;
  }
  .fs-mdl-2 {
    font-size: 2rem !important;
  }
  .fs-mdl-3 {
    font-size: 1.75rem !important;
  }
  .fs-mdl-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/* .bs-datepicker */
.bs-datepicker {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  background: #FFFFFF;
  box-shadow: 0 0 10px 0 #aaa;
  position: relative;
  z-index: 1;
  border-radius: 12px;
  margin-top: 8px;
  /* button */
  /* .bs-datepicker-head */
  /* .bs-datepicker-body */
  /* .current-timedate */
  /* .bs-datepicker-multiple */
  /* .bs-datepicker-btns */
  /*.bs-datepicker-custom-range */
  /* .bs-datepicker-predefined-btns */
  /* .bs-datepicker-buttons */
}
.bs-datepicker:after {
  clear: both;
  content: "";
  display: block;
}
.bs-datepicker bs-day-picker {
  float: left;
}
.bs-datepicker button:hover,
.bs-datepicker button:focus,
.bs-datepicker button:active,
.bs-datepicker input:hover,
.bs-datepicker input:focus,
.bs-datepicker input:active, .bs-datepicker-btns button:hover, .bs-datepicker-btns button:focus, .bs-datepicker-btns button:active, .bs-datepicker-predefined-btns button:active, .bs-datepicker-predefined-btns button:focus {
  outline: none;
}
.bs-datepicker-head {
  min-width: 270px;
  height: 50px;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  text-align: justify;
  /* .bs-datepicker-head button */
}
.bs-datepicker-head:after {
  content: "";
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.bs-datepicker-head button {
  display: inline-block;
  vertical-align: top;
  padding: 0;
  height: 30px;
  line-height: 30px;
  border: 0;
  background: transparent;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  transition: 0.3s;
}
.bs-datepicker-head button[disabled], .bs-datepicker-head button[disabled]:hover, .bs-datepicker-head button[disabled]:active {
  background: rgba(233, 236, 239, 0.3);
  color: #f8f9fa;
  cursor: not-allowed;
}
.bs-datepicker-head button.previous span {
  transform: translate(-1px, -1px);
}
.bs-datepicker-head button.next span {
  transform: translate(1px, -1px);
}
.bs-datepicker-head button.next[disabled] {
  visibility: hidden !important;
}
.bs-datepicker-head button.next, .bs-datepicker-head button.previous {
  font-weight: 300;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.bs-datepicker-head button.next span, .bs-datepicker-head button.previous span {
  font-size: 28px;
  line-height: 1;
  display: inline-block;
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.bs-datepicker-head button.current {
  border-radius: 15px;
  max-width: 155px;
  padding: 0 13px;
  font-weight: 700;
  font-size: 14px;
}
.bs-datepicker-head button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.bs-datepicker-head button:active {
  background-color: rgba(0, 0, 0, 0.2);
}
.bs-datepicker-body {
  padding: 10px;
  border-radius: 0 0 3px 3px;
  min-height: 232px;
  min-width: 278px;
  /* .bs-datepicker-body table */
}
.bs-datepicker-body .days.weeks {
  position: relative;
  z-index: 1;
}
.bs-datepicker-body table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  /* .bs-datepicker-body table.days */
  /* .bs-datepicker-body table.weeks */
}
.bs-datepicker-body table th {
  font-size: 13px;
  color: #f8f9fa;
  font-weight: 400;
  text-align: center;
}
@media (max-width: 599.98px) {
  .bs-datepicker-body table th {
    font-size: 16px;
  }
}
.bs-datepicker-body table td {
  color: #ffffff;
  text-align: center;
  position: relative;
  padding: 0;
  /* todo: check first :not(.disabled) usage */
}
.bs-datepicker-body table td span {
  display: block;
  margin: 0 auto;
  font-size: 13px;
  border-radius: 8px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
@media (max-width: 599.98px) {
  .bs-datepicker-body table td span {
    font-size: 16px;
  }
}
.bs-datepicker-body table td:not(.disabled):not(.week) span:not(.disabled):not(.is-other-month) {
  cursor: pointer;
}
.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
  background-color: #E8EFFF;
  transition: 0s;
}
.bs-datepicker-body table td.is-active-other-month:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-active-other-month:not(.disabled):not(.selected) {
  background-color: #E8EFFF;
  transition: 0s;
  cursor: pointer;
}
.bs-datepicker-body table td span.disabled, .bs-datepicker-body table td.disabled span {
  color: #f8f9fa;
}
.bs-datepicker-body table td span.selected, .bs-datepicker-body table td.selected span {
  color: #fff;
}
.bs-datepicker-body table td span.is-other-month, .bs-datepicker-body table td.is-other-month span {
  color: rgba(0, 0, 0, 0.25);
}
.bs-datepicker-body table td.active {
  position: relative;
}
.bs-datepicker-body table td.active.select-start:before {
  left: 35%;
}
.bs-datepicker-body table td.active.select-end:before {
  left: -85%;
}
.bs-datepicker-body table td span.active.select-start:after,
.bs-datepicker-body table td span.active.select-end:after, .bs-datepicker-body table td.active.select-start span:after, .bs-datepicker-body table td.active.select-end span:after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  top: 0;
  border-radius: 50%;
}
.bs-datepicker-body table td:before,
.bs-datepicker-body table td span:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: -3px;
  right: -2px;
  box-sizing: content-box;
  background: transparent;
}
.bs-datepicker-body table td.active.select-start + td.active:before {
  left: -20%;
}
.bs-datepicker-body table td:last-child.active:before {
  border-radius: 0 3px 3px 0;
  width: 125%;
  left: -25%;
}
.bs-datepicker-body table td span[class*=select-], .bs-datepicker-body table td[class*=select-] span {
  color: #fff;
}
.bs-datepicker-body table.days td.active:not(.select-start):before, .bs-datepicker-body table.days td.in-range:not(.select-start):before,
.bs-datepicker-body table.days span.active:not(.select-start):before,
.bs-datepicker-body table.days span.in-range:not(.select-start):before {
  background: #E8EFFF;
}
.bs-datepicker-body table.days span {
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.bs-datepicker-body table.days span.select-start {
  z-index: 2;
  border-radius: 8px 0 0 8px;
}
.bs-datepicker-body table.days span.select-end {
  border-radius: 0 8px 8px 0;
}
.bs-datepicker-body table.days span.is-highlighted.in-range:before {
  right: 3px;
  left: 0;
}
.bs-datepicker-body table.days span.in-range.select-end:before {
  right: 4px;
  left: 0;
}
@media (max-width: 599.98px) {
  .bs-datepicker-body table.days span {
    width: auto;
    height: auto;
    line-height: 1;
    padding: 13px 12px;
  }
}
.bs-datepicker-body table.days td.select-start + td.select-end:before, .bs-datepicker-body table.days td.select-start + td.is-highlighted:before, .bs-datepicker-body table.days td.active + td.is-highlighted:before, .bs-datepicker-body table.days td.active + td.select-end:before, .bs-datepicker-body table.days td.in-range + td.is-highlighted:before, .bs-datepicker-body table.days td.in-range + td.select-end:before {
  background: #E8EFFF;
  width: 100%;
}
.bs-datepicker-body table.weeks tr td:nth-child(2).active:before {
  border-radius: 3px 0 0 3px;
  left: 0;
  width: 100%;
}
.bs-datepicker-body table:not(.weeks) tr td:first-child:before {
  border-radius: 3px 0 0 3px;
}
.bs-datepicker-body table.years td span {
  width: 46px;
  height: 46px;
  line-height: 45px;
  margin: 0 auto;
}
.bs-datepicker-body table.years tr:not(:last-child) td span {
  margin-bottom: 8px;
}
.bs-datepicker-body table.months td {
  height: 52px;
}
.bs-datepicker-body table.months td span {
  padding: 6px;
  border-radius: 15px;
}
.bs-datepicker .current-timedate {
  color: #ffffff;
  font-size: 15px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  border-radius: 20px;
  border: 1px solid #E8EFFF;
  margin-bottom: 10px;
  cursor: pointer;
  text-transform: uppercase;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.bs-datepicker .current-timedate span:not(:empty):before {
  content: "";
  width: 15px;
  height: 16px;
  display: inline-block;
  margin-right: 4px;
  vertical-align: text-bottom;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAABMklEQVQoU9VTwW3CQBCcOUgBtEBKSAukAnBKME+wFCAlYIhk8sQlxFABtJAScAsuAPBEewYcxCP8ouxrPDsza61uiVN1o6RNHD4htSCmq49RfO71BvMJqBBkITRf1kmUW49nQRC9h1I5AZlBClaL8aP1fKgOOxCx8aSLs+Q19eZuNO8QmPqJRtDFguy7OAcDbJPs+/BKVPDIPrvD2ZJgWAmVe7O0rI0Vqs1seyWUXpuJoppYCa5L+U++NpNPkr5OE2oMdARsb3gykJT5ydZcL8Z9Ww60nxg2LhjON9li9OwXZzo+xLbp3nC2s9CL2RrueGyVrgwNm8HpsCzZ9EEW6kqXlo1GQe03FzP/7W8Hl0dBtu7Bf7zt6mIwvX1RvzDCm7+q3mAW0Dl/GPdUCeXrZLT9BrDrGkm4qlPvAAAAAElFTkSuQmCC);
}
.bs-datepicker-multiple {
  border-radius: 4px 0 0 4px;
}
.bs-datepicker-multiple + .bs-datepicker-multiple {
  margin-left: 10px;
}
.bs-datepicker-multiple .bs-datepicker {
  box-shadow: none;
  position: relative;
}
.bs-datepicker-multiple .bs-datepicker:not(:last-child) {
  padding-right: 10px;
}
.bs-datepicker-multiple .bs-datepicker + .bs-datepicker:after {
  content: "";
  display: block;
  width: 14px;
  height: 10px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAYAAABrGwT5AAAA1ElEQVQoU42RsQrCUAxF77VuDu7O4oMWW//BURBBpZvgKk4uIrjoqKOTf+DopIO4uYggtFTfw3+pkQqCW1/G5J7kJiFy4m5MxUlxAzgIPHX+lzMPzupRYlYgxiR7vqsOP8YKzsTx0yxFMCUZ+q7aZzlr+OvgoWcAFyAHgat2jLWu48252DdqAihDJGSSJNUUxYmQjs3+hPQBlAh2rG2LCOPnaw3IiGDX99TRCs7ASJsNhUOA7d/LcuHvRG22FIZvsNXw1MX6VZExCilOQKEfeLXr/10+aC9Ho7arh7oAAAAASUVORK5CYII=);
  position: absolute;
  top: 25px;
  left: -8px;
}
.bs-datepicker-multiple .bs-datepicker .left {
  float: left;
}
.bs-datepicker-multiple .bs-datepicker .right {
  float: right;
}
.bs-datepicker .bs-media-container {
  display: flex;
}
@media (max-width: 599.98px) {
  .bs-datepicker .bs-media-container {
    flex-direction: column;
  }
}
.bs-datepicker .bs-timepicker-in-datepicker-container {
  display: flex;
  justify-content: space-around;
}
.bs-datepicker-custom-range {
  padding: 15px;
  background: #eee;
}
.bs-datepicker-predefined-btns button {
  width: 100%;
  display: block;
  height: 30px;
  background-color: #9aaec1;
  border-radius: 4px;
  color: #fff;
  border: 0;
  margin-bottom: 10px;
  padding: 0 18px;
  text-align: left;
  transition: 0.3s;
}
.bs-datepicker-predefined-btns button:hover {
  background-color: #54708b;
  border-radius: 8px;
}
.bs-datepicker-buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  padding-top: 10px;
  border-top: 1px solid #E8EFFF;
}
.bs-datepicker-buttons .btn-default {
  margin-left: 10px;
}
.bs-datepicker-buttons .btn-today-wrapper {
  display: flex;
  flex-flow: row wrap;
}
.bs-datepicker-buttons .clear-right,
.bs-datepicker-buttons .today-right {
  flex-grow: 0;
}
.bs-datepicker-buttons .clear-left,
.bs-datepicker-buttons .today-left {
  flex-grow: 1;
}
.bs-datepicker-buttons .clear-center,
.bs-datepicker-buttons .today-center {
  flex-grow: 0.5;
}

/* .bs-timepicker */
.bs-timepicker-container {
  padding: 10px 0;
}
.bs-timepicker-label {
  color: #ffffff;
  margin-bottom: 10px;
}
.bs-timepicker-controls {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
.bs-timepicker-controls button {
  width: 20px;
  height: 20px;
  border-radius: 8px;
  border: 0;
  background-color: #e9edf0;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  vertical-align: middle;
  line-height: 0;
  padding: 0;
  transition: 0.3s;
}
.bs-timepicker-controls button:hover {
  background-color: #d5dadd;
}
.bs-timepicker-controls input {
  width: 35px;
  height: 25px;
  border-radius: 13px;
  text-align: center;
  border: 1px solid #E8EFFF;
}
.bs-timepicker .switch-time-format {
  text-transform: uppercase;
  min-width: 54px;
  height: 25px;
  border-radius: 20px;
  border: 1px solid #E8EFFF;
  background: #FFFFFF;
  color: #ffffff;
  font-size: 13px;
}
.bs-timepicker .switch-time-format img {
  vertical-align: initial;
  margin-left: 4px;
}

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 1080;
}

@media (max-width: 599.98px) {
  bs-daterangepicker-inline-container .bs-datepicker {
    width: min-content;
    justify-content: center;
    box-shadow: none;
  }
  bs-daterangepicker-inline-container .bs-datepicker-multiple {
    display: flex;
  }
  bs-daterangepicker-inline-container .bs-datepicker-multiple + bs-daterangepicker-inline-container .bs-datepicker-multiple {
    margin-top: 10px;
    margin-left: 0;
  }
}
/* theming */
.theme-default .bs-datepicker-head {
  background-color: transparent;
}
.theme-default .btn-today-wrapper .btn-success,
.theme-default .btn-clear-wrapper .btn-success {
  background-color: #6c757d;
  border-color: #6c757d;
}
.theme-default .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus,
.theme-default .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.theme-default .btn-today-wrapper .btn-success:focus,
.theme-default .btn-clear-wrapper .btn-success:focus {
  box-shadow: none;
}
.theme-default .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active,
.theme-default .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #616161;
  border-color: #616161;
}
.theme-default .btn-today-wrapper .btn-success:hover,
.theme-default .btn-clear-wrapper .btn-success:hover {
  background-color: #6F6E6E;
  border-color: #6F6E6E;
}
.theme-default .bs-datepicker-predefined-btns button.selected {
  background-color: #6c757d;
}
.theme-default .bs-datepicker-body table td span.selected, .theme-default .bs-datepicker-body table td.selected span,
.theme-default .bs-datepicker-body table td span[class*=select-]:after, .theme-default .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #6c757d;
}
.theme-default .bs-datepicker-body table td.week span {
  color: #6c757d;
}
.theme-default .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #6c757d;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-dark-blue .bs-datepicker-head {
  background-color: transparent;
}
.theme-dark-blue .btn-today-wrapper .btn-success,
.theme-dark-blue .btn-clear-wrapper .btn-success {
  background-color: #00236F;
  border-color: #00236F;
}
.theme-dark-blue .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus,
.theme-dark-blue .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.theme-dark-blue .btn-today-wrapper .btn-success:focus,
.theme-dark-blue .btn-clear-wrapper .btn-success:focus {
  box-shadow: none;
}
.theme-dark-blue .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active,
.theme-dark-blue .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #00236F;
  border-color: #00236F;
}
.theme-dark-blue .btn-today-wrapper .btn-success:hover,
.theme-dark-blue .btn-clear-wrapper .btn-success:hover {
  background-color: #6edff6;
  border-color: #6edff6;
}
.theme-dark-blue .bs-datepicker-predefined-btns button.selected {
  background-color: #00236F;
}
.theme-dark-blue .bs-datepicker-body table td span.selected, .theme-dark-blue .bs-datepicker-body table td.selected span,
.theme-dark-blue .bs-datepicker-body table td span[class*=select-]:after, .theme-dark-blue .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #00236F;
}
.theme-dark-blue .bs-datepicker-body table td.week span {
  color: #00236F;
}
.theme-dark-blue .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #00236F;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: 0.375rem;
  --bs-toast-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: rgba(255, 255, 255, 0.85);
  --bs-toast-header-border-color: rgba(0, 0, 0, 0.05);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

:root {
  --cui-primary: #00236F;
  --cui-primary-rgb: 0, 35, 111;
  --cui-primary-1: #0B3187;
  --cui-primary-2: #113A97;
  --cui-primary-3: #1843A7;
  --cui-primary-4: #466FD1;
  --cui-primary-5: #C4D5FE;
  --cui-primary-6: #F5F8FE;
  --cui-primary-7: #E8EFFF;
  --cui-primary-8: #C4D5FE;
  --cui-primary-10: #001e5e;
  --cui-secondary: #F5F8FE;
  --cui-secondary-1: #CCD7EE;
  --cui-secondary-2: #D5DFF3;
  --cui-secondary-3: #E8EFFF;
  --cui-secondary-4: #E6E9F1;
  --cui-aqua: #77D0ED;
  --cui-aqua-1: #8DD8F1;
  --cui-aqua-2: #A7E2F6;
  --cui-aqua-3: #BDEBFB;
  --cui-aqua-4: #D7F5FF;
  --cui-aqua-5: #0EA7DA;
  --cui-aqua-6: #5FC6E8;
  --cui-neutral: #4D4D4D;
  --cui-neutral-1: #919396;
  --cui-neutral-2: #EDECEB;
  --cui-neutral-3: #CED1D8;
  --cui-neutral-4: #555863;
  --cui-neutral-5: #FBFBFB;
  --cui-monotone: #F5F7FB;
  --cui-monotone-1: #FBFCFE;
  --cui-monotone-2: #E7E9ED;
  --cui-monotone-3: #B3B6C2;
  --cui-monotone-4: #888B9C;
  --cui-monotone-5: #23252B;
  --cui-monotone-6: #191A1D;
  --cui-danger: #BA1A1A;
  --cui-danger-1: #861816;
  --cui-danger-2: #BA1A1A;
  --cui-danger-3: #FF8080;
  --cui-danger-4: #F0DEE0;
  --cui-error: #ED6565;
  --cui-error-rgb: 237, 101, 101;
  --cui-error-1: #B50E0E;
  --cui-error-2: #FFDAD6;
  --cui-error-rgb-2: 255, 218, 214;
  --cui-error-3: #FFF5F4;
  --cui-error-4: #FFE5E5;
  --cui-alert: #FFE1B5;
  --cui-info: #0dcaf0;
  --cui-info-1: #FFC142;
  --cui-info-2: #FFE18E;
  --cui-success: #198754;
  --cui-success-1: #0EB551;
  --cui-success-2: #B0F3B7;
  --cui-success-rgb-2: 176, 243, 183;
  --cui-success-3: #007235;
  --cui-warning: #ffc107;
  --cui-warning-1: #930188;
  --cui-warning-2: #CCA5FD;
  --cui-warning-3: #FCE3FF;
  --cui-warning-4: #9C5400;
  --cui-warning-5: #FFAB00;
  --cui-accent: #9C5400;
  --cui-accent-1: #FFAB00;
  --cui-accent-2: #FFE1B5;
  --cui-violet: #480670;
  --cui-violet-1: #CCA5FD;
  --cui-violet-2: #9753EB;
  --cui-violet-3: #F0E4FF;
  --cui-honey: #672500;
  --cui-honey-1: #FFC142;
  --cui-coral: #FF8080;
  --cui-coral-1: #670104;
  --cui-navy-5: #2656C8;
  --cui-blush: #70065B;
  --cui-blush-1: #F697D4;
  --cui-white: #fff;
  --cui-black: #000;
  --cui-alert: #FFE1B5;
  --cui-alert-1: #E99537;
  --cui-alert-3: #804500;
  --cui-fs-14: 0.875rem;
  --cui-fs-12: 0.75rem;
  --breakpoint-xs: 0;
  --breakpoint-sm: 600px;
  --breakpoint-md: 900px;
  --breakpoint-lg: 1200px;
  --breakpoint-xl: 1536px;
  --bs-input-padding-y: 1rem;
  --bs-input-padding-x: 0.75rem;
  --bs-input-padding-y-sm: 1rem;
  --bs-input-padding-x-sm: 0.5rem;
  --bs-input-padding-y-lg: 1rem;
  --bs-input-padding-x-lg: 1rem;
  --bs-form-floating-padding-x: 0.5rem;
  --bs-form-floating-padding-x-sm: 0.25rem;
  --bs-form-floating-padding-x-lg: 0.75rem;
  --bs-form-floating-input-padding-t: 1.625rem;
  --bs-form-floating-input-padding-b: 0.625rem;
  --bs-form-floating-input-padding-t-sm: 0.75rem;
  --bs-form-floating-input-padding-b-sm: 0.38rem;
  --bs-form-floating-input-padding-t-lg: 1rem;
  --bs-form-floating-input-padding-b-lg: 0.38rem;
  --bs-input-btn-padding-y: 0.375rem;
}

/*FONTS DESKTOP*/
/*FONTS MOBILE*/
/*FONT BUTTON*/
/*FONT COLOR*/
/*PRIMARY COLOR*/
/*THEME PRIMARY*/
/*THEME SECONDARY*/
/*LIGHT THEME*/
/*THEME SUCCESS*/
/*THEME ERROR*/
/*MARGIN*/
/*PADDING-INTERNAL*/
/*INPUT*/
/*TABLE*/
/*NAVBAR*/
/*BUTTON-COLOR*/
/*BOOKING-STATUS*/
/*NAVIGATION-BAR*/
/*
* Theme / Colours
* Semantic naming for primary, secondary, tertiary, danger, info, success, warning
* Core colors - ESSENTIAL WEIGHTS
*/
/* Custom color utilities */
.color-primary {
  background-color: #00236F;
}

.color-primary-light {
  background-color: #F5F8FE;
}

.color-primary-aqua-10 {
  color: #00A2D7;
}

.color-tertiary {
  background-color: #BDEBFB;
}

.color-primary-light-text {
  color: #F5F8FE;
}

.color-primary-text {
  color: #FFFFFF;
}

.color-secondary {
  background-color: #E8EFFF;
}

.color-secondary-text {
  color: #00236F;
}

.color-warning {
  background-color: #CCA5FD;
}

.color-warning-text {
  color: #250569;
}

.color-warning-icon-light {
  color: #9C5400;
}

.color-warning-text-light {
  color: #9C5400;
}
.color-warning-text-light:hover {
  color: #663904 !important;
}
.color-warning-text-light:active {
  color: #663904 !important;
}
.color-warning-text-light:focus {
  color: #663904 !important;
}

.color-warning-secondary {
  background-color: #FCE3FF;
}

.color-warning-secondary-text {
  color: #930188;
}

.color-info {
  background-color: #FFE18E;
}

.color-info-light {
  background-color: #FFE1B5;
}
.color-info-light:hover {
  background-color: #FFE1B5;
}
.color-info-light:active {
  background-color: #FFE1B5 !important;
  border: none !important;
}
.color-info-light:focus {
  background-color: #FFE1B5 !important;
  border: none !important;
}
.color-info-light:disabled {
  background-color: #FFE1B5;
  opacity: 50%;
  border: none;
  color: #663904 !important;
}

.color-info-secondary {
  background-color: #FFC142;
}

.color-info-text {
  color: #663904;
}

.color-danger {
  background-color: #BA1A1A;
}

.color-danger-text {
  color: #410002;
}

.color-danger-secondary {
  background-color: #FF8080;
}

.color-danger-secondary-text {
  color: #861816;
}

.color-danger-tertiary {
  background-color: #F0DEE0;
}

.color-danger-tertiary-text {
  color: #BA1A1A;
}

.color-success-primary-text {
  color: #007235;
}

.color-success {
  background-color: #B0F3B7;
}

.color-success-secondary {
  background-color: #0EB551;
}

.color-success-text {
  color: #014420;
}

.color-brand {
  background-color: #0EA7DA;
}

.color-brand-secondary {
  background-color: #5FC6E8;
}

.color-neutral {
  background-color: #F5F7FB;
}

.color-neutral-tertiary {
  color: #919396;
}

.color-neutral-text {
  color: #FFFFFF;
  fill: #FFFFFF;
}

.color-outline {
  background-color: #E7E9ED;
}

.color-outline-text {
  color: #E7E9ED;
}

.border-neutral {
  border-color: #E7E9ED;
}

.color-error-primary {
  background-color: #B50E0E;
}

.color-error-primary-light {
  background-color: #ED6565;
}

.color-error-primary-light-text {
  color: #ED6565;
}

.color-error-primary-text {
  color: #B50E0E;
}

.color-error-secondary-light {
  background-color: #FFF5F4;
}

.color-error-secondary {
  background-color: #FFDAD6;
}

.color-error-tertiary {
  background-color: #FFE5E5;
}

.color-violet {
  background-color: #F0E4FF;
}

.color-violet-secondary {
  background-color: #9753EB;
}

.color-violet-text {
  color: #480670;
}

.color-mono-primary-text {
  color: #888B9C;
}

.color-mono-secondary-text {
  color: #23252B;
}

.color-mono-tertiary-text {
  color: #191A1D;
}

.color-navy {
  background-color: #C4D5FE;
}

.accordion-button:not(.collapsed) {
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-preview {
  --bs-accordion-bg: #FFFFFF;
}

.accordion-preview .collapse:not(.show) {
  display: block;
}

.accordion-preview .collapse:not(.show) .accordion-body {
  max-height: 118px;
  position: relative;
  overflow: hidden;
}

.accordion-preview .collapse:not(.show) .accordion-body:after {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
  background: linear-gradient(to top, rgb(251, 251, 251) 10%, rgba(251, 251, 251, 0) 60%);
  pointer-events: none;
}

.accordion-preview .accordion-button:not(.collapsed) {
  --bs-accordion-bg: #fbfbfb;
  --bs-accordion-active-bg: #fbfbfb;
  --bs-accordion-btn-focus-border-color: #fbfbfb;
  box-shadow: none;
}

.accordion-max-width {
  max-width: 820px;
}

.accordion-alt-btn {
  --bs-accordion-btn-icon: url("../../../assets/images/accordian-down.svg");
  --bs-accordion-btn-active-icon: url("../../../assets/images/accordian-down.svg");
  --bs-accordion-btn-icon-width: 2.5rem;
}

.accordion-alt-btn-small {
  --bs-accordion-btn-icon: url("../../../assets/images/accordian-down.svg");
  --bs-accordion-btn-active-icon: url("../../../assets/images/accordian-down.svg");
  --bs-accordion-btn-icon-width: 24px;
}

.accordion-alt-btn-small .accordion-button:not(.collapsed) {
  background-color: #FFFFFF;
  box-shadow: none !important;
}

.accordion-alt-btn-small-text {
  color: #191A1D;
}

/*
* Theme / Colours
* Semantic naming for primary, secondary, tertiary, danger, info, success, warning
* Core colors - ESSENTIAL WEIGHTS
*/
/* Custom color utilities */
.color-primary {
  background-color: #00236F;
}

.color-primary-light {
  background-color: #F5F8FE;
}

.color-primary-aqua-10 {
  color: #00A2D7;
}

.color-tertiary {
  background-color: #BDEBFB;
}

.color-primary-light-text {
  color: #F5F8FE;
}

.color-primary-text {
  color: #FFFFFF;
}

.color-secondary {
  background-color: #E8EFFF;
}

.color-secondary-text {
  color: #00236F;
}

.color-warning {
  background-color: #CCA5FD;
}

.color-warning-text {
  color: #250569;
}

.color-warning-icon-light {
  color: #9C5400;
}

.color-warning-text-light {
  color: #9C5400;
}
.color-warning-text-light:hover {
  color: #663904 !important;
}
.color-warning-text-light:active {
  color: #663904 !important;
}
.color-warning-text-light:focus {
  color: #663904 !important;
}

.color-warning-secondary {
  background-color: #FCE3FF;
}

.color-warning-secondary-text {
  color: #930188;
}

.color-info {
  background-color: #FFE18E;
}

.color-info-light {
  background-color: #FFE1B5;
}
.color-info-light:hover {
  background-color: #FFE1B5;
}
.color-info-light:active {
  background-color: #FFE1B5 !important;
  border: none !important;
}
.color-info-light:focus {
  background-color: #FFE1B5 !important;
  border: none !important;
}
.color-info-light:disabled {
  background-color: #FFE1B5;
  opacity: 50%;
  border: none;
  color: #663904 !important;
}

.color-info-secondary {
  background-color: #FFC142;
}

.color-info-text {
  color: #663904;
}

.color-danger {
  background-color: #BA1A1A;
}

.color-danger-text {
  color: #410002;
}

.color-danger-secondary {
  background-color: #FF8080;
}

.color-danger-secondary-text {
  color: #861816;
}

.color-danger-tertiary {
  background-color: #F0DEE0;
}

.color-danger-tertiary-text {
  color: #BA1A1A;
}

.color-success-primary-text {
  color: #007235;
}

.color-success {
  background-color: #B0F3B7;
}

.color-success-secondary {
  background-color: #0EB551;
}

.color-success-text {
  color: #014420;
}

.color-brand {
  background-color: #0EA7DA;
}

.color-brand-secondary {
  background-color: #5FC6E8;
}

.color-neutral {
  background-color: #F5F7FB;
}

.color-neutral-tertiary {
  color: #919396;
}

.color-neutral-text {
  color: #FFFFFF;
  fill: #FFFFFF;
}

.color-outline {
  background-color: #E7E9ED;
}

.color-outline-text {
  color: #E7E9ED;
}

.border-neutral {
  border-color: #E7E9ED;
}

.color-error-primary {
  background-color: #B50E0E;
}

.color-error-primary-light {
  background-color: #ED6565;
}

.color-error-primary-light-text {
  color: #ED6565;
}

.color-error-primary-text {
  color: #B50E0E;
}

.color-error-secondary-light {
  background-color: #FFF5F4;
}

.color-error-secondary {
  background-color: #FFDAD6;
}

.color-error-tertiary {
  background-color: #FFE5E5;
}

.color-violet {
  background-color: #F0E4FF;
}

.color-violet-secondary {
  background-color: #9753EB;
}

.color-violet-text {
  color: #480670;
}

.color-mono-primary-text {
  color: #888B9C;
}

.color-mono-secondary-text {
  color: #23252B;
}

.color-mono-tertiary-text {
  color: #191A1D;
}

.color-navy {
  background-color: #C4D5FE;
}

/*
* Component bootstrap variables
*/
/* Accordion */
/* Input */
/* Button */
/* Form Control */
/* Table */
/* Card */
/* Date picker */
.min-w-40 {
  min-width: 40px !important;
}

.max-w-40 {
  max-width: 40px !important;
}

.min-h-40 {
  min-height: 40px !important;
}

.max-h-40 {
  max-height: 40px !important;
}

.min-w-48 {
  min-width: 48px !important;
}

.max-w-48 {
  max-width: 48px !important;
}

.min-h-48 {
  min-height: 48px !important;
}

.max-h-48 {
  max-height: 48px !important;
}

.min-w-75 {
  min-width: 75px !important;
}

.max-w-75 {
  max-width: 75px !important;
}

.min-h-75 {
  min-height: 75px !important;
}

.max-h-75 {
  max-height: 75px !important;
}

.min-w-80 {
  min-width: 80px !important;
}

.max-w-80 {
  max-width: 80px !important;
}

.min-h-80 {
  min-height: 80px !important;
}

.max-h-80 {
  max-height: 80px !important;
}

.min-w-96 {
  min-width: 96px !important;
}

.max-w-96 {
  max-width: 96px !important;
}

.min-h-96 {
  min-height: 96px !important;
}

.max-h-96 {
  max-height: 96px !important;
}

.min-w-100 {
  min-width: 100px !important;
}

.max-w-100 {
  max-width: 100px !important;
}

.min-h-100 {
  min-height: 100px !important;
}

.max-h-100 {
  max-height: 100px !important;
}

.min-w-112 {
  min-width: 112px !important;
}

.max-w-112 {
  max-width: 112px !important;
}

.min-h-112 {
  min-height: 112px !important;
}

.max-h-112 {
  max-height: 112px !important;
}

.min-w-120 {
  min-width: 120px !important;
}

.max-w-120 {
  max-width: 120px !important;
}

.min-h-120 {
  min-height: 120px !important;
}

.max-h-120 {
  max-height: 120px !important;
}

.min-w-130 {
  min-width: 130px !important;
}

.max-w-130 {
  max-width: 130px !important;
}

.min-h-130 {
  min-height: 130px !important;
}

.max-h-130 {
  max-height: 130px !important;
}

.min-w-140 {
  min-width: 140px !important;
}

.max-w-140 {
  max-width: 140px !important;
}

.min-h-140 {
  min-height: 140px !important;
}

.max-h-140 {
  max-height: 140px !important;
}

.min-w-170 {
  min-width: 170px !important;
}

.max-w-170 {
  max-width: 170px !important;
}

.min-h-170 {
  min-height: 170px !important;
}

.max-h-170 {
  max-height: 170px !important;
}

.min-w-180 {
  min-width: 180px !important;
}

.max-w-180 {
  max-width: 180px !important;
}

.min-h-180 {
  min-height: 180px !important;
}

.max-h-180 {
  max-height: 180px !important;
}

.min-w-265 {
  min-width: 265px !important;
}

.max-w-265 {
  max-width: 265px !important;
}

.min-h-265 {
  min-height: 265px !important;
}

.max-h-265 {
  max-height: 265px !important;
}

.min-w-320 {
  min-width: 320px !important;
}

.max-w-320 {
  max-width: 320px !important;
}

.min-h-320 {
  min-height: 320px !important;
}

.max-h-320 {
  max-height: 320px !important;
}

.min-w-400 {
  min-width: 400px !important;
}

.max-w-400 {
  max-width: 400px !important;
}

.min-h-400 {
  min-height: 400px !important;
}

.max-h-400 {
  max-height: 400px !important;
}

.min-w-410 {
  min-width: 410px !important;
}

.max-w-410 {
  max-width: 410px !important;
}

.min-h-410 {
  min-height: 410px !important;
}

.max-h-410 {
  max-height: 410px !important;
}

.min-w-560 {
  min-width: 560px !important;
}

.max-w-560 {
  max-width: 560px !important;
}

.min-h-560 {
  min-height: 560px !important;
}

.max-h-560 {
  max-height: 560px !important;
}

.min-w-620 {
  min-width: 620px !important;
}

.max-w-620 {
  max-width: 620px !important;
}

.min-h-620 {
  min-height: 620px !important;
}

.max-h-620 {
  max-height: 620px !important;
}

.max-wh-40 {
  max-width: 40px;
  max-height: 40px;
}

.max-h {
  max-height: 16px;
}

@media (min-width: 600px) {
  .max-h-sm-none {
    max-height: none !important;
  }
}

@media (min-width: 600px) {
  .max-h-sm-48 {
    max-height: 48px !important;
  }
}

.max-w-50 {
  max-width: 50% !important;
}

@media (min-width: 600px) {
  .min-w-sm-620 {
    min-width: 620px !important;
  }
}

@media (min-width: 600px) {
  .max-w-sm-70 {
    max-width: 70%;
  }
}

@media (min-width: 600px) {
  .max-sm-w-100 {
    max-width: 100% !important;
  }
}

@media (min-width: 600px) {
  .max-sm-w-30 {
    max-width: 30% !important;
  }
}

.min-vh-100 {
  min-height: 100vh;
}

@media (min-width: 600px) {
  .max-vh-sm-365 {
    max-height: calc(100vh - 365px) !important;
  }
}

@media (min-width: 600px) {
  .vw-sm-50 {
    width: 50vw !important;
  }
}

.w-304 {
  width: 19rem !important;
}

.w-128 {
  width: 8rem !important;
}

.w-120 {
  width: 120% !important;
}

.h-25 {
  height: 25px !important;
}

.h-32 {
  height: 32px !important;
}

.w-32 {
  width: 32px !important;
}

.h-612 {
  height: 612px;
}

.w-500 {
  width: 500px !important;
}

.w-600 {
  width: 600px;
}

.w-250 {
  width: 250px;
}

.w-240 {
  width: 240px;
}

.h-40 {
  height: 40px !important;
}

.max-h-40 {
  max-height: 40px !important;
}

.h-80 {
  height: 80px;
}

.h-100 {
  height: 100%;
}

.h-98 {
  height: 6.125rem;
}

.h-64 {
  height: 64px;
}

.h-45 {
  height: 45px !important;
}

.h-50vh {
  height: 50vh;
}

@media (min-width: 600px) {
  .h-sm-38 {
    height: 38px;
  }
}

@media (min-width: 600px) {
  .h-sm-36 {
    height: 2rem !important;
  }
}

@media (min-width: 600px) {
  .h-sm-80 {
    height: 80px !important;
  }
}

@media (min-width: 600px) {
  .h-sm-500 {
    height: 500px !important;
  }
}

.w-160 {
  width: 10rem !important;
}

.w-24 {
  width: 24px;
}

.w-80 {
  width: 80px;
}

.w-48 {
  width: 3rem !important;
}

.w-66 {
  width: 66px;
}

.w-60 {
  width: 60px;
}

.w-40 {
  width: 40px !important;
}

.max-content {
  width: max-content;
}

.lh-25 {
  line-height: 25px;
}

.lh-24 {
  line-height: 24px;
}

@media (min-width: 600px) {
  .lh-sm-24 {
    line-height: 24px !important;
  }
}

::ng-deep .button-icon-svg > span > div > svg {
  width: 24px !important;
  height: 1rem !important;
}

.lh-21 {
  line-height: 21px;
}

.lh-20 {
  line-height: 21px;
}

.lh-32 {
  line-height: 32px;
}

.lh-35 {
  line-height: 35px;
}

.lh-38 {
  line-height: 18px;
}

.lh-16 {
  line-height: 16px;
}

.lh-18 {
  line-height: 18px;
}

@media (min-width: 600px) {
  .lh-sm-38 {
    line-height: 38px;
  }
}

@media (min-width: 600px) {
  .pr-sm-0 {
    padding-right: 0px !important;
  }
}

.lh-30 {
  line-height: 30px;
}

.lh-40 {
  line-height: 40px;
}

.lh-45 {
  line-height: 45px;
}

@media (min-width: 600px) {
  .lh-sm-45 {
    line-height: 45px;
  }
}

@media (min-width: 600px) {
  .lh-sm-normal {
    line-height: normal;
  }
}

.lh-150 {
  line-height: 150%;
}

.ls-tight {
  letter-spacing: -0.5px;
}

.mx-n3 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.white-space-pre-line {
  white-space: pre-line;
}

.fw-medium {
  font-weight: 500;
}

@media (min-width: 600px) {
  .fw-sm-bold {
    font-weight: 700;
  }
}

.fw-semi-bold {
  font-weight: 600;
}

.flex-auto {
  flex: auto;
}

.flex-none {
  flex: none;
}

@media (min-width: 600px) {
  .flex-sm-none {
    flex: none !important;
  }
}

.flex-1 {
  flex: 1 1 0;
}

@media (min-width: 600px) {
  .flex-sm-no-grow {
    flex: 0 1 0 !important;
  }
}

@media (min-width: 600px) {
  .flex-sm-1 {
    flex: 1 1 0 !important;
  }
}

.flex-2 {
  flex: 2 1 0;
}

@media (min-width: 600px) {
  .flex-sm-2 {
    flex: 2 1 0 !important;
  }
}

.flex-3 {
  flex: 3 1 0;
}

@media (min-width: 600px) {
  .flex-sm-3 {
    flex: 3 1 0 !important;
  }
}

.flex-shrink-1 {
  flex: 1 1 0;
}

.flex-basis-0 {
  flex-basis: 0 !important;
}

@media (min-width: 600px) {
  .flex-basis-sm-0 {
    flex-basis: 0 !important;
  }
}

.flex-basis-48 {
  flex-basis: 48% !important;
}

@media (min-width: 600px) {
  .flex-basis-sm-48 {
    flex-basis: 48% !important;
  }
}

.justify-self-start {
  justify-self: start;
}

.vertical-align-sub {
  vertical-align: sub !important;
}

.text-align-right > input {
  text-align: right !important;
}

.row-gap-3 {
  row-gap: 1rem;
}

.column-gap-2 {
  column-gap: 0.5rem;
}

.cursor-pointer {
  cursor: pointer;
}

.text-align-right {
  text-align: right;
}

.float-right {
  float: right;
}

.indent-li {
  margin-left: 20px;
}

.border-radius-50 {
  border-radius: 50% !important;
}

.border-radius-16 {
  border-radius: 16px !important;
}

.col-md-3 {
  width: 25% !important;
}

.col-md-4 {
  width: 33.33333333% !important;
}

.border-none {
  border: none !important;
}

.position-relative {
  position: relative;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.fs-20 {
  font-size: 20px;
}

.invalid-feedback {
  display: block;
}

.w-0p {
  width: 0%;
}

@media (min-width: 600px) {
  .w-sm-0p {
    width: 0% !important;
  }
}

.w-max-0p {
  max-width: 0%;
}

@media (min-width: 600px) {
  .w-max-sm-0p {
    max-width: 0% !important;
  }
}

.w-min-0p {
  min-width: 0%;
}

@media (min-width: 600px) {
  .w-min-sm-0p {
    max-width: 0% !important;
  }
}

.w-5p {
  width: 5%;
}

@media (min-width: 600px) {
  .w-sm-5p {
    width: 5% !important;
  }
}

.w-max-5p {
  max-width: 5%;
}

@media (min-width: 600px) {
  .w-max-sm-5p {
    max-width: 5% !important;
  }
}

.w-min-5p {
  min-width: 5%;
}

@media (min-width: 600px) {
  .w-min-sm-5p {
    max-width: 5% !important;
  }
}

.w-10p {
  width: 10%;
}

@media (min-width: 600px) {
  .w-sm-10p {
    width: 10% !important;
  }
}

.w-max-10p {
  max-width: 10%;
}

@media (min-width: 600px) {
  .w-max-sm-10p {
    max-width: 10% !important;
  }
}

.w-min-10p {
  min-width: 10%;
}

@media (min-width: 600px) {
  .w-min-sm-10p {
    max-width: 10% !important;
  }
}

.w-15p {
  width: 15%;
}

@media (min-width: 600px) {
  .w-sm-15p {
    width: 15% !important;
  }
}

.w-max-15p {
  max-width: 15%;
}

@media (min-width: 600px) {
  .w-max-sm-15p {
    max-width: 15% !important;
  }
}

.w-min-15p {
  min-width: 15%;
}

@media (min-width: 600px) {
  .w-min-sm-15p {
    max-width: 15% !important;
  }
}

.w-18p {
  width: 18%;
}

@media (min-width: 600px) {
  .w-sm-18p {
    width: 18% !important;
  }
}

.w-max-18p {
  max-width: 18%;
}

@media (min-width: 600px) {
  .w-max-sm-18p {
    max-width: 18% !important;
  }
}

.w-min-18p {
  min-width: 18%;
}

@media (min-width: 600px) {
  .w-min-sm-18p {
    max-width: 18% !important;
  }
}

.w-20p {
  width: 20%;
}

@media (min-width: 600px) {
  .w-sm-20p {
    width: 20% !important;
  }
}

.w-max-20p {
  max-width: 20%;
}

@media (min-width: 600px) {
  .w-max-sm-20p {
    max-width: 20% !important;
  }
}

.w-min-20p {
  min-width: 20%;
}

@media (min-width: 600px) {
  .w-min-sm-20p {
    max-width: 20% !important;
  }
}

.w-22p {
  width: 23%;
}

@media (min-width: 600px) {
  .w-sm-22p {
    width: 23% !important;
  }
}

.w-max-22p {
  max-width: 23%;
}

@media (min-width: 600px) {
  .w-max-sm-22p {
    max-width: 23% !important;
  }
}

.w-min-22p {
  min-width: 23%;
}

@media (min-width: 600px) {
  .w-min-sm-22p {
    max-width: 23% !important;
  }
}

.w-25p {
  width: 25%;
}

@media (min-width: 600px) {
  .w-sm-25p {
    width: 25% !important;
  }
}

.w-max-25p {
  max-width: 25%;
}

@media (min-width: 600px) {
  .w-max-sm-25p {
    max-width: 25% !important;
  }
}

.w-min-25p {
  min-width: 25%;
}

@media (min-width: 600px) {
  .w-min-sm-25p {
    max-width: 25% !important;
  }
}

.w-30p {
  width: 30%;
}

@media (min-width: 600px) {
  .w-sm-30p {
    width: 30% !important;
  }
}

.w-max-30p {
  max-width: 30%;
}

@media (min-width: 600px) {
  .w-max-sm-30p {
    max-width: 30% !important;
  }
}

.w-min-30p {
  min-width: 30%;
}

@media (min-width: 600px) {
  .w-min-sm-30p {
    min-width: 30% !important;
  }
}

.w-35p {
  width: 35%;
}

@media (min-width: 600px) {
  .w-sm-35p {
    width: 35% !important;
  }
}

.w-max-35p {
  max-width: 35%;
}

@media (min-width: 600px) {
  .w-max-sm-35p {
    max-width: 35% !important;
  }
}

.w-min-35p {
  min-width: 35%;
}

@media (min-width: 600px) {
  .w-min-sm-35p {
    min-width: 35% !important;
  }
}

.w-40p {
  width: 40%;
}

@media (min-width: 600px) {
  .w-sm-40p {
    width: 40% !important;
  }
}

.w-max-40p {
  max-width: 40%;
}

@media (min-width: 600px) {
  .w-max-sm-40p {
    max-width: 40% !important;
  }
}

.w-min-40p {
  min-width: 40%;
}

@media (min-width: 600px) {
  .w-min-sm-40p {
    min-width: 40% !important;
  }
}

.w-45p {
  width: 45%;
}

@media (min-width: 600px) {
  .w-sm-45p {
    width: 45% !important;
  }
}

.w-max-45p {
  max-width: 45%;
}

@media (min-width: 600px) {
  .w-max-sm-45p {
    max-width: 45% !important;
  }
}

.w-min-45p {
  min-width: 45%;
}

@media (min-width: 600px) {
  .w-min-sm-45p {
    min-width: 45% !important;
  }
}

.w-48p {
  width: 48%;
}

@media (min-width: 600px) {
  .w-sm-48p {
    width: 48% !important;
  }
}

.w-max-48p {
  max-width: 48%;
}

@media (min-width: 600px) {
  .w-max-sm-48p {
    max-width: 48% !important;
  }
}

.w-min-48p {
  min-width: 45%;
}

@media (min-width: 600px) {
  .w-min-sm-48p {
    min-width: 45% !important;
  }
}

.w-50p {
  width: 50%;
}

@media (min-width: 600px) {
  .w-sm-50p {
    width: 50% !important;
  }
}

.w-max-50p {
  max-width: 50%;
}

@media (min-width: 600px) {
  .w-max-sm-50p {
    max-width: 50% !important;
  }
}

.w-min-50p {
  min-width: 50%;
}

@media (min-width: 600px) {
  .w-min-sm-50p {
    min-width: 50% !important;
  }
}

.w-55p {
  width: 55%;
}

@media (min-width: 600px) {
  .w-sm-55p {
    width: 55% !important;
  }
}

.w-max-55p {
  max-width: 55%;
}

@media (min-width: 600px) {
  .w-max-sm-55p {
    max-width: 55% !important;
  }
}

.w-min-55p {
  min-width: 55%;
}

@media (min-width: 600px) {
  .w-min-sm-55p {
    min-width: 55% !important;
  }
}

.w-60p {
  width: 60%;
}

@media (min-width: 600px) {
  .w-sm-60p {
    width: 60% !important;
  }
}

.w-max-60p {
  max-width: 60%;
}

@media (min-width: 600px) {
  .w-max-sm-60p {
    max-width: 60% !important;
  }
}

.w-min-60p {
  min-width: 60%;
}

@media (min-width: 600px) {
  .w-min-sm-60p {
    min-width: 60% !important;
  }
}

.w-65p {
  width: 65%;
}

@media (min-width: 600px) {
  .w-sm-65p {
    width: 65% !important;
  }
}

.w-max-65p {
  max-width: 65%;
}

@media (min-width: 600px) {
  .w-max-sm-65p {
    max-width: 65% !important;
  }
}

.w-min-65p {
  min-width: 65%;
}

@media (min-width: 600px) {
  .w-min-sm-65p {
    min-width: 65% !important;
  }
}

.w-70p {
  width: 70%;
}

@media (min-width: 600px) {
  .w-sm-70p {
    width: 70% !important;
  }
}

.w-max-70p {
  max-width: 70%;
}

@media (min-width: 600px) {
  .w-max-sm-70p {
    max-width: 70% !important;
  }
}

.w-min-70p {
  min-width: 70%;
}

@media (min-width: 600px) {
  .w-min-sm-70p {
    min-width: 70% !important;
  }
}

.w-75p {
  width: 75%;
}

@media (min-width: 600px) {
  .w-sm-75p {
    width: 75% !important;
  }
}

.w-max-75p {
  max-width: 75%;
}

@media (min-width: 600px) {
  .w-max-sm-75p {
    max-width: 75% !important;
  }
}

.w-min-75p {
  min-width: 75%;
}

@media (min-width: 600px) {
  .w-min-sm-75p {
    min-width: 75% !important;
  }
}

.w-80p {
  width: 80%;
}

@media (min-width: 600px) {
  .w-sm-80p {
    width: 80% !important;
  }
}

.w-max-80p {
  max-width: 80%;
}

@media (min-width: 600px) {
  .w-max-sm-80p {
    max-width: 80% !important;
  }
}

.w-min-80p {
  min-width: 80%;
}

@media (min-width: 600px) {
  .w-min-sm-80p {
    min-width: 80% !important;
  }
}

.w-85p {
  width: 85%;
}

@media (min-width: 600px) {
  .w-sm-85p {
    width: 85% !important;
  }
}

.w-max-85p {
  max-width: 85%;
}

@media (min-width: 600px) {
  .w-max-sm-85p {
    max-width: 85% !important;
  }
}

.w-min-85p {
  min-width: 85%;
}

@media (min-width: 600px) {
  .w-min-sm-85p {
    min-width: 85% !important;
  }
}

.w-90p {
  width: 90%;
}

@media (min-width: 600px) {
  .w-sm-90p {
    width: 90% !important;
  }
}

.w-max-90p {
  max-width: 90%;
}

@media (min-width: 600px) {
  .w-max-sm-90p {
    max-width: 90% !important;
  }
}

.w-min-90p {
  min-width: 90%;
}

@media (min-width: 600px) {
  .w-min-sm-90p {
    min-width: 90% !important;
  }
}

.w-95p {
  width: 95%;
}

@media (min-width: 600px) {
  .w-sm-95p {
    width: 95% !important;
  }
}

.w-max-95p {
  max-width: 95%;
}

@media (min-width: 600px) {
  .w-max-sm-95p {
    max-width: 95% !important;
  }
}

.w-min-95p {
  min-width: 95%;
}

@media (min-width: 600px) {
  .w-min-sm-95p {
    min-width: 95% !important;
  }
}

.w-100p {
  width: 100%;
}

@media (min-width: 600px) {
  .w-sm-100p {
    width: 100% !important;
  }
}

.w-max-100p {
  max-width: 100%;
}

@media (min-width: 600px) {
  .w-max-sm-100p {
    max-width: 100% !important;
  }
}

.w-min-100p {
  min-width: 100%;
}

@media (min-width: 600px) {
  .w-min-sm-100p {
    min-width: 100% !important;
  }
}

.h-0p {
  height: 0%;
}

@media (min-width: 600px) {
  .h-sm-0p {
    height: 0% !important;
  }
}

.h-max-0p {
  max-height: 0%;
}

@media (min-width: 600px) {
  .h-max-sm-0p {
    max-height: 0% !important;
  }
}

.h-min-0p {
  min-height: 0%;
}

@media (min-width: 600px) {
  .h-min-sm-0p {
    min-height: 0% !important;
  }
}

.h-5p {
  height: 5%;
}

@media (min-width: 600px) {
  .h-sm-5p {
    height: 5% !important;
  }
}

.h-max-5p {
  max-height: 5%;
}

@media (min-width: 600px) {
  .h-max-sm-5p {
    max-height: 5% !important;
  }
}

.h-min-5p {
  min-height: 5%;
}

@media (min-width: 600px) {
  .h-min-sm-5p {
    min-height: 5% !important;
  }
}

.h-10p {
  height: 10%;
}

@media (min-width: 600px) {
  .h-sm-10p {
    height: 10% !important;
  }
}

.h-max-10p {
  max-height: 10%;
}

@media (min-width: 600px) {
  .h-max-sm-10p {
    max-height: 10% !important;
  }
}

.h-min-10p {
  min-height: 10%;
}

@media (min-width: 600px) {
  .h-min-sm-10p {
    min-height: 10% !important;
  }
}

.h-15p {
  height: 15%;
}

@media (min-width: 600px) {
  .h-sm-15p {
    height: 15% !important;
  }
}

.h-max-15p {
  max-height: 15%;
}

@media (min-width: 600px) {
  .h-max-sm-15p {
    max-height: 15% !important;
  }
}

.h-min-15p {
  min-height: 15%;
}

@media (min-width: 600px) {
  .h-min-sm-15p {
    min-height: 15% !important;
  }
}

.h-20p {
  height: 20%;
}

@media (min-width: 600px) {
  .h-sm-20p {
    height: 20% !important;
  }
}

.h-max-20p {
  max-height: 20%;
}

@media (min-width: 600px) {
  .h-max-sm-20p {
    max-height: 20% !important;
  }
}

.h-min-20p {
  min-height: 20%;
}

@media (min-width: 600px) {
  .h-min-sm-20p {
    min-height: 20% !important;
  }
}

.h-25p {
  height: 25%;
}

@media (min-width: 600px) {
  .h-sm-25p {
    height: 25% !important;
  }
}

.h-max-25p {
  max-height: 25%;
}

@media (min-width: 600px) {
  .h-max-sm-25p {
    max-height: 25% !important;
  }
}

.h-min-25p {
  min-height: 25%;
}

@media (min-width: 600px) {
  .h-min-sm-25p {
    min-height: 25% !important;
  }
}

.h-30p {
  height: 30%;
}

@media (min-width: 600px) {
  .h-sm-30p {
    height: 30% !important;
  }
}

.h-max-30p {
  max-height: 30%;
}

@media (min-width: 600px) {
  .h-max-sm-30p {
    max-height: 30% !important;
  }
}

.h-min-30p {
  min-height: 30%;
}

@media (min-width: 600px) {
  .h-min-sm-30p {
    min-height: 30% !important;
  }
}

.h-35p {
  height: 35%;
}

@media (min-width: 600px) {
  .h-sm-35p {
    height: 35% !important;
  }
}

.h-max-35p {
  max-height: 35%;
}

@media (min-width: 600px) {
  .h-max-sm-35p {
    max-height: 35% !important;
  }
}

.h-min-35p {
  min-height: 35%;
}

@media (min-width: 600px) {
  .h-min-sm-35p {
    min-height: 35% !important;
  }
}

.h-40p {
  height: 40%;
}

@media (min-width: 600px) {
  .h-sm-40p {
    height: 40% !important;
  }
}

.h-max-40p {
  max-height: 40%;
}

@media (min-width: 600px) {
  .h-max-sm-40p {
    max-height: 40% !important;
  }
}

.h-min-40p {
  min-height: 40%;
}

@media (min-width: 600px) {
  .h-min-sm-40p {
    min-height: 40% !important;
  }
}

.h-45p {
  height: 45%;
}

@media (min-width: 600px) {
  .h-sm-45p {
    height: 45% !important;
  }
}

.h-max-45p {
  max-height: 45%;
}

@media (min-width: 600px) {
  .h-max-sm-45p {
    max-height: 45% !important;
  }
}

.h-min-45p {
  min-height: 45%;
}

@media (min-width: 600px) {
  .h-min-sm-45p {
    min-height: 45% !important;
  }
}

.h-50p {
  height: 50%;
}

@media (min-width: 600px) {
  .h-sm-50p {
    height: 50% !important;
  }
}

.h-max-50p {
  max-height: 50%;
}

@media (min-width: 600px) {
  .h-max-sm-50p {
    max-height: 50% !important;
  }
}

.h-min-50p {
  min-height: 50%;
}

@media (min-width: 600px) {
  .h-min-sm-50p {
    min-height: 50% !important;
  }
}

.h-55p {
  height: 55%;
}

@media (min-width: 600px) {
  .h-sm-55p {
    height: 55% !important;
  }
}

.h-max-55p {
  max-height: 55%;
}

@media (min-width: 600px) {
  .h-max-sm-55p {
    max-height: 55% !important;
  }
}

.h-min-55p {
  min-height: 55%;
}

@media (min-width: 600px) {
  .h-min-sm-55p {
    min-height: 55% !important;
  }
}

.h-60p {
  height: 60%;
}

@media (min-width: 600px) {
  .h-sm-60p {
    height: 60% !important;
  }
}

.h-max-60p {
  max-height: 60%;
}

@media (min-width: 600px) {
  .h-max-sm-60p {
    max-height: 60% !important;
  }
}

.h-min-60p {
  min-height: 60%;
}

@media (min-width: 600px) {
  .h-min-sm-60p {
    min-height: 60% !important;
  }
}

.h-65p {
  height: 65%;
}

@media (min-width: 600px) {
  .h-sm-65p {
    height: 65% !important;
  }
}

.h-max-65p {
  max-height: 65%;
}

@media (min-width: 600px) {
  .h-max-sm-65p {
    max-height: 65% !important;
  }
}

.h-min-65p {
  min-height: 65%;
}

@media (min-width: 600px) {
  .h-min-sm-65p {
    min-height: 65% !important;
  }
}

.h-70p {
  height: 70%;
}

@media (min-width: 600px) {
  .h-sm-70p {
    height: 70% !important;
  }
}

.h-max-70p {
  max-height: 70%;
}

@media (min-width: 600px) {
  .h-max-sm-70p {
    max-height: 70% !important;
  }
}

.h-min-70p {
  min-height: 70%;
}

@media (min-width: 600px) {
  .h-min-sm-70p {
    min-height: 70% !important;
  }
}

.h-75p {
  height: 75%;
}

@media (min-width: 600px) {
  .h-sm-75p {
    height: 75% !important;
  }
}

.h-max-75p {
  max-height: 75%;
}

@media (min-width: 600px) {
  .h-max-sm-75p {
    max-height: 75% !important;
  }
}

.h-min-75p {
  min-height: 75%;
}

@media (min-width: 600px) {
  .h-min-sm-75p {
    min-height: 75% !important;
  }
}

.h-80p {
  height: 80%;
}

@media (min-width: 600px) {
  .h-sm-80p {
    height: 80% !important;
  }
}

.h-max-80p {
  max-height: 80%;
}

@media (min-width: 600px) {
  .h-max-sm-80p {
    max-height: 80% !important;
  }
}

.h-min-80p {
  min-height: 80%;
}

@media (min-width: 600px) {
  .h-min-sm-80p {
    min-height: 80% !important;
  }
}

.h-85p {
  height: 85%;
}

@media (min-width: 600px) {
  .h-sm-85p {
    height: 85% !important;
  }
}

.h-max-85p {
  max-height: 85%;
}

@media (min-width: 600px) {
  .h-max-sm-85p {
    max-height: 85% !important;
  }
}

.h-min-85p {
  min-height: 85%;
}

@media (min-width: 600px) {
  .h-min-sm-85p {
    min-height: 85% !important;
  }
}

.h-90p {
  height: 90%;
}

@media (min-width: 600px) {
  .h-sm-90p {
    height: 90% !important;
  }
}

.h-max-90p {
  max-height: 90%;
}

@media (min-width: 600px) {
  .h-max-sm-90p {
    max-height: 90% !important;
  }
}

.h-min-90p {
  min-height: 90%;
}

@media (min-width: 600px) {
  .h-min-sm-90p {
    min-height: 90% !important;
  }
}

.h-95p {
  height: 95%;
}

@media (min-width: 600px) {
  .h-sm-95p {
    height: 95% !important;
  }
}

.h-max-95p {
  max-height: 95%;
}

@media (min-width: 600px) {
  .h-max-sm-95p {
    max-height: 95% !important;
  }
}

.h-min-95p {
  min-height: 95%;
}

@media (min-width: 600px) {
  .h-min-sm-95p {
    min-height: 95% !important;
  }
}

.h-100p {
  height: 100%;
}

@media (min-width: 600px) {
  .h-sm-100p {
    height: 100% !important;
  }
}

.h-max-100p {
  max-height: 100%;
}

@media (min-width: 600px) {
  .h-max-sm-100p {
    max-height: 100% !important;
  }
}

.h-min-100p {
  min-height: 100%;
}

@media (min-width: 600px) {
  .h-min-sm-100p {
    min-height: 100% !important;
  }
}

.min-w-120p {
  min-width: 7.5rem !important;
}

@media (min-width: 600px) {
  .w-max-sm-120p {
    max-width: 7.5rem !important;
  }
}

@media (min-width: 600px) {
  .w-min-sm-120p {
    min-width: 7.5rem !important;
  }
}

.w-150p {
  width: 9.375rem !important;
}

@media (min-width: 600px) {
  .w-max-sm-150p {
    max-width: 9.375rem !important;
  }
}

@media (min-width: 600px) {
  .w-min-sm-150p {
    min-width: 9.375rem !important;
  }
}

.w-200p {
  width: 12.5rem !important;
}

.w-max-200p {
  max-width: 12.5rem !important;
}

@media (min-width: 600px) {
  .w-max-sm-200p {
    max-width: 12.5rem !important;
  }
}

@media (min-width: 600px) {
  .w-min-sm-200p {
    min-width: 12.5rem !important;
  }
}

.w-360p {
  width: 22.5rem !important;
}

@media (min-width: 600px) {
  .w-max-sm-360 {
    max-width: 22.5rem !important;
  }
}

@media (min-width: 600px) {
  .w-min-sm-360 {
    min-width: 22.5rem !important;
  }
}

.h-48 {
  height: 3rem;
}

.max-h-48 {
  max-height: 48px;
}

@media (min-width: 600px) {
  .h-sm-48 {
    height: 3rem !important;
  }
}

@media (min-width: 600px) {
  .max-h-sm-48 {
    max-height: 48px !important;
  }
}

.no-wrap {
  white-space: nowrap !important;
}

@media (max-width: 599.98px) {
  .w-sm-auto {
    width: auto !important;
  }
}

@media (min-width: 600px) {
  .border-sm-bottom-0 {
    border-bottom: 0 !important;
  }
}

/*FONTS DESKTOP*/
/*FONTS MOBILE*/
/*FONT BUTTON*/
/*FONT COLOR*/
/*PRIMARY COLOR*/
/*THEME PRIMARY*/
/*THEME SECONDARY*/
/*LIGHT THEME*/
/*THEME SUCCESS*/
/*THEME ERROR*/
/*MARGIN*/
/*PADDING-INTERNAL*/
/*INPUT*/
/*TABLE*/
/*NAVBAR*/
/*BUTTON-COLOR*/
/*BOOKING-STATUS*/
/*NAVIGATION-BAR*/
@media screen and (min-width: 768px) {
  .typography__h1 {
    font-size: 18px;
    line-height: 24px/1, 2;
    font-width: 400;
  }
}
@media screen and (min-width: 1024px) {
  .typography__h1 {
    font-size: 52px;
    line-height: 60px;
    font-width: 700;
  }
}

@media screen and (min-width: 768px) {
  .typography__h2 {
    font-size: 16px;
    line-height: 22px/1, 2;
    font-weight: 400;
  }
}
@media screen and (min-width: 1024px) {
  .typography__h2 {
    font-size: 26px;
    line-height: 36px;
    font-weight: 600;
  }
}

.typography__h3 {
  font-weight: 600;
}
@media screen and (min-width: 768px) {
  .typography__h3 {
    font-size: 14px;
  }
}
@media screen and (min-width: 1024px) {
  .typography__h3 {
    font-size: 24px;
    line-height: 33px;
  }
}

.typography__h4 {
  line-height: 24px;
  font-weight: 600;
}
@media screen and (min-width: 768px) {
  .typography__h4 {
    font-size: 14px;
  }
}
@media screen and (min-width: 1024px) {
  .typography__h4 {
    font-size: 18px;
  }
}

.typography__p, .table__bordered_style td, .table__bordered_style th, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option, .dropdown-item, .dropdown-menu li a, .inputField__label {
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .typography__p, .table__bordered_style td, .table__bordered_style th, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option, .dropdown-item, .dropdown-menu li a, .inputField__label {
    font-size: 12px;
    line-height: 20px/1.2;
  }
}
@media screen and (min-width: 1024px) {
  .typography__p, .table__bordered_style td, .table__bordered_style th, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option, .dropdown-item, .dropdown-menu li a, .inputField__label {
    font-size: 16px;
    line-height: 24px;
  }
}

.error-field {
  color: #AB2930;
  font-size: 80%;
}

.inputField__label {
  font-weight: 300 !important;
}

.currencyTextAlign {
  text-align: end !important;
}

.currencyTextAlignRight {
  text-align: right !important;
}

.btn {
  border-radius: 0.25em;
  color: #FFF;
  font-size: 16px;
  margin: 0 12px 0 12px;
}
@media screen and (max-width: 1200px) {
  .btn {
    margin: 0 3px 0 3px;
  }
}

.btn-secondary {
  color: #00236F;
  background-color: #E8EFFF;
}
.btn-secondary:hover {
  color: #00236F;
}

.btn__regular {
  max-width: 200px !important;
}
@media screen and (min-width: 768px) {
  .btn__regular {
    min-width: 100px;
    min-height: 40px;
  }
}
@media screen and (min-width: 1200px) {
  .btn__regular {
    min-width: 120px;
    line-height: 150%;
  }
}

.btn__icon {
  border-radius: 0.25em;
  border: solid 0.071em;
  color: #FFF;
  height: 30px;
  width: 30px;
}
@media screen and (min-width: 1200px) {
  .btn__icon {
    height: 40px;
    width: 40px;
  }
}
.btn__icon img {
  height: 15px;
  width: 15px;
}
@media screen and (min-width: 1200px) {
  .btn__icon img {
    height: 20px;
    width: 20px;
  }
}
.btn__icon--round-always {
  border-radius: 50%;
  width: 2.2rem !important;
  height: 2.2rem !important;
  align-items: unset;
}
@media (min-width: 600px) {
  .btn__icon--round-desktop {
    border-radius: 50%;
    width: 2rem !important;
    height: 2rem !important;
    align-items: unset;
  }
}

.btn__regular-icon {
  background-color: #E4F1FC;
  border-radius: 0.25em;
  border: none !important;
}
.btn__regular-icon--active-green {
  background-color: #CAE9D9;
  border: none !important;
}
.btn__regular-icon--active-green svg {
  fill: #008843;
}
@media screen and (max-width: 1200px) {
  .btn__regular-icon--active-green svg {
    height: 13px;
  }
}
.btn__regular-icon--active-green:disabled {
  background-color: #EAEAEA;
  opacity: 1 !important;
  border: none !important;
}
.btn__regular-icon--active-green:disabled svg {
  fill: #A6ACAF;
}
@media screen and (max-width: 1200px) {
  .btn__regular-icon--active-green:disabled svg {
    height: 13px;
  }
}
.btn__regular-icon--active-green:hover {
  background-color: #CAE9D9 !important;
  border: none !important;
}
.btn__regular-icon--active-green:focus {
  background-color: #CAE9D9 !important;
  border: none !important;
}
.btn__regular-icon--active-green:active {
  border: none !important;
}
.btn__regular-icon--active-blue {
  background-color: #E4F1FC;
  border: none !important;
}
.btn__regular-icon--active-blue svg {
  fill: #103783;
}
@media screen and (max-width: 1200px) {
  .btn__regular-icon--active-blue svg {
    height: 13px;
  }
}
.btn__regular-icon--active-blue:disabled {
  background-color: #EAEAEA;
  opacity: 1 !important;
  border: none !important;
}
.btn__regular-icon--active-blue:disabled svg {
  fill: #A6ACAF;
}
@media screen and (max-width: 1200px) {
  .btn__regular-icon--active-blue:disabled svg {
    height: 13px;
  }
}
.btn__regular-icon--active-blue:hover {
  background-color: #E4F1FC !important;
  border: none !important;
}
.btn__regular-icon--active-blue:focus {
  background-color: #E4F1FC !important;
  border: none !important;
}
.btn__regular-icon--active-blue:active {
  border: none !important;
}
.btn__regular-icon--active-red {
  background-color: #F3CFD1;
}
.btn__regular-icon--active-red svg {
  fill: #AB2930;
}
.btn__regular-icon--active-red:disabled {
  background-color: #EAEAEA;
  opacity: 1 !important;
}
.btn__regular-icon--active-red:disabled svg {
  fill: #A6ACAF;
}
.btn__regular-icon:disabled {
  background-color: #EAEAEA;
  opacity: 1 !important;
  border: none !important;
}
.btn__regular-icon:disabled svg {
  fill: #A6ACAF;
}

.btn__table {
  width: 93px !important;
}

.btn__secondary-icon {
  background-color: #103783;
  color: white;
}

.btn__green-icon {
  background-color: #bfdecc;
  border-radius: 0.25em;
  width: auto;
}

.btn__red-icon {
  background-color: #ffe9ea;
  border-radius: 0.25em;
  width: auto;
}

.btn__send-email {
  background-color: rgba(176, 243, 183, 0.6);
  color: #007235;
  border: none;
}

.btn__stretched-icon {
  background-color: #00A3D8;
  border-radius: 0.25em;
  width: auto;
}
.btn__stretched-icon img {
  width: auto;
}
.btn__stretched-icon:hover {
  background-color: #E4F1FC;
  color: #103783;
}
.btn__stretched-icon:hover img {
  filter: brightness(0) saturate(100%) invert(14%) sepia(59%) saturate(3435%) hue-rotate(217deg) brightness(93%) contrast(97%);
}

.btn_padding {
  padding: 0 14px !important;
}

.btn__sm {
  height: 32px;
  width: 80px;
  font-size: 14px;
  padding: 6px 12px;
  line-height: 140%;
}

.btn__md {
  font-size: 12px;
  padding: 6px 12px;
  line-height: 150%;
}
@media screen and (min-width: 768px) {
  .btn__md {
    width: 60px;
    height: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .btn__md {
    width: 120px;
    height: 35px;
  }
}

.btn__primary {
  background-color: #103783;
  border: none;
}

.btn__primary:active {
  background-color: #103783;
}

.btn__primary_navy_light {
  border-radius: 8px;
  background-color: #F5F8FE;
  color: #00236F;
  border: none;
}
.btn__primary_navy_light:hover {
  background-color: #E8EFFF;
}

.btn__secondary {
  background-color: #00A3D8;
}

.btn__secondary_action {
  background-color: #00A3D8;
  color: #FFF;
  border: none;
}

.btn__tertiary {
  background-color: #5C71DE;
}

.btn_back_button {
  color: #103783;
  background-color: #E4F1FC;
  border-radius: 0.25em;
  border: none;
}

.btn__warning {
  background-color: #f3cfd1 !important;
  color: #ab2930 !important;
}

.btn__yes {
  background-color: rgba(176, 243, 183, 0.6) !important;
  color: #007235 !important;
  border: none !important;
}
.btn__yes:hover {
  background-color: rgba(176, 243, 183, 0.8) !important;
  color: #007235 !important;
  border: none !important;
}

.btn__roomoptions {
  background-color: #FFFFFF !important;
  color: #00236F !important;
  border: 1px solid #00236F;
  margin: 0 !important;
}
.btn__roomoptions:hover {
  background-color: #E8EFFF !important;
  border: 0.5px solid #00236F !important;
}

.btn__outlined {
  background-color: #FFFFFF !important;
  color: #00236F !important;
  border: 1px solid #00236F;
}
.btn__outlined:hover {
  background-color: #E8EFFF !important;
  border: 0.5px solid #00236F !important;
}

.btn__no {
  background-color: rgba(255, 218, 214, 0.6) !important;
  color: #A00101 !important;
  border: none !important;
}
.btn__no:hover {
  background-color: rgba(255, 218, 214, 0.8) !important;
  color: #A00101 !important;
  border: none !important;
}

.btn__green {
  background-color: #008843 !important;
  color: #FFF !important;
}

.btn__red {
  background-color: #AB2930;
}

.btn__green-confirm-icon {
  background-color: #CAE9D9;
  border: none;
}
.btn__green-confirm-icon svg {
  fill: #008843;
}

.btn__red-cancel-icon {
  background-color: #F3CFD1;
}
.btn__red-cancel-icon svg {
  fill: #AB2930;
}

.btn__green:hover,
.btn__regular-icon:hover,
.btn__warning:hover,
.btn_back_button:hover,
.btn__tertiary:hover,
.btn__secondary-icon:hover,
.btn__green-confirm-icon:hover,
.btn__red-cancel-icon:hover {
  opacity: 0.5;
}

.btn__green[disabled],
.btn__regular-icon[disabled],
.btn__warning[disabled],
.btn_back_button[disabled],
.btn__tertiary[disabled],
.btn__secondary_action[disabled],
.btn__secondary[disabled],
.btn__primary[disabled],
.btn__secondary-icon[disabled],
.btn__green-confirm-icon[disabled] {
  color: #555;
  background-color: #E9E9E9;
  opacity: 1;
  border: none !important;
  cursor: default;
}
.btn__green[disabled] svg,
.btn__regular-icon[disabled] svg,
.btn__warning[disabled] svg,
.btn_back_button[disabled] svg,
.btn__tertiary[disabled] svg,
.btn__secondary_action[disabled] svg,
.btn__secondary[disabled] svg,
.btn__primary[disabled] svg,
.btn__secondary-icon[disabled] svg,
.btn__green-confirm-icon[disabled] svg {
  fill: #555;
}

.btn__secondary_action:hover,
.btn__secondary:hover,
.btn__primary:hover {
  background-color: #E4F1FC;
  color: #103783;
}

.btn__fullWidth {
  width: 100%;
  width: -webkit-fill-available;
  width: stretch;
  max-width: unset !important;
}

.btn__minWidth {
  padding: 0 5px;
  max-width: unset !important;
  min-width: unset !important;
}

.btn__badge {
  position: relative;
}
.btn__badge--icon {
  width: 26px;
  height: 26px;
  display: block;
  border-radius: 50%;
  background-color: #F3CFD1;
  position: absolute;
  right: -13px;
  top: -13px;
}
.btn__badge--icon svg {
  height: 18px;
  width: 14px;
  fill: #AB2930;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn__badge .badge-info {
  background-color: #A7E2F6;
}
.btn__badge .badge-info svg {
  fill: #00236F;
}

.btn__badge_responsive {
  position: relative;
}
.btn__badge_responsive--icon {
  width: 26px;
  height: 26px;
  display: block;
  border-radius: 50%;
  background-color: #F3CFD1;
  position: absolute;
  right: -5px;
  top: -5px;
}
.btn__badge_responsive--icon svg {
  height: 18px;
  width: 14px;
  fill: #AB2930;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn__badge_warning {
  position: relative;
}
.btn__badge_warning--icon {
  width: 26px;
  height: 26px;
  display: block;
  border-radius: 50%;
  background-color: #FFC142;
  position: absolute;
  right: -13px;
  top: -13px;
}
.btn__badge_warning--icon svg {
  height: 18px;
  width: 14px;
  fill: #663904;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn__badge_responsive_warning {
  position: relative;
}
.btn__badge_responsive_warning--icon {
  width: 26px;
  height: 26px;
  display: block;
  border-radius: 50%;
  background-color: #FFC142;
  position: absolute;
  right: -8px;
  top: -5px;
}
.btn__badge_responsive_warning--icon svg {
  height: 18px;
  width: 14px;
  fill: #663904;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn__tool-tip {
  margin: 0;
  position: absolute;
  right: 13px;
  bottom: 19px;
}

::ng-deep .no-spinner ::-webkit-inner-spin-button,
.no-spinner ::-webkit-outer-spin-button {
  display: none;
}

/*FONTS DESKTOP*/
/*FONTS MOBILE*/
/*FONT BUTTON*/
/*FONT COLOR*/
/*PRIMARY COLOR*/
/*THEME PRIMARY*/
/*THEME SECONDARY*/
/*LIGHT THEME*/
/*THEME SUCCESS*/
/*THEME ERROR*/
/*MARGIN*/
/*PADDING-INTERNAL*/
/*INPUT*/
/*TABLE*/
/*NAVBAR*/
/*BUTTON-COLOR*/
/*BOOKING-STATUS*/
/*NAVIGATION-BAR*/
.bs-datepicker {
  border-radius: 3px;
}
.bs-datepicker .bs-datepicker-container {
  padding: 0;
}
.bs-datepicker .bs-datepicker-head {
  background-color: #103783 !important;
}
.bs-datepicker .bs-datepicker-body table td span.selected {
  background-color: #103783 !important;
}
.bs-datepicker .bs-datepicker-body table td.week span {
  color: #103783 !important;
  font-size: 16px !important;
}
.bs-datepicker .bs-datepicker-body table.days span {
  font-size: 16px;
}
.bs-datepicker .bs-datepicker-body table th {
  color: #000;
}
.bs-datepicker .bs-datepicker-body table td {
  color: #000;
}

/*FONTS DESKTOP*/
/*FONTS MOBILE*/
/*FONT BUTTON*/
/*FONT COLOR*/
/*PRIMARY COLOR*/
/*THEME PRIMARY*/
/*THEME SECONDARY*/
/*LIGHT THEME*/
/*THEME SUCCESS*/
/*THEME ERROR*/
/*MARGIN*/
/*PADDING-INTERNAL*/
/*INPUT*/
/*TABLE*/
/*NAVBAR*/
/*BUTTON-COLOR*/
/*BOOKING-STATUS*/
/*NAVIGATION-BAR*/
/*
* Theme / Colours
* Semantic naming for primary, secondary, tertiary, danger, info, success, warning
* Core colors - ESSENTIAL WEIGHTS
*/
/* Custom color utilities */
.color-primary {
  background-color: #00236F;
}

.color-primary-light {
  background-color: #F5F8FE;
}

.color-primary-aqua-10 {
  color: #00A2D7;
}

.color-tertiary {
  background-color: #BDEBFB;
}

.color-primary-light-text {
  color: #F5F8FE;
}

.color-primary-text {
  color: #FFFFFF;
}

.color-secondary {
  background-color: #E8EFFF;
}

.color-secondary-text {
  color: #00236F;
}

.color-warning {
  background-color: #CCA5FD;
}

.color-warning-text {
  color: #250569;
}

.color-warning-icon-light {
  color: #9C5400;
}

.color-warning-text-light {
  color: #9C5400;
}
.color-warning-text-light:hover {
  color: #663904 !important;
}
.color-warning-text-light:active {
  color: #663904 !important;
}
.color-warning-text-light:focus {
  color: #663904 !important;
}

.color-warning-secondary {
  background-color: #FCE3FF;
}

.color-warning-secondary-text {
  color: #930188;
}

.color-info {
  background-color: #FFE18E;
}

.color-info-light {
  background-color: #FFE1B5;
}
.color-info-light:hover {
  background-color: #FFE1B5;
}
.color-info-light:active {
  background-color: #FFE1B5 !important;
  border: none !important;
}
.color-info-light:focus {
  background-color: #FFE1B5 !important;
  border: none !important;
}
.color-info-light:disabled {
  background-color: #FFE1B5;
  opacity: 50%;
  border: none;
  color: #663904 !important;
}

.color-info-secondary {
  background-color: #FFC142;
}

.color-info-text {
  color: #663904;
}

.color-danger {
  background-color: #BA1A1A;
}

.color-danger-text {
  color: #410002;
}

.color-danger-secondary {
  background-color: #FF8080;
}

.color-danger-secondary-text {
  color: #861816;
}

.color-danger-tertiary {
  background-color: #F0DEE0;
}

.color-danger-tertiary-text {
  color: #BA1A1A;
}

.color-success-primary-text {
  color: #007235;
}

.color-success {
  background-color: #B0F3B7;
}

.color-success-secondary {
  background-color: #0EB551;
}

.color-success-text {
  color: #014420;
}

.color-brand {
  background-color: #0EA7DA;
}

.color-brand-secondary {
  background-color: #5FC6E8;
}

.color-neutral {
  background-color: #F5F7FB;
}

.color-neutral-tertiary {
  color: #919396;
}

.color-neutral-text {
  color: #FFFFFF;
  fill: #FFFFFF;
}

.color-outline {
  background-color: #E7E9ED;
}

.color-outline-text {
  color: #E7E9ED;
}

.border-neutral {
  border-color: #E7E9ED;
}

.color-error-primary {
  background-color: #B50E0E;
}

.color-error-primary-light {
  background-color: #ED6565;
}

.color-error-primary-light-text {
  color: #ED6565;
}

.color-error-primary-text {
  color: #B50E0E;
}

.color-error-secondary-light {
  background-color: #FFF5F4;
}

.color-error-secondary {
  background-color: #FFDAD6;
}

.color-error-tertiary {
  background-color: #FFE5E5;
}

.color-violet {
  background-color: #F0E4FF;
}

.color-violet-secondary {
  background-color: #9753EB;
}

.color-violet-text {
  color: #480670;
}

.color-mono-primary-text {
  color: #888B9C;
}

.color-mono-secondary-text {
  color: #23252B;
}

.color-mono-tertiary-text {
  color: #191A1D;
}

.color-navy {
  background-color: #C4D5FE;
}

/*
* Component bootstrap variables
*/
/* Accordion */
/* Input */
/* Button */
/* Form Control */
/* Table */
/* Card */
/* Date picker */
.min-w-40 {
  min-width: 40px !important;
}

.max-w-40 {
  max-width: 40px !important;
}

.min-h-40 {
  min-height: 40px !important;
}

.max-h-40 {
  max-height: 40px !important;
}

.min-w-48 {
  min-width: 48px !important;
}

.max-w-48 {
  max-width: 48px !important;
}

.min-h-48 {
  min-height: 48px !important;
}

.max-h-48 {
  max-height: 48px !important;
}

.min-w-75 {
  min-width: 75px !important;
}

.max-w-75 {
  max-width: 75px !important;
}

.min-h-75 {
  min-height: 75px !important;
}

.max-h-75 {
  max-height: 75px !important;
}

.min-w-80 {
  min-width: 80px !important;
}

.max-w-80 {
  max-width: 80px !important;
}

.min-h-80 {
  min-height: 80px !important;
}

.max-h-80 {
  max-height: 80px !important;
}

.min-w-96 {
  min-width: 96px !important;
}

.max-w-96 {
  max-width: 96px !important;
}

.min-h-96 {
  min-height: 96px !important;
}

.max-h-96 {
  max-height: 96px !important;
}

.min-w-100 {
  min-width: 100px !important;
}

.max-w-100 {
  max-width: 100px !important;
}

.min-h-100 {
  min-height: 100px !important;
}

.max-h-100 {
  max-height: 100px !important;
}

.min-w-112 {
  min-width: 112px !important;
}

.max-w-112 {
  max-width: 112px !important;
}

.min-h-112 {
  min-height: 112px !important;
}

.max-h-112 {
  max-height: 112px !important;
}

.min-w-120 {
  min-width: 120px !important;
}

.max-w-120 {
  max-width: 120px !important;
}

.min-h-120 {
  min-height: 120px !important;
}

.max-h-120 {
  max-height: 120px !important;
}

.min-w-130 {
  min-width: 130px !important;
}

.max-w-130 {
  max-width: 130px !important;
}

.min-h-130 {
  min-height: 130px !important;
}

.max-h-130 {
  max-height: 130px !important;
}

.min-w-140 {
  min-width: 140px !important;
}

.max-w-140 {
  max-width: 140px !important;
}

.min-h-140 {
  min-height: 140px !important;
}

.max-h-140 {
  max-height: 140px !important;
}

.min-w-170 {
  min-width: 170px !important;
}

.max-w-170 {
  max-width: 170px !important;
}

.min-h-170 {
  min-height: 170px !important;
}

.max-h-170 {
  max-height: 170px !important;
}

.min-w-180 {
  min-width: 180px !important;
}

.max-w-180 {
  max-width: 180px !important;
}

.min-h-180 {
  min-height: 180px !important;
}

.max-h-180 {
  max-height: 180px !important;
}

.min-w-265 {
  min-width: 265px !important;
}

.max-w-265 {
  max-width: 265px !important;
}

.min-h-265 {
  min-height: 265px !important;
}

.max-h-265 {
  max-height: 265px !important;
}

.min-w-320 {
  min-width: 320px !important;
}

.max-w-320 {
  max-width: 320px !important;
}

.min-h-320 {
  min-height: 320px !important;
}

.max-h-320 {
  max-height: 320px !important;
}

.min-w-400 {
  min-width: 400px !important;
}

.max-w-400 {
  max-width: 400px !important;
}

.min-h-400 {
  min-height: 400px !important;
}

.max-h-400 {
  max-height: 400px !important;
}

.min-w-410 {
  min-width: 410px !important;
}

.max-w-410 {
  max-width: 410px !important;
}

.min-h-410 {
  min-height: 410px !important;
}

.max-h-410 {
  max-height: 410px !important;
}

.min-w-560 {
  min-width: 560px !important;
}

.max-w-560 {
  max-width: 560px !important;
}

.min-h-560 {
  min-height: 560px !important;
}

.max-h-560 {
  max-height: 560px !important;
}

.min-w-620 {
  min-width: 620px !important;
}

.max-w-620 {
  max-width: 620px !important;
}

.min-h-620 {
  min-height: 620px !important;
}

.max-h-620 {
  max-height: 620px !important;
}

.max-wh-40 {
  max-width: 40px;
  max-height: 40px;
}

.max-h {
  max-height: 16px;
}

@media (min-width: 600px) {
  .max-h-sm-none {
    max-height: none !important;
  }
}

@media (min-width: 600px) {
  .max-h-sm-48 {
    max-height: 48px !important;
  }
}

.max-w-50 {
  max-width: 50% !important;
}

@media (min-width: 600px) {
  .min-w-sm-620 {
    min-width: 620px !important;
  }
}

@media (min-width: 600px) {
  .max-w-sm-70 {
    max-width: 70%;
  }
}

@media (min-width: 600px) {
  .max-sm-w-100 {
    max-width: 100% !important;
  }
}

@media (min-width: 600px) {
  .max-sm-w-30 {
    max-width: 30% !important;
  }
}

.min-vh-100 {
  min-height: 100vh;
}

@media (min-width: 600px) {
  .max-vh-sm-365 {
    max-height: calc(100vh - 365px) !important;
  }
}

@media (min-width: 600px) {
  .vw-sm-50 {
    width: 50vw !important;
  }
}

.w-304 {
  width: 19rem !important;
}

.w-128 {
  width: 8rem !important;
}

.w-120 {
  width: 120% !important;
}

.h-25 {
  height: 25px !important;
}

.h-32 {
  height: 32px !important;
}

.w-32 {
  width: 32px !important;
}

.h-612 {
  height: 612px;
}

.w-500 {
  width: 500px !important;
}

.w-600 {
  width: 600px;
}

.w-250 {
  width: 250px;
}

.w-240 {
  width: 240px;
}

.h-40 {
  height: 40px !important;
}

.max-h-40 {
  max-height: 40px !important;
}

.h-80 {
  height: 80px;
}

.h-100 {
  height: 100%;
}

.h-98 {
  height: 6.125rem;
}

.h-64 {
  height: 64px;
}

.h-45 {
  height: 45px !important;
}

.h-50vh {
  height: 50vh;
}

@media (min-width: 600px) {
  .h-sm-38 {
    height: 38px;
  }
}

@media (min-width: 600px) {
  .h-sm-36 {
    height: 2rem !important;
  }
}

@media (min-width: 600px) {
  .h-sm-80 {
    height: 80px !important;
  }
}

@media (min-width: 600px) {
  .h-sm-500 {
    height: 500px !important;
  }
}

.w-160 {
  width: 10rem !important;
}

.w-24 {
  width: 24px;
}

.w-80 {
  width: 80px;
}

.w-48 {
  width: 3rem !important;
}

.w-66 {
  width: 66px;
}

.w-60 {
  width: 60px;
}

.w-40 {
  width: 40px !important;
}

.max-content {
  width: max-content;
}

.lh-25 {
  line-height: 25px;
}

.lh-24 {
  line-height: 24px;
}

@media (min-width: 600px) {
  .lh-sm-24 {
    line-height: 24px !important;
  }
}

::ng-deep .button-icon-svg > span > div > svg {
  width: 24px !important;
  height: 1rem !important;
}

.lh-21 {
  line-height: 21px;
}

.lh-20 {
  line-height: 21px;
}

.lh-32 {
  line-height: 32px;
}

.lh-35 {
  line-height: 35px;
}

.lh-38 {
  line-height: 18px;
}

.lh-16 {
  line-height: 16px;
}

.lh-18 {
  line-height: 18px;
}

@media (min-width: 600px) {
  .lh-sm-38 {
    line-height: 38px;
  }
}

@media (min-width: 600px) {
  .pr-sm-0 {
    padding-right: 0px !important;
  }
}

.lh-30 {
  line-height: 30px;
}

.lh-40 {
  line-height: 40px;
}

.lh-45 {
  line-height: 45px;
}

@media (min-width: 600px) {
  .lh-sm-45 {
    line-height: 45px;
  }
}

@media (min-width: 600px) {
  .lh-sm-normal {
    line-height: normal;
  }
}

.lh-150 {
  line-height: 150%;
}

.ls-tight {
  letter-spacing: -0.5px;
}

.mx-n3 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.white-space-pre-line {
  white-space: pre-line;
}

.fw-medium {
  font-weight: 500;
}

@media (min-width: 600px) {
  .fw-sm-bold {
    font-weight: 700;
  }
}

.fw-semi-bold {
  font-weight: 600;
}

.flex-auto {
  flex: auto;
}

.flex-none {
  flex: none;
}

@media (min-width: 600px) {
  .flex-sm-none {
    flex: none !important;
  }
}

.flex-1 {
  flex: 1 1 0;
}

@media (min-width: 600px) {
  .flex-sm-no-grow {
    flex: 0 1 0 !important;
  }
}

@media (min-width: 600px) {
  .flex-sm-1 {
    flex: 1 1 0 !important;
  }
}

.flex-2 {
  flex: 2 1 0;
}

@media (min-width: 600px) {
  .flex-sm-2 {
    flex: 2 1 0 !important;
  }
}

.flex-3 {
  flex: 3 1 0;
}

@media (min-width: 600px) {
  .flex-sm-3 {
    flex: 3 1 0 !important;
  }
}

.flex-shrink-1 {
  flex: 1 1 0;
}

.flex-basis-0 {
  flex-basis: 0 !important;
}

@media (min-width: 600px) {
  .flex-basis-sm-0 {
    flex-basis: 0 !important;
  }
}

.flex-basis-48 {
  flex-basis: 48% !important;
}

@media (min-width: 600px) {
  .flex-basis-sm-48 {
    flex-basis: 48% !important;
  }
}

.justify-self-start {
  justify-self: start;
}

.vertical-align-sub {
  vertical-align: sub !important;
}

.text-align-right > input {
  text-align: right !important;
}

.row-gap-3 {
  row-gap: 1rem;
}

.column-gap-2 {
  column-gap: 0.5rem;
}

.cursor-pointer {
  cursor: pointer;
}

.text-align-right {
  text-align: right;
}

.float-right {
  float: right;
}

.indent-li {
  margin-left: 20px;
}

.border-radius-50 {
  border-radius: 50% !important;
}

.border-radius-16 {
  border-radius: 16px !important;
}

.col-md-3 {
  width: 25% !important;
}

.col-md-4 {
  width: 33.33333333% !important;
}

.border-none {
  border: none !important;
}

.position-relative {
  position: relative;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.fs-20 {
  font-size: 20px;
}

.invalid-feedback {
  display: block;
}

.w-0p {
  width: 0%;
}

@media (min-width: 600px) {
  .w-sm-0p {
    width: 0% !important;
  }
}

.w-max-0p {
  max-width: 0%;
}

@media (min-width: 600px) {
  .w-max-sm-0p {
    max-width: 0% !important;
  }
}

.w-min-0p {
  min-width: 0%;
}

@media (min-width: 600px) {
  .w-min-sm-0p {
    max-width: 0% !important;
  }
}

.w-5p {
  width: 5%;
}

@media (min-width: 600px) {
  .w-sm-5p {
    width: 5% !important;
  }
}

.w-max-5p {
  max-width: 5%;
}

@media (min-width: 600px) {
  .w-max-sm-5p {
    max-width: 5% !important;
  }
}

.w-min-5p {
  min-width: 5%;
}

@media (min-width: 600px) {
  .w-min-sm-5p {
    max-width: 5% !important;
  }
}

.w-10p {
  width: 10%;
}

@media (min-width: 600px) {
  .w-sm-10p {
    width: 10% !important;
  }
}

.w-max-10p {
  max-width: 10%;
}

@media (min-width: 600px) {
  .w-max-sm-10p {
    max-width: 10% !important;
  }
}

.w-min-10p {
  min-width: 10%;
}

@media (min-width: 600px) {
  .w-min-sm-10p {
    max-width: 10% !important;
  }
}

.w-15p {
  width: 15%;
}

@media (min-width: 600px) {
  .w-sm-15p {
    width: 15% !important;
  }
}

.w-max-15p {
  max-width: 15%;
}

@media (min-width: 600px) {
  .w-max-sm-15p {
    max-width: 15% !important;
  }
}

.w-min-15p {
  min-width: 15%;
}

@media (min-width: 600px) {
  .w-min-sm-15p {
    max-width: 15% !important;
  }
}

.w-18p {
  width: 18%;
}

@media (min-width: 600px) {
  .w-sm-18p {
    width: 18% !important;
  }
}

.w-max-18p {
  max-width: 18%;
}

@media (min-width: 600px) {
  .w-max-sm-18p {
    max-width: 18% !important;
  }
}

.w-min-18p {
  min-width: 18%;
}

@media (min-width: 600px) {
  .w-min-sm-18p {
    max-width: 18% !important;
  }
}

.w-20p {
  width: 20%;
}

@media (min-width: 600px) {
  .w-sm-20p {
    width: 20% !important;
  }
}

.w-max-20p {
  max-width: 20%;
}

@media (min-width: 600px) {
  .w-max-sm-20p {
    max-width: 20% !important;
  }
}

.w-min-20p {
  min-width: 20%;
}

@media (min-width: 600px) {
  .w-min-sm-20p {
    max-width: 20% !important;
  }
}

.w-22p {
  width: 23%;
}

@media (min-width: 600px) {
  .w-sm-22p {
    width: 23% !important;
  }
}

.w-max-22p {
  max-width: 23%;
}

@media (min-width: 600px) {
  .w-max-sm-22p {
    max-width: 23% !important;
  }
}

.w-min-22p {
  min-width: 23%;
}

@media (min-width: 600px) {
  .w-min-sm-22p {
    max-width: 23% !important;
  }
}

.w-25p {
  width: 25%;
}

@media (min-width: 600px) {
  .w-sm-25p {
    width: 25% !important;
  }
}

.w-max-25p {
  max-width: 25%;
}

@media (min-width: 600px) {
  .w-max-sm-25p {
    max-width: 25% !important;
  }
}

.w-min-25p {
  min-width: 25%;
}

@media (min-width: 600px) {
  .w-min-sm-25p {
    max-width: 25% !important;
  }
}

.w-30p {
  width: 30%;
}

@media (min-width: 600px) {
  .w-sm-30p {
    width: 30% !important;
  }
}

.w-max-30p {
  max-width: 30%;
}

@media (min-width: 600px) {
  .w-max-sm-30p {
    max-width: 30% !important;
  }
}

.w-min-30p {
  min-width: 30%;
}

@media (min-width: 600px) {
  .w-min-sm-30p {
    min-width: 30% !important;
  }
}

.w-35p {
  width: 35%;
}

@media (min-width: 600px) {
  .w-sm-35p {
    width: 35% !important;
  }
}

.w-max-35p {
  max-width: 35%;
}

@media (min-width: 600px) {
  .w-max-sm-35p {
    max-width: 35% !important;
  }
}

.w-min-35p {
  min-width: 35%;
}

@media (min-width: 600px) {
  .w-min-sm-35p {
    min-width: 35% !important;
  }
}

.w-40p {
  width: 40%;
}

@media (min-width: 600px) {
  .w-sm-40p {
    width: 40% !important;
  }
}

.w-max-40p {
  max-width: 40%;
}

@media (min-width: 600px) {
  .w-max-sm-40p {
    max-width: 40% !important;
  }
}

.w-min-40p {
  min-width: 40%;
}

@media (min-width: 600px) {
  .w-min-sm-40p {
    min-width: 40% !important;
  }
}

.w-45p {
  width: 45%;
}

@media (min-width: 600px) {
  .w-sm-45p {
    width: 45% !important;
  }
}

.w-max-45p {
  max-width: 45%;
}

@media (min-width: 600px) {
  .w-max-sm-45p {
    max-width: 45% !important;
  }
}

.w-min-45p {
  min-width: 45%;
}

@media (min-width: 600px) {
  .w-min-sm-45p {
    min-width: 45% !important;
  }
}

.w-48p {
  width: 48%;
}

@media (min-width: 600px) {
  .w-sm-48p {
    width: 48% !important;
  }
}

.w-max-48p {
  max-width: 48%;
}

@media (min-width: 600px) {
  .w-max-sm-48p {
    max-width: 48% !important;
  }
}

.w-min-48p {
  min-width: 45%;
}

@media (min-width: 600px) {
  .w-min-sm-48p {
    min-width: 45% !important;
  }
}

.w-50p {
  width: 50%;
}

@media (min-width: 600px) {
  .w-sm-50p {
    width: 50% !important;
  }
}

.w-max-50p {
  max-width: 50%;
}

@media (min-width: 600px) {
  .w-max-sm-50p {
    max-width: 50% !important;
  }
}

.w-min-50p {
  min-width: 50%;
}

@media (min-width: 600px) {
  .w-min-sm-50p {
    min-width: 50% !important;
  }
}

.w-55p {
  width: 55%;
}

@media (min-width: 600px) {
  .w-sm-55p {
    width: 55% !important;
  }
}

.w-max-55p {
  max-width: 55%;
}

@media (min-width: 600px) {
  .w-max-sm-55p {
    max-width: 55% !important;
  }
}

.w-min-55p {
  min-width: 55%;
}

@media (min-width: 600px) {
  .w-min-sm-55p {
    min-width: 55% !important;
  }
}

.w-60p {
  width: 60%;
}

@media (min-width: 600px) {
  .w-sm-60p {
    width: 60% !important;
  }
}

.w-max-60p {
  max-width: 60%;
}

@media (min-width: 600px) {
  .w-max-sm-60p {
    max-width: 60% !important;
  }
}

.w-min-60p {
  min-width: 60%;
}

@media (min-width: 600px) {
  .w-min-sm-60p {
    min-width: 60% !important;
  }
}

.w-65p {
  width: 65%;
}

@media (min-width: 600px) {
  .w-sm-65p {
    width: 65% !important;
  }
}

.w-max-65p {
  max-width: 65%;
}

@media (min-width: 600px) {
  .w-max-sm-65p {
    max-width: 65% !important;
  }
}

.w-min-65p {
  min-width: 65%;
}

@media (min-width: 600px) {
  .w-min-sm-65p {
    min-width: 65% !important;
  }
}

.w-70p {
  width: 70%;
}

@media (min-width: 600px) {
  .w-sm-70p {
    width: 70% !important;
  }
}

.w-max-70p {
  max-width: 70%;
}

@media (min-width: 600px) {
  .w-max-sm-70p {
    max-width: 70% !important;
  }
}

.w-min-70p {
  min-width: 70%;
}

@media (min-width: 600px) {
  .w-min-sm-70p {
    min-width: 70% !important;
  }
}

.w-75p {
  width: 75%;
}

@media (min-width: 600px) {
  .w-sm-75p {
    width: 75% !important;
  }
}

.w-max-75p {
  max-width: 75%;
}

@media (min-width: 600px) {
  .w-max-sm-75p {
    max-width: 75% !important;
  }
}

.w-min-75p {
  min-width: 75%;
}

@media (min-width: 600px) {
  .w-min-sm-75p {
    min-width: 75% !important;
  }
}

.w-80p {
  width: 80%;
}

@media (min-width: 600px) {
  .w-sm-80p {
    width: 80% !important;
  }
}

.w-max-80p {
  max-width: 80%;
}

@media (min-width: 600px) {
  .w-max-sm-80p {
    max-width: 80% !important;
  }
}

.w-min-80p {
  min-width: 80%;
}

@media (min-width: 600px) {
  .w-min-sm-80p {
    min-width: 80% !important;
  }
}

.w-85p {
  width: 85%;
}

@media (min-width: 600px) {
  .w-sm-85p {
    width: 85% !important;
  }
}

.w-max-85p {
  max-width: 85%;
}

@media (min-width: 600px) {
  .w-max-sm-85p {
    max-width: 85% !important;
  }
}

.w-min-85p {
  min-width: 85%;
}

@media (min-width: 600px) {
  .w-min-sm-85p {
    min-width: 85% !important;
  }
}

.w-90p {
  width: 90%;
}

@media (min-width: 600px) {
  .w-sm-90p {
    width: 90% !important;
  }
}

.w-max-90p {
  max-width: 90%;
}

@media (min-width: 600px) {
  .w-max-sm-90p {
    max-width: 90% !important;
  }
}

.w-min-90p {
  min-width: 90%;
}

@media (min-width: 600px) {
  .w-min-sm-90p {
    min-width: 90% !important;
  }
}

.w-95p {
  width: 95%;
}

@media (min-width: 600px) {
  .w-sm-95p {
    width: 95% !important;
  }
}

.w-max-95p {
  max-width: 95%;
}

@media (min-width: 600px) {
  .w-max-sm-95p {
    max-width: 95% !important;
  }
}

.w-min-95p {
  min-width: 95%;
}

@media (min-width: 600px) {
  .w-min-sm-95p {
    min-width: 95% !important;
  }
}

.w-100p {
  width: 100%;
}

@media (min-width: 600px) {
  .w-sm-100p {
    width: 100% !important;
  }
}

.w-max-100p {
  max-width: 100%;
}

@media (min-width: 600px) {
  .w-max-sm-100p {
    max-width: 100% !important;
  }
}

.w-min-100p {
  min-width: 100%;
}

@media (min-width: 600px) {
  .w-min-sm-100p {
    min-width: 100% !important;
  }
}

.h-0p {
  height: 0%;
}

@media (min-width: 600px) {
  .h-sm-0p {
    height: 0% !important;
  }
}

.h-max-0p {
  max-height: 0%;
}

@media (min-width: 600px) {
  .h-max-sm-0p {
    max-height: 0% !important;
  }
}

.h-min-0p {
  min-height: 0%;
}

@media (min-width: 600px) {
  .h-min-sm-0p {
    min-height: 0% !important;
  }
}

.h-5p {
  height: 5%;
}

@media (min-width: 600px) {
  .h-sm-5p {
    height: 5% !important;
  }
}

.h-max-5p {
  max-height: 5%;
}

@media (min-width: 600px) {
  .h-max-sm-5p {
    max-height: 5% !important;
  }
}

.h-min-5p {
  min-height: 5%;
}

@media (min-width: 600px) {
  .h-min-sm-5p {
    min-height: 5% !important;
  }
}

.h-10p {
  height: 10%;
}

@media (min-width: 600px) {
  .h-sm-10p {
    height: 10% !important;
  }
}

.h-max-10p {
  max-height: 10%;
}

@media (min-width: 600px) {
  .h-max-sm-10p {
    max-height: 10% !important;
  }
}

.h-min-10p {
  min-height: 10%;
}

@media (min-width: 600px) {
  .h-min-sm-10p {
    min-height: 10% !important;
  }
}

.h-15p {
  height: 15%;
}

@media (min-width: 600px) {
  .h-sm-15p {
    height: 15% !important;
  }
}

.h-max-15p {
  max-height: 15%;
}

@media (min-width: 600px) {
  .h-max-sm-15p {
    max-height: 15% !important;
  }
}

.h-min-15p {
  min-height: 15%;
}

@media (min-width: 600px) {
  .h-min-sm-15p {
    min-height: 15% !important;
  }
}

.h-20p {
  height: 20%;
}

@media (min-width: 600px) {
  .h-sm-20p {
    height: 20% !important;
  }
}

.h-max-20p {
  max-height: 20%;
}

@media (min-width: 600px) {
  .h-max-sm-20p {
    max-height: 20% !important;
  }
}

.h-min-20p {
  min-height: 20%;
}

@media (min-width: 600px) {
  .h-min-sm-20p {
    min-height: 20% !important;
  }
}

.h-25p {
  height: 25%;
}

@media (min-width: 600px) {
  .h-sm-25p {
    height: 25% !important;
  }
}

.h-max-25p {
  max-height: 25%;
}

@media (min-width: 600px) {
  .h-max-sm-25p {
    max-height: 25% !important;
  }
}

.h-min-25p {
  min-height: 25%;
}

@media (min-width: 600px) {
  .h-min-sm-25p {
    min-height: 25% !important;
  }
}

.h-30p {
  height: 30%;
}

@media (min-width: 600px) {
  .h-sm-30p {
    height: 30% !important;
  }
}

.h-max-30p {
  max-height: 30%;
}

@media (min-width: 600px) {
  .h-max-sm-30p {
    max-height: 30% !important;
  }
}

.h-min-30p {
  min-height: 30%;
}

@media (min-width: 600px) {
  .h-min-sm-30p {
    min-height: 30% !important;
  }
}

.h-35p {
  height: 35%;
}

@media (min-width: 600px) {
  .h-sm-35p {
    height: 35% !important;
  }
}

.h-max-35p {
  max-height: 35%;
}

@media (min-width: 600px) {
  .h-max-sm-35p {
    max-height: 35% !important;
  }
}

.h-min-35p {
  min-height: 35%;
}

@media (min-width: 600px) {
  .h-min-sm-35p {
    min-height: 35% !important;
  }
}

.h-40p {
  height: 40%;
}

@media (min-width: 600px) {
  .h-sm-40p {
    height: 40% !important;
  }
}

.h-max-40p {
  max-height: 40%;
}

@media (min-width: 600px) {
  .h-max-sm-40p {
    max-height: 40% !important;
  }
}

.h-min-40p {
  min-height: 40%;
}

@media (min-width: 600px) {
  .h-min-sm-40p {
    min-height: 40% !important;
  }
}

.h-45p {
  height: 45%;
}

@media (min-width: 600px) {
  .h-sm-45p {
    height: 45% !important;
  }
}

.h-max-45p {
  max-height: 45%;
}

@media (min-width: 600px) {
  .h-max-sm-45p {
    max-height: 45% !important;
  }
}

.h-min-45p {
  min-height: 45%;
}

@media (min-width: 600px) {
  .h-min-sm-45p {
    min-height: 45% !important;
  }
}

.h-50p {
  height: 50%;
}

@media (min-width: 600px) {
  .h-sm-50p {
    height: 50% !important;
  }
}

.h-max-50p {
  max-height: 50%;
}

@media (min-width: 600px) {
  .h-max-sm-50p {
    max-height: 50% !important;
  }
}

.h-min-50p {
  min-height: 50%;
}

@media (min-width: 600px) {
  .h-min-sm-50p {
    min-height: 50% !important;
  }
}

.h-55p {
  height: 55%;
}

@media (min-width: 600px) {
  .h-sm-55p {
    height: 55% !important;
  }
}

.h-max-55p {
  max-height: 55%;
}

@media (min-width: 600px) {
  .h-max-sm-55p {
    max-height: 55% !important;
  }
}

.h-min-55p {
  min-height: 55%;
}

@media (min-width: 600px) {
  .h-min-sm-55p {
    min-height: 55% !important;
  }
}

.h-60p {
  height: 60%;
}

@media (min-width: 600px) {
  .h-sm-60p {
    height: 60% !important;
  }
}

.h-max-60p {
  max-height: 60%;
}

@media (min-width: 600px) {
  .h-max-sm-60p {
    max-height: 60% !important;
  }
}

.h-min-60p {
  min-height: 60%;
}

@media (min-width: 600px) {
  .h-min-sm-60p {
    min-height: 60% !important;
  }
}

.h-65p {
  height: 65%;
}

@media (min-width: 600px) {
  .h-sm-65p {
    height: 65% !important;
  }
}

.h-max-65p {
  max-height: 65%;
}

@media (min-width: 600px) {
  .h-max-sm-65p {
    max-height: 65% !important;
  }
}

.h-min-65p {
  min-height: 65%;
}

@media (min-width: 600px) {
  .h-min-sm-65p {
    min-height: 65% !important;
  }
}

.h-70p {
  height: 70%;
}

@media (min-width: 600px) {
  .h-sm-70p {
    height: 70% !important;
  }
}

.h-max-70p {
  max-height: 70%;
}

@media (min-width: 600px) {
  .h-max-sm-70p {
    max-height: 70% !important;
  }
}

.h-min-70p {
  min-height: 70%;
}

@media (min-width: 600px) {
  .h-min-sm-70p {
    min-height: 70% !important;
  }
}

.h-75p {
  height: 75%;
}

@media (min-width: 600px) {
  .h-sm-75p {
    height: 75% !important;
  }
}

.h-max-75p {
  max-height: 75%;
}

@media (min-width: 600px) {
  .h-max-sm-75p {
    max-height: 75% !important;
  }
}

.h-min-75p {
  min-height: 75%;
}

@media (min-width: 600px) {
  .h-min-sm-75p {
    min-height: 75% !important;
  }
}

.h-80p {
  height: 80%;
}

@media (min-width: 600px) {
  .h-sm-80p {
    height: 80% !important;
  }
}

.h-max-80p {
  max-height: 80%;
}

@media (min-width: 600px) {
  .h-max-sm-80p {
    max-height: 80% !important;
  }
}

.h-min-80p {
  min-height: 80%;
}

@media (min-width: 600px) {
  .h-min-sm-80p {
    min-height: 80% !important;
  }
}

.h-85p {
  height: 85%;
}

@media (min-width: 600px) {
  .h-sm-85p {
    height: 85% !important;
  }
}

.h-max-85p {
  max-height: 85%;
}

@media (min-width: 600px) {
  .h-max-sm-85p {
    max-height: 85% !important;
  }
}

.h-min-85p {
  min-height: 85%;
}

@media (min-width: 600px) {
  .h-min-sm-85p {
    min-height: 85% !important;
  }
}

.h-90p {
  height: 90%;
}

@media (min-width: 600px) {
  .h-sm-90p {
    height: 90% !important;
  }
}

.h-max-90p {
  max-height: 90%;
}

@media (min-width: 600px) {
  .h-max-sm-90p {
    max-height: 90% !important;
  }
}

.h-min-90p {
  min-height: 90%;
}

@media (min-width: 600px) {
  .h-min-sm-90p {
    min-height: 90% !important;
  }
}

.h-95p {
  height: 95%;
}

@media (min-width: 600px) {
  .h-sm-95p {
    height: 95% !important;
  }
}

.h-max-95p {
  max-height: 95%;
}

@media (min-width: 600px) {
  .h-max-sm-95p {
    max-height: 95% !important;
  }
}

.h-min-95p {
  min-height: 95%;
}

@media (min-width: 600px) {
  .h-min-sm-95p {
    min-height: 95% !important;
  }
}

.h-100p {
  height: 100%;
}

@media (min-width: 600px) {
  .h-sm-100p {
    height: 100% !important;
  }
}

.h-max-100p {
  max-height: 100%;
}

@media (min-width: 600px) {
  .h-max-sm-100p {
    max-height: 100% !important;
  }
}

.h-min-100p {
  min-height: 100%;
}

@media (min-width: 600px) {
  .h-min-sm-100p {
    min-height: 100% !important;
  }
}

.min-w-120p {
  min-width: 7.5rem !important;
}

@media (min-width: 600px) {
  .w-max-sm-120p {
    max-width: 7.5rem !important;
  }
}

@media (min-width: 600px) {
  .w-min-sm-120p {
    min-width: 7.5rem !important;
  }
}

.w-150p {
  width: 9.375rem !important;
}

@media (min-width: 600px) {
  .w-max-sm-150p {
    max-width: 9.375rem !important;
  }
}

@media (min-width: 600px) {
  .w-min-sm-150p {
    min-width: 9.375rem !important;
  }
}

.w-200p {
  width: 12.5rem !important;
}

.w-max-200p {
  max-width: 12.5rem !important;
}

@media (min-width: 600px) {
  .w-max-sm-200p {
    max-width: 12.5rem !important;
  }
}

@media (min-width: 600px) {
  .w-min-sm-200p {
    min-width: 12.5rem !important;
  }
}

.w-360p {
  width: 22.5rem !important;
}

@media (min-width: 600px) {
  .w-max-sm-360 {
    max-width: 22.5rem !important;
  }
}

@media (min-width: 600px) {
  .w-min-sm-360 {
    min-width: 22.5rem !important;
  }
}

.h-48 {
  height: 3rem;
}

.max-h-48 {
  max-height: 48px;
}

@media (min-width: 600px) {
  .h-sm-48 {
    height: 3rem !important;
  }
}

@media (min-width: 600px) {
  .max-h-sm-48 {
    max-height: 48px !important;
  }
}

.no-wrap {
  white-space: nowrap !important;
}

@media (max-width: 599.98px) {
  .w-sm-auto {
    width: auto !important;
  }
}

@media (min-width: 600px) {
  .border-sm-bottom-0 {
    border-bottom: 0 !important;
  }
}

/*FONTS DESKTOP*/
/*FONTS MOBILE*/
/*FONT BUTTON*/
/*FONT COLOR*/
/*PRIMARY COLOR*/
/*THEME PRIMARY*/
/*THEME SECONDARY*/
/*LIGHT THEME*/
/*THEME SUCCESS*/
/*THEME ERROR*/
/*MARGIN*/
/*PADDING-INTERNAL*/
/*INPUT*/
/*TABLE*/
/*NAVBAR*/
/*BUTTON-COLOR*/
/*BOOKING-STATUS*/
/*NAVIGATION-BAR*/
@media screen and (min-width: 768px) {
  .typography__h1 {
    font-size: 18px;
    line-height: 24px/1, 2;
    font-width: 400;
  }
}
@media screen and (min-width: 1024px) {
  .typography__h1 {
    font-size: 52px;
    line-height: 60px;
    font-width: 700;
  }
}

@media screen and (min-width: 768px) {
  .typography__h2 {
    font-size: 16px;
    line-height: 22px/1, 2;
    font-weight: 400;
  }
}
@media screen and (min-width: 1024px) {
  .typography__h2 {
    font-size: 26px;
    line-height: 36px;
    font-weight: 600;
  }
}

.typography__h3 {
  font-weight: 600;
}
@media screen and (min-width: 768px) {
  .typography__h3 {
    font-size: 14px;
  }
}
@media screen and (min-width: 1024px) {
  .typography__h3 {
    font-size: 24px;
    line-height: 33px;
  }
}

.typography__h4 {
  line-height: 24px;
  font-weight: 600;
}
@media screen and (min-width: 768px) {
  .typography__h4 {
    font-size: 14px;
  }
}
@media screen and (min-width: 1024px) {
  .typography__h4 {
    font-size: 18px;
  }
}

.typography__p, .table__bordered_style td, .table__bordered_style th, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option, .dropdown-item, .dropdown-menu li a, .inputField__label {
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .typography__p, .table__bordered_style td, .table__bordered_style th, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option, .dropdown-item, .dropdown-menu li a, .inputField__label {
    font-size: 12px;
    line-height: 20px/1.2;
  }
}
@media screen and (min-width: 1024px) {
  .typography__p, .table__bordered_style td, .table__bordered_style th, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option, .dropdown-item, .dropdown-menu li a, .inputField__label {
    font-size: 16px;
    line-height: 24px;
  }
}

.error-field {
  color: #AB2930;
  font-size: 80%;
}

.inputField__label {
  font-weight: 300 !important;
}

.currencyTextAlign {
  text-align: end !important;
}

.currencyTextAlignRight {
  text-align: right !important;
}

.btn {
  border-radius: 0.25em;
  color: #FFF;
  font-size: 16px;
  margin: 0 12px 0 12px;
}
@media screen and (max-width: 1200px) {
  .btn {
    margin: 0 3px 0 3px;
  }
}

.btn-secondary {
  color: #00236F;
  background-color: #E8EFFF;
}
.btn-secondary:hover {
  color: #00236F;
}

.btn__regular {
  max-width: 200px !important;
}
@media screen and (min-width: 768px) {
  .btn__regular {
    min-width: 100px;
    min-height: 40px;
  }
}
@media screen and (min-width: 1200px) {
  .btn__regular {
    min-width: 120px;
    line-height: 150%;
  }
}

.btn__icon {
  border-radius: 0.25em;
  border: solid 0.071em;
  color: #FFF;
  height: 30px;
  width: 30px;
}
@media screen and (min-width: 1200px) {
  .btn__icon {
    height: 40px;
    width: 40px;
  }
}
.btn__icon img {
  height: 15px;
  width: 15px;
}
@media screen and (min-width: 1200px) {
  .btn__icon img {
    height: 20px;
    width: 20px;
  }
}
.btn__icon--round-always {
  border-radius: 50%;
  width: 2.2rem !important;
  height: 2.2rem !important;
  align-items: unset;
}
@media (min-width: 600px) {
  .btn__icon--round-desktop {
    border-radius: 50%;
    width: 2rem !important;
    height: 2rem !important;
    align-items: unset;
  }
}

.btn__regular-icon {
  background-color: #E4F1FC;
  border-radius: 0.25em;
  border: none !important;
}
.btn__regular-icon--active-green {
  background-color: #CAE9D9;
  border: none !important;
}
.btn__regular-icon--active-green svg {
  fill: #008843;
}
@media screen and (max-width: 1200px) {
  .btn__regular-icon--active-green svg {
    height: 13px;
  }
}
.btn__regular-icon--active-green:disabled {
  background-color: #EAEAEA;
  opacity: 1 !important;
  border: none !important;
}
.btn__regular-icon--active-green:disabled svg {
  fill: #A6ACAF;
}
@media screen and (max-width: 1200px) {
  .btn__regular-icon--active-green:disabled svg {
    height: 13px;
  }
}
.btn__regular-icon--active-green:hover {
  background-color: #CAE9D9 !important;
  border: none !important;
}
.btn__regular-icon--active-green:focus {
  background-color: #CAE9D9 !important;
  border: none !important;
}
.btn__regular-icon--active-green:active {
  border: none !important;
}
.btn__regular-icon--active-blue {
  background-color: #E4F1FC;
  border: none !important;
}
.btn__regular-icon--active-blue svg {
  fill: #103783;
}
@media screen and (max-width: 1200px) {
  .btn__regular-icon--active-blue svg {
    height: 13px;
  }
}
.btn__regular-icon--active-blue:disabled {
  background-color: #EAEAEA;
  opacity: 1 !important;
  border: none !important;
}
.btn__regular-icon--active-blue:disabled svg {
  fill: #A6ACAF;
}
@media screen and (max-width: 1200px) {
  .btn__regular-icon--active-blue:disabled svg {
    height: 13px;
  }
}
.btn__regular-icon--active-blue:hover {
  background-color: #E4F1FC !important;
  border: none !important;
}
.btn__regular-icon--active-blue:focus {
  background-color: #E4F1FC !important;
  border: none !important;
}
.btn__regular-icon--active-blue:active {
  border: none !important;
}
.btn__regular-icon--active-red {
  background-color: #F3CFD1;
}
.btn__regular-icon--active-red svg {
  fill: #AB2930;
}
.btn__regular-icon--active-red:disabled {
  background-color: #EAEAEA;
  opacity: 1 !important;
}
.btn__regular-icon--active-red:disabled svg {
  fill: #A6ACAF;
}
.btn__regular-icon:disabled {
  background-color: #EAEAEA;
  opacity: 1 !important;
  border: none !important;
}
.btn__regular-icon:disabled svg {
  fill: #A6ACAF;
}

.btn__table {
  width: 93px !important;
}

.btn__secondary-icon {
  background-color: #103783;
  color: white;
}

.btn__green-icon {
  background-color: #bfdecc;
  border-radius: 0.25em;
  width: auto;
}

.btn__red-icon {
  background-color: #ffe9ea;
  border-radius: 0.25em;
  width: auto;
}

.btn__send-email {
  background-color: rgba(176, 243, 183, 0.6);
  color: #007235;
  border: none;
}

.btn__stretched-icon {
  background-color: #00A3D8;
  border-radius: 0.25em;
  width: auto;
}
.btn__stretched-icon img {
  width: auto;
}
.btn__stretched-icon:hover {
  background-color: #E4F1FC;
  color: #103783;
}
.btn__stretched-icon:hover img {
  filter: brightness(0) saturate(100%) invert(14%) sepia(59%) saturate(3435%) hue-rotate(217deg) brightness(93%) contrast(97%);
}

.btn_padding {
  padding: 0 14px !important;
}

.btn__sm {
  height: 32px;
  width: 80px;
  font-size: 14px;
  padding: 6px 12px;
  line-height: 140%;
}

.btn__md {
  font-size: 12px;
  padding: 6px 12px;
  line-height: 150%;
}
@media screen and (min-width: 768px) {
  .btn__md {
    width: 60px;
    height: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .btn__md {
    width: 120px;
    height: 35px;
  }
}

.btn__primary {
  background-color: #103783;
  border: none;
}

.btn__primary:active {
  background-color: #103783;
}

.btn__primary_navy_light {
  border-radius: 8px;
  background-color: #F5F8FE;
  color: #00236F;
  border: none;
}
.btn__primary_navy_light:hover {
  background-color: #E8EFFF;
}

.btn__secondary {
  background-color: #00A3D8;
}

.btn__secondary_action {
  background-color: #00A3D8;
  color: #FFF;
  border: none;
}

.btn__tertiary {
  background-color: #5C71DE;
}

.btn_back_button {
  color: #103783;
  background-color: #E4F1FC;
  border-radius: 0.25em;
  border: none;
}

.btn__warning {
  background-color: #f3cfd1 !important;
  color: #ab2930 !important;
}

.btn__yes {
  background-color: rgba(176, 243, 183, 0.6) !important;
  color: #007235 !important;
  border: none !important;
}
.btn__yes:hover {
  background-color: rgba(176, 243, 183, 0.8) !important;
  color: #007235 !important;
  border: none !important;
}

.btn__roomoptions {
  background-color: #FFFFFF !important;
  color: #00236F !important;
  border: 1px solid #00236F;
  margin: 0 !important;
}
.btn__roomoptions:hover {
  background-color: #E8EFFF !important;
  border: 0.5px solid #00236F !important;
}

.btn__outlined {
  background-color: #FFFFFF !important;
  color: #00236F !important;
  border: 1px solid #00236F;
}
.btn__outlined:hover {
  background-color: #E8EFFF !important;
  border: 0.5px solid #00236F !important;
}

.btn__no {
  background-color: rgba(255, 218, 214, 0.6) !important;
  color: #A00101 !important;
  border: none !important;
}
.btn__no:hover {
  background-color: rgba(255, 218, 214, 0.8) !important;
  color: #A00101 !important;
  border: none !important;
}

.btn__green {
  background-color: #008843 !important;
  color: #FFF !important;
}

.btn__red {
  background-color: #AB2930;
}

.btn__green-confirm-icon {
  background-color: #CAE9D9;
  border: none;
}
.btn__green-confirm-icon svg {
  fill: #008843;
}

.btn__red-cancel-icon {
  background-color: #F3CFD1;
}
.btn__red-cancel-icon svg {
  fill: #AB2930;
}

.btn__green:hover,
.btn__regular-icon:hover,
.btn__warning:hover,
.btn_back_button:hover,
.btn__tertiary:hover,
.btn__secondary-icon:hover,
.btn__green-confirm-icon:hover,
.btn__red-cancel-icon:hover {
  opacity: 0.5;
}

.btn__green[disabled],
.btn__regular-icon[disabled],
.btn__warning[disabled],
.btn_back_button[disabled],
.btn__tertiary[disabled],
.btn__secondary_action[disabled],
.btn__secondary[disabled],
.btn__primary[disabled],
.btn__secondary-icon[disabled],
.btn__green-confirm-icon[disabled] {
  color: #555;
  background-color: #E9E9E9;
  opacity: 1;
  border: none !important;
  cursor: default;
}
.btn__green[disabled] svg,
.btn__regular-icon[disabled] svg,
.btn__warning[disabled] svg,
.btn_back_button[disabled] svg,
.btn__tertiary[disabled] svg,
.btn__secondary_action[disabled] svg,
.btn__secondary[disabled] svg,
.btn__primary[disabled] svg,
.btn__secondary-icon[disabled] svg,
.btn__green-confirm-icon[disabled] svg {
  fill: #555;
}

.btn__secondary_action:hover,
.btn__secondary:hover,
.btn__primary:hover {
  background-color: #E4F1FC;
  color: #103783;
}

.btn__fullWidth {
  width: 100%;
  width: -webkit-fill-available;
  width: stretch;
  max-width: unset !important;
}

.btn__minWidth {
  padding: 0 5px;
  max-width: unset !important;
  min-width: unset !important;
}

.btn__badge {
  position: relative;
}
.btn__badge--icon {
  width: 26px;
  height: 26px;
  display: block;
  border-radius: 50%;
  background-color: #F3CFD1;
  position: absolute;
  right: -13px;
  top: -13px;
}
.btn__badge--icon svg {
  height: 18px;
  width: 14px;
  fill: #AB2930;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn__badge .badge-info {
  background-color: #A7E2F6;
}
.btn__badge .badge-info svg {
  fill: #00236F;
}

.btn__badge_responsive {
  position: relative;
}
.btn__badge_responsive--icon {
  width: 26px;
  height: 26px;
  display: block;
  border-radius: 50%;
  background-color: #F3CFD1;
  position: absolute;
  right: -5px;
  top: -5px;
}
.btn__badge_responsive--icon svg {
  height: 18px;
  width: 14px;
  fill: #AB2930;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn__badge_warning {
  position: relative;
}
.btn__badge_warning--icon {
  width: 26px;
  height: 26px;
  display: block;
  border-radius: 50%;
  background-color: #FFC142;
  position: absolute;
  right: -13px;
  top: -13px;
}
.btn__badge_warning--icon svg {
  height: 18px;
  width: 14px;
  fill: #663904;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn__badge_responsive_warning {
  position: relative;
}
.btn__badge_responsive_warning--icon {
  width: 26px;
  height: 26px;
  display: block;
  border-radius: 50%;
  background-color: #FFC142;
  position: absolute;
  right: -8px;
  top: -5px;
}
.btn__badge_responsive_warning--icon svg {
  height: 18px;
  width: 14px;
  fill: #663904;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn__tool-tip {
  margin: 0;
  position: absolute;
  right: 13px;
  bottom: 19px;
}

::ng-deep .no-spinner ::-webkit-inner-spin-button,
.no-spinner ::-webkit-outer-spin-button {
  display: none;
}

label:not(.form-radio-label):not(.form-check-label):not(.label-ouside) {
  padding-top: 8px;
}

/* DROP DOWN STYLE*/
.dropdown-menu {
  box-shadow: 0 0 2px #a6adaf;
  border: none;
  position: absolute;
}
.dropdown-toggle::after {
  display: none;
}

.dropdown-item:hover {
  background-color: #00A3D8;
  cursor: pointer;
}

/*INPUT STYLE*/
.input,
input:not([type=radio]) {
  border-radius: 0.25em;
  width: 100%;
  border: 0.071em solid #E7E7ED;
  box-sizing: border-box;
  background-color: #fffcf9;
}
@media screen and (min-width: 768px) {
  .input,
input:not([type=radio]) {
    height: 30px !important;
  }
}
@media screen and (min-width: 1200px) {
  .input,
input:not([type=radio]) {
    height: 40px !important;
  }
}

@media screen and (min-width: 768px) {
  .input,
input:not([type=radio])::placeholder {
    font-size: 80%;
  }
}
@media screen and (min-width: 1200px) {
  .input,
input:not([type=radio])::placeholder {
    font-size: 14px;
  }
}

input:active {
  background-color: #FFF;
}

textarea:focus,
textarea:focus-visible,
input:focus,
input:focus-visible {
  outline: none;
  border: 0.051em solid #80ACFF !important;
}

input:focus-visible ~ .input-group-prepend > .input-group-text {
  outline: none;
  border: 0.07em solid #80ACFF !important;
  border-left: none !important;
}

/*INPUT GROUP STYLE*/
input {
  padding-left: 12px;
}
@media screen and (min-width: 768px) {
  input {
    font-size: 12px;
  }
}
@media screen and (min-width: 1200px) {
  input {
    font-size: 16px;
  }
}
input[readonly] {
  cursor: default;
}
input[disabled] {
  cursor: no-drop;
}

.input-group input {
  border-right: none !important;
  width: 70% !important;
}
@media screen and (min-width: 768px) {
  .input-group input {
    height: 30px;
    font-size: 12px;
  }
}
@media screen and (min-width: 1200px) {
  .input-group input {
    height: 40px;
    font-size: 16px;
  }
}
.input-group .input-group-prepend {
  width: 20%;
}
@media screen and (min-width: 768px) {
  .input-group .input-group-prepend {
    height: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .input-group .input-group-prepend {
    height: 40px;
  }
}
.input-group .input-group-text {
  background-color: #fffcf9;
  border-radius: 0 0.25em 0.25em 0;
  border: 0.071em solid #E7E7ED;
}
@media screen and (min-width: 768px) {
  .input-group .input-group-text {
    height: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .input-group .input-group-text {
    height: 40px;
  }
}
.input-group .input-group-text:active {
  background-color: #FFF;
}

.input-group-text {
  line-height: 1.94rem;
}

/*Checkbox STYLE*/
input[type=checkbox] {
  border: 1px solid #a6adaf !important;
}

/*NG-SELECT*/
ng-select {
  border: 0.071em #E7E7ED !important;
  border-radius: 0.25em !important;
}

.ng-select.ng-select-single .ng-select-container {
  background-color: #fffcf9;
}
@media screen and (min-width: 768px) {
  .ng-select.ng-select-single .ng-select-container {
    height: 30px !important;
    min-height: 30px !important;
  }
}
@media screen and (min-width: 1200px) {
  .ng-select.ng-select-single .ng-select-container {
    height: 40px !important;
  }
}

.ng-select .ng-select-container {
  position: initial !important;
}

@media screen and (min-width: 768px) {
  .ng-select .ng-select-container .ng-value-container .ng-input > input::placeholder {
    font-size: 80%;
  }
}
@media screen and (min-width: 1200px) {
  .ng-select .ng-select-container .ng-value-container .ng-input > input::placeholder {
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) {
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    font-size: 12px;
  }
}
@media screen and (min-width: 1200px) {
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    font-size: 16px;
  }
}

.ng-select input {
  height: 25px;
  margin-top: 2px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 0;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  border: none !important;
}

ng-select.required.ng-invalid.ng-touched .ng-select-container {
  border-color: #dc3545;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
  background-color: rgba(0, 163, 216, 0.2) !important;
}

textarea {
  resize: none;
  border-radius: 0.25em;
  width: 100%;
  border: 0.071em solid #E7E7ED;
  box-sizing: border-box;
  background-color: #fffcf9;
}

bs-datepicker-container {
  width: 278px;
}

/*FORM-CONTROL*/
.form-control:focus {
  box-shadow: none;
  border-color: #a6adaf;
}

.form-check-input[type=checkbox] {
  height: 18px !important;
  width: 18px !important;
  background-color: #FFF;
  margin-top: 5px;
}

.form-check-input:checked[type=checkbox] {
  background-color: #103783;
}

.form-check-label {
  font-weight: 600 !important;
}
.form-check-input[type=checkbox] ~ .form-check-label {
  padding-left: 0.5em;
}

.form-radio-input[type=radio] {
  position: absolute;
  opacity: 0;
}
.form-radio-input[type=radio] + .form-radio-label:before {
  width: 17px;
  height: 17px;
  content: "";
  background: #FFF;
  border-radius: 100%;
  border: 1px solid #9e9eb6;
  display: inline-block;
  position: relative;
  margin-right: 0.5em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
  top: 4px;
}
@media screen and (max-width: 1200px) {
  .form-radio-input[type=radio] + .form-radio-label:before {
    top: 2px;
  }
}
.form-radio-input[type=radio]:checked + .form-radio-label:before {
  box-shadow: inset 0 0 0 4px #103783;
  border: none;
}
.form-radio-input[type=radio]:focus + .form-radio-label:before {
  outline: none;
}
.form-radio-input[type=radio]:disabled:checked + .form-radio-label:before {
  box-shadow: inset 0 0 0 4px rgba(16, 55, 131, 0.6);
  border: none;
}
.form-radio-input[type=radio]:disabled + .form-radio-label:before {
  background: #fffcf9;
}
.form-radio-input[type=radio] + .form-radio-label:empty:before {
  margin-right: 0;
}

.form-radio-label {
  font-weight: 600 !important;
}
@media screen and (min-width: 768px) {
  .form-radio-label {
    font-size: 12px;
  }
}
@media screen and (min-width: 1200px) {
  .form-radio-label {
    font-size: 14px;
  }
}

.ng-invalid.ng-touched:not(form):not([formarrayname]):not(ng-select):not(.ng-valid):not([appdatepicker]):not(cui-input):not(div) {
  border: 1px solid #AB2930 !important;
}

input.ng-invalid.ng-touched ~ .input-group-prepend > button,
input.ng-invalid.ng-touched ~ .input-group-prepend > button {
  border: 1px solid #AB2930 !important;
  border-left: none !important;
}

input[appdatepicker].ng-invalid.ng-touched {
  border: 1px solid #AB2930 !important;
  border-right: none !important;
}

/*
* Angular material check box
*/
div.checkbox__material-container {
  position: relative;
}

input.checkbox__material {
  display: none;
}

.checkbox__material-container span.box {
  margin-right: 10px;
  position: relative;
  top: 2px;
}

input.checkbox__material + span.box:before,
input.checkbox__material + span.box:after {
  transition: all 0.3s;
}

input.checkbox__material + span.box:after {
  position: relative;
  left: 4px;
  top: 3px;
  margin-top: -5px;
  margin-left: -4px;
  display: inline-block;
  content: " ";
  width: 20px;
  height: 20px;
  border: 2px solid #666;
  border-radius: 2px;
  background-color: #fff;
  z-index: 1000;
}

input.checkbox__material:checked + span.box:after {
  background-color: #2196f3;
  border-color: #2196f3;
}

input.checkbox__material:checked + span.box:before {
  transform: rotate(45deg);
  position: absolute;
  left: 7px;
  top: 0;
  width: 6px;
  height: 13px;
  border-width: 2px;
  border-style: solid;
  border-top: 0;
  border-left: 0;
  border-color: #fff;
  content: "";
  z-index: 10000;
}

/*FONTS DESKTOP*/
/*FONTS MOBILE*/
/*FONT BUTTON*/
/*FONT COLOR*/
/*PRIMARY COLOR*/
/*THEME PRIMARY*/
/*THEME SECONDARY*/
/*LIGHT THEME*/
/*THEME SUCCESS*/
/*THEME ERROR*/
/*MARGIN*/
/*PADDING-INTERNAL*/
/*INPUT*/
/*TABLE*/
/*NAVBAR*/
/*BUTTON-COLOR*/
/*BOOKING-STATUS*/
/*NAVIGATION-BAR*/
.display__end {
  float: right;
}

.tab-content > .active {
  margin-top: 12px;
}

.margin__right {
  margin-right: 12px;
}

@media screen and (min-width: 768px) {
  .row {
    padding: 8px;
  }
}
@media screen and (min-width: 1200px) {
  .row {
    padding: 12px;
  }
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.m-top-12 {
  margin-top: 12px;
}

.m-bottom-12 {
  margin-bottom: 12px;
}

.m-left-12 {
  margin-left: 12px;
}

.m-left-8 {
  margin-left: 8px;
}

.m-right-12 {
  margin-right: 12px;
}

.p-top-12 {
  padding-top: 12px;
}

@media (min-width: 600px) {
  .p-sm-top-12 {
    padding-top: 12px;
  }
}

.p-bottom-12 {
  padding-bottom: 12px;
}

.p-left-12 {
  padding-left: 12px;
}

.p-right-12 {
  padding-right: 12px;
}

.bottom_layout_container {
  position: relative;
}

.bottom_layout_container_position {
  position: absolute;
  bottom: 0;
  left: 0;
}

.m-left-align {
  margin-left: 0 !important;
}

.left-align {
  padding-left: 0 !important;
}

.m-right-align {
  margin-right: 0 !important;
}

.right-align {
  padding-right: 0 !important;
}

.top-align {
  padding-top: 0 !important;
}

@media screen and (max-width: 1200px) {
  .searchField_alignment {
    padding-top: 4px !important;
  }
}

.text-right {
  text-align: right !important;
}

.gap-md {
  gap: 0.9rem;
}

.full-width {
  width: 100%;
}

/*
* Theme / Colours
* Semantic naming for primary, secondary, tertiary, danger, info, success, warning
* Core colors - ESSENTIAL WEIGHTS
*/
/* Custom color utilities */
.color-primary {
  background-color: #00236F;
}

.color-primary-light {
  background-color: #F5F8FE;
}

.color-primary-aqua-10 {
  color: #00A2D7;
}

.color-tertiary {
  background-color: #BDEBFB;
}

.color-primary-light-text {
  color: #F5F8FE;
}

.color-primary-text {
  color: #FFFFFF;
}

.color-secondary {
  background-color: #E8EFFF;
}

.color-secondary-text {
  color: #00236F;
}

.color-warning {
  background-color: #CCA5FD;
}

.color-warning-text {
  color: #250569;
}

.color-warning-icon-light {
  color: #9C5400;
}

.color-warning-text-light {
  color: #9C5400;
}
.color-warning-text-light:hover {
  color: #663904 !important;
}
.color-warning-text-light:active {
  color: #663904 !important;
}
.color-warning-text-light:focus {
  color: #663904 !important;
}

.color-warning-secondary {
  background-color: #FCE3FF;
}

.color-warning-secondary-text {
  color: #930188;
}

.color-info {
  background-color: #FFE18E;
}

.color-info-light {
  background-color: #FFE1B5;
}
.color-info-light:hover {
  background-color: #FFE1B5;
}
.color-info-light:active {
  background-color: #FFE1B5 !important;
  border: none !important;
}
.color-info-light:focus {
  background-color: #FFE1B5 !important;
  border: none !important;
}
.color-info-light:disabled {
  background-color: #FFE1B5;
  opacity: 50%;
  border: none;
  color: #663904 !important;
}

.color-info-secondary {
  background-color: #FFC142;
}

.color-info-text {
  color: #663904;
}

.color-danger {
  background-color: #BA1A1A;
}

.color-danger-text {
  color: #410002;
}

.color-danger-secondary {
  background-color: #FF8080;
}

.color-danger-secondary-text {
  color: #861816;
}

.color-danger-tertiary {
  background-color: #F0DEE0;
}

.color-danger-tertiary-text {
  color: #BA1A1A;
}

.color-success-primary-text {
  color: #007235;
}

.color-success {
  background-color: #B0F3B7;
}

.color-success-secondary {
  background-color: #0EB551;
}

.color-success-text {
  color: #014420;
}

.color-brand {
  background-color: #0EA7DA;
}

.color-brand-secondary {
  background-color: #5FC6E8;
}

.color-neutral {
  background-color: #F5F7FB;
}

.color-neutral-tertiary {
  color: #919396;
}

.color-neutral-text {
  color: #FFFFFF;
  fill: #FFFFFF;
}

.color-outline {
  background-color: #E7E9ED;
}

.color-outline-text {
  color: #E7E9ED;
}

.border-neutral {
  border-color: #E7E9ED;
}

.color-error-primary {
  background-color: #B50E0E;
}

.color-error-primary-light {
  background-color: #ED6565;
}

.color-error-primary-light-text {
  color: #ED6565;
}

.color-error-primary-text {
  color: #B50E0E;
}

.color-error-secondary-light {
  background-color: #FFF5F4;
}

.color-error-secondary {
  background-color: #FFDAD6;
}

.color-error-tertiary {
  background-color: #FFE5E5;
}

.color-violet {
  background-color: #F0E4FF;
}

.color-violet-secondary {
  background-color: #9753EB;
}

.color-violet-text {
  color: #480670;
}

.color-mono-primary-text {
  color: #888B9C;
}

.color-mono-secondary-text {
  color: #23252B;
}

.color-mono-tertiary-text {
  color: #191A1D;
}

.color-navy {
  background-color: #C4D5FE;
}

/*
* Component bootstrap variables
*/
/* Accordion */
/* Input */
/* Button */
/* Form Control */
/* Table */
/* Card */
/* Date picker */
.min-w-40 {
  min-width: 40px !important;
}

.max-w-40 {
  max-width: 40px !important;
}

.min-h-40 {
  min-height: 40px !important;
}

.max-h-40 {
  max-height: 40px !important;
}

.min-w-48 {
  min-width: 48px !important;
}

.max-w-48 {
  max-width: 48px !important;
}

.min-h-48 {
  min-height: 48px !important;
}

.max-h-48 {
  max-height: 48px !important;
}

.min-w-75 {
  min-width: 75px !important;
}

.max-w-75 {
  max-width: 75px !important;
}

.min-h-75 {
  min-height: 75px !important;
}

.max-h-75 {
  max-height: 75px !important;
}

.min-w-80 {
  min-width: 80px !important;
}

.max-w-80 {
  max-width: 80px !important;
}

.min-h-80 {
  min-height: 80px !important;
}

.max-h-80 {
  max-height: 80px !important;
}

.min-w-96 {
  min-width: 96px !important;
}

.max-w-96 {
  max-width: 96px !important;
}

.min-h-96 {
  min-height: 96px !important;
}

.max-h-96 {
  max-height: 96px !important;
}

.min-w-100 {
  min-width: 100px !important;
}

.max-w-100 {
  max-width: 100px !important;
}

.min-h-100 {
  min-height: 100px !important;
}

.max-h-100 {
  max-height: 100px !important;
}

.min-w-112 {
  min-width: 112px !important;
}

.max-w-112 {
  max-width: 112px !important;
}

.min-h-112 {
  min-height: 112px !important;
}

.max-h-112 {
  max-height: 112px !important;
}

.min-w-120 {
  min-width: 120px !important;
}

.max-w-120 {
  max-width: 120px !important;
}

.min-h-120 {
  min-height: 120px !important;
}

.max-h-120 {
  max-height: 120px !important;
}

.min-w-130 {
  min-width: 130px !important;
}

.max-w-130 {
  max-width: 130px !important;
}

.min-h-130 {
  min-height: 130px !important;
}

.max-h-130 {
  max-height: 130px !important;
}

.min-w-140 {
  min-width: 140px !important;
}

.max-w-140 {
  max-width: 140px !important;
}

.min-h-140 {
  min-height: 140px !important;
}

.max-h-140 {
  max-height: 140px !important;
}

.min-w-170 {
  min-width: 170px !important;
}

.max-w-170 {
  max-width: 170px !important;
}

.min-h-170 {
  min-height: 170px !important;
}

.max-h-170 {
  max-height: 170px !important;
}

.min-w-180 {
  min-width: 180px !important;
}

.max-w-180 {
  max-width: 180px !important;
}

.min-h-180 {
  min-height: 180px !important;
}

.max-h-180 {
  max-height: 180px !important;
}

.min-w-265 {
  min-width: 265px !important;
}

.max-w-265 {
  max-width: 265px !important;
}

.min-h-265 {
  min-height: 265px !important;
}

.max-h-265 {
  max-height: 265px !important;
}

.min-w-320 {
  min-width: 320px !important;
}

.max-w-320 {
  max-width: 320px !important;
}

.min-h-320 {
  min-height: 320px !important;
}

.max-h-320 {
  max-height: 320px !important;
}

.min-w-400 {
  min-width: 400px !important;
}

.max-w-400 {
  max-width: 400px !important;
}

.min-h-400 {
  min-height: 400px !important;
}

.max-h-400 {
  max-height: 400px !important;
}

.min-w-410 {
  min-width: 410px !important;
}

.max-w-410 {
  max-width: 410px !important;
}

.min-h-410 {
  min-height: 410px !important;
}

.max-h-410 {
  max-height: 410px !important;
}

.min-w-560 {
  min-width: 560px !important;
}

.max-w-560 {
  max-width: 560px !important;
}

.min-h-560 {
  min-height: 560px !important;
}

.max-h-560 {
  max-height: 560px !important;
}

.min-w-620 {
  min-width: 620px !important;
}

.max-w-620 {
  max-width: 620px !important;
}

.min-h-620 {
  min-height: 620px !important;
}

.max-h-620 {
  max-height: 620px !important;
}

.max-wh-40 {
  max-width: 40px;
  max-height: 40px;
}

.max-h {
  max-height: 16px;
}

@media (min-width: 600px) {
  .max-h-sm-none {
    max-height: none !important;
  }
}

@media (min-width: 600px) {
  .max-h-sm-48 {
    max-height: 48px !important;
  }
}

.max-w-50 {
  max-width: 50% !important;
}

@media (min-width: 600px) {
  .min-w-sm-620 {
    min-width: 620px !important;
  }
}

@media (min-width: 600px) {
  .max-w-sm-70 {
    max-width: 70%;
  }
}

@media (min-width: 600px) {
  .max-sm-w-100 {
    max-width: 100% !important;
  }
}

@media (min-width: 600px) {
  .max-sm-w-30 {
    max-width: 30% !important;
  }
}

.min-vh-100 {
  min-height: 100vh;
}

@media (min-width: 600px) {
  .max-vh-sm-365 {
    max-height: calc(100vh - 365px) !important;
  }
}

@media (min-width: 600px) {
  .vw-sm-50 {
    width: 50vw !important;
  }
}

.w-304 {
  width: 19rem !important;
}

.w-128 {
  width: 8rem !important;
}

.w-120 {
  width: 120% !important;
}

.h-25 {
  height: 25px !important;
}

.h-32 {
  height: 32px !important;
}

.w-32 {
  width: 32px !important;
}

.h-612 {
  height: 612px;
}

.w-500 {
  width: 500px !important;
}

.w-600 {
  width: 600px;
}

.w-250 {
  width: 250px;
}

.w-240 {
  width: 240px;
}

.h-40 {
  height: 40px !important;
}

.max-h-40 {
  max-height: 40px !important;
}

.h-80 {
  height: 80px;
}

.h-100 {
  height: 100%;
}

.h-98 {
  height: 6.125rem;
}

.h-64 {
  height: 64px;
}

.h-45 {
  height: 45px !important;
}

.h-50vh {
  height: 50vh;
}

@media (min-width: 600px) {
  .h-sm-38 {
    height: 38px;
  }
}

@media (min-width: 600px) {
  .h-sm-36 {
    height: 2rem !important;
  }
}

@media (min-width: 600px) {
  .h-sm-80 {
    height: 80px !important;
  }
}

@media (min-width: 600px) {
  .h-sm-500 {
    height: 500px !important;
  }
}

.w-160 {
  width: 10rem !important;
}

.w-24 {
  width: 24px;
}

.w-80 {
  width: 80px;
}

.w-48 {
  width: 3rem !important;
}

.w-66 {
  width: 66px;
}

.w-60 {
  width: 60px;
}

.w-40 {
  width: 40px !important;
}

.max-content {
  width: max-content;
}

.lh-25 {
  line-height: 25px;
}

.lh-24 {
  line-height: 24px;
}

@media (min-width: 600px) {
  .lh-sm-24 {
    line-height: 24px !important;
  }
}

::ng-deep .button-icon-svg > span > div > svg {
  width: 24px !important;
  height: 1rem !important;
}

.lh-21 {
  line-height: 21px;
}

.lh-20 {
  line-height: 21px;
}

.lh-32 {
  line-height: 32px;
}

.lh-35 {
  line-height: 35px;
}

.lh-38 {
  line-height: 18px;
}

.lh-16 {
  line-height: 16px;
}

.lh-18 {
  line-height: 18px;
}

@media (min-width: 600px) {
  .lh-sm-38 {
    line-height: 38px;
  }
}

@media (min-width: 600px) {
  .pr-sm-0 {
    padding-right: 0px !important;
  }
}

.lh-30 {
  line-height: 30px;
}

.lh-40 {
  line-height: 40px;
}

.lh-45 {
  line-height: 45px;
}

@media (min-width: 600px) {
  .lh-sm-45 {
    line-height: 45px;
  }
}

@media (min-width: 600px) {
  .lh-sm-normal {
    line-height: normal;
  }
}

.lh-150 {
  line-height: 150%;
}

.ls-tight {
  letter-spacing: -0.5px;
}

.mx-n3 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.white-space-pre-line {
  white-space: pre-line;
}

.fw-medium {
  font-weight: 500;
}

@media (min-width: 600px) {
  .fw-sm-bold {
    font-weight: 700;
  }
}

.fw-semi-bold {
  font-weight: 600;
}

.flex-auto {
  flex: auto;
}

.flex-none {
  flex: none;
}

@media (min-width: 600px) {
  .flex-sm-none {
    flex: none !important;
  }
}

.flex-1 {
  flex: 1 1 0;
}

@media (min-width: 600px) {
  .flex-sm-no-grow {
    flex: 0 1 0 !important;
  }
}

@media (min-width: 600px) {
  .flex-sm-1 {
    flex: 1 1 0 !important;
  }
}

.flex-2 {
  flex: 2 1 0;
}

@media (min-width: 600px) {
  .flex-sm-2 {
    flex: 2 1 0 !important;
  }
}

.flex-3 {
  flex: 3 1 0;
}

@media (min-width: 600px) {
  .flex-sm-3 {
    flex: 3 1 0 !important;
  }
}

.flex-shrink-1 {
  flex: 1 1 0;
}

.flex-basis-0 {
  flex-basis: 0 !important;
}

@media (min-width: 600px) {
  .flex-basis-sm-0 {
    flex-basis: 0 !important;
  }
}

.flex-basis-48 {
  flex-basis: 48% !important;
}

@media (min-width: 600px) {
  .flex-basis-sm-48 {
    flex-basis: 48% !important;
  }
}

.justify-self-start {
  justify-self: start;
}

.vertical-align-sub {
  vertical-align: sub !important;
}

.text-align-right > input {
  text-align: right !important;
}

.row-gap-3 {
  row-gap: 1rem;
}

.column-gap-2 {
  column-gap: 0.5rem;
}

.cursor-pointer {
  cursor: pointer;
}

.text-align-right {
  text-align: right;
}

.float-right {
  float: right;
}

.indent-li {
  margin-left: 20px;
}

.border-radius-50 {
  border-radius: 50% !important;
}

.border-radius-16 {
  border-radius: 16px !important;
}

.col-md-3 {
  width: 25% !important;
}

.col-md-4 {
  width: 33.33333333% !important;
}

.border-none {
  border: none !important;
}

.position-relative {
  position: relative;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.fs-20 {
  font-size: 20px;
}

.invalid-feedback {
  display: block;
}

.w-0p {
  width: 0%;
}

@media (min-width: 600px) {
  .w-sm-0p {
    width: 0% !important;
  }
}

.w-max-0p {
  max-width: 0%;
}

@media (min-width: 600px) {
  .w-max-sm-0p {
    max-width: 0% !important;
  }
}

.w-min-0p {
  min-width: 0%;
}

@media (min-width: 600px) {
  .w-min-sm-0p {
    max-width: 0% !important;
  }
}

.w-5p {
  width: 5%;
}

@media (min-width: 600px) {
  .w-sm-5p {
    width: 5% !important;
  }
}

.w-max-5p {
  max-width: 5%;
}

@media (min-width: 600px) {
  .w-max-sm-5p {
    max-width: 5% !important;
  }
}

.w-min-5p {
  min-width: 5%;
}

@media (min-width: 600px) {
  .w-min-sm-5p {
    max-width: 5% !important;
  }
}

.w-10p {
  width: 10%;
}

@media (min-width: 600px) {
  .w-sm-10p {
    width: 10% !important;
  }
}

.w-max-10p {
  max-width: 10%;
}

@media (min-width: 600px) {
  .w-max-sm-10p {
    max-width: 10% !important;
  }
}

.w-min-10p {
  min-width: 10%;
}

@media (min-width: 600px) {
  .w-min-sm-10p {
    max-width: 10% !important;
  }
}

.w-15p {
  width: 15%;
}

@media (min-width: 600px) {
  .w-sm-15p {
    width: 15% !important;
  }
}

.w-max-15p {
  max-width: 15%;
}

@media (min-width: 600px) {
  .w-max-sm-15p {
    max-width: 15% !important;
  }
}

.w-min-15p {
  min-width: 15%;
}

@media (min-width: 600px) {
  .w-min-sm-15p {
    max-width: 15% !important;
  }
}

.w-18p {
  width: 18%;
}

@media (min-width: 600px) {
  .w-sm-18p {
    width: 18% !important;
  }
}

.w-max-18p {
  max-width: 18%;
}

@media (min-width: 600px) {
  .w-max-sm-18p {
    max-width: 18% !important;
  }
}

.w-min-18p {
  min-width: 18%;
}

@media (min-width: 600px) {
  .w-min-sm-18p {
    max-width: 18% !important;
  }
}

.w-20p {
  width: 20%;
}

@media (min-width: 600px) {
  .w-sm-20p {
    width: 20% !important;
  }
}

.w-max-20p {
  max-width: 20%;
}

@media (min-width: 600px) {
  .w-max-sm-20p {
    max-width: 20% !important;
  }
}

.w-min-20p {
  min-width: 20%;
}

@media (min-width: 600px) {
  .w-min-sm-20p {
    max-width: 20% !important;
  }
}

.w-22p {
  width: 23%;
}

@media (min-width: 600px) {
  .w-sm-22p {
    width: 23% !important;
  }
}

.w-max-22p {
  max-width: 23%;
}

@media (min-width: 600px) {
  .w-max-sm-22p {
    max-width: 23% !important;
  }
}

.w-min-22p {
  min-width: 23%;
}

@media (min-width: 600px) {
  .w-min-sm-22p {
    max-width: 23% !important;
  }
}

.w-25p {
  width: 25%;
}

@media (min-width: 600px) {
  .w-sm-25p {
    width: 25% !important;
  }
}

.w-max-25p {
  max-width: 25%;
}

@media (min-width: 600px) {
  .w-max-sm-25p {
    max-width: 25% !important;
  }
}

.w-min-25p {
  min-width: 25%;
}

@media (min-width: 600px) {
  .w-min-sm-25p {
    max-width: 25% !important;
  }
}

.w-30p {
  width: 30%;
}

@media (min-width: 600px) {
  .w-sm-30p {
    width: 30% !important;
  }
}

.w-max-30p {
  max-width: 30%;
}

@media (min-width: 600px) {
  .w-max-sm-30p {
    max-width: 30% !important;
  }
}

.w-min-30p {
  min-width: 30%;
}

@media (min-width: 600px) {
  .w-min-sm-30p {
    min-width: 30% !important;
  }
}

.w-35p {
  width: 35%;
}

@media (min-width: 600px) {
  .w-sm-35p {
    width: 35% !important;
  }
}

.w-max-35p {
  max-width: 35%;
}

@media (min-width: 600px) {
  .w-max-sm-35p {
    max-width: 35% !important;
  }
}

.w-min-35p {
  min-width: 35%;
}

@media (min-width: 600px) {
  .w-min-sm-35p {
    min-width: 35% !important;
  }
}

.w-40p {
  width: 40%;
}

@media (min-width: 600px) {
  .w-sm-40p {
    width: 40% !important;
  }
}

.w-max-40p {
  max-width: 40%;
}

@media (min-width: 600px) {
  .w-max-sm-40p {
    max-width: 40% !important;
  }
}

.w-min-40p {
  min-width: 40%;
}

@media (min-width: 600px) {
  .w-min-sm-40p {
    min-width: 40% !important;
  }
}

.w-45p {
  width: 45%;
}

@media (min-width: 600px) {
  .w-sm-45p {
    width: 45% !important;
  }
}

.w-max-45p {
  max-width: 45%;
}

@media (min-width: 600px) {
  .w-max-sm-45p {
    max-width: 45% !important;
  }
}

.w-min-45p {
  min-width: 45%;
}

@media (min-width: 600px) {
  .w-min-sm-45p {
    min-width: 45% !important;
  }
}

.w-48p {
  width: 48%;
}

@media (min-width: 600px) {
  .w-sm-48p {
    width: 48% !important;
  }
}

.w-max-48p {
  max-width: 48%;
}

@media (min-width: 600px) {
  .w-max-sm-48p {
    max-width: 48% !important;
  }
}

.w-min-48p {
  min-width: 45%;
}

@media (min-width: 600px) {
  .w-min-sm-48p {
    min-width: 45% !important;
  }
}

.w-50p {
  width: 50%;
}

@media (min-width: 600px) {
  .w-sm-50p {
    width: 50% !important;
  }
}

.w-max-50p {
  max-width: 50%;
}

@media (min-width: 600px) {
  .w-max-sm-50p {
    max-width: 50% !important;
  }
}

.w-min-50p {
  min-width: 50%;
}

@media (min-width: 600px) {
  .w-min-sm-50p {
    min-width: 50% !important;
  }
}

.w-55p {
  width: 55%;
}

@media (min-width: 600px) {
  .w-sm-55p {
    width: 55% !important;
  }
}

.w-max-55p {
  max-width: 55%;
}

@media (min-width: 600px) {
  .w-max-sm-55p {
    max-width: 55% !important;
  }
}

.w-min-55p {
  min-width: 55%;
}

@media (min-width: 600px) {
  .w-min-sm-55p {
    min-width: 55% !important;
  }
}

.w-60p {
  width: 60%;
}

@media (min-width: 600px) {
  .w-sm-60p {
    width: 60% !important;
  }
}

.w-max-60p {
  max-width: 60%;
}

@media (min-width: 600px) {
  .w-max-sm-60p {
    max-width: 60% !important;
  }
}

.w-min-60p {
  min-width: 60%;
}

@media (min-width: 600px) {
  .w-min-sm-60p {
    min-width: 60% !important;
  }
}

.w-65p {
  width: 65%;
}

@media (min-width: 600px) {
  .w-sm-65p {
    width: 65% !important;
  }
}

.w-max-65p {
  max-width: 65%;
}

@media (min-width: 600px) {
  .w-max-sm-65p {
    max-width: 65% !important;
  }
}

.w-min-65p {
  min-width: 65%;
}

@media (min-width: 600px) {
  .w-min-sm-65p {
    min-width: 65% !important;
  }
}

.w-70p {
  width: 70%;
}

@media (min-width: 600px) {
  .w-sm-70p {
    width: 70% !important;
  }
}

.w-max-70p {
  max-width: 70%;
}

@media (min-width: 600px) {
  .w-max-sm-70p {
    max-width: 70% !important;
  }
}

.w-min-70p {
  min-width: 70%;
}

@media (min-width: 600px) {
  .w-min-sm-70p {
    min-width: 70% !important;
  }
}

.w-75p {
  width: 75%;
}

@media (min-width: 600px) {
  .w-sm-75p {
    width: 75% !important;
  }
}

.w-max-75p {
  max-width: 75%;
}

@media (min-width: 600px) {
  .w-max-sm-75p {
    max-width: 75% !important;
  }
}

.w-min-75p {
  min-width: 75%;
}

@media (min-width: 600px) {
  .w-min-sm-75p {
    min-width: 75% !important;
  }
}

.w-80p {
  width: 80%;
}

@media (min-width: 600px) {
  .w-sm-80p {
    width: 80% !important;
  }
}

.w-max-80p {
  max-width: 80%;
}

@media (min-width: 600px) {
  .w-max-sm-80p {
    max-width: 80% !important;
  }
}

.w-min-80p {
  min-width: 80%;
}

@media (min-width: 600px) {
  .w-min-sm-80p {
    min-width: 80% !important;
  }
}

.w-85p {
  width: 85%;
}

@media (min-width: 600px) {
  .w-sm-85p {
    width: 85% !important;
  }
}

.w-max-85p {
  max-width: 85%;
}

@media (min-width: 600px) {
  .w-max-sm-85p {
    max-width: 85% !important;
  }
}

.w-min-85p {
  min-width: 85%;
}

@media (min-width: 600px) {
  .w-min-sm-85p {
    min-width: 85% !important;
  }
}

.w-90p {
  width: 90%;
}

@media (min-width: 600px) {
  .w-sm-90p {
    width: 90% !important;
  }
}

.w-max-90p {
  max-width: 90%;
}

@media (min-width: 600px) {
  .w-max-sm-90p {
    max-width: 90% !important;
  }
}

.w-min-90p {
  min-width: 90%;
}

@media (min-width: 600px) {
  .w-min-sm-90p {
    min-width: 90% !important;
  }
}

.w-95p {
  width: 95%;
}

@media (min-width: 600px) {
  .w-sm-95p {
    width: 95% !important;
  }
}

.w-max-95p {
  max-width: 95%;
}

@media (min-width: 600px) {
  .w-max-sm-95p {
    max-width: 95% !important;
  }
}

.w-min-95p {
  min-width: 95%;
}

@media (min-width: 600px) {
  .w-min-sm-95p {
    min-width: 95% !important;
  }
}

.w-100p {
  width: 100%;
}

@media (min-width: 600px) {
  .w-sm-100p {
    width: 100% !important;
  }
}

.w-max-100p {
  max-width: 100%;
}

@media (min-width: 600px) {
  .w-max-sm-100p {
    max-width: 100% !important;
  }
}

.w-min-100p {
  min-width: 100%;
}

@media (min-width: 600px) {
  .w-min-sm-100p {
    min-width: 100% !important;
  }
}

.h-0p {
  height: 0%;
}

@media (min-width: 600px) {
  .h-sm-0p {
    height: 0% !important;
  }
}

.h-max-0p {
  max-height: 0%;
}

@media (min-width: 600px) {
  .h-max-sm-0p {
    max-height: 0% !important;
  }
}

.h-min-0p {
  min-height: 0%;
}

@media (min-width: 600px) {
  .h-min-sm-0p {
    min-height: 0% !important;
  }
}

.h-5p {
  height: 5%;
}

@media (min-width: 600px) {
  .h-sm-5p {
    height: 5% !important;
  }
}

.h-max-5p {
  max-height: 5%;
}

@media (min-width: 600px) {
  .h-max-sm-5p {
    max-height: 5% !important;
  }
}

.h-min-5p {
  min-height: 5%;
}

@media (min-width: 600px) {
  .h-min-sm-5p {
    min-height: 5% !important;
  }
}

.h-10p {
  height: 10%;
}

@media (min-width: 600px) {
  .h-sm-10p {
    height: 10% !important;
  }
}

.h-max-10p {
  max-height: 10%;
}

@media (min-width: 600px) {
  .h-max-sm-10p {
    max-height: 10% !important;
  }
}

.h-min-10p {
  min-height: 10%;
}

@media (min-width: 600px) {
  .h-min-sm-10p {
    min-height: 10% !important;
  }
}

.h-15p {
  height: 15%;
}

@media (min-width: 600px) {
  .h-sm-15p {
    height: 15% !important;
  }
}

.h-max-15p {
  max-height: 15%;
}

@media (min-width: 600px) {
  .h-max-sm-15p {
    max-height: 15% !important;
  }
}

.h-min-15p {
  min-height: 15%;
}

@media (min-width: 600px) {
  .h-min-sm-15p {
    min-height: 15% !important;
  }
}

.h-20p {
  height: 20%;
}

@media (min-width: 600px) {
  .h-sm-20p {
    height: 20% !important;
  }
}

.h-max-20p {
  max-height: 20%;
}

@media (min-width: 600px) {
  .h-max-sm-20p {
    max-height: 20% !important;
  }
}

.h-min-20p {
  min-height: 20%;
}

@media (min-width: 600px) {
  .h-min-sm-20p {
    min-height: 20% !important;
  }
}

.h-25p {
  height: 25%;
}

@media (min-width: 600px) {
  .h-sm-25p {
    height: 25% !important;
  }
}

.h-max-25p {
  max-height: 25%;
}

@media (min-width: 600px) {
  .h-max-sm-25p {
    max-height: 25% !important;
  }
}

.h-min-25p {
  min-height: 25%;
}

@media (min-width: 600px) {
  .h-min-sm-25p {
    min-height: 25% !important;
  }
}

.h-30p {
  height: 30%;
}

@media (min-width: 600px) {
  .h-sm-30p {
    height: 30% !important;
  }
}

.h-max-30p {
  max-height: 30%;
}

@media (min-width: 600px) {
  .h-max-sm-30p {
    max-height: 30% !important;
  }
}

.h-min-30p {
  min-height: 30%;
}

@media (min-width: 600px) {
  .h-min-sm-30p {
    min-height: 30% !important;
  }
}

.h-35p {
  height: 35%;
}

@media (min-width: 600px) {
  .h-sm-35p {
    height: 35% !important;
  }
}

.h-max-35p {
  max-height: 35%;
}

@media (min-width: 600px) {
  .h-max-sm-35p {
    max-height: 35% !important;
  }
}

.h-min-35p {
  min-height: 35%;
}

@media (min-width: 600px) {
  .h-min-sm-35p {
    min-height: 35% !important;
  }
}

.h-40p {
  height: 40%;
}

@media (min-width: 600px) {
  .h-sm-40p {
    height: 40% !important;
  }
}

.h-max-40p {
  max-height: 40%;
}

@media (min-width: 600px) {
  .h-max-sm-40p {
    max-height: 40% !important;
  }
}

.h-min-40p {
  min-height: 40%;
}

@media (min-width: 600px) {
  .h-min-sm-40p {
    min-height: 40% !important;
  }
}

.h-45p {
  height: 45%;
}

@media (min-width: 600px) {
  .h-sm-45p {
    height: 45% !important;
  }
}

.h-max-45p {
  max-height: 45%;
}

@media (min-width: 600px) {
  .h-max-sm-45p {
    max-height: 45% !important;
  }
}

.h-min-45p {
  min-height: 45%;
}

@media (min-width: 600px) {
  .h-min-sm-45p {
    min-height: 45% !important;
  }
}

.h-50p {
  height: 50%;
}

@media (min-width: 600px) {
  .h-sm-50p {
    height: 50% !important;
  }
}

.h-max-50p {
  max-height: 50%;
}

@media (min-width: 600px) {
  .h-max-sm-50p {
    max-height: 50% !important;
  }
}

.h-min-50p {
  min-height: 50%;
}

@media (min-width: 600px) {
  .h-min-sm-50p {
    min-height: 50% !important;
  }
}

.h-55p {
  height: 55%;
}

@media (min-width: 600px) {
  .h-sm-55p {
    height: 55% !important;
  }
}

.h-max-55p {
  max-height: 55%;
}

@media (min-width: 600px) {
  .h-max-sm-55p {
    max-height: 55% !important;
  }
}

.h-min-55p {
  min-height: 55%;
}

@media (min-width: 600px) {
  .h-min-sm-55p {
    min-height: 55% !important;
  }
}

.h-60p {
  height: 60%;
}

@media (min-width: 600px) {
  .h-sm-60p {
    height: 60% !important;
  }
}

.h-max-60p {
  max-height: 60%;
}

@media (min-width: 600px) {
  .h-max-sm-60p {
    max-height: 60% !important;
  }
}

.h-min-60p {
  min-height: 60%;
}

@media (min-width: 600px) {
  .h-min-sm-60p {
    min-height: 60% !important;
  }
}

.h-65p {
  height: 65%;
}

@media (min-width: 600px) {
  .h-sm-65p {
    height: 65% !important;
  }
}

.h-max-65p {
  max-height: 65%;
}

@media (min-width: 600px) {
  .h-max-sm-65p {
    max-height: 65% !important;
  }
}

.h-min-65p {
  min-height: 65%;
}

@media (min-width: 600px) {
  .h-min-sm-65p {
    min-height: 65% !important;
  }
}

.h-70p {
  height: 70%;
}

@media (min-width: 600px) {
  .h-sm-70p {
    height: 70% !important;
  }
}

.h-max-70p {
  max-height: 70%;
}

@media (min-width: 600px) {
  .h-max-sm-70p {
    max-height: 70% !important;
  }
}

.h-min-70p {
  min-height: 70%;
}

@media (min-width: 600px) {
  .h-min-sm-70p {
    min-height: 70% !important;
  }
}

.h-75p {
  height: 75%;
}

@media (min-width: 600px) {
  .h-sm-75p {
    height: 75% !important;
  }
}

.h-max-75p {
  max-height: 75%;
}

@media (min-width: 600px) {
  .h-max-sm-75p {
    max-height: 75% !important;
  }
}

.h-min-75p {
  min-height: 75%;
}

@media (min-width: 600px) {
  .h-min-sm-75p {
    min-height: 75% !important;
  }
}

.h-80p {
  height: 80%;
}

@media (min-width: 600px) {
  .h-sm-80p {
    height: 80% !important;
  }
}

.h-max-80p {
  max-height: 80%;
}

@media (min-width: 600px) {
  .h-max-sm-80p {
    max-height: 80% !important;
  }
}

.h-min-80p {
  min-height: 80%;
}

@media (min-width: 600px) {
  .h-min-sm-80p {
    min-height: 80% !important;
  }
}

.h-85p {
  height: 85%;
}

@media (min-width: 600px) {
  .h-sm-85p {
    height: 85% !important;
  }
}

.h-max-85p {
  max-height: 85%;
}

@media (min-width: 600px) {
  .h-max-sm-85p {
    max-height: 85% !important;
  }
}

.h-min-85p {
  min-height: 85%;
}

@media (min-width: 600px) {
  .h-min-sm-85p {
    min-height: 85% !important;
  }
}

.h-90p {
  height: 90%;
}

@media (min-width: 600px) {
  .h-sm-90p {
    height: 90% !important;
  }
}

.h-max-90p {
  max-height: 90%;
}

@media (min-width: 600px) {
  .h-max-sm-90p {
    max-height: 90% !important;
  }
}

.h-min-90p {
  min-height: 90%;
}

@media (min-width: 600px) {
  .h-min-sm-90p {
    min-height: 90% !important;
  }
}

.h-95p {
  height: 95%;
}

@media (min-width: 600px) {
  .h-sm-95p {
    height: 95% !important;
  }
}

.h-max-95p {
  max-height: 95%;
}

@media (min-width: 600px) {
  .h-max-sm-95p {
    max-height: 95% !important;
  }
}

.h-min-95p {
  min-height: 95%;
}

@media (min-width: 600px) {
  .h-min-sm-95p {
    min-height: 95% !important;
  }
}

.h-100p {
  height: 100%;
}

@media (min-width: 600px) {
  .h-sm-100p {
    height: 100% !important;
  }
}

.h-max-100p {
  max-height: 100%;
}

@media (min-width: 600px) {
  .h-max-sm-100p {
    max-height: 100% !important;
  }
}

.h-min-100p {
  min-height: 100%;
}

@media (min-width: 600px) {
  .h-min-sm-100p {
    min-height: 100% !important;
  }
}

.min-w-120p {
  min-width: 7.5rem !important;
}

@media (min-width: 600px) {
  .w-max-sm-120p {
    max-width: 7.5rem !important;
  }
}

@media (min-width: 600px) {
  .w-min-sm-120p {
    min-width: 7.5rem !important;
  }
}

.w-150p {
  width: 9.375rem !important;
}

@media (min-width: 600px) {
  .w-max-sm-150p {
    max-width: 9.375rem !important;
  }
}

@media (min-width: 600px) {
  .w-min-sm-150p {
    min-width: 9.375rem !important;
  }
}

.w-200p {
  width: 12.5rem !important;
}

.w-max-200p {
  max-width: 12.5rem !important;
}

@media (min-width: 600px) {
  .w-max-sm-200p {
    max-width: 12.5rem !important;
  }
}

@media (min-width: 600px) {
  .w-min-sm-200p {
    min-width: 12.5rem !important;
  }
}

.w-360p {
  width: 22.5rem !important;
}

@media (min-width: 600px) {
  .w-max-sm-360 {
    max-width: 22.5rem !important;
  }
}

@media (min-width: 600px) {
  .w-min-sm-360 {
    min-width: 22.5rem !important;
  }
}

.h-48 {
  height: 3rem;
}

.max-h-48 {
  max-height: 48px;
}

@media (min-width: 600px) {
  .h-sm-48 {
    height: 3rem !important;
  }
}

@media (min-width: 600px) {
  .max-h-sm-48 {
    max-height: 48px !important;
  }
}

.no-wrap {
  white-space: nowrap !important;
}

@media (max-width: 599.98px) {
  .w-sm-auto {
    width: auto !important;
  }
}

@media (min-width: 600px) {
  .border-sm-bottom-0 {
    border-bottom: 0 !important;
  }
}

.modal {
  border-radius: 3px;
}

.modal-padding {
  padding-top: 24px;
  padding-bottom: 24px;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 90%;
  }
}

.modal-dialog-centered {
  margin: 0 auto;
  align-items: baseline;
  justify-content: center;
}

.modal-content {
  background-color: #FFFCF9;
  width: inherit;
  border-radius: 3px;
  box-shadow: 0 2px 16px 2px rgba(222, 222, 222, 0.5);
}

.modal.fade-anim {
  transition: opacity 400ms ease-in-out;
  will-change: opacity;
  opacity: 0;
}

.modal.fade-anim.in {
  opacity: 1;
}

.modal-open {
  overflow: hidden;
}
@media (max-width: 599.98px) {
  .modal-open {
    padding-right: 0 !important;
  }
}

.modal-footer,
.modal-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 4em;
  padding: 0 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.modal-header {
  border-bottom: none;
  justify-content: space-between;
  display: flex;
  margin: 12px 0 12px 0;
}

.modal-body {
  padding: 24px;
  background-color: #FFF;
  box-shadow: 0 2px 16px 2px rgba(222, 222, 222, 0.5);
  border-radius: 0.25em;
}
@media screen and (min-width: 768px) {
  .modal-body {
    margin: 0 12px 8px 12px;
  }
}
@media screen and (min-width: 1024px) {
  .modal-body {
    margin: 0 24px 16px 24px;
  }
}
@media screen and (max-width: 1200px) {
  .modal-body {
    padding: 12px;
  }
}

.modal-footer {
  border-top: none;
  width: 100%;
  height: 15%;
  padding: 12px;
}

.close-modal {
  width: 40px;
  text-align: center;
}
.close-modal .helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.close-modal img {
  vertical-align: middle;
  height: 16px;
  width: 16px;
}

.close-modal:hover {
  cursor: pointer;
}

.modal-backdrop-overlay {
  z-index: 1055 !important;
}

.modal-window-centered .modal-dialog-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  min-height: initial !important;
}

.modal-decorator.confirmation {
  max-width: 450px !important;
}
.modal-decorator.confirmation .btn-close:focus {
  box-shadow: none;
}
.modal-decorator .modal-footer .btn-primary {
  min-width: 120px;
}
@media (min-width: 600px) {
  .modal-decorator .modal-content {
    border-radius: 8px;
  }
}

.model-rounded .modal-content {
  border-radius: 8px;
}

@media (max-width: 1024px) {
  .modal-dialog.modal-fullscreen-mdl-down {
    background-color: red;
    width: 100vw;
    height: 100vh;
    max-width: none;
    margin: 0;
  }
  .modal-dialog.modal-fullscreen-mdl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
}
.btn-toggle {
  margin: 0 4rem;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
  color: #6b7381;
  background: #bdc1c8;
}

.btn-toggle:focus,
.btn-toggle.focus,
.btn-toggle:focus.active,
.btn-toggle.focus.active {
  outline: none;
}

.btn-toggle:before,
.btn-toggle:after {
  line-height: 1.5rem;
  width: 5rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.65rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  transition: opacity 0.25s;
}

.btn-toggle > .handle {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: #fff;
  transition: left 0.25s;
}

.btn-toggle.active {
  transition: background-color 0.25s;
}

.btn-toggle.active > .handle {
  left: 1.6875rem;
  transition: left 0.25s;
}

.btn-toggle.active:before {
  opacity: 0.5;
}

.btn-toggle.active:after {
  opacity: 1;
}

.btn-toggle.btn-sm:before, .btn-group-sm > .btn-toggle.btn:before,
.btn-toggle.btn-sm:after,
.btn-group-sm > .btn-toggle.btn:after {
  line-height: -0.5rem;
  color: #fff;
  letter-spacing: 0.75px;
  left: 0.4125rem;
  width: 2.325rem;
}

.btn-toggle.btn-sm:before, .btn-group-sm > .btn-toggle.btn:before {
  text-align: right;
}

.btn-toggle.btn-sm:after, .btn-group-sm > .btn-toggle.btn:after {
  text-align: left;
  opacity: 0;
}

.btn-toggle.btn-sm.active:before, .btn-group-sm > .btn-toggle.active.btn:before {
  opacity: 0;
}

.btn-toggle.btn-sm.active:after, .btn-group-sm > .btn-toggle.active.btn:after {
  opacity: 1;
}

.btn-toggle.btn-xs:before,
.btn-toggle.btn-xs:after {
  display: none;
}

.btn-toggle:before,
.btn-toggle:after {
  color: #6b7381;
}

.btn-toggle.active {
  background-color: #29b5a8;
}

.btn-toggle.btn-lg, .btn-group-lg > .btn-toggle.btn {
  margin: 0 5rem;
  padding: 0;
  position: relative;
  border: none;
  height: 2.5rem;
  width: 5rem;
  border-radius: 2.5rem;
}

.btn-toggle.btn-lg:focus, .btn-group-lg > .btn-toggle.btn:focus,
.btn-toggle.btn-lg.focus,
.btn-group-lg > .btn-toggle.focus.btn,
.btn-toggle.btn-lg:focus.active,
.btn-toggle.btn-lg.focus.active {
  outline: none;
}

.btn-toggle.btn-lg:before, .btn-group-lg > .btn-toggle.btn:before,
.btn-toggle.btn-lg:after,
.btn-group-lg > .btn-toggle.btn:after {
  line-height: 2.5rem;
  width: 5rem;
  text-align: center;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  transition: opacity 0.25s;
}

.btn-toggle.btn-lg:before, .btn-group-lg > .btn-toggle.btn:before {
  content: "Off";
  left: -5rem;
}

.btn-toggle.btn-lg:after, .btn-group-lg > .btn-toggle.btn:after {
  content: "On";
  right: -5rem;
  opacity: 0.5;
}

.btn-toggle.btn-lg > .handle, .btn-group-lg > .btn-toggle.btn > .handle {
  position: absolute;
  top: 0.3125rem;
  left: 0.3125rem;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 1.875rem;
  background: #fff;
  transition: left 0.25s;
}

.btn-toggle.btn-lg.active, .btn-group-lg > .btn-toggle.active.btn {
  transition: background-color 0.25s;
}

.btn-toggle.btn-lg.active > .handle, .btn-group-lg > .btn-toggle.active.btn > .handle {
  left: 2.8125rem;
  transition: left 0.25s;
}

.btn-toggle.btn-lg.active:before, .btn-group-lg > .btn-toggle.active.btn:before {
  opacity: 0.5;
}

.btn-toggle.btn-lg.active:after, .btn-group-lg > .btn-toggle.active.btn:after {
  opacity: 1;
}

.btn-toggle.btn-lg.btn-sm:before, .btn-group-lg > .btn-toggle.btn-sm.btn:before, .btn-group-sm > .btn-toggle.btn-lg.btn:before, .btn-group-sm.btn-group-lg > .btn-toggle.btn:before,
.btn-toggle.btn-lg.btn-sm:after,
.btn-group-lg > .btn-toggle.btn-sm.btn:after,
.btn-group-sm > .btn-toggle.btn-lg.btn:after,
.btn-group-sm.btn-group-lg > .btn-toggle.btn:after {
  line-height: 0.5rem;
  color: #fff;
  letter-spacing: 0.75px;
  left: 0.6875rem;
  width: 3.875rem;
}

.btn-toggle.btn-lg.btn-sm:before, .btn-group-lg > .btn-toggle.btn-sm.btn:before, .btn-group-sm > .btn-toggle.btn-lg.btn:before, .btn-group-sm.btn-group-lg > .btn-toggle.btn:before {
  text-align: right;
}

.btn-toggle.btn-lg.btn-sm:after, .btn-group-lg > .btn-toggle.btn-sm.btn:after, .btn-group-sm > .btn-toggle.btn-lg.btn:after, .btn-group-sm.btn-group-lg > .btn-toggle.btn:after {
  text-align: left;
  opacity: 0;
}

.btn-toggle.btn-lg.btn-sm.active:before, .btn-group-lg > .btn-toggle.btn-sm.active.btn:before, .btn-group-sm > .btn-toggle.btn-lg.active.btn:before, .btn-group-sm.btn-group-lg > .btn-toggle.active.btn:before {
  opacity: 0;
}

.btn-toggle.btn-lg.btn-sm.active:after, .btn-group-lg > .btn-toggle.btn-sm.active.btn:after, .btn-group-sm > .btn-toggle.btn-lg.active.btn:after, .btn-group-sm.btn-group-lg > .btn-toggle.active.btn:after {
  opacity: 1;
}

.btn-toggle.btn-lg.btn-xs:before, .btn-group-lg > .btn-toggle.btn-xs.btn:before,
.btn-toggle.btn-lg.btn-xs:after,
.btn-group-lg > .btn-toggle.btn-xs.btn:after {
  display: none;
}

.btn-toggle.btn-sm, .btn-group-sm > .btn-toggle.btn {
  margin: 0 0.5rem;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
}

.btn-toggle.btn-sm:focus, .btn-group-sm > .btn-toggle.btn:focus,
.btn-toggle.btn-sm.focus,
.btn-group-sm > .btn-toggle.focus.btn,
.btn-toggle.btn-sm:focus.active,
.btn-toggle.btn-sm.focus.active {
  outline: none;
}

.btn-toggle.btn-sm:before, .btn-group-sm > .btn-toggle.btn:before,
.btn-toggle.btn-sm:after,
.btn-group-sm > .btn-toggle.btn:after {
  line-height: 1.5rem;
  width: 0.5rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.55rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  transition: opacity 0.25s;
}

.btn-toggle.btn-sm:before, .btn-group-sm > .btn-toggle.btn:before {
  content: "Off";
  left: -0.5rem;
}

.btn-toggle.btn-sm:after, .btn-group-sm > .btn-toggle.btn:after {
  content: "On";
  right: -0.5rem;
  opacity: 0.5;
}

.btn-toggle.btn-sm > .handle, .btn-group-sm > .btn-toggle.btn > .handle {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: #fff;
  transition: left 0.25s;
}

.btn-toggle.btn-sm.active, .btn-group-sm > .btn-toggle.active.btn {
  transition: background-color 0.25s;
}

.btn-toggle.btn-sm.active > .handle, .btn-group-sm > .btn-toggle.active.btn > .handle {
  left: 1.6875rem;
  transition: left 0.25s;
}

.btn-toggle.btn-sm.active:before, .btn-group-sm > .btn-toggle.active.btn:before {
  opacity: 0.5;
}

.btn-toggle.btn-sm.active:after, .btn-group-sm > .btn-toggle.active.btn:after {
  opacity: 1;
}

.btn-toggle.btn-sm.btn-sm:before, .btn-group-sm > .btn-toggle.btn:before,
.btn-toggle.btn-sm.btn-sm:after,
.btn-group-sm > .btn-toggle.btn:after {
  line-height: -0.5rem;
  color: #fff;
  letter-spacing: 0.75px;
  left: 0.4125rem;
  width: 2.325rem;
}

.btn-toggle.btn-sm.btn-sm:before, .btn-group-sm > .btn-toggle.btn:before {
  text-align: right;
}

.btn-toggle.btn-sm.btn-sm:after, .btn-group-sm > .btn-toggle.btn:after {
  text-align: left;
  opacity: 0;
}

.btn-toggle.btn-sm.btn-sm.active:before, .btn-group-sm > .btn-toggle.active.btn:before {
  opacity: 0;
}

.btn-toggle.btn-sm.btn-sm.active:after, .btn-group-sm > .btn-toggle.active.btn:after {
  opacity: 1;
}

.btn-toggle.btn-sm.btn-xs:before, .btn-group-sm > .btn-toggle.btn-xs.btn:before,
.btn-toggle.btn-sm.btn-xs:after,
.btn-group-sm > .btn-toggle.btn-xs.btn:after {
  display: none;
}

.btn-toggle.btn-xs {
  margin: 0 0;
  padding: 0;
  position: relative;
  border: none;
  height: 1rem;
  width: 2rem;
  border-radius: 1rem;
}

.btn-toggle.btn-xs:focus,
.btn-toggle.btn-xs.focus,
.btn-toggle.btn-xs:focus.active,
.btn-toggle.btn-xs.focus.active {
  outline: none;
}

.btn-toggle.btn-xs:before,
.btn-toggle.btn-xs:after {
  line-height: 1rem;
  width: 0;
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  transition: opacity 0.25s;
}

.btn-toggle.btn-xs:before {
  content: "Off";
  left: 0;
}

.btn-toggle.btn-xs:after {
  content: "On";
  right: 0;
  opacity: 0.5;
}

.btn-toggle.btn-xs > .handle {
  position: absolute;
  top: 0.125rem;
  left: 0.125rem;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.75rem;
  background: #fff;
  transition: left 0.25s;
}

.btn-toggle.btn-xs.active {
  transition: background-color 0.25s;
}

.btn-toggle.btn-xs.active > .handle {
  left: 1.125rem;
  transition: left 0.25s;
}

.btn-toggle.btn-xs.active:before {
  opacity: 0.5;
}

.btn-toggle.btn-xs.active:after {
  opacity: 1;
}

.btn-toggle.btn-xs.btn-sm:before, .btn-group-sm > .btn-toggle.btn-xs.btn:before,
.btn-toggle.btn-xs.btn-sm:after,
.btn-group-sm > .btn-toggle.btn-xs.btn:after {
  line-height: -1rem;
  color: #fff;
  letter-spacing: 0.75px;
  left: 0.275rem;
  width: 1.55rem;
}

.btn-toggle.btn-xs.btn-sm:before, .btn-group-sm > .btn-toggle.btn-xs.btn:before {
  text-align: right;
}

.btn-toggle.btn-xs.btn-sm:after, .btn-group-sm > .btn-toggle.btn-xs.btn:after {
  text-align: left;
  opacity: 0;
}

.btn-toggle.btn-xs.btn-sm.active:before, .btn-group-sm > .btn-toggle.btn-xs.active.btn:before {
  opacity: 0;
}

.btn-toggle.btn-xs.btn-sm.active:after, .btn-group-sm > .btn-toggle.btn-xs.active.btn:after {
  opacity: 1;
}

.btn-toggle.btn-xs.btn-xs:before,
.btn-toggle.btn-xs.btn-xs:after {
  display: none;
}

.btn-toggle.btn-secondary {
  color: #6b7381;
  background: #bdc1c8;
}

.btn-toggle.btn-secondary:before,
.btn-toggle.btn-secondary:after {
  color: #6b7381;
}

.btn-toggle.btn-secondary.active {
  background-color: #ff8300;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 10px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/*FONTS DESKTOP*/
/*FONTS MOBILE*/
/*FONT BUTTON*/
/*FONT COLOR*/
/*PRIMARY COLOR*/
/*THEME PRIMARY*/
/*THEME SECONDARY*/
/*LIGHT THEME*/
/*THEME SUCCESS*/
/*THEME ERROR*/
/*MARGIN*/
/*PADDING-INTERNAL*/
/*INPUT*/
/*TABLE*/
/*NAVBAR*/
/*BUTTON-COLOR*/
/*BOOKING-STATUS*/
/*NAVIGATION-BAR*/
table {
  border-collapse: separate !important;
  border-spacing: 0;
}

.table__bordered_style {
  border: solid #ccc 1px;
  border-radius: 0.25em;
}
.table__bordered_style .table__bordered_style > :not(caption) > * {
  border-width: 0;
}

.table__bordered_style tr:hover {
  background-color: #F0F5FF;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.table__bordered_style td, .table__bordered_style th {
  border-left: none;
  border-top: 1px solid #ccc;
  padding: 12px;
  text-align: left;
}

.table__bordered_style th {
  background-color: #3C3C46;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#F8F8F8), to(#ECECEC));
  background-image: -webkit-linear-gradient(to bottom, #F8F8F8, #ECECEC);
  background-image: -moz-linear-gradient(to bottom, #F8F8F8, #ECECEC);
  background-image: linear-gradient(to bottom, #F8F8F8, #ECECEC);
  border-top: none;
  font-weight: 600 !important;
}

.table__bordered_style td:last-child td:first-child {
  border: none;
}

.table__bordered_style th:first-child {
  border-radius: 0.25em 0 0 0;
}

.table__bordered_style th:last-child {
  border-radius: 0 0.25em 0 0;
}

.table__bordered_style th:only-child {
  border-radius: 0.25em 0.25em 0 0;
}

.table__bordered_style tr:last-child td:first-child {
  border-radius: 0 0 0 0.25em;
}

.table__bordered_style tr:last-child td:last-child {
  border-radius: 0 0 0.25em 0;
}

.table__bordered_style tr:last-child {
  border: 1px transparent;
}

.table_container__scrollable {
  overflow: auto;
  border-radius: 0.25em;
  max-height: 400px;
}
@media screen and (min-width: 768px) and (max-width: 960px) {
  .table_container__scrollable {
    max-height: 200px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .table_container__scrollable {
    max-height: 400px;
  }
}
.table_container__scrollable table {
  width: 100%;
}
.table_container__scrollable thead th {
  position: sticky;
  top: 0;
  border-top: 1px solid #ccc;
}
.table_container__scrollable tbody th {
  position: sticky;
  left: 0;
}

.table_container__scrollable__small {
  max-height: 200px !important;
}

.table-scrollable-md {
  max-height: 300px !important;
  overflow-y: scroll;
}

/*FONTS DESKTOP*/
/*FONTS MOBILE*/
/*FONT BUTTON*/
/*FONT COLOR*/
/*PRIMARY COLOR*/
/*THEME PRIMARY*/
/*THEME SECONDARY*/
/*LIGHT THEME*/
/*THEME SUCCESS*/
/*THEME ERROR*/
/*MARGIN*/
/*PADDING-INTERNAL*/
/*INPUT*/
/*TABLE*/
/*NAVBAR*/
/*BUTTON-COLOR*/
/*BOOKING-STATUS*/
/*NAVIGATION-BAR*/
/* Needed to show tooltips on table rows */
.tooltip__container {
  z-index: 9999;
  overflow: visible;
  position: relative;
}
.tooltip__container .tooltip-inner {
  background: #FFF;
  width: max-content;
  color: #000;
  font-size: 1rem;
  border: 1px solid #00A3D8;
}
@media screen and (min-width: 768px) {
  .tooltip__container .tooltip-inner {
    font-size: 12px;
  }
}
@media screen and (min-width: 1200px) {
  .tooltip__container .tooltip-inner {
    font-size: 14px;
  }
}
.tooltip__container .arrow::before {
  border-top-color: #FFF;
}
.tooltip__container.left .tooltip-inner {
  text-align: left;
  white-space: pre-wrap;
}

.tooltip__primary .tooltip-inner {
  background-color: #BDEBFB;
  color: #00236F;
}

.tooltip.show {
  opacity: 1;
}

.tooltip-arrow {
  display: none !important;
}

@media screen and (min-width: 768px) {
  .typography__h1 {
    font-size: 18px;
    line-height: 24px/1, 2;
    font-width: 400;
  }
}
@media screen and (min-width: 1024px) {
  .typography__h1 {
    font-size: 52px;
    line-height: 60px;
    font-width: 700;
  }
}

@media screen and (min-width: 768px) {
  .typography__h2 {
    font-size: 16px;
    line-height: 22px/1, 2;
    font-weight: 400;
  }
}
@media screen and (min-width: 1024px) {
  .typography__h2 {
    font-size: 26px;
    line-height: 36px;
    font-weight: 600;
  }
}

.typography__h3 {
  font-weight: 600;
}
@media screen and (min-width: 768px) {
  .typography__h3 {
    font-size: 14px;
  }
}
@media screen and (min-width: 1024px) {
  .typography__h3 {
    font-size: 24px;
    line-height: 33px;
  }
}

.typography__h4 {
  line-height: 24px;
  font-weight: 600;
}
@media screen and (min-width: 768px) {
  .typography__h4 {
    font-size: 14px;
  }
}
@media screen and (min-width: 1024px) {
  .typography__h4 {
    font-size: 18px;
  }
}

.typography__p, .inputField__label, .dropdown-menu li a, .dropdown-item, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option, .table__bordered_style th, .table__bordered_style td {
  font-weight: 400;
}
@media screen and (min-width: 768px) {
  .typography__p, .inputField__label, .dropdown-menu li a, .dropdown-item, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option, .table__bordered_style th, .table__bordered_style td {
    font-size: 12px;
    line-height: 20px/1.2;
  }
}
@media screen and (min-width: 1024px) {
  .typography__p, .inputField__label, .dropdown-menu li a, .dropdown-item, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option, .table__bordered_style th, .table__bordered_style td {
    font-size: 16px;
    line-height: 24px;
  }
}

.error-field {
  color: #AB2930;
  font-size: 80%;
}

.inputField__label {
  font-weight: 300 !important;
}

.currencyTextAlign {
  text-align: end !important;
}

.currencyTextAlignRight {
  text-align: right !important;
}

/*FONTS DESKTOP*/
/*FONTS MOBILE*/
/*FONT BUTTON*/
/*FONT COLOR*/
/*PRIMARY COLOR*/
/*THEME PRIMARY*/
/*THEME SECONDARY*/
/*LIGHT THEME*/
/*THEME SUCCESS*/
/*THEME ERROR*/
/*MARGIN*/
/*PADDING-INTERNAL*/
/*INPUT*/
/*TABLE*/
/*NAVBAR*/
/*BUTTON-COLOR*/
/*BOOKING-STATUS*/
/*NAVIGATION-BAR*/
.ic {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.ic_button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 2.2rem !important;
  height: 2.2rem !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ic_center {
  display: flex;
  margin: auto;
}

/* ==========================================
Single-colored icons can be modified like so:
.ic_name {
  font-size: 32px;
  color: red;
}
========================================== */
.ic_house {
  width: 1.125em;
}

.ic_mail {
  width: 1.22265625em;
}

.ic_pax2 {
  width: 1.84375em;
}

.ic_pax {
  width: 0.78125em;
}

.ic_trash {
  width: 0.888671875em;
}

.ic_bell {
  width: 0.77734375em;
}

.ic_add_person {
  width: 1.288671875em;
}

.ic_calculator {
  width: 0.8em;
}
@media screen and (min-width: 1200px) {
  .ic_calculator {
    height: 1.2em;
  }
}

.ic_minus_circle {
  height: 1.5em;
}

.ic_plus_circle {
  height: 1.5em;
}

.ic_file_excel {
  width: 1.5em;
  height: 1.3em;
}
@media screen and (max-width: 1024px) {
  .ic_file_excel {
    width: 1.6em;
    height: 1.35em;
  }
}

.ic_tooltip_info {
  height: 1.5em;
}

.ic_chat_icon {
  font-size: 18px !important;
  fill: black;
}

.ic_download {
  width: 1.3em;
  height: 1.3em;
}

.ic_20 {
  width: 20px;
  height: 20px;
}

::ng-deep .ic-active-green {
  background-color: #CAE9D9 !important;
  color: #FFF !important;
  border: none !important;
}
::ng-deep .ic-active-green svg {
  fill: #008843 !important;
}
::ng-deep .ic-active-green:disabled {
  background-color: #EAEAEA !important;
  opacity: 1 !important;
  border: none !important;
}
::ng-deep .ic-active-green:disabled svg {
  fill: #A6ACAF !important;
}
::ng-deep .ic-active-green:hover {
  background-color: #CAE9D9 !important;
  border: none !important;
}
::ng-deep .ic-active-green:focus {
  background-color: #CAE9D9 !important;
  border: none !important;
}
::ng-deep .ic-active-green:active {
  border: none !important;
}

::ng-deep .ic-active-blue {
  background-color: #E4F1FC !important;
  color: #FFF !important;
  border: none !important;
}
::ng-deep .ic-active-blue svg {
  fill: #103783 !important;
}
::ng-deep .ic-active-blue:disabled {
  background-color: #EAEAEA !important;
  opacity: 1 !important;
  border: none !important;
}
::ng-deep .ic-active-blue:disabled svg {
  fill: #A6ACAF !important;
}
::ng-deep .ic-active-blue:hover {
  background-color: #E4F1FC !important;
  border: none !important;
}
::ng-deep .ic-active-blue:focus {
  background-color: #E4F1FC !important;
  border: none !important;
}
::ng-deep .ic-active-blue:active {
  border: none !important;
}

::ng-deep .ic-active-red {
  background-color: #F3CFD1 !important;
  color: #FFF !important;
  border: none !important;
}
::ng-deep .ic-active-red svg {
  fill: #AB2930 !important;
}
::ng-deep .ic-active-red:disabled {
  background-color: #EAEAEA !important;
  opacity: 1 !important;
  border: none !important;
}
::ng-deep .ic-active-red:disabled svg {
  fill: #A6ACAF !important;
}
::ng-deep .ic-active-red:hover {
  background-color: #F3CFD1 !important;
  border: none !important;
}
::ng-deep .ic-active-red:focus {
  background-color: #F3CFD1 !important;
  border: none !important;
}
::ng-deep .ic-active-red:active {
  border: none !important;
}

.ic-22 {
  width: 22px !important;
  height: 22px !important;
  font-size: 16px !important;
}

.ic-18 {
  width: 18px !important;
  height: 18px !important;
}

.ic-15 {
  width: 15px !important;
  height: 15px !important;
}

.ic-32 {
  width: 32px !important;
  height: 32px !important;
}

::ng-deep .ic-16-svg > span > div > svg {
  width: 16px !important;
  height: 16px !important;
}

::ng-deep .ic-18-svg > span > div > svg {
  width: 18px !important;
  height: 18px !important;
}

::ng-deep .ic-20-svg > span > div > svg {
  width: 20px !important;
  height: 20px !important;
}

::ng-deep .ic-change-client-svg > span > div > svg {
  width: 22px !important;
  height: 16px !important;
}

::ng-deep .ic-email-guest-svg > span > div > svg {
  width: 20px !important;
  height: 16px !important;
}

::ng-deep .ic-clear-guest-svg > span > div > svg {
  width: 20px !important;
  height: 20px !important;
  position: relative;
  bottom: 2px;
}

.nb-toast-success {
  background-color: #F1F1F1;
  border: solid 1px #47C464;
  border-radius: 4px;
  width: 28em !important;
  color: #287D3C !important;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00Ljg3Ni4yMkMxLjQxNyAxLjE4Mi0uNjg3IDQuNzEyLjIwNSA4LjA1N2MuNjE3IDIuMzE4IDIuMTggMy45NDEgNC40NzcgNC42NSAyLjMwMi43MTEgNC42Ni4xMTEgNi40LTEuNjI4IDEuODYyLTEuODYyIDIuNDQ4LTQuODE3IDEuNDA1LTcuMDg3bC0uMzY0LS43OTMtMy4xNDQgMy4wNjgtMy4xNDUgMy4wNjgtMS41MzYtMS41My0xLjUzNy0xLjUzLjQzOS0uNDU3LjQzOC0uNDU4IDEuMTEgMS4xMDEgMS4xMSAxLjEwMkw4LjU4IDQuODRsMi43MjItMi43MjItLjM2OC0uMzkyQzEwLjA4Mi44MTggNy44MzItLjAyOCA2LjM1IDBBNy4xNjYgNy4xNjYgMCAwIDAgNC44NzYuMjJ6IiBmaWxsPSIjMjk3RDNEIi8+PC9zdmc+);
}
.nb-toast-success .toast-close-button {
  color: #287D3C !important;
  font-size: 28px;
  font-weight: 400;
  right: -0.1em;
  top: -0.5em;
}

.tap-n-pay-button-override {
  margin-left: 0px !important;
  border-radius: 8px !important;
}

.tap-n-pay-btn-action-override {
  margin: 0px !important;
}

* {
  font-family: "Open Sans", sans-serif;
}
* .tooltip-inner {
  max-width: 200px;
  max-height: 200px;
  padding: 0.25rem 0.5rem;
  color: #000;
  text-align: center;
  background-color: #000;
  overflow: hidden;
}

body {
  background-color: #FEFCF9;
}

cui-input label {
  font-size: 13px !important;
}