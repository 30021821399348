@import "variables";

.ic {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.ic_button {
  display: flex!important;
  align-items: center !important;
  justify-content: center !important;
  width: 2.2rem !important;
  height: 2.2rem !important;

  padding-left: 0!important;
  padding-right: 0!important;
}

.ic_center {
  display: flex;
  margin: auto;
}

/* ==========================================
Single-colored icons can be modified like so:
.ic_name {
  font-size: 32px;
  color: red;
}
========================================== */

.ic_house {
  width: 1.125em;
}

.ic_mail {
  width: 1.22265625em;
}

.ic_pax2 {
  width: 1.84375em;
}

.ic_pax {
  width: 0.78125em;
}

.ic_trash {
  width: 0.888671875em;
}

.ic_bell {
  width: 0.77734375em;
}

.ic_add_person {
  width: 1.288671875em;
}

.ic_calculator {
  width: 0.8em;

  @media screen and (min-width: 1200px) {
    height: 1.2em;
  }
}

.ic_minus_circle {
  height: 1.5em;
}

.ic_plus_circle {
  height: 1.5em;
}

.ic_file_excel {
  width: 1.5em;
  height: 1.3em;

  @media screen and (max-width: 1024px) {
    width: 1.6em;
    height: 1.35em;
  }
}

.ic_tooltip_info {
  height: 1.5em;
}

.ic_chat_icon {
  font-size: 18px !important; // TODO change to em
  fill: black;
}

.ic_download {
  width: 1.3em;
  height: 1.3em;
}

.ic_20 {
  width: 20px;
  height: 20px;
}

::ng-deep .ic-active-green {
  background-color: $primary-active-green !important;
  color: $primary-white !important;
  border: none !important;
  svg {
    fill: $theme-success-green-dark !important;
  }
  &:disabled {
    background-color: $primary-disabled !important;
    opacity: 1 !important;
    border: none !important;

    svg {
      fill: $booking-status-unavailable !important;
    }
  }
  &:hover {
    background-color: $primary-active-green !important;
    border: none !important;
  }
  &:focus {
    background-color: $primary-active-green !important;
    border: none !important;
  }
  &:active {
    border: none !important;
  }
}

::ng-deep .ic-active-blue {
  background-color: $primary-active-blue !important;
  color: $primary-white !important;
  border: none !important;
  svg {
    fill: $theme-primary-dark-blue !important;
  }
  &:disabled {
    background-color: $primary-disabled !important;
    opacity: 1 !important;
    border: none !important;

    svg {
      fill: $booking-status-unavailable !important;
    }
  }
  &:hover {
    background-color: $primary-active-blue !important;
    border: none !important;
  }
  &:focus {
    background-color: $primary-active-blue !important;
    border: none !important;
  }
  &:active {
    border: none !important;
  }
}

::ng-deep .ic-active-red {
  background-color: $primary-active-red !important;
  color: $primary-white !important;
  border: none !important;
  svg {
    fill: $primary-dark-red !important;
  }
  &:disabled {
    background-color: $primary-disabled !important;
    opacity: 1 !important;
    border: none !important;

    svg {
      fill: $booking-status-unavailable !important;
    }
  }
  &:hover {
    background-color: $primary-active-red !important;
    border: none !important;
  }
  &:focus {
    background-color: $primary-active-red !important;
    border: none !important;
  }
  &:active {
    border: none !important;
  }
}

.ic-22 {
  width: 22px !important;
  height: 22px !important;

  font-size: 16px !important;
}

.ic-18 {
  width: 18px !important;
  height: 18px !important;
}

.ic-15 {
  width: 15px !important;
  height: 15px !important;
}

.ic-32 {
  width: 32px !important;
  height: 32px !important;
}


::ng-deep .ic-16-svg > span > div > svg {
  width: 16px !important;
  height: 16px !important;
}

::ng-deep .ic-18-svg > span > div > svg {
  width: 18px !important;
  height: 18px !important;
}

::ng-deep .ic-20-svg > span > div > svg {
  width: 20px !important;
  height: 20px !important;
}

::ng-deep .ic-change-client-svg > span > div > svg {
  width: 22px !important;
  height: 16px !important;
}

::ng-deep .ic-email-guest-svg > span > div > svg {
  width: 20px !important;
  height: 16px !important;
}

::ng-deep .ic-clear-guest-svg > span > div > svg {
  width: 20px !important;
  height: 20px !important;
  position: relative;
  bottom: 2px;
}
