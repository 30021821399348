@import "./variables";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

$box-shadow: 0 .5rem 1rem rgba($black, .15);
$box-shadow-sm: 0 .125rem .25rem rgba($black, .075);
$box-shadow-lg: 0 1rem 3rem rgba($black, .175);
$box-shadow-inset: inset 0 1px 2px rgba($black, .075);

$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  auto: auto
);
$utilities: (
  "width": (responsive: true,
    property: width,
    class: w,
    values: $sizes,
  ),
  "height": (responsive: true,
    property: height,
    class: h,
    values: $sizes ),
  "shadow": (property: box-shadow,
    class: shadow,
    values: (null: $box-shadow,
      sm: $box-shadow-sm,
      lg: $box-shadow-lg,
      none: none,
    )),
);

$utilities: map-merge($utilities,
    ("font-size": (rfs: true,
        property: font-size,
        class: fs,
        values: $font-sizes,
        responsive: true),
    ));

$dimension-ranges: (
  "40": 40px,
  "48": 48px,
  "75": 75px,
  "80": 80px,
  "96": 96px,
  "100": 100px,
  "112": 112px,
  "120": 120px,
  "130": 130px,
  "140": 140px,
  "170": 170px,
  "180": 180px,
  "265": 265px,
  "320": 320px,
  "400": 400px,
  "410": 410px,
  "560": 560px,
  "620": 620px,
);

@each $name, $value in $dimension-ranges {
  .min-w-#{$name} {
    min-width: $value !important;
  }

  .max-w-#{$name} {
    max-width: $value !important;
  }

  .min-h-#{$name} {
    min-height: $value !important;
  }

  .max-h-#{$name} {
    max-height: $value !important;
  }
}

.max-wh-40 {
  max-width: 40px;
  max-height: 40px;
}

.max-h {
  max-height: 16px; // 1rem
}

.max-h-sm-none {
  @include media-breakpoint-up(sm) {
    max-height: none !important;
  }
}

.max-h-sm-48 {
  @include media-breakpoint-up(sm) {
    max-height: 48px !important;
  }
}

.max-w-50 {
  max-width: 50% !important;
}

.min-w-sm-620 {
  @include media-breakpoint-up(sm) {
    min-width: 620px !important;
  }
}

.max-w-sm-70 {
  @include media-breakpoint-up(sm) {
    max-width: 70%;
  }
}

.max-sm-w-100 {
  @include media-breakpoint-up(sm) {
    max-width: 100% !important;
  }
}

.max-sm-w-30 {
  @include media-breakpoint-up(sm) {
    max-width: 30% !important;
  }
}


.min-vh-100 {
  min-height: 100vh;
}

.max-vh-sm-365 {
  @include media-breakpoint-up(sm) {
    max-height: calc(100vh - 365px) !important;
  }
}

.vw-sm-50 {
  @include media-breakpoint-up(sm) {
    width: 50vw !important;
  }
}

.w-304 {
  width: 19rem !important;
}

.w-128 {
  width: 8rem !important;
}

.w-120 {
  width: 120% !important;
}

.h-25 {
  height: 25px !important;
}

.h-32 {
  height: 32px !important;
}

.w-32 {
  width: 32px !important;
}

.h-612 {
  height: 612px;
}

.w-500 {
  width: 500px !important;
}

.w-600 {
  width: 600px;
}

.w-250 {
  width: 250px;
}

.w-240 {
  width: 240px;
}

.h-40 {
  height: 40px !important;
}

.max-h-40 {
  max-height: 40px !important;
}

.h-80 {
  height: 80px;
}

.h-100 {
  height: 100%;
}

.h-98 {
  height: 6.125rem;
}

.h-64 {
  height: 64px;
}

.h-45 {
  height: 45px !important;
}

.h-50vh {
  height: 50vh;
}

.h-sm-38 {
  @include media-breakpoint-up(sm) {
    height: 38px;
  }
}

.h-sm-36 {
  @include media-breakpoint-up(sm) {
    height: 2rem !important;
  }
}

.h-sm-80 {
  @include media-breakpoint-up(sm) {
    height: 80px !important;
  }
}

.h-sm-500 {
  @include media-breakpoint-up(sm) {
    height: 500px !important;
  }
}

.w-160 {
  width: 10rem !important;
}

.w-24 {
  width: 24px;
}

.w-80 {
  width: 80px;
}

.w-48 {
  width: 3rem !important;
}

.w-66 {
  width: 66px;
}

.w-60 {
  width: 60px;
}

.w-40 {
  width: 40px !important;
}

.max-content {
  width: max-content;
}

.lh-25 {
  line-height: 25px;
}

.lh-24 {
  line-height: 24px;
}

.lh-sm-24 {
  @include media-breakpoint-up(sm) {
    line-height: 24px !important;
  }
}

::ng-deep .button-icon-svg > span > div > svg {
  width: 24px !important;
  height: 1rem !important;
}

.lh-21 {
  line-height: 21px;
}

.lh-20 {
  line-height: 21px;
}

.lh-32 {
  line-height: 32px;
}

.lh-35 {
  line-height: 35px;
}

.lh-38 {
  line-height: 18px;
}

.lh-16 {
  line-height: 16px;
}

.lh-18 {
  line-height: 18px;
}

.lh-sm-38 {
  @include media-breakpoint-up(sm) {
    line-height: 38px;
  }
}

.pr-sm-0 {
  @include media-breakpoint-up(sm) {
    padding-right: 0px !important;
  }
}

.lh-30 {
  line-height: 30px;
}

.lh-40 {
  line-height: 40px;
}

.lh-45 {
  line-height: 45px;
}

.lh-sm-45 {
  @include media-breakpoint-up(sm) {
    line-height: 45px;
  }
}

.lh-sm-normal {
  @include media-breakpoint-up(sm) {
    line-height: normal;
  }
}

.lh-150 {
  line-height: 150%;
}

.ls-tight {
  letter-spacing: -0.5px;
}

.mx-n3 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.white-space-pre-line {
  white-space: pre-line;
}

// more font sizes

.fw-medium {
  font-weight: 500;
}

.fw-sm-bold {
  @include media-breakpoint-up(sm) {
    font-weight: 700;
  }
}

.fw-semi-bold {
  font-weight: 600;
}

// flex
.flex-auto {
  flex: auto;
}

.flex-none {
  flex: none;
}

.flex-sm-none {
  @include media-breakpoint-up(sm) {
    flex: none !important;
  }
}

.flex-1 {
  flex: 1 1 0;
}

.flex-sm-no-grow {
  @include media-breakpoint-up(sm) {
    flex: 0 1 0 !important;
  }
}

.flex-sm-1 {
  @include media-breakpoint-up(sm) {
    flex: 1 1 0 !important;
  }
}

.flex-2 {
  flex: 2 1 0;
}

.flex-sm-2 {
  @include media-breakpoint-up(sm) {
    flex: 2 1 0 !important;
  }
}

.flex-3 {
  flex: 3 1 0;
}

.flex-sm-3 {
  @include media-breakpoint-up(sm) {
    flex: 3 1 0 !important;
  }
}

.flex-shrink-1 {
  flex: 1 1 0;
}

.flex-basis-0 {
  flex-basis: 0 !important;
}

.flex-basis-sm-0 {
  @include media-breakpoint-up(sm) {
    flex-basis: 0 !important;
  }
}

.flex-basis-48 {
  flex-basis: 48% !important;
}

.flex-basis-sm-48 {
  @include media-breakpoint-up(sm) {
    flex-basis: 48% !important;
  }
}

.justify-self-start {
  justify-self: start;
}

//alignment
.vertical-align-sub {
  vertical-align: sub !important;
}

.text-align-right > input {
  text-align: right !important;
}

.row-gap-3 {
  row-gap: 1rem;
}

.column-gap-2 {
  column-gap: 0.5rem;
}

.cursor-pointer {
  cursor: pointer;
}

.text-align-right {
  text-align: right;
}

//float
.float-right {
  float: right;
}

.indent-li {
  margin-left: 20px;
}

//radius
.border-radius-50 {
  border-radius: 50% !important;
}

.border-radius-16 {
  border-radius: 16px !important;
}


.col-md-3 {
  width: 25% !important;
}

.col-md-4 {
  width: 33.33333333% !important;
}

//borders
.border-none {
  border: none !important;
}

//positions
.position-relative {
  position: relative;
}

//overflow
.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.fs-20 {
  font-size: 20px;
}

.invalid-feedback {
  display: block;
}


// WIDTH PERCENTAGE

// width 0% <
.w-0p {
  width: 0%;
}

.w-sm-0p {
  @include media-breakpoint-up(sm) {
    width: 0% !important;
  }
}

.w-max-0p {
  max-width: 0%;
}

.w-max-sm-0p {
  @include media-breakpoint-up(sm) {
    max-width: 0% !important;
  }
}

.w-min-0p {
  min-width: 0%;
}

.w-min-sm-0p {
  @include media-breakpoint-up(sm) {
    max-width: 0% !important;
  }
}
// width 0% />

// width 5% <
.w-5p {
  width: 5%;
}

.w-sm-5p {
  @include media-breakpoint-up(sm) {
    width: 5% !important;
  }
}

.w-max-5p {
  max-width: 5%;
}

.w-max-sm-5p {
  @include media-breakpoint-up(sm) {
    max-width: 5% !important;
  }
}

.w-min-5p {
  min-width: 5%;
}

.w-min-sm-5p {
  @include media-breakpoint-up(sm) {
    max-width: 5% !important;
  }
}
// width 5% />

// width 10% <
.w-10p {
  width: 10%;
}

.w-sm-10p {
  @include media-breakpoint-up(sm) {
    width: 10% !important;
  }
}

.w-max-10p {
  max-width: 10%;
}

.w-max-sm-10p {
  @include media-breakpoint-up(sm) {
    max-width: 10% !important;
  }
}

.w-min-10p {
  min-width: 10%;
}

.w-min-sm-10p {
  @include media-breakpoint-up(sm) {
    max-width: 10% !important;
  }
}
// width 10% />

// width 15% <
.w-15p {
  width: 15%;
}

.w-sm-15p {
  @include media-breakpoint-up(sm) {
    width: 15% !important;
  }
}

.w-max-15p {
  max-width: 15%;
}

.w-max-sm-15p {
  @include media-breakpoint-up(sm) {
    max-width: 15% !important;
  }
}

.w-min-15p {
  min-width: 15%;
}

.w-min-sm-15p {
  @include media-breakpoint-up(sm) {
    max-width: 15% !important;
  }
}
// width 15% />

// width 18% <
.w-18p {
  width: 18%;
}

.w-sm-18p {
  @include media-breakpoint-up(sm) {
    width: 18% !important;
  }
}

.w-max-18p {
  max-width: 18%;
}

.w-max-sm-18p {
  @include media-breakpoint-up(sm) {
    max-width: 18% !important;
  }
}

.w-min-18p {
  min-width: 18%;
}

.w-min-sm-18p {
  @include media-breakpoint-up(sm) {
    max-width: 18% !important;
  }
}
// width 18% />

// width 20% <
.w-20p {
  width: 20%;
}

.w-sm-20p {
  @include media-breakpoint-up(sm) {
    width: 20% !important;
  }
}

.w-max-20p {
  max-width: 20%;
}

.w-max-sm-20p {
  @include media-breakpoint-up(sm) {
    max-width: 20% !important;
  }
}

.w-min-20p {
  min-width: 20%;
}

.w-min-sm-20p {
  @include media-breakpoint-up(sm) {
    max-width: 20% !important;
  }
}
// width 20% />

// width 22% <
.w-22p {
  width: 23%;
}

.w-sm-22p {
  @include media-breakpoint-up(sm) {
    width: 23% !important;
  }
}

.w-max-22p {
  max-width: 23%;
}

.w-max-sm-22p {
  @include media-breakpoint-up(sm) {
    max-width: 23% !important;
  }
}

.w-min-22p {
  min-width: 23%;
}

.w-min-sm-22p {
  @include media-breakpoint-up(sm) {
    max-width: 23% !important;
  }
}
// width 22% />

// width 25% <
.w-25p {
  width: 25%;
}

.w-sm-25p {
  @include media-breakpoint-up(sm) {
    width: 25% !important;
  }
}

.w-max-25p {
  max-width: 25%;
}

.w-max-sm-25p {
  @include media-breakpoint-up(sm) {
    max-width: 25% !important;
  }
}

.w-min-25p {
  min-width: 25%;
}

.w-min-sm-25p {
  @include media-breakpoint-up(sm) {
    max-width: 25% !important;
  }
}
// width 25% />

// width 30% <
.w-30p {
  width: 30%;
}

.w-sm-30p {
  @include media-breakpoint-up(sm) {
    width: 30% !important;
  }
}

.w-max-30p {
  max-width: 30%;
}

.w-max-sm-30p {
  @include media-breakpoint-up(sm) {
    max-width: 30% !important;
  }
}

.w-min-30p {
  min-width: 30%;
}

.w-min-sm-30p {
  @include media-breakpoint-up(sm) {
    min-width: 30% !important;
  }
}
// width 30% />

// width 35% <
.w-35p {
  width: 35%;
}

.w-sm-35p {
  @include media-breakpoint-up(sm) {
    width: 35% !important;
  }
}

.w-max-35p {
  max-width: 35%;
}

.w-max-sm-35p {
  @include media-breakpoint-up(sm) {
    max-width: 35% !important;
  }
}

.w-min-35p {
  min-width: 35%;
}

.w-min-sm-35p {
  @include media-breakpoint-up(sm) {
    min-width: 35% !important;
  }
}
// width 35% />

// width 40% <
.w-40p {
  width: 40%;
}

.w-sm-40p {
  @include media-breakpoint-up(sm) {
    width: 40% !important;
  }
}

.w-max-40p {
  max-width: 40%;
}

.w-max-sm-40p {
  @include media-breakpoint-up(sm) {
    max-width: 40% !important;
  }
}

.w-min-40p {
  min-width: 40%;
}

.w-min-sm-40p {
  @include media-breakpoint-up(sm) {
    min-width: 40% !important;
  }
}
// width 40% />

// width 45% <
.w-45p {
  width: 45%;
}

.w-sm-45p {
  @include media-breakpoint-up(sm) {
    width: 45% !important;
  }
}

.w-max-45p {
  max-width: 45%;
}

.w-max-sm-45p {
  @include media-breakpoint-up(sm) {
    max-width: 45% !important;
  }
}

.w-min-45p {
  min-width: 45%;
}

.w-min-sm-45p {
  @include media-breakpoint-up(sm) {
    min-width: 45% !important;
  }
}
// width 45% />

// width 48% <
.w-48p {
  width: 48%;
}

.w-sm-48p {
  @include media-breakpoint-up(sm) {
    width: 48% !important;
  }
}

.w-max-48p {
  max-width: 48%;
}

.w-max-sm-48p {
  @include media-breakpoint-up(sm) {
    max-width: 48% !important;
  }
}

.w-min-48p {
  min-width: 45%;
}

.w-min-sm-48p {
  @include media-breakpoint-up(sm) {
    min-width: 45% !important;
  }
}
// width 48% />

// width 50% <
.w-50p {
  width: 50%;
}

.w-sm-50p {
  @include media-breakpoint-up(sm) {
    width: 50% !important;
  }
}

.w-max-50p {
  max-width: 50%;
}

.w-max-sm-50p {
  @include media-breakpoint-up(sm) {
    max-width: 50% !important;
  }
}

.w-min-50p {
  min-width: 50%;
}

.w-min-sm-50p {
  @include media-breakpoint-up(sm) {
    min-width: 50% !important;
  }
}
// width 50% />

// width 55% <
.w-55p {
  width: 55%;
}

.w-sm-55p {
  @include media-breakpoint-up(sm) {
    width: 55% !important;
  }
}

.w-max-55p {
  max-width: 55%;
}

.w-max-sm-55p {
  @include media-breakpoint-up(sm) {
    max-width: 55% !important;
  }
}

.w-min-55p {
  min-width: 55%;
}

.w-min-sm-55p {
  @include media-breakpoint-up(sm) {
    min-width: 55% !important;
  }
}
// width 55% />

// width 60% <
.w-60p {
  width: 60%;
}

.w-sm-60p {
  @include media-breakpoint-up(sm) {
    width: 60% !important;
  }
}

.w-max-60p {
  max-width: 60%;
}

.w-max-sm-60p {
  @include media-breakpoint-up(sm) {
    max-width: 60% !important;
  }
}

.w-min-60p {
  min-width: 60%;
}

.w-min-sm-60p {
  @include media-breakpoint-up(sm) {
    min-width: 60% !important;
  }
}
// width 60% />

// width 65% <
.w-65p {
  width: 65%;
}

.w-sm-65p {
  @include media-breakpoint-up(sm) {
    width: 65% !important;
  }
}

.w-max-65p {
  max-width: 65%;
}

.w-max-sm-65p {
  @include media-breakpoint-up(sm) {
    max-width: 65% !important;
  }
}

.w-min-65p {
  min-width: 65%;
}

.w-min-sm-65p {
  @include media-breakpoint-up(sm) {
    min-width: 65% !important;
  }
}
// width 65% />

// width 70% <
.w-70p {
  width: 70%;
}

.w-sm-70p {
  @include media-breakpoint-up(sm) {
    width: 70% !important;
  }
}

.w-max-70p {
  max-width: 70%;
}

.w-max-sm-70p {
  @include media-breakpoint-up(sm) {
    max-width: 70% !important;
  }
}

.w-min-70p {
  min-width: 70%;
}


.w-min-sm-70p {
  @include media-breakpoint-up(sm) {
    min-width: 70% !important;
  }
}
// width 70% />

// width 75% <
.w-75p {
  width: 75%;
}

.w-sm-75p {
  @include media-breakpoint-up(sm) {
    width: 75% !important;
  }
}

.w-max-75p {
  max-width: 75%;
}

.w-max-sm-75p {
  @include media-breakpoint-up(sm) {
    max-width: 75% !important;
  }
}

.w-min-75p {
  min-width: 75%;
}

.w-min-sm-75p {
  @include media-breakpoint-up(sm) {
    min-width: 75% !important;
  }
}
// width 75% />

// width 80% <
.w-80p {
  width: 80%;
}

.w-sm-80p {
  @include media-breakpoint-up(sm) {
    width: 80% !important;
  }
}

.w-max-80p {
  max-width: 80%;
}

.w-max-sm-80p {
  @include media-breakpoint-up(sm) {
    max-width: 80% !important;
  }
}

.w-min-80p {
  min-width: 80%;
}

.w-min-sm-80p {
  @include media-breakpoint-up(sm) {
    min-width: 80% !important;
  }
}
// width 80% />

// width 85% <
.w-85p {
  width: 85%;
}

.w-sm-85p {
  @include media-breakpoint-up(sm) {
    width: 85% !important;
  }
}

.w-max-85p {
  max-width: 85%;
}

.w-max-sm-85p {
  @include media-breakpoint-up(sm) {
    max-width: 85% !important;
  }
}

.w-min-85p {
  min-width: 85%;
}

.w-min-sm-85p {
  @include media-breakpoint-up(sm) {
    min-width: 85% !important;
  }
}
// width 85% />

// width 90% <
.w-90p {
  width: 90%;
}

.w-sm-90p {
  @include media-breakpoint-up(sm) {
    width: 90% !important;
  }
}

.w-max-90p {
  max-width: 90%;
}

.w-max-sm-90p {
  @include media-breakpoint-up(sm) {
    max-width: 90% !important;
  }
}

.w-min-90p {
  min-width: 90%;
}

.w-min-sm-90p {
  @include media-breakpoint-up(sm) {
    min-width: 90% !important;
  }
}
// width 90% />

// width 95% <
.w-95p {
  width: 95%;
}

.w-sm-95p {
  @include media-breakpoint-up(sm) {
    width: 95% !important;
  }
}

.w-max-95p {
  max-width: 95%;
}

.w-max-sm-95p {
  @include media-breakpoint-up(sm) {
    max-width: 95% !important;
  }
}

.w-min-95p {
  min-width: 95%;
}

.w-min-sm-95p {
  @include media-breakpoint-up(sm) {
    min-width: 95% !important;
  }
}
// width 95% />

// width 100% <
.w-100p {
  width: 100%;
}

.w-sm-100p {
  @include media-breakpoint-up(sm) {
    width: 100% !important;
  }
}

.w-max-100p {
  max-width: 100%;
}

.w-max-sm-100p {
  @include media-breakpoint-up(sm) {
    max-width: 100% !important;
  }
}

.w-min-100p {
  min-width: 100%;
}

.w-min-sm-100p {
  @include media-breakpoint-up(sm) {
    min-width: 100% !important;
  }
}
// width 100% />

// HEIGHT PERCENTAGE

// height 0% <
.h-0p {
  height: 0%;
}

.h-sm-0p {
  @include media-breakpoint-up(sm) {
    height: 0% !important;
  }
}

.h-max-0p {
  max-height: 0%;
}

.h-max-sm-0p {
  @include media-breakpoint-up(sm) {
    max-height: 0% !important;
  }
}

.h-min-0p {
  min-height: 0%;
}

.h-min-sm-0p {
  @include media-breakpoint-up(sm) {
    min-height: 0% !important;
  }
}
// height 0% />

// height 5% <
.h-5p {
  height: 5%;
}

.h-sm-5p {
  @include media-breakpoint-up(sm) {
    height: 5% !important;
  }
}

.h-max-5p {
  max-height: 5%;
}

.h-max-sm-5p {
  @include media-breakpoint-up(sm) {
    max-height: 5% !important;
  }
}

.h-min-5p {
  min-height: 5%;
}

.h-min-sm-5p {
  @include media-breakpoint-up(sm) {
    min-height: 5% !important;
  }
}
// height 5% />

// height 10% <
.h-10p {
  height: 10%;
}

.h-sm-10p {
  @include media-breakpoint-up(sm) {
    height: 10% !important;
  }
}

.h-max-10p {
  max-height: 10%;
}

.h-max-sm-10p {
  @include media-breakpoint-up(sm) {
    max-height: 10% !important;
  }
}

.h-min-10p {
  min-height: 10%;
}

.h-min-sm-10p {
  @include media-breakpoint-up(sm) {
    min-height: 10% !important;
  }
}
// height 10% />

// height 15% <
.h-15p {
  height: 15%;
}

.h-sm-15p {
  @include media-breakpoint-up(sm) {
    height: 15% !important;
  }
}

.h-max-15p {
  max-height: 15%;
}

.h-max-sm-15p {
  @include media-breakpoint-up(sm) {
    max-height: 15% !important;
  }
}

.h-min-15p {
  min-height: 15%;
}

.h-min-sm-15p {
  @include media-breakpoint-up(sm) {
    min-height: 15% !important;
  }
}
// height 15% />

// height 20% <
.h-20p {
  height: 20%;
}

.h-sm-20p {
  @include media-breakpoint-up(sm) {
    height: 20% !important;
  }
}

.h-max-20p {
  max-height: 20%;
}

.h-max-sm-20p {
  @include media-breakpoint-up(sm) {
    max-height: 20% !important;
  }
}

.h-min-20p {
  min-height: 20%;
}

.h-min-sm-20p {
  @include media-breakpoint-up(sm) {
    min-height: 20% !important;
  }
}
// height 20% />

// height 25% <
.h-25p {
  height: 25%;
}

.h-sm-25p {
  @include media-breakpoint-up(sm) {
    height: 25% !important;
  }
}

.h-max-25p {
  max-height: 25%;
}

.h-max-sm-25p {
  @include media-breakpoint-up(sm) {
    max-height: 25% !important;
  }
}

.h-min-25p {
  min-height: 25%;
}

.h-min-sm-25p {
  @include media-breakpoint-up(sm) {
    min-height: 25% !important;
  }
}
// height 25% />

// height 30% <
.h-30p {
  height: 30%;
}

.h-sm-30p {
  @include media-breakpoint-up(sm) {
    height: 30% !important;
  }
}

.h-max-30p {
  max-height: 30%;
}

.h-max-sm-30p {
  @include media-breakpoint-up(sm) {
    max-height: 30% !important;
  }
}

.h-min-30p {
  min-height: 30%;
}

.h-min-sm-30p {
  @include media-breakpoint-up(sm) {
    min-height: 30% !important;
  }
}
// height 30% />

// height 35% <
.h-35p {
  height: 35%;
}

.h-sm-35p {
  @include media-breakpoint-up(sm) {
    height: 35% !important;
  }
}

.h-max-35p {
  max-height: 35%;
}

.h-max-sm-35p {
  @include media-breakpoint-up(sm) {
    max-height: 35% !important;
  }
}

.h-min-35p {
  min-height: 35%;
}

.h-min-sm-35p {
  @include media-breakpoint-up(sm) {
    min-height: 35% !important;
  }
}
// height 35% />

// height 40% <
.h-40p {
  height: 40%;
}

.h-sm-40p {
  @include media-breakpoint-up(sm) {
    height: 40% !important;
  }
}

.h-max-40p {
  max-height: 40%;
}

.h-max-sm-40p {
  @include media-breakpoint-up(sm) {
    max-height: 40% !important;
  }
}

.h-min-40p {
  min-height: 40%;
}

.h-min-sm-40p {
  @include media-breakpoint-up(sm) {
    min-height: 40% !important;
  }
}
// height 40% />

// height 45% <
.h-45p {
  height: 45%;
}

.h-sm-45p {
  @include media-breakpoint-up(sm) {
    height: 45% !important;
  }
}

.h-max-45p {
  max-height: 45%;
}

.h-max-sm-45p {
  @include media-breakpoint-up(sm) {
    max-height: 45% !important;
  }
}

.h-min-45p {
  min-height: 45%;
}

.h-min-sm-45p {
  @include media-breakpoint-up(sm) {
    min-height: 45% !important;
  }
}
// height 45% />

// height 50% <
.h-50p {
  height: 50%;
}

.h-sm-50p {
  @include media-breakpoint-up(sm) {
    height: 50% !important;
  }
}

.h-max-50p {
  max-height: 50%;
}

.h-max-sm-50p {
  @include media-breakpoint-up(sm) {
    max-height: 50% !important;
  }
}

.h-min-50p {
  min-height: 50%;
}

.h-min-sm-50p {
  @include media-breakpoint-up(sm) {
    min-height: 50% !important;
  }
}
// height 50% />

// height 55% <
.h-55p {
  height: 55%;
}

.h-sm-55p {
  @include media-breakpoint-up(sm) {
    height: 55% !important;
  }
}

.h-max-55p {
  max-height: 55%;
}

.h-max-sm-55p {
  @include media-breakpoint-up(sm) {
    max-height: 55% !important;
  }
}

.h-min-55p {
  min-height: 55%;
}

.h-min-sm-55p {
  @include media-breakpoint-up(sm) {
    min-height: 55% !important;
  }
}
// height 55% />

// height 60% <
.h-60p {
  height: 60%;
}

.h-sm-60p {
  @include media-breakpoint-up(sm) {
    height: 60% !important;
  }
}

.h-max-60p {
  max-height: 60%;
}

.h-max-sm-60p {
  @include media-breakpoint-up(sm) {
    max-height: 60% !important;
  }
}

.h-min-60p {
  min-height: 60%;
}

.h-min-sm-60p {
  @include media-breakpoint-up(sm) {
    min-height: 60% !important;
  }
}
// height 60% />

// height 65% <
.h-65p {
  height: 65%;
}

.h-sm-65p {
  @include media-breakpoint-up(sm) {
    height: 65% !important;
  }
}

.h-max-65p {
  max-height: 65%;
}

.h-max-sm-65p {
  @include media-breakpoint-up(sm) {
    max-height: 65% !important;
  }
}

.h-min-65p {
  min-height: 65%;
}

.h-min-sm-65p {
  @include media-breakpoint-up(sm) {
    min-height: 65% !important;
  }
}
// height 65% />

// height 70% <
.h-70p {
  height: 70%;
}

.h-sm-70p {
  @include media-breakpoint-up(sm) {
    height: 70% !important;
  }
}

.h-max-70p {
  max-height: 70%;
}

.h-max-sm-70p {
  @include media-breakpoint-up(sm) {
    max-height: 70% !important;
  }
}

.h-min-70p {
  min-height: 70%;
}

.h-min-sm-70p {
  @include media-breakpoint-up(sm) {
    min-height: 70% !important;
  }
}
// height 70% />

// height 75% <
.h-75p {
  height: 75%;
}

.h-sm-75p {
  @include media-breakpoint-up(sm) {
    height: 75% !important;
  }
}

.h-max-75p {
  max-height: 75%;
}

.h-max-sm-75p {
  @include media-breakpoint-up(sm) {
    max-height: 75% !important;
  }
}

.h-min-75p {
  min-height: 75%;
}

.h-min-sm-75p {
  @include media-breakpoint-up(sm) {
    min-height: 75% !important;
  }
}
// height 75% />

// height 80% <
.h-80p {
  height: 80%;
}

.h-sm-80p {
  @include media-breakpoint-up(sm) {
    height: 80% !important;
  }
}

.h-max-80p {
  max-height: 80%;
}

.h-max-sm-80p {
  @include media-breakpoint-up(sm) {
    max-height: 80% !important;
  }
}

.h-min-80p {
  min-height: 80%;
}

.h-min-sm-80p {
  @include media-breakpoint-up(sm) {
    min-height: 80% !important;
  }
}
// height 80% />

// height 85% <
.h-85p {
  height: 85%;
}

.h-sm-85p {
  @include media-breakpoint-up(sm) {
    height: 85% !important;
  }
}

.h-max-85p {
  max-height: 85%;
}

.h-max-sm-85p {
  @include media-breakpoint-up(sm) {
    max-height: 85% !important;
  }
}

.h-min-85p {
  min-height: 85%;
}

.h-min-sm-85p {
  @include media-breakpoint-up(sm) {
    min-height: 85% !important;
  }
}
// height 85% />

// height 90% <
.h-90p {
  height: 90%;
}

.h-sm-90p {
  @include media-breakpoint-up(sm) {
    height: 90% !important;
  }
}

.h-max-90p {
  max-height: 90%;
}

.h-max-sm-90p {
  @include media-breakpoint-up(sm) {
    max-height: 90% !important;
  }
}

.h-min-90p {
  min-height: 90%;
}

.h-min-sm-90p {
  @include media-breakpoint-up(sm) {
    min-height: 90% !important;
  }
}
// height 90% />

// height 95% <
.h-95p {
  height: 95%;
}

.h-sm-95p {
  @include media-breakpoint-up(sm) {
    height: 95% !important;
  }
}

.h-max-95p {
  max-height: 95%;
}

.h-max-sm-95p {
  @include media-breakpoint-up(sm) {
    max-height: 95% !important;
  }
}

.h-min-95p {
  min-height: 95%;
}

.h-min-sm-95p {
  @include media-breakpoint-up(sm) {
    min-height: 95% !important;
  }
}
// height 95% />

// height 100% <
.h-100p {
  height: 100%;
}

.h-sm-100p {
  @include media-breakpoint-up(sm) {
    height: 100% !important;
  }
}

.h-max-100p {
  max-height: 100%;
}

.h-max-sm-100p {
  @include media-breakpoint-up(sm) {
    max-height: 100% !important;
  }
}

.h-min-100p {
  min-height: 100%;
}

.h-min-sm-100p {
  @include media-breakpoint-up(sm) {
    min-height: 100% !important;
  }
}
// height 100% />

// PIXEL WIDTH

.min-w-120p {
  min-width: 7.5rem !important;
}

.w-max-sm-120p {
  @include media-breakpoint-up(sm) {
    max-width: 7.5rem !important;
  }
}

.w-min-sm-120p {
  @include media-breakpoint-up(sm) {
    min-width: 7.5rem !important;
  }
}

.w-150p {
  width: 9.375rem !important;
}

.w-max-sm-150p {
  @include media-breakpoint-up(sm) {
    max-width: 9.375rem !important;
  }
}

.w-min-sm-150p {
  @include media-breakpoint-up(sm) {
    min-width: 9.375rem !important;
  }
}

.w-200p {
  width: 12.5rem !important;
}

.w-max-200p {
  max-width: 12.5rem !important;
}

.w-max-sm-200p {
  @include media-breakpoint-up(sm) {
    max-width: 12.5rem !important;
  }
}

.w-min-sm-200p {
  @include media-breakpoint-up(sm) {
    min-width: 12.5rem !important;
  }
}

.w-360p {
  width: 22.5rem !important;
}

.w-max-sm-360 {
  @include media-breakpoint-up(sm) {
    max-width: 22.5rem !important;
  }
}

.w-min-sm-360 {
  @include media-breakpoint-up(sm) {
    min-width: 22.5rem !important;
  }
}

// PIXEL HEIGHT

.h-48 {
    height: 3rem;
}

.max-h-48 {
    max-height: 48px;
}

.h-sm-48 {
  @include media-breakpoint-up(sm) {
    height: 3rem !important;
  }
}

.max-h-sm-48 {
  @include media-breakpoint-up(sm) {
    max-height: 48px !important;
  }
}

.no-wrap {
  white-space: nowrap !important;
}

.w-sm-auto{
  @include media-breakpoint-down(sm) {
    width: auto !important;
  }
}

.border-sm-bottom-0 {
  @include media-breakpoint-up(sm) {
    border-bottom: 0 !important;
  }
}
